<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DataTableVue
            :isLoading="pageData.isLoading"
            :options="pageData.options"
            :data="pageData.rows"
            :header="pageData.tableHeader"
            :backValueMethod="setClickRow"
            :editValueMethod="edit"
            :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod"
            :pageData="pageData"
          ></DataTableVue>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading">
        <v-col cols="12" sm="12">
          <Pagination
            :pagination="pageData.pagination"
            :limit="pageData.rowsNumber"
            :backValueMethod="changePage"
            :isLoading="pageData.isLoading"
          ></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "../../../components/ui/EntityHeader.vue";
import DataTableVue from "../../../components/DataTable.vue";
import Pagination from "../../../components/ui/Pagination.vue";
export default {
  name: "DynamicEntity",

  data: () => ({
    pageData: {
      url: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
  }),
  components: {
    EntityHeader,
    DataTableVue,
    Pagination,
  },
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.$store.state.activeScreenCode
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.$store.state.activeScreenCode
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.$store.state.activeScreenCode
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.$store.state.activeScreenCode).url;
      this.pageData.tableHeader = [
        {
          text: "#",
          sortable: true,
          value: "id",
        },
        {
          text: this.$i18n.t("name"),
          sortable: true,
          value: "name",
        },
        {
          text: this.$i18n.t("account code"),
          sortable: true,
          value: "account_tree_id",
        },
        {
          text: this.$i18n.t("created_at"),
          sortable: true,
          value: "add_date",
        },

        { text: this.$i18n.t("actions"), value: "actions", sortable: false },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.$store.state.activeScreenCode, 5),
        delete: this.$global.CheckAction(this.$store.state.activeScreenCode, 4),
        edit: this.$global.CheckAction(this.$store.state.activeScreenCode, 3),
        restore: this.$global.CheckAction(this.$store.state.activeScreenCode, 4),
        view: this.$global.CheckAction(this.$store.state.activeScreenCode, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.$store.state.activeScreenCode, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `${this.$store.state.entityName}?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
          )
          .then((response) => {
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t(`deleted ${this.$store.state.entityName}`)
                  : this.$global.FilterPermissions(this.$store.state.activeScreenCode).sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };
              this.pageData.isLoading = false;
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      console.log("page");
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`${this.$store.state.entityName}/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`${this.$store.state.entityName}_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
  
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.$store.state.controlRoute}/` + row.id);
    },
  },
};
</script>
