<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData"
        :createBtnText="$i18n.t('add') + ' ' + this.$store.getters.activeScreen.sub_title" :getSearch="getSearch">
        <template v-slot:rightSide>
          <v-btn color="blue4" v-if="selectedRow.length > 0" @click="createInvoice" :disabled="!(sameCustomerInvoice)"
            outlined class="">{{
              $t('create invoice')
            }}</v-btn>
        </template>
      </EntityHeader>
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden">
              <v-col cols="12" class="pa-0 px-10">
                <v-tabs color="secondary" active-class="green11">
                  <v-tab class="font-weight-bold" @click="changeRoute(1)"><strong>{{
                    $t('pending delivery note')
                  }}</strong>
                  </v-tab>
                  <v-tab class="font-weight-bold" @click="changeRoute(2)"><strong>{{ $t('completed invoice') }}</strong>
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
              <v-alert type="info" class="rounded-lg mt-5" outlined
                v-if="sameCustomerInvoice == false && selectedRow.length > 0">
                {{ $t('delivery notes not for the same customer') }}
              </v-alert>
              <DynamicTable :key="tableKey" :isLoading="pageData.isLoading" deleteProperty="m_id" :data="pageData.rows"
                :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" :selectRow="tab == 1"
                :checkboxMethod="checkboxMethod" cardClasses="shadow-none">

                <template v-slot:td="{ row, header }">
                  <div v-if="header.key == 'actions'">
                    <PrintInvoice endpoint="print_sales_invoice" :screenCode="pageData.screen_code" :id="row.m_id" />
                    <v-btn @click="edit(row)" x-small icon fab>
                      <img src="@/assets/img/svg_icons/fi_eye.svg" height="16" />
                    </v-btn>
                  </div>
                  <div class="d-flex justify-center" v-if="header.key == 'proforma_invoice_id'">
                    <v-hover v-slot="{ hover }" close-delay="100" open-delay="100">
                      <v-btn color="secondary" small @click="openProforma(row)" min-width="80" :text="!hover" depressed
                        v-if="row[header.key]"> {{
                          hover ? $t('show') :
                          row[header.key] || '-' }}</v-btn>
                    </v-hover>
                  </div>

                  <v-hover v-if="header.key == 'journal_code'" v-slot="{ hover }" close-delay="50" open-delay="50">
                    <div style="width: 100%;height: 100%;">
                      <v-btn color="secondary" min-width="80" text v-if="!hover" small>
                        {{ row[header.key] || '-' }}
                      </v-btn>
                      <v-btn @click="openJournal(row)" v-if="hover" class="mx-1" x-small icon>
                        <img src="@/assets/img/svg_icons/fi_eye.svg" height="16" />
                      </v-btn>
                      <v-btn @click="printJournal(row)" class="mx-1"
                        :loading="pageData.printLoading.loading && pageData.printLoading.id == row.m_id" v-if="hover" x-small
                        icon>
                        <img src="@/assets/img/svg_icons/fi_printer.svg" height="16" />
                      </v-btn>
                    </div>
                  </v-hover>
                </template>
              </DynamicTable>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
    <DailyJournalPrint :printAction="() => print = false" :print="print" :printData="printData"
      :printStaticData="printStaticData" />
  </section>
</template>

<script>


export default {
  name: "InvoicesEntity",

  data: () => ({
    pageData: {
      screen_code: "06-008",
      url: null,
      controlRoute: "sales/invoice-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?',
      printLoading: {
        loading: false,
        id: null,
        key: 'm_id'
      },
    },
    delivery_notes: [],
    sales_invoice: [],
    selectedRow: [],
    sameCustomerInvoice: false,
    tab: 0,
    tableKey: 1,
    printData: [],
    printStaticData: Object,
    print: false,
  }),
  components: {},
  computed: {},
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    selectedRow() {
      const sameCustomer = (firstCustomer) => firstCustomer.customer_id == this.selectedRow[0].customer_id;
      this.sameCustomerInvoice = this.selectedRow.every(sameCustomer)
    },
    tab() {
      this.selectedRow = [];

      switch (+this.tab) {
        case 1:
          this.pageData.tableHeader = [
            { text: "invoice no", key: "m_id", type: 'text', classes: "" },
            { text: "date", key: "delivery_note_date", type: 'date', width: 120, classes: "" },
            { text: "customer name", key: "customer_name", type: 'text', classes: "" },
            { text: "quotation no", key: "quotation_id", type: 'text', classes: "" },
            { text: "po no", key: "purchase_order_id", type: 'text', classes: "" },
            { text: "total without vat", key: "total_without_vat", type: 'float', classes: "" },
            { text: "total vat", key: "total_vat", type: 'float', classes: "" },
            { text: "total with vat", key: "total_with_vat", type: 'float', classes: "" },
          ];
          break;
        case 2:
          this.pageData.tableHeader = [
            { text: "invoice no", key: "m_id", type: 'text', classes: "" },
            { text: "date", key: "sales_invoice_date", type: 'date', width: 120, classes: "" },
            { text: "invoice type", key: "type_title", type: 'text', classes: "" },
            { text: "customer name", key: "customer_name", type: 'text', classes: "" },
            { text: "proforma", key: "proforma_invoice_id", type: 'slot', classes: "" },
            { text: "total without vat", key: "total_without_vat", type: 'float', classes: "" },
            { text: "total vat", key: "total_vat", type: 'float', classes: "" },
            { text: "total with vat", key: "total_with_vat", type: 'float', classes: "" },
            { text: "journal", key: "journal_code", type: 'slot', classes: "", width: 80 },
            { text: "actions", key: "actions", type: 'slot', classes: "", width: 80 },
          ];
          break;


        default:
          break;
      }
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.tab = this.$store.state.activeScreen.queryParams.tab || 1;
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    changeRoute(next_tab) {
      if (+this.tab !== next_tab) {
        this.$router.push(this.pageData.url + `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search || ''}&tab=${next_tab}`)
      }
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}&tab=${this.tab}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `${+this.tab == 1 ? 'get_pending_delivery_notes' : 'sales_invoice'}${this.pageData.queryParam}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;

              this.delivery_notes = response.data.items.data;
              this.sales_invoice = response.data.items.data;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}&tab=${this.tab}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    checkboxMethod(rows) {
      this.selectedRow = rows;
      console.log("selectedRow", this.selectedRow)

    },
    createInvoice() {
      this.$router.push({
        name: "SalesInvoicesControlADD",
        params: { data: this.selectedRow },
      });
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`sales_invoice/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`sales_invoice_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {

    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.m_id);
    },
    openProforma(row) {
      window.open('/sales/proforma-control/' + row.proforma_invoice_id, '_blank')
    },
    openJournal(row) {
      window.open('/accounting/daily_journal-control/' + row.daily_journal_m_id, '_blank')
    },
    printJournal(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.m_id;
      this.$api
        .GET_METHOD(`daily_journal/${row.daily_journal_m_id}`).then((response) => {
          this.pageData.printLoading.loading = false;
          if (response.check) {
            this.printData = response.data.details;
            this.printStaticData = response.data.master;
            this.print = true;
            setTimeout(() => {
              this.printData = [];
              this.printStaticData = Object;
              this.print = false;
              this.pageData.printLoading.loading = false;
            }, 1000);
          }
        });
    },
  },
};
</script>
