<template>
  <v-card class="shadow-none" :height="noticeInvoice == false ? this.$store.state.Settings.windowSize.y - 260 : 'auto'"
    :class="{ 'scrollItems': !noticeInvoice }">
    <v-col cols="12" class="pa-0 px-2" sm="12">
      <v-row align="center">

        <!-- Loop -->
        <v-col cols="6" xl="3" :lg="$store.state.sidebarIsOpen ? 4 : 3" md="4" class="itemCol"
          v-for="(item, index) in items" :key="index">
          <!-- <v-card @click="selectItem(item)" class="pa-2 rounded-sm shadow-none">
            <v-avatar size="85" class="rounded-0 d-block mx-auto" v-lazy-container="{ selector: 'img'  }">
              <img :data-src="$store.state.endpointURL + item.image" :alt="item.name" />
            </v-avatar>
            <div class="caption font-weight-bold success--text text-end">{{item.price_with_vat || 0 | float}}</div>
            <div class="caption font-weight-bold shadow py-2 text-center">{{item.name || '-' | capitalize}}</div>
          </v-card> -->
          <button v-ripple class="item_button mt-5" @click="selectItem(item)">
            <div class="caption item_price   text-center">{{ item.price_with_vat || 0 | float }}</div>
            <div class="caption font-weight-bold pb-1 text-center">
              <small>
                {{ item.name || '-' | capitalize }}
              </small>
            </div>
          </button>

        </v-col>
        <!-- End Of loop -->

        <v-col v-if="items.length == 0" class="text-center">
          <span>{{ $t("items") | capitalize }} {{ $t("not available here") }}</span>
        </v-col>


      </v-row>
    </v-col>
  </v-card>
</template>


<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "CategoryItems",
  props: {
    backValueMethod: { type: Function },
    items: { default: [] },
    activeCategory: { default: 1 },
    disabledCategory: { default: false },
    noticeInvoice: { default: false },
    backItemMethod: { type: Function },
  },
  data: () => ({
    active: null
  }),
  computed: {
    ...mapState(["invoice", "totalPrice"]),
  },
  watch: {
    active() {
      this.backValueMethod(this.active)
    },
    activeCategory() {
      this.active = this.activeCategory
    }
  },
  mounted() {

    this.active = this.activeCategory
  },
  beforeDestroy: function () {

  },
  methods: {
    ...mapActions(["addInvoiceAction", "clearInvoice"]),
    selectItem(item) {
      this.active = item.id;
      // item.tax_percent_id = this.taxes.length > 0 ? this.taxes[0].id : null
      // item.tax_percentage = this.taxes.length > 0 ? this.taxes[0].tax_percentage : null
      // item.count = item.count || 1
      if (this.noticeInvoice) {
        item.quantity = 1
        this.backItemMethod(item);
      } else {
        this.addInvoiceAction(item).finally(() => { });
      }
    }
  },
};
</script>



<style scoped lang="scss">
.itemCol {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-width: 110px;

  &:hover {
    .item_button {
      background: #45ade2;
      color: #fff;
      border: 1px solid #45ade2 !important;
    }

    .item_price {
      background: #FFF;
      border: 1px solid #45ade2 !important;
      color: #354c72 !important;
    }
  }
}

.item_price {
  width: min-content;
  background: #45ade2;
  border: 1px solid #45ade2 !important;
  transition: 0.3s;
  margin: auto;
  margin-top: -0.8rem;
  padding: 0.2rem 0.6rem;
  border-radius: 25px;
  color: #fff !important;
}

.item_button {
  // border: 1px solid var(--primary);.
  transition: 0.3s;
  border: 1px solid #ccc !important;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  position: relative;
}

.scrollItems {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
}
</style>
