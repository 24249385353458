<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData"
        :createBtnText="$i18n.t('add') + ' ' + this.$store.getters.activeScreen.sub_title" actionBtnText="research"
        :actionBtn="!showFilter" :createBtn="!showFilter" :showDeleted="!showFilter" :actionBtnValid="true"
        actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" actionBtnIcon="mdi-filter-variant"
        :actionBtnOutline="true" actionBtnClass="blue4--text" :getSearch="getSearch">
        <template v-slot:rightSide>
          <v-btn color="blue4" v-if="selectedRow.length > 0" @click="createInvoice" :disabled="!(sameCustomerInvoice)"
            outlined class="">{{
            $t('create invoice')
            }}</v-btn>
        </template>
      </EntityHeader>
      <!-- End Of Header -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" justify="space-between" class="">
              <GenericInput type="select" :clearable="true" :lookups="customers" :value="pageData.customer_id"
                @input="pageData.customer_id = $event; getData()" :multi="false" :required="false" selected_label="name"
                label="customer" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"></GenericInput>
              <GenericInput type="select" :clearable="true" :lookups="proforma_invoices"
                :value="pageData.proforma_invoice_id" @input="pageData.proforma_invoice_id = $event; getData()"
                :multi="false" :required="false" selected_label="m_id" label="proforma invoice" selected_prop="m_id"
                :hide-details="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
              <v-col cols="auto">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed color="blue4" class="mx-1 white--text">
                  <v-icon size="18" left>mdi-filter-variant</v-icon>{{ $t("filter") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-alert type="info" class="rounded-lg mt-5" outlined
        v-if="sameCustomerInvoice == false && selectedRow.length > 0">
        {{ $t('delivery notes not for the same customer') }}
      </v-alert>
      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden shadow">
              <v-col cols="12" class="pa-0 px-10">
                <v-tabs color="secondary" active-class="green11">
                  <v-tab class="font-weight-bold" @click="tab = 1"><strong>{{ $t('pending') }}</strong></v-tab>
                  <v-tab class="font-weight-bold" @click="tab = 2"><strong>{{ $t('complete') }}</strong></v-tab>
                  <v-tab class="font-weight-bold" @click="tab = 3"><strong>{{ $t('closed') }}</strong></v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
              <DynamicTable  :isLoading="pageData.isLoading" deleteProperty="m_id" :data="pageData.rows"
                :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :selectRow="tab == 1"
                :checkboxMethod="checkboxMethod" :pageData="pageData" :deleteValueMethod="deleteMethod"
                :restoreValueMethod="restoreMethod" cardClasses="shadow-none">
                <template v-slot:actions="{ row }">
                    <PrintInvoice endpoint="print_delivery_note" :screenCode="pageData.screen_code" :id="row.m_id" />
                </template>
              </DynamicTable>

            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "DeliveryNoteEntity",

  data: () => ({
    pageData: {
      screen_code: "06-007",
      url: null,
      controlRoute: "sales/delivery_note-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      proforma_invoice_id: null,
      customer_id: null,
      queryParam: '?'
    },
    tab: 1,
    showFilter: false,
    selectedRow: [],
    customers: [],
    proforma_invoices: [],
    sameCustomerInvoice: false
  }),
  components: {},
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    selectedRow() {
      const sameCustomer = (firstCustomer) => firstCustomer.customer_id == this.selectedRow[0].customer_id;
      this.sameCustomerInvoice = this.selectedRow.every(sameCustomer)
    },
    // "pageData.proforma_invoice"(){
    //   this.getData()
    // },
    tab() {
      this.selectedRow = [];
      this.tableKey = this.tableKey + 1;
      this.getData();
      
      if (this.tab == 2) {
        this.pageData.tableHeader = [
          { text: "invoice no", key: "m_id", type: 'text', classes: "" },
          { text: "date", key: "delivery_note_date", type: 'date',width: 120, classes: "" },
          { text: "customer name", key: "customer_name", type: 'text', classes: "" },
          { text: "quotation no", key: "quotation_id", type: 'text', classes: "" },
          { text: "po no", key: "purchase_order_id", type: 'text', classes: "" },
          { text: "total without vat", key: "total_without_vat", type: 'price', classes: "" },
          { text: "total vat", key: "total_vat", type: 'price', classes: "" },
          { text: "total with vat", key: "total_with_vat", type: 'price', classes: "" },
          { text: "actions", key: "actions", type: 'actions', classes: "" },
        ];
        this.pageData.options.delete = false;
        this.pageData.options.edit = false;
      } else {
        this.pageData.options.edit = this.$global.CheckAction(this.pageData.screen_code, 3)
        this.pageData.options.delete = this.$global.CheckAction(this.pageData.screen_code, 4)
      }
    }
  },
  computed: {

  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "invoice no", key: "m_id", type: 'text', classes: "" },
        { text: "date", key: "delivery_note_date", type: 'date',width: 120, classes: "" },
        { text: "customer name", key: "customer_name", type: 'text', classes: "" },
        { text: "quotation no", key: "quotation_id", type: 'text', classes: "" },
        { text: "po no", key: "purchase_order_id", type: 'text', classes: "" },
        { text: "total without vat", key: "total_without_vat", type: 'price', classes: "" },
        { text: "total vat", key: "total_vat", type: 'price', classes: "" },
        { text: "total with vat", key: "total_with_vat", type: 'price', classes: "" },
        { text: "actions", key: "actions", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `delivery_note${this.pageData.queryParam}&proforma_invoice_id=${this.pageData.proforma_invoice_id || ''}&customer_id=${this.pageData.customer_id || ''}&status=${this.tab}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.proforma_invoices = response.data.proforma_invoices;
              this.customers = response.data.customers;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    createInvoice() {
      this.$router.push({
        name: "SalesInvoicesControlADD",
        params: { data: this.selectedRow },
      });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },

    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`delivery_note/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`delivery_note_toggle_active/${row.m_id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    checkboxMethod(rows) {
      this.selectedRow = rows;
      // this.selectedRowID ;
      console.log("selectedRow", this.selectedRow)

    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.m_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.blue4--border {
  border: 2px solid blue !important
}
</style>