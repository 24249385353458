<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <div class="sticky_headerss">


        <EntityHeader :pageData="pageData" :showSearch="false" :getData="getData" :showDeleted="false" :createBtn="false">
          <template v-slot:rightSide>
            <v-col cols="12" class="px-0">
              <v-row align="center" justify="end">

                <GenericInput type="date" :solo="true" :dense="true" classes="rounded-lg" :value="pageData.from"
                  :maxDate="pageData.to || today" @input="pageData.from = $event" label="from" :required="false"
                  :isPickerOpened="() => to_date = null" :hide-details="true" :isLoading="pageData.editIsLoading"
                  :cols="[12, 'auto', 'auto']">
                </GenericInput>
                <GenericInput type="date" :solo="true" :dense="true" classes="rounded-lg" :value="pageData.to"
                  :minDate="pageData.from" :maxDate="today" @input="pageData.to = $event" label="to" :required="false"
                  :hide-details="true" :isLoading="pageData.editIsLoading" :cols="[12, 'auto', 'auto']"></GenericInput>
                <v-col cols="12" md="auto">
                  <v-btn depressed color="primary" width="120" :height="37" :disabled="!(pageData.from && pageData.to)"
                    @click="getReports">{{
                      $t('view report')
                    }}</v-btn>
                </v-col>
                <v-col cols="12" sm="auto" v-if="$global.CheckAction(pageData.screen_code, 5)">
                  <PrintBtn :backValueMethod="print" :width="120" :height="37"
                    :loading="pageData.printLoading || pageData.printLoading" :image="false"
                    :disabled="!(pageData.rows.length > 0)" />
                </v-col>
              </v-row>
            </v-col>
          </template>
        </EntityHeader>
      </div>
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" id="trial_balance_report" v-if="!pageData.isLoading">
          <v-col cols="12" class="d-flex align-center justify-space-between" v-if="tree.length">
            <div style="width : 240px">
              <v-text-field v-model="search" :label="$i18n.t('search')" flat hide-details clearable
                clear-icon="mdi-close-circle-outline" append-icon="mdi-magnify" />
            </div>
            <v-col cols="auto" class="d-flex justify-end">
              <div class="text-center font-weight-bold caption" style="width : 240px">
                {{ $t('first duration balance') }}
              </div>
              <div class="text-center font-weight-bold caption" style="width : 240px">
                {{ $t('balance in duration') }}
              </div>
              <div class="text-center font-weight-bold caption" style="width : 240px">
                {{ $t('end duration balance') }}
              </div>
            </v-col>
          </v-col>
          <v-divider />
          <!--  -->
          <v-col cols="12" class="d-flex justify-space-between" v-if="tree.length">
            <div class="font-weight-bold gray5--text   caption" style="width : 120px">
              {{ $t('accounts') }} :
            </div>
            <div class="d-flex justify-end">
              <div class="text-center font-weight-bold gray5--text   caption" style="width : 120px">
                {{ $t('debit') }}
              </div>
              <div class="text-center font-weight-bold gray5--text   caption" style="width : 120px">
                {{ $t('credit') }}
              </div>
              <div class="text-center font-weight-bold gray5--text   caption" style="width : 120px">
                {{ $t('debit') }}
              </div>
              <div class="text-center font-weight-bold gray5--text   caption" style="width : 120px">
                {{ $t('credit') }}
              </div>
              <div class="text-center font-weight-bold gray5--text   caption" style="width : 120px">
                {{ $t('debit') }}
              </div>
              <div class="text-center font-weight-bold gray5--text   caption" style="width : 120px">
                {{ $t('credit') }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" v-if="tree.length == 0">
            <v-alert outlined type="info" small>
              <v-row justify="space-between" align="center">
                <v-col cols="auto">
                  {{ $t('please enter date range to view report') }}
                </v-col>
                <v-col cols="auto">
                  <v-btn depressed small @click="showReportForThisMonth" text class="text-decoration-underline"
                    color="secondary">
                    {{ $t('view report for this month') }}</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-treeview open-on-click dense :items="filter.length ? filter : tree" :search="search" :open.sync="open"
            item-key="account_tree_id">
            <template v-slot:label="{ item }">
              <v-col cols="12">
                <v-row justify="space-between" align="center" :class="item.account_type == 1 ? '' : 'child'"
                  @click.self="openAccountStatement(item)">
                  <v-col cols="auto" class="py-0 d-flex">
                    <div :class="item.account_type == 1 ? 'font-weight-bold caption' : 'caption '"
                      @click="openAccountStatement(item)">
                      {{ item.account_code }} - {{ item.name }}
                    </div>
                    <v-btn x-small :loading="activeAccountIsLoading && activeAccount.id == item.account_tree_id"
                      color="success" v-if="item.account_type == 2" @click="editAccount(item)" icon>
                      <v-icon>mdi-note-edit-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="d-flex justify-end py-0" @click="openAccountStatement(item)">
                    <div class="text-center caption" dir="ltr" style="width : 120px"
                      :class="{ 'error--text': item['first_debit'] < 0 }">
                      {{ item.first_debit | nonZero }}
                    </div>
                    <div class="text-center caption" dir="ltr" style="width : 120px"
                      :class="{ 'error--text': item['first_credit'] < 0 }">
                      {{ item.first_credit | nonZero }}
                    </div>
                    <v-divider vertical />
                    <div class="text-center caption" dir="ltr" style="width : 120px"
                      :class="{ 'error--text': item['actions_debit'] < 0 }">
                      {{ item.actions_debit | nonZero }}
                    </div>
                    <div class="text-center caption" dir="ltr" style="width : 120px"
                      :class="{ 'error--text': item['actions_credit'] < 0 }">
                      {{ item.actions_credit | nonZero }}
                    </div>
                    <v-divider vertical />
                    <div class="text-center caption" dir="ltr" style="width : 120px"
                      :class="{ 'error--text': item['final_debit'] < 0 }">
                      {{ item.final_debit | nonZero }}
                    </div>
                    <div class="text-center caption" dir="ltr" style="width : 120px"
                      :class="{ 'error--text': item['final_credit'] < 0 }">
                      {{ item.final_credit | nonZero }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-treeview>
          <!--  -->
          <v-divider class="mt-4" v-if="tree.length" />
          <v-col cols="12" class="d-flex justify-space-between " v-if="tree.length">
            <div class="text-center font-weight-bold gray2--text caption">
              {{ $t('total') }}
            </div>
            <div class="d-flex justify-end">
              <div class="text-center font-weight-bold caption" dir="ltr" style="width : 120px"
                :class="{ 'error--text': totalCalculations.first_debit < 0 }">
                {{ totalCalculations.first_debit | nonZero }}
              </div>
              <div class="text-center font-weight-bold caption" dir="ltr" style="width : 120px"
                :class="{ 'error--text': totalCalculations.first_credit < 0 }">
                {{ totalCalculations.first_credit | nonZero }}
              </div>
              <div class="text-center font-weight-bold caption" dir="ltr" style="width : 120px"
                :class="{ 'error--text': totalCalculations.actions_debit < 0 }">
                {{ totalCalculations.actions_debit | nonZero }}
              </div>
              <div class="text-center font-weight-bold caption" dir="ltr" style="width : 120px"
                :class="{ 'error--text': totalCalculations.actions_credit < 0 }">
                {{ totalCalculations.actions_credit | nonZero }}
              </div>
              <div class="text-center font-weight-bold caption" dir="ltr" style="width : 120px"
                :class="{ 'error--text': totalCalculations.final_debit < 0 }">
                {{ totalCalculations.final_debit | nonZero }}
              </div>
              <div class="text-center font-weight-bold caption" dir="ltr" style="width : 120px"
                :class="{ 'error--text': totalCalculations.final_credit < 0 }">
                {{ totalCalculations.final_credit | nonZero }}
              </div>
            </div>
          </v-col>

        </v-col>
        <v-col cols="12" class="mt-16" v-else>
          <AnimatedLoading classes="pt-2  pa-5" :isLoading="pageData.isLoading" height="100" :hideText="false" />
        </v-col>
      </v-row>
      <v-dialog v-model="editAccountDialog" width="700">
        <v-card>
          <AccountingTreeControl :key="activeAccount" selectionType="edit" :cost_centers="cost_centers"
            :item="activeAccount" :backValueMethod="() => editAccountDialog = false" />
        </v-card>
      </v-dialog>
    </v-container>
    <!-- <TrialBalanceFinalTotals  v-if="tree.length" :computedItems="totalCalculations" :isLoading="pageData.isLoading" /> -->
  </section>
</template>

<script>
import AccountingTreeControl from '../../Codes/AccountingTree/AccountingTreeControl.vue';
// import TrialBalanceFinalTotals from './TrialBalanceFinalTotals.vue';


export default {
  name: "TrialBalanceEntity",

  data: () => ({
    pageData: {
      screen_code: "02-005",
      url: null,
      controlRoute: "accounting/trial_balance_report-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      from: null,
      to: null,
      account_id: null,
      account_tree: null,
      first: [],
      printLoading: false
    },
    cost_centers: [],
    groupHeader: [],
    accounts: [],
    tree: [],
    open: [],
    search: null,
    editAccountDialog: false,
    activeAccount: Object,
    activeAccountIsLoading: false,
    today: null,
  }),
  components: {
    AccountingTreeControl,
    // TrialBalanceFinalTotals,
  },
  computed: {
    filter() {
      if (this.search) {
        let filter = this.pageData.rows.filter((obj) => {
          return String(obj['name']).startsWith(this.search)
        })
        const nest = (items, account_tree_id = 0, link = 'parent_id') =>
          items.filter(item => item[link] === account_tree_id).map(item => ({ ...item, children: nest(items, item.account_tree_id) }));
        let nested_tree = nest(filter)
        return nested_tree.length ? nested_tree : filter
      } else {
        return this.tree || []
      }
    },
    totalCalculations() {
      return {
        first_debit: this.$global.ARRAY_REDUCE(this.pageData.rows, "first_debit") || 0,
        first_credit: this.$global.ARRAY_REDUCE(this.pageData.rows, "first_credit") || 0,
        actions_debit: this.$global.ARRAY_REDUCE(this.pageData.rows, "actions_debit") || 0,
        actions_credit: this.$global.ARRAY_REDUCE(this.pageData.rows, "actions_credit") || 0,
        final_debit: this.$global.ARRAY_REDUCE(this.pageData.rows, "final_debit") || 0,
        final_credit: this.$global.ARRAY_REDUCE(this.pageData.rows, "final_credit") || 0,
      }
    }
  },
  watch: {
    editAccountDialog() {
      if (!this.editAccountDialog) {
        this.activeAccount = Object;
        this.getReports(false);
      }
    }
  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;

      this.pageData.tableHeader = [
        { text: "account number", key: "account_code", width: '130', type: 'slot', classes: "" },
        { text: "account name", key: "name", type: 'text', classes: "" },
        { text: "debit", print_text: 'first duration balance debit', key: "first_debit", type: 'float', classes: "" },
        { text: "credit", print_text: 'first duration balance credit', key: "first_credit", type: 'float', classes: "" },
        { text: "debit", print_text: 'balance in duration debit', key: "actions_debit", type: 'float', classes: "" },
        { text: "credit", print_text: 'balance in duration credit', key: "actions_credit", type: 'float', classes: "" },
        { text: "debit", print_text: 'end duration balance debit', key: "final_debit", type: 'float', classes: "" },
        { text: "credit", print_text: 'end duration balance credit', key: "final_credit", type: 'float', classes: "" },
      ];

      this.groupHeader = [
        { text: " ", colspan: 2, classes: "" },
        { text: "first duration balance", colspan: 2, type: 'text', classes: "" },
        { text: "balance in duration", colspan: 2, type: 'text', classes: "" },
        { text: "end duration balance", colspan: 2, type: 'text', classes: "" },
      ];

      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getData() {
      this.pageData.isLoading = true;
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `trial_balance_report`
          )
          .then((response) => {
            if (response.check) {
              this.accounts = response.data.account_trees;
              this.pageData.isLoading = false;
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getReports(loading = true) {
      this.pageData.isLoading = loading;
      const body = {
        from: this.pageData.from,
        to: this.pageData.to,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`trial_balance_report`, body, false)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            const nest = (items, account_tree_id = 0, link = 'parent_id') =>
              items.filter(item => item[link] === account_tree_id).map(item => ({ ...item, children: nest(items, item.account_tree_id) }));
            this.tree = nest(response.data.report);
            this.pageData.rows = response.data.report;
            this.$store.state.sidebarIsOpen = true;
          }
        });
    },
    print(type) {
      this.pageData.printLoading = true;
      const body = {
        from: this.pageData.from,
        to: this.pageData.to,
        type: type
      }

      this.$api
        .DOWNLOAD_METHOD_BODY(`trial_balance_report`, body, `trial_balance_report.${type}`)
        .then((response) => {
          this.pageData.printLoading = false;
          if (response.check) {
            // this.pageData.rows = response.data.report;
          }
        });
    },
    editAccount(row) {
      this.activeAccount.id = row.account_tree_id;
      this.activeAccountIsLoading = true;
      this.$api
        .GET_METHOD(`account_tree/${row.account_tree_id}`)
        .then((response) => {
          this.activeAccountIsLoading = false;
          if (response.check) {
            this.activeAccount = response.data.item;
            this.cost_centers = response.data.cost_centers || [];
            this.editAccountDialog = true;
          }
        });
    },
    openAccountStatement(row) {
      if (row.account_type == 2)
        window.open(`/accounting/account_statment_anal_report?account_tree_id=${row.account_tree_id}&from_date=${this.pageData.from}&to_date=${this.pageData.to}`, '_blank')
    },
    showReportForThisMonth() {
      this.pageData.to = this.$global.GetCurrentDate();
      this.pageData.from = this.$global.GetFirstDayOfMonth();
      this.getReports();
    }
  },
};
</script>

<style lang="scss" scoped>
.child {
  &:hover {
    .caption {
      color: var(--secondary);
      text-decoration: underline;
    }
  }
}
</style>