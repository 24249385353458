<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" createBtnText="save" :form="form" :save="save" :valid="valid"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <div class="pa-3 rounded">
            <v-form ref="form" v-model="valid" class="pa-0">
               <v-row>
                  <v-col cols="12" lg="8">
                     <v-card class="backgroundW shadow pa-5 rounded-lg">
                        <v-row align="center">
                           <GenericInput type="date" :value="form.action_date" @input="form.action_date = $event"
                              label="movement date" :disabled="false" :required="true"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                           <GenericInput type="number" :value="form.document_number"
                              @input="form.document_number = $event" label="the bond number" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                           <GenericInput type="number" :value="form.id" @input="form.id = $event" label="movement code"
                              :disabled="true" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"
                              v-if="pageData.isEdit == true"></GenericInput>


                           <GenericInput type="select"
                              :lookups="[{ id: 1, name: $i18n.t('account') }, { id: 2, name: $i18n.t('supplier') }, { id: 3, name: $i18n.t('customer') }]"
                              :value="form.from_type" @input="form.from_type = $event" label="the entity" :multi="false"
                              :required="true" selected_label="name" :disabled="false" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                           <GenericInput type="select" v-if="form.from_type == 1" :lookups="account_trees"
                              :value="form.from_account_tree_id" @input="form.from_account_tree_id = $event"
                              label="account" :multi="false" :required="true" selected_label="name" :disabled="false"
                              selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                           </GenericInput>



                           <v-col cols="6" class="position-relative pa-0" v-if="form.from_type == 3">
                              <GenericInput type="select" :lookups="customers" :value="form.from_customer_id"
                                 @input="form.from_customer_id = $event" label="customer" :multi="false"
                                 :required="true" selected_label="name" :disabled="false" selected_prop="id"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>

                              <v-btn icon fab x-small class="lockupsAddButton" @click="addCustomerDialog = true"
                                 color="success">
                                 <v-icon>mdi-plus</v-icon>
                              </v-btn>
                           </v-col>


                           <GenericInput type="select" v-if="form.from_type == 3" :lookups="sales_agents"
                              :value="form.sales_agent_id" @input="form.sales_agent_id = $event" label="sales agent"
                              :multi="false" :required="true" selected_label="name" :disabled="false" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                           <GenericInput type="select" v-if="form.from_type == 2" :lookups="purchase_agents"
                              :value="form.purchases_agent_id" @input="form.purchases_agent_id = $event"
                              label="purchase agent" :multi="false" :required="true" selected_label="name"
                              :disabled="false" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 6]"></GenericInput>

                           <GenericInput type="select" v-if="form.from_type == 2" :lookups="purchases_vendors"
                              :value="form.purchases_vendor_id" @input="form.purchases_vendor_id = $event"
                              label="purchase vendor" :multi="false" :required="true" selected_label="name"
                              :disabled="false" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 6]"></GenericInput>


                           <GenericInput type="float" :value="form.amount" @input="form.amount = $event" label="value"
                              :disabled="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                           </GenericInput>

                           <v-col cols="12" class="pa-0 my-3">
                              <v-sheet class="px-3 py-1 shadow" color="#CBCBCB">
                                 {{ $t('the value in Arabic') }} :
                                 <span v-if="form.amount >= 1">
                                    {{ form.amount | tafqeet }}
                                 </span>
                              </v-sheet>
                           </v-col>
                           <v-col cols="12">
                              <v-sheet class="px-3 row py-1 shadow" color="#F4F5F7">
                                 <GenericInput type="textarea" :value="form.description"
                                    @input="form.description = $event" label="description" :disabled="false"
                                    :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                 </GenericInput>


                                 <v-col cols="12" lg="6" class="d-flex align-center" v-if="form.from_type == 1">
                                    <GenericInput type="checkbox" :value="form.invoice_type" selected_label="name"
                                       selected_prop="id" @input="form.type = $event" label="payment advance"
                                       :disabled="false" :required="false" :isLoading="pageData.editIsLoading"
                                       :cols="['auto', 'auto', 'auto']"></GenericInput>
                                 </v-col>

                              </v-sheet>
                           </v-col>
                        </v-row>
                     </v-card>
                  </v-col>
                  <v-col cols="12" lg="4">
                     <v-card class="backgroundW shadow pa-5 rounded-lg">
                        <v-row align="center">

                           <GenericInput type="checkbox" :value="linked" @input="linked = $event"
                              label="linked to a cost center?" :disabled="false" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>

                           <GenericInput type="select" v-if="linked" :lookups="cost_centers"
                              :value="form.cost_center_id" @input="form.cost_center_id = $event" label="cost center"
                              :multi="false" :required="true" selected_label="name" :disabled="false" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>

                           <v-col cols="12" lg="6" v-if="pageData.isEdit">
                              <v-btn :disabled="!(form.daily_journal_id)"
                                 style="background: linear-gradient(to bottom, #eee,  #ccc, #bbb, #aaa);" small block
                                 @click="showVoucher" depressed>{{ $t('Show Entry') }}</v-btn>
                           </v-col>
                           <!-- <v-col cols="12" lg="6" v-if="pageData.isEdit">
                              <v-btn small color="secondary" block @click="attachmentDialog = true" width="120"
                                 depressed>
                                 {{ form.attach_ext ? $t('download/edit attachment') : $t('add attachment') }}
                              </v-btn>
                           </v-col> -->
                        </v-row>
                     </v-card>
                     <v-card class="backgroundW shadow pa-5 rounded-lg mt-3">
                        <v-row align="center">
                           <GenericInput type="select"
                              :lookups="[{ name: $i18n.t('account'), id: 1 }, { name: $i18n.t('treasury'), id: 2 }, { name: $i18n.t('transfer'), id: 3 }, { name: $i18n.t('cheq'), id: 4 }]"
                              :value="form.to_type" @input="form.to_type = $event" label="payment method" :multi="false"
                              :required="true" selected_label="name" :disabled="false" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                           <GenericInput type="select" v-if="form.to_type == 1" :lookups="account_trees"
                              :value="form.to_account_tree_id" @input="form.to_account_tree_id = $event" label="account"
                              :multi="false" :required="true" selected_label="name" :disabled="false" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                           <GenericInput type="select" v-if="form.to_type == 2" :lookups="treasuries"
                              :value="form.to_treasury_id" @input="form.to_treasury_id = $event" label="treasury"
                              :multi="false" :required="true" selected_label="name" :disabled="false" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                           <GenericInput type="number" v-if="form.to_type == 4" :lookups="bank"
                              :value="form.cheque_number" @input="form.cheque_number = $event" label="cheq number"
                              :required="true" :disabled="false" :isLoading="pageData.editIsLoading"
                              :cols="[12, 12, 12]"></GenericInput>
                           <GenericInput type="select" v-if="form.to_type == 3 || form.to_type == 4" :lookups="bank"
                              :value="form.to_bank_id" @input="form.to_bank_id = $event" label="bank" :multi="false"
                              :required="true" selected_label="name" :disabled="false" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                           <GenericInput type="date" v-if="(form.to_type == 3 || form.to_type == 4) && form.to_bank_id"
                              :value="form.due_date" @input="form.due_date = $event" label="due date" :required="true"
                              :disabled="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                        </v-row>
                     </v-card>
                  </v-col>
               </v-row>
            </v-form>
         </div>
         <!-- End Form -->

         <!-- Table -->
         <v-col cols="12" sm="12" v-if="pageData.selectedBills.length > 0">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-simple-table>
                  <template v-slot:default>
                     <thead>
                        <tr>
                           <th>{{ $t('invoice number') }}</th>
                           <th>{{ $t('invoice date') }}</th>
                           <th>{{ $t('due date') }}</th>
                           <th>{{ $t('value') }}</th>
                           <th>{{ $t('previously paid') }}</th>
                           <th>{{ $t('residual') }}</th>
                           <th>{{ $t('the amount paid') }}</th>
                           <th>{{ $t('delete') }}</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr v-for="(row, index) in pageData.selectedBills" :key="index">
                           <td>{{ row.invoice_number }}</td>
                           <td>{{ row.invoice_date }}</td>
                           <td>{{ row.due_date }}</td>
                           <td>{{ row.value }}</td>
                           <td>{{ row.previously_paid }}</td>
                           <td>{{ row.residual }}</td>
                           <td>{{ row.paid }}</td>

                           <td>
                              <v-btn elevation="0" @click="removeLine(index)" x-small color="transparent">
                                 <v-icon color="error">mdi-minus-box</v-icon>
                              </v-btn>
                              <v-btn icon class="mx-1" color="primary" @click="payBillsDialog = true">
                                 <v-icon>mdi-square-edit-outline</v-icon>
                              </v-btn>
                           </td>
                        </tr>
                     </tbody>
                     <tfoot>
                        <tr>
                           <th colspan="1">{{ $t('total') }}</th>
                           <th colspan="2"></th>
                           <th colspan="1">
                              {{ totalValue }}
                              <small class="text--disabled">{{ $t('ryal') }}</small>
                           </th>
                           <th colspan="1">
                              {{ totalPreviouslyPaid }}
                              <small class="text--disabled">{{ $t('ryal') }}</small>
                           </th>
                           <th colspan="1">
                              {{ totalResidual }}
                              <small class="text--disabled">{{ $t('ryal') }}</small>
                           </th>
                           <th colspan="1">
                              {{ totalPaid }}
                              <small class="text--disabled">{{ $t('ryal') }}</small>
                           </th>
                           <th colspan="1"></th>
                        </tr>
                     </tfoot>
                  </template>
               </v-simple-table>
            </v-card>
         </v-col>
         <!-- End Table -->

         <!-- Attachments -->
         <v-col cols="12" v-if="pageData.isEdit">
            <v-card class="shadow px-3">
               <v-col cols="12" class="d-flex justify-end">
                  <v-btn color="secondary" depressed @click="attachmentDialog = true" :loading="attachDownloadLoading">
                     {{ $t('add attachment') }}
                  </v-btn>
                  <v-btn color="primary" outlined class="blue12 mx-2" v-if="pageData.isEdit"
                     :disabled="(attachments.length == 0)" @click="downloadAllAttachment" depressed
                     :loading="attachDownloadLoading">
                     {{ $t('download all attachment') }}
                     <v-icon size="20" right>mdi-download</v-icon>
                  </v-btn>
               </v-col>
               <div class="py-4">
                  <v-row align="center">
                     <v-col cols="12" md="6" lg="4" v-for="(attach, index) in attachments" :key="index">
                        <v-hover v-slot="{ hover }">
                           <v-card height="280" class="overflow-hidden rounded-lg shadow pa-2">
                              <div class=" my-2" :class="{ 'opacity-015': hover }">
                                 <div v-lazy-container="{ selector: 'img' }"
                                    v-if="attach.file_ext == 'jpeg' || attach.file_ext == 'jpg' || attach.file_ext == 'png' || attach.file_ext == 'gif' || attach.file_ext == 'svg'">
                                    <img class="d-block ma-auto" style="height: 200px;" :key="index"
                                       :data-src="String(attach.file_path).substring(0, 10) == 'data:image' ? attach.file_path : $api.serverUrl + attach.file_path"
                                       alt="qarat" />
                                 </div>

                                 <div v-else>
                                    <img v-if="attach.file_ext !== 'pdf'"
                                       :src="require(`@/assets/img/png/files/${attach.file_ext}.png`)" height="200"
                                       class="d-block ma-auto" alt="qarat" />
                                    <div v-if="attach.file_ext == 'pdf'">
                                       <iframe width='100%' height='200'
                                          :src="String(attach.file_path).substring(0, 20) == 'data:application/pdf' ? attach.file_path : $api.serverUrl + attach.file_path" />
                                    </div>

                                 </div>
                              </div>
                              <div class="text-center subtitle-1" :class="{ 'd-none': hover }">
                                 {{ attach.file_name }}</div>

                              <div class="absolute-center justify-end px-5" :class="{ 'd-none': !hover }">
                                 <v-btn icon color="primary" class="mx-3" large v-if="pageData.isEdit"
                                    @click="downloadAttachment(attach)" :loading="attachDownloadLoading">
                                    <v-icon size="40">mdi-download</v-icon>
                                 </v-btn>
                                 <v-btn icon color="error" class="mx-3" large @click="deleteAttachment(attach, index)"
                                    :loading="attachDownloadLoading">
                                    <v-icon size="40">mdi-trash-can-outline</v-icon>
                                 </v-btn>
                              </div>
                           </v-card>
                        </v-hover>
                     </v-col>
                  </v-row>
                  <v-col v-if="attachments.length == 0" cols="12">
                     <v-alert type="info" outlined dense>
                        {{ $t('no attachment') }}
                     </v-alert>
                  </v-col>
               </div>
            </v-card>
         </v-col>

         <BillsSelectionVue v-if="payBillsDialog == true" :dialog="payBillsDialog" :defaultValues="pageData.bills"
            :selected="pageData.selectedBills" :type="form.type" :paid="form.value" :backValueMethod="setSelections">
         </BillsSelectionVue>

         <AttachmentDialog :dialog="attachmentDialog" entityName="receipt voucher" dataKey="id"
            :data="form" uploadResource="add_receipt_voucher_attach" downloadResource="download_receipt_voucher_attach"
            :returnMethod="(reload) => { attachmentDialog = false; reload ? getData() : null }" :multiPart="true" />

         <AddLockupsDialog entityName="customer" endPoint="customer" :enablePhone="true" :dialog="addCustomerDialog"
            :closeMethod="() => { addCustomerDialog = false }" :saveMethod="saveCustomer" />

         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import BillsSelectionVue from "./BillsSelection.vue";
export default {
   name: "ReceiptVoucherControl",

   data: () => ({
      pageData: {
         screen_code: "02-007",
         url: null,
         controlRoute: "/accounting/receipt_voucher-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: false,
         isEdit: false,
         editIsLoading: false,
         bills: [],
         selectedBills: [],
      },
      addCustomerDialog: false,
      attachmentDialog: false,
      payBillsDialog: false,
      valid: false,
      account_trees: [],
      cost_centers: [],
      customers: [],
      suppliers: [],
      delegates: [],
      treasuries: [],
      sales_agents: [],
      purchase_agents: [],
      purchases_vendors: [],
      bank: [],
      modalKey: 1,
      linked: 0,
      attachDownloadLoading: false,
      attachments: [],
      form: {
         document_number: null,
         description: null,
         action_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
         cost_center_id: null,
         amount: null,
         delegate_id: null,
         invoice_type: 1,
         from_type: 1,
         from_account_tree_id: 0,
         from_supplier_id: 0,
         from_customer_id: 0,
         to_type: null,
         to_account_tree_id: 0,
         to_treasury_id: 0,
         to_bank_id: 0,
         due_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
         cheque_number: null
      },
   }),
   watch: {
      linked() {
         if (this.linked == 0) {
            this.form.cost_center_id = null
         }
      },
      'form.from_type'() {
         if (!this.pageData.isEdit) {
            this.form.purchases_agent_id = null;
            this.form.sales_agent_id = null;
            this.form.from_account_tree_id = null;
         }
      },
   },
   components: {
      BillsSelectionVue,
   },
   computed: {
      totalResidual() {
         return this.pageData.selectedBills.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(+newValue.residual);
         }, 0)
      },
      totalPaid() {
         return this.pageData.selectedBills.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(+newValue.paid);
         }, 0)
      },
      totalValue() {
         return this.pageData.selectedBills.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(+newValue.value);
         }, 0)
      },
      totalPreviouslyPaid() {
         return this.pageData.selectedBills.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(+newValue.previously_paid);
         }, 0)
      },
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {

      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('receipt_voucher') : this.$i18n.t('receipt_voucher');
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`receipt_voucher/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.linked = response.data.data.cost_center_id ? 1 : 0;
                     this.form = response.data.data;
                     this.attachments = response.data.attachments || [];
                     this.form.from_type = Number(+response.data.data.from_type);
                     this.form.to_type = Number(+response.data.data.to_type);
                     this.form.invoice_type = Number(+response.data.data.invoice_type);
                     this.account_trees = response.data.account_trees;
                     this.cost_centers = response.data.cost_centers;
                     this.customers = response.data.customers;
                     this.suppliers = response.data.suppliers;
                     this.purchases_vendors = response.data.purchases_vendors;
                     this.sales_agents = response.data.sales_agents || [];
                     this.purchase_agents = response.data.purchases_agents || [];
                     this.bank = response.data.bank;
                     this.treasuries = response.data.treasurys;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`receipt_voucher/create`).then((response) => {
                  if (response.check) {
                     this.pageData.isLoading = false;
                     this.account_trees = response.data.account_trees;
                     this.cost_centers = response.data.cost_centers;
                     this.customers = response.data.customers;
                     this.suppliers = response.data.suppliers;
                     this.purchases_vendors = response.data.purchases_vendors;
                     this.sales_agents = response.data.sales_agents || [];
                     this.purchase_agents = response.data.purchases_agents || [];
                     this.bank = response.data.bank;
                     this.treasuries = response.data.treasurys;
                     Object.keys(response.data.defaults || Object).forEach(key => {
                        this.form[key] = response.data.defaults[key]
                     })
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      setSelections(bills, status) {
         if (status == true) {
            this.pageData.selectedBills = bills;
         }
         this.payBillsDialog = false;
      },
      save() {
         switch (this.form.from_type) {
            case 1:
               this.form.from_supplier_id = 0;
               this.form.from_customer_id = 0;
               break;

            case 2:
               this.form.from_account_tree_id = 0;
               this.form.from_customer_id = 0;
               break;

            case 3:
               this.form.from_account_tree_id = 0;
               this.form.from_supplier_id = 0;
               break;

            default:
               break;
         }
         switch (this.form.to_type) {
            case 1:
               this.form.to_treasury_id = 0;
               this.form.to_bank_id = 0;
               break;

            case 2:
               this.form.to_account_tree_id = 0;
               this.form.to_bank_id = 0;
               break;

            case 3:
               this.form.to_treasury_id = 0;
               this.form.to_account_tree_id = 0;
               break;

            default:
               break;
         }
         console.log("my form", this.form);
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`receipt_voucher/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.printReport(this.form.id)
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`receipt_voucher`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.printReport(response.data.id)
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`receipt_voucher/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      showVoucher() {
         this.pageData.printLoading = true;
         const src = `export_journal_data/${this.form.daily_journal_id}?type=pdf`;
         this.$api.DOWNLOAD_METHOD(src, `${this.$i18n.t('receipt_voucher')}_${this.form.daily_journal_id}.pdf`).then(() => {
            this.pageData.printLoading = false;
         })
      },
      removeLine(index) {
         console.log("index", index);
         this.pageData.selectedBills.splice(index, 1);
         // this.totalResidual = this.pageData.selectedBills.reduce((oldValue, newValue) => {
         //    return Number(+oldValue) + Number(+newValue.residual);
         // }, 0);
         // this.totalPaid = this.pageData.selectedBills.reduce((oldValue, newValue) => {
         //    return Number(+oldValue) + Number(+newValue.paid);
         // }, 0);
         // this.totalPreviouslyPaid = this.pageData.selectedBills.reduce((oldValue, newValue) => {
         //    return Number(+oldValue) + Number(+newValue.previously_paid);
         // }, 0);

      },
      saveCustomer(customer) {
         this.customers.push(customer);
         this.form.from_customer_id = customer.id;
         this.addCustomerDialog = false
      },
      printReport(id) {
         this.$api.PrintPOST('export_receipt_voucher', id, 'print').then(() => { })
      },
      downloadAttachment(attach) {
         console.log(attach);
         this.attachDownloadLoading = true
         var src = `download_receipt_voucher_attach/${attach.attachment_id}`;
         var download_file_name = `${attach.file_name}.${attach.file_ext}`
         this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
            this.attachDownloadLoading = false;
         })
      },
      deleteAttachment(attach, index) {
         if (this.pageData.isEdit) {
            console.log(attach);
            this.attachDownloadLoading = true
            this.$api.POST_METHOD(`delete_receipt_voucher_attachment/${attach.attachment_id}`).then((res) => {
               this.attachDownloadLoading = false;
               if (res.check) {
                  this.getData()
               }
            })
         } else {
            this.attachments.splice(index, 1);
         }

      },
      downloadAllAttachment() {
         this.attachments.forEach(attachment => {
            this.downloadAttachment(attachment, 0)
         });
      },
   },
};
</script>
