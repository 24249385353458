<template>
  <!-- @mousewheel="leave" -->
  <v-sheet elevation="0" class="transparent">
    <!-- <v-btn
      v-for="(list) in $store.state.screens"
      :key="list.id"
      :value="list.id"
      @mouseover="over(list.id)"
      @click="over(list.id)"
      class="mx-1 rounded-pill"
      :color="list.id == $store.state.active_menu ? 'primary' : 'gray5'"
      :outlined="list.id == $store.state.active_menu ? true : false"
      :text="list.id == $store.state.active_menu ? false : true"
      :style="list.id != $store.state.active_menu ? 'border: 1px solid transparent;' : ''"
    >
      <img v-if="list.image" :src="$api.serverUrl + list.image" :alt="list.title" />
      {{ list.title }}
      <v-icon small>mdi-chevron-down</v-icon>
    </v-btn>-->

    <!-- level one -->
    <v-menu
      bottom
      transition="scale-transition"
      offset-y
      close-on-content
      :z-index="9999999"
      :close-on-content-click="false"
      :open-on-hover="false"
      v-for="(menu, index) in menus"
      :key="menu.id"
      v-model="menu.menuStatus"
      eager
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-1 rounded-pill"
          :color="attrs['aria-expanded'] == 'true' ? 'primary' : ''"
          :outlined="attrs['aria-expanded'] == 'true' ? true : false"
          :text="attrs['aria-expanded'] == 'false' ? true : false"
          :style="attrs['aria-expanded'] == 'false' ? 'border: 1px solid transparent;' : ''"
          v-bind="attrs"
          v-on="on"
          @mouseover="hover = true"
        >
          {{ menu.title | capitalize}}
          <v-icon small>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <template v-slot:default>
        <v-list dense class="shadow">
          <AppBarRoutesCatMenu
            :backValueMethod="close"
            :hover="disableHover"
            :menuIndex="index"
            :menuList="menu"
          />
        </v-list>
      </template>

      <!-- <v-list class="shadow">
        <v-list-item
          style="width: 100%;"
          class="px-0"
          v-for="(menuList) in menu.cats"
          :key="menuList.cat_id"
        >
          <AppBarRoutesCatMenu :backValueMethod="hideMenu = true" :menuList="menuList" />
      
      
        </v-list-item>
      </v-list>-->
    </v-menu>
  </v-sheet>
</template>


<script>
import AppBarRoutesCatMenu from "./AppBarRoutesCatMenu.vue"

export default {
  name: "AppBarRoutesMenu",
  components: {
    AppBarRoutesCatMenu
  },
  computed: {

  },
  watch: {
    menus() {
      console.log('menus', this.menus)
    }
  },
  data: () => ({
    menus: [],
    hover: true,
    hideMenu: false,
  }),
  mounted() {
    this.menus = this.$store.state.screens
  },

  methods: {
    close(status, menuIndex) {
      this.hover = true;
      this.hideMenu = false;
      this.menus[menuIndex].menuStatus = false;
      // console.log('menus', this.menus)
    },
    disableHover(status) {
      this.hover = status;
    },
    over(active) {
      // console.log("over", active);
      this.$store.state.active_menu = active;
    },
    leave() {
      // console.log("leave");
      this.$store.state.active_menu = 0;
    },
  },
};
</script>



<style lang="scss" scoped>
.v-menu__content {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  //top: 3.5rem !important;
  padding-top: 0.3rem !important;
  button:focus {
    background: transparent !important;
  }
}
</style>