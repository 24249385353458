<template>
  <v-menu open-on-hover offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed color="primary" outline v-bind="attrs" :loading="loading" :width="width" :height="height" :disabled="disabled"
        v-on="on" :class="$store.state.Settings.darkTheme == true ? 'white--text '  + classes : classes">
        <v-icon dark left>mdi-cloud-print-outline</v-icon>
        <span>{{ $t(btnText) }}</span>
      </v-btn>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-title class="cursor_pointer d-flex align-center" @click="backValueMethod('pdf')" v-if="pdf">
          <img src="@/assets/img/svg/download-pdf.svg" height="17" class="mx-2" alt="Wms">
          <p class="my-0">PDF</p>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="excel">
        <v-list-item-title class="cursor_pointer d-flex align-center" @click="backValueMethod('excel')">
          <img src="@/assets/img/svg/microsoft-excel.svg" height="17" class="mx-2" alt="Wms">
          <p class="my-0">Excel</p>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="image">
        <v-list-item-title class="cursor_pointer d-flex align-center" @click="backValueMethod('image')">
          <p class="my-0">Image</p>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>


<script>
export default {
  name: "PrintBtn",
  props: {
    backValueMethod: { type: Function },
    btnText: { default: 'print' },
    width: { default: 130 },
    height: { default: 'auto' },
    pdf: { default: true },
    excel: { default: true },
    image: { default: true },
    disabled: { default: false },
    loading: { default: false },
    classes: { default: "my-2 success white--text" },
  },
  data: () => ({

  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">
.category {
  padding: 3px;
  min-width: 110px;
}
</style>
