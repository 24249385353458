<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :createBtn="true"
        :showDeleted="false" />
      <!-- End Of Header -->

      <!-- Table -->
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <DynamicTable :printURL="'export_notice_debtor'" :option="pageData.options" :isLoading="pageData.isLoading"
            :data="pageData.rows" :header="pageData.tableHeader">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'print'">
                <PrintInvoice endpoint="export_notice_debtor" :screenCode="pageData.screen_code" :id="row.m_id"
                  type="print" />
              </div>
              <div class="d-flex justify-center" v-if="header.key == 'sales_invoice_m_id'">
                <v-btn color="transparent" text class="text-decoration-underline blue--text"
                  @click="$router.push(`/sales/invoice-control/${row[header.key]}`)" depressed v-if="row[header.key]">{{
                    row[header.key] }}</v-btn>
              </div>

              <v-hover v-if="header.key == 'journal_code'" v-slot="{ hover }" close-delay="50" open-delay="50">
                <div style="width: 100%;height: 100%;">
                  <v-btn color="secondary" min-width="80" text v-if="!hover" small>
                    {{ row[header.key] || '-' }}
                  </v-btn>
                  <v-btn @click="openJournal(row)" v-if="hover" class="mx-1" x-small icon>
                    <img src="@/assets/img/svg_icons/fi_eye.svg" height="16" />
                  </v-btn>
                  <v-btn @click="printJournal(row)" class="mx-1"
                    :loading="pageData.printLoading.loading && pageData.printLoading.id == row.m_id" v-if="hover" x-small
                    icon>
                    <img src="@/assets/img/svg_icons/fi_printer.svg" height="16" />
                  </v-btn>
                </div>
              </v-hover>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <!-- End of table -->

      <!-- Pagination -->
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :pageData="pageData" :options="pageData.options"
            :limit="pageData.rowsNumber" :backValueMethod="changePage" :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
      <!-- End of Pagination -->

    </v-container>
    <DailyJournalPrint :printAction="() => print = false" :print="print" :printData="printData"
      :printStaticData="printStaticData" />
  </section>
</template>

<script>
export default {
  name: "NoticeDebtorEntity",

  data: () => ({
    pageData: {
      screen_code: "06-010",
      url: null,
      controlRoute: "sales/notice_debtor-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      date: null,
      queryParam: '?',
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
    },
    printData: [],
    printStaticData: Object,
    print: false,
    today: null,
    invoiceDate: null,
    suppliers: [],
    categories: [],
    subcategories: [],
  }),

  watch: {
    invoiceDate() {
      this.getData();
    },
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
  },
  components: {},
  computed: {},

  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "invoice number", key: "m_id", type: 'text', classes: "" },
        { text: "associated sales invoice", key: "sales_invoice_m_id", type: 'slot', classes: "" },
        { text: "customerName", key: "customer_name", type: 'text', classes: "" },
        { text: "customerMobile", key: "customer_phone", type: 'text', classes: "" },
        { text: "date", key: "invoice_date", type: 'text', classes: "" },
        { text: "journal", key: "journal_code", type: 'slot', classes: "", width: 80 },
        { text: "print", key: "print", type: 'slot', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getData() {
      this.pageData.isLoading = true;
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.$api
          .GET_METHOD(`get_notice_debtors${this.pageData.queryParam}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.isLoading = false;
              if (response.check) {
                this.pageData.rows = response.data.items.data;
                this.pageData.page = response.data.items.current_page;
                this.pageData.pagination = {
                  page: response.data.items.current_page,
                  totalPages: response.data.items.last_page,
                  per_page: response.data.items.per_page,
                  totalRows: response.data.items.total,
                };
              }
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    openJournal(row) {
      window.open('/accounting/daily_journal-control/' + row.daily_journal_m_id, '_blank')
    },
    printJournal(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.m_id;
      this.$api
        .GET_METHOD(`daily_journal/${row.daily_journal_m_id}`).then((response) => {
          this.pageData.printLoading.loading = false;
          if (response.check) {
            this.printData = response.data.details;
            this.printStaticData = response.data.master;
            this.print = true;
            setTimeout(() => {
              this.printData = [];
              this.printStaticData = Object;
              this.print = false;
              this.pageData.printLoading.loading = false;
            }, 1000);
          }
        });
    },
  },
};
</script>
