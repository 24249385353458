<template>
  <v-form ref="form" v-model="valid" class="pa-6" style=" text-align: initial;">
    <v-row align="center" justify="space-around">
      <v-col cols="5">
        <span v-if="selectionType == 'add' && parent.name">{{ $t(selectionType) + " " + $t('in') + " " + parent.name
        }}</span>
        <span v-if="selectionType == 'edit' && form.name">{{ $t(selectionType) + " " + form.name }}</span>
      </v-col>
      <v-col cols="5">
        <v-btn color="green11" class="d-block rounded-lg green2--text mi-start-auto" @click="save" :loading="isLoading"
          :disabled="!valid" :width="100" depressed>{{ $t('save') }}</v-btn>
      </v-col>
      <v-col cols="5">
        <div class="body-2">{{ $t('code') }}</div>
      </v-col>
      <GenericInput type="text" :value="form.account_code" label="" :required="false" :isLoading="false"
        :cols="[12, 5, 5]" :disabled="true" />
      <v-col cols="5">
        <div class="body-2">
          {{ $t("account type") }} :
          <span v-if="form.account_nature == 1">{{ $t("creditor") }}</span>
          <span v-if="form.account_nature == 2">{{ $t("debtor") }}</span>
        </div>
      </v-col>
      <v-col cols="5" />

      <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar" :required="true"
        :isLoading="false" :cols="[5, 5, 5]"></GenericInput>
      <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en" :required="true"
        :isLoading="false" :cols="[5, 5, 5]"></GenericInput>
      <v-col cols="4">
        <div class="body-2">{{ $t('account type') }} :</div>
      </v-col>

      <GenericInput type="radiogroup" 
        :lookups="[{ name: $i18n.t('main account'), id: 1 }, { name: $i18n.t('sub account'), id: 2 }]"
        selected_label="name" selected_prop="id" :value="form.account_type" @input="form.account_type = $event"
        label=" " :isLoading="false" :cols="[6,6, 6]"></GenericInput>

      <v-col cols="4" v-if="form.account_type == 1">
        <div class="body-2">{{ $t('number of including accounts') | capitalize }} :</div>
      </v-col>
      <GenericInput type="select" v-if="form.account_type == 1" :required="true"
        :lookups="[{ name: $i18n.t('1-9'), id: 1 }, { name: $i18n.t('1-99'), id: 2 }, { name: $i18n.t('1-999'), id: 3 }, { name: $i18n.t('1-9999'), id: 4 }]"
        selected_label="name" selected_prop="id" :value="form.level_chars" @input="form.level_chars = $event"
        label="choose from the list" :isLoading="false" :cols="[6, 6, 6]"></GenericInput>

      <v-col cols="4">
        <div class="body-2">{{ $t("cost center relation type") | capitalize }} :</div>
      </v-col>

      <GenericInput type="radiogroup"
        :lookups="[{ name: $i18n.t('relation'), id: 1 }, { name: $i18n.t('not relation'), id: 0 }]"
        selected_label="name" selected_prop="id" :value="form.cost_center" @input="form.cost_center = $event" label=" "
        :isLoading="false" :cols="[6, 6, 6]"></GenericInput>

      <GenericInput type="autocomplete" v-if="form.cost_center == 1" :lookups="cost_centers" selected_label="name"
        selected_prop="id" :value="form.cost_center_id" @input="form.cost_center_id = $event" label="account code"
        :isLoading="false" :cols="[11, 11, 11]"></GenericInput>
      <v-col cols="11" class="d-flex align-center px-0">
        <v-col cols="12" sm="auto" class="py-0 body-2">{{ $t("not protected") | capitalize }}</v-col>
        <GenericInput type="switch" :value="form.protection" @input="form.protection = $event" label=" "
          :required="false" :isLoading="false" :cols="[12, 'auto', 'auto']"></GenericInput>
        <v-col cols="12" sm="auto" class="py-0 px-0 body-2">{{ $t("protected") | capitalize }}</v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

 
<script>
export default {
  name: "AccountingTreeControl",
  data: () => ({
    valid: false,
    isLoading: false,
    form: {
      account_code: null,
      name_en: null,
      name_ar: null,
      account_type: 1,
      account_nature: null,
      protection: false,
      cost_center: null,
      cost_center_id: []
    },
    parent: {
      account_code: null,
      name_en: null,
      name_ar: null,
      account_type: 1,
      account_nature: 1,
      protection: false,
      cost_center: null,
      cost_center_id: []
    },

  }),
  props: {
    backValueMethod: { default: Function },
    selectionType: { default: "" },
    item: { default: {} },
    cost_centers: { default: {} },
  },
  components: { },
  mounted() {
    console.log(this.selectionType, this.item);

    if (this.selectionType == "edit") {
      this.form = this.item
    } else {
      this.parent = this.item;
      this.form.account_nature = this.item.account_nature
      this.form.parent_id = this.item.id
    }
  },
  methods: {
    save() {
      this.isLoading = true;
      this.form.protection = this.form.protection == 1 ? 1 : 0;
      if (this.selectionType == "add") {
        let nodeData = { ...this.form }
        delete this.form.account_code
        this.$api.POST_METHOD(`account_tree`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.backValueMethod(nodeData)
          }
        })
      } else {
        let nodeData = { ...this.form }
        let requestBody = this.form
        delete requestBody.children
        let id = requestBody.id
        delete requestBody.id
        delete requestBody.name
        delete requestBody.type
        delete requestBody.hasChild
        this.$api.PUT_METHOD(`account_tree`, id, requestBody).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.backValueMethod(nodeData)
          }
        })
      }
    }
  }

};
</script>
 