<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showSearch="false" :createBtn="false" :showDeleted="false"
        actionBtnText="research" :actionBtn="!showFilter" :actionBtnValid="true" actionBtnColor="transparent"
        :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true" actionBtnClass="blue4--text">


        <template v-slot:rightSide>
          <v-col cols="12" sm="auto">
            <PrintBtn classes="my-2 gray4 white--text" :backValueMethod="print" :width="120" :height="37"
              :loading="pageData.printLoading || pageData.printLoading" :image="false"
              :disabled="!(pageData.rows.length > 0)" />
          </v-col>
        </template>
      </EntityHeader>
      <!-- End Of Header -->


      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="">
          <v-card class="shadow pa-7">
            <v-row align="center">
              <v-col cols="6" class="py-0">
                <div class="caption gray6--text">{{ $t('date') | capitalize }}</div>
              </v-col>
            </v-row>
            <v-row align="center" class="">
              <GenericInput type="date" :isPickerOpened="() => pageData.to = null" :value="pageData.from"
                :clearable="true" :maxDate="pageData.to || today" @input="pageData.from = $event" label="from"
                :required="false" :hide-details="true" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
              </GenericInput>

              <GenericInput type="date" :value="pageData.to" :clearable="true" :minDate="pageData.from" :maxDate="today"
                @input="pageData.to = $event" label="to" :required="false" :hide-details="true"
                :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]"></GenericInput>


              <v-col cols="6" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" outlined
                  :loading="(pageData.isLoading || pageData.detailIsLoading)" @click="getReports"
                  class="mx-1 white--text">
                  {{ $t("Search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="m_id" :data="pageData.rows"
            :footerData="footerData"
            :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 320 : $store.state.Settings.windowSize.y - 220"
            :header="pageData.tableHeader" :fixedHeader="true" :option="pageData.options" :pageData="pageData"
            :showGroupHeader="false">
            <template v-slot:td="{ row, header }">
              <div class="d-flex justify-center" v-if="header.key == 'document_id'">
                <v-hover v-slot="{ hover }" close-delay="150" open-delay="150">
                  <v-btn color="primary" @click="openStatement(row)" min-width="90" :text="!hover" depressed
                    v-if="row[header.key]"> {{hover ? $t('show') :
                    row[header.key] || '-'}}</v-btn>
                </v-hover>
              </div>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
export default {
  name: "DebtAgeEntity",

  data: () => ({
    pageData: {
      screen_code: "06-016",
      url: null,
      controlRoute: "accounting/debt_age-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      from: null,
      to: null,
      account_id: null,
      account_tree: null,
      type: 1,
      first: [],
      printLoading: false
    },
    accounts: [],
    showFilter: true,
    today: null,
    footerData: [{
      journal_code: 'total',
      debit: 0,
      credit: 0,
      finalStringTotal: 0,
    }],
  }),
  watch: {
    accounts() {
      this.accounts.forEach(account => {
        account.account_name_code = account.account_code + ' - ' + account.name
      });
    },
    'pageData.rows'() {
      this.footerData[0].journal_code = this.$i18n.t('total');
      this.footerData[0].debit = this.totalDebit;
      this.footerData[0].credit = this.totalCredit;
      this.footerData[0].finalStringTotal = this.finalToTal;
    },
    'pageData.account_id'() {
      if (this.pageData.account_id && this.$router.currentRoute.query.account_tree_id) {
        this.getReports();
      }
    }
  },
  components: {},
  computed: {

    totalDebit() {
      return this.pageData.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.debit || 0);
      }, 0);
    },
    totalCredit() {
      return this.pageData.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.credit  || 0);
      }, 0);
    },
    finalToTal() {
      const total = Number(this.totalDebit - this.totalCredit).toFixed(2)
      console.log(this.$global.NumberWithCommas(total));
      if (total >= 0) {
        return this.$global.NumberWithCommas(total || 0)
      }
      else {
        return `(${this.$global.NumberWithCommas(total || 0)})`
      }
    },
  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    console.log(this.$router.currentRoute.query);
    this.pageData.from = this.$router.currentRoute.query.from_date || this.$global.GetFirstDayOfMonth();
    this.pageData.to = this.$router.currentRoute.query.to_date || this.$global.GetCurrentDate();
    this.getData();
    this.pageData.account_id = Number(this.$router.currentRoute.query.account_tree_id) || null
  },
  methods: {

    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;

      this.pageData.tableHeader = [
        { text: "transaction number", key: "document_id", type: 'slot', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "", width: '120' },
        { text: "payment deadline", key: "payment_deadline", type: 'text', classes: "" },
        { text: "balance", key: "balance", type: 'float', classes: "" },
        { text: "0-30 day", key: "debit", type: 'float', classes: "" },
        { text: "31-60 day", key: "credit", type: 'float', classes: "" },
        { text: "61-90 day", key: "credit", type: 'float', classes: "" },
        { text: "91-120 day", key: "credit", type: 'float', classes: "" },
        { text: "+120 day", key: "credit", type: 'float', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getData() {
      this.pageData.isLoading = true;
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `account_statment_anal_report`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.accounts = response.data.account_trees;
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getReports() {
      this.pageData.isLoading = true;
      const body = {
        from: this.pageData.from,
        to: this.pageData.to,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`account_statment_anal_report`, body, false)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            response.data.report = [
              {
                customer_name : 'sawy'
              }
            ]
            this.pageData.rows = response.data.report;
            this.showFilter = false
          }
        });
    },

    setClickRow() {
    },
    print(type) {
      this.pageData.printLoading = true;
      const body = {
        from: this.pageData.from,
        to: this.pageData.to,
        type: type
      }

      this.$api
        .DOWNLOAD_METHOD_BODY(`account_statment_anal_report`, body, `account_statment_anal_report.${type}`)
        .then((response) => {
          this.pageData.printLoading = false;
          if (response.check) {
            // this.pageData.rows = response.data.report;
          }
        });
    },
    openStatement(row) {
      window.open('/sales/customer_statement/' + row.document_id, '_blank')
    },
  },
};
</script>
