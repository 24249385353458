<template>
  <v-card class="shadow-none"  :height="noticeInvoice ==false ? this.$store.state.Settings.windowSize.y - 225 : 'auto'"
  :class="{'scrollItems' : !noticeInvoice}">
    <v-col cols="12" class="pa-0 py-3" sm="12">
      <v-row align="center">
        <!-- Loop -->
        <v-col cols="6" xl="3" :lg="$store.state.sidebarIsOpen ? 4 : 3" md="auto" class="category"
          v-for="(category, index) in categories" :key="index">
          <div class="light text-center rounded-sm cursor_pointer body-2 pa-3" v-on:click="selectCategory(category)"
            :class="active != category.id ? '' : 'font-weight-bold'">
            <!-- <v-icon color="#0A6ADA" size="17" :disabled="active != category.id">mdi-check-circle</v-icon> -->
            {{ category.name | capitalize }}
          </div>
        </v-col>
        <!-- End Of loop -->

        <v-col v-if="categories.length == 0" class="text-center">
          <span>{{ $t("categories") }} {{ $t("not available here") }}</span>
        </v-col>


      </v-row>
    </v-col>
  </v-card>
</template>


<script>
export default {
  name: "CategoriesBar",
  props: {
    backValueMethod: { type: Function },
    categories: { default: [] },
    activeCategory: { default: 1 },
    disabledCategory: { default: false },
    noticeInvoice: { default: false },
  },
  data: () => ({
    active: null
  }),
  computed: {

  },
  watch: {
    active() {
      this.backValueMethod(this.active)
    },
    activeCategory() {
      this.active = this.activeCategory
    }
  },
  mounted() {
    this.active = this.activeCategory
  },
  beforeDestroy: function () {

  },
  methods: {
    selectCategory(category) {
      if (this.disabledCategory == false) {
        this.active = category.id
      }
    }
  },
};
</script>



<style scoped lang="scss">
.category {
  padding: 5px;
  min-width: 110px;
   .light{
    transition: 0.3s;
   }
  &:hover .light{
    background-color:  #d7d8da !important; ;
  }
}
.scrollItems {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
}
</style>
