<template>
  <v-form ref="form" v-model="valid" class="pa-6" style="text-align: initial;">
    <v-row align="center" justify="space-around">
      <v-col cols="5">
        <span v-if="selectionType == 'add' && parent.name">{{ $t(selectionType) + " " + $t('in') + " " + parent.name
        }}</span>
        <span v-if="selectionType == 'edit' && form.name">{{ $t(selectionType) + " " + form.name }}</span>
      </v-col>
      <v-col cols="5">
        <v-btn color="green11" class="d-block rounded-lg green2--text mi-start-auto" @click="save" :loading="isLoading"
          :disabled="!valid" :width="100" depressed>{{ $t('save') }}</v-btn>
      </v-col>
      <v-col cols="5"  v-if="form.cost_center_code">
        <div class="body-2">{{ $t('code') | capitalize }}</div>
      </v-col>
      <GenericInput type="text" :value="form.cost_center_code" v-if="form.cost_center_code" :required="false"
        :isLoading="false" :cols="[5, 5, 5]" :disabled="true"></GenericInput>
      <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar" :required="true"
        :isLoading="false" :cols="[5, 5, 5]"></GenericInput>
      <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en" :required="true"
        :isLoading="false" :cols="[5, 5, 5]"></GenericInput>
      <v-col cols="5">
        <div class="body-2">{{ $t('account') | capitalize }}</div>
      </v-col>
      <GenericInput type="radiogroup" 
        :lookups="[{ name: $i18n.t('parent'), id: 1 }, { name: $i18n.t('sub-parent'), id: 2 }]" selected_label="name"
        selected_prop="id" :value="form.account_type" @input="form.account_type = $event" label=" " :isLoading="false"
        :cols="[5, 5, 5]"></GenericInput>
      <GenericInput type="select" v-if="form.account_type == 1" :required="true"
        :lookups="[{ name: $i18n.t('1-9'), id: 1 }, { name: $i18n.t('1-99'), id: 2 }, { name: $i18n.t('1-999'), id: 3 }]"
        selected_label="name" selected_prop="id" :value="form.level_chars" @input="form.level_chars = $event"
        label="number of sub cost centers" :isLoading="false" :cols="[11, 11, 11]"></GenericInput>

    </v-row>
  </v-form>
</template>

 
<script>
export default {
  name: "CostCenterControl",
  data: () => ({
    valid: false,
    isLoading: false,
    form: {
      name_en: null,
      name_ar: null,
      account_type: 1,
      account_nature: null,
      level_chars: 1,
    },
    parent: {
      cost_center_code: null,
      name_en: null,
      name_ar: null,
      account_type: 1,
      account_nature: 1,
      level_chars: null,
    },

  }),
  props: {
    backValueMethod: { type: Function },
    selectionType: { default: "" },
    item: { default: {} },
    cost_centers: { default: {} },
  },
  components: {},
  mounted() {
    if (this.selectionType == "edit") {
      this.form = this.item
    } else {
      this.parent = this.item;
      this.form.account_nature = this.item.account_nature
      this.form.parent_id = this.item.id
    }
  },
  methods: {
    save() {
      this.isLoading = true;
      this.form.protection = this.form.protection == 1 ? 1 : 0;
      if (this.selectionType == "add") {
        let nodeData = { ...this.form }
        this.$api.POST_METHOD(`cost_center`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.backValueMethod(nodeData)
          }
        })
      } else {
        let nodeData = { ...this.form }
        let requestBody = this.form
        delete requestBody.children
        let id = requestBody.id
        delete requestBody.id
        delete requestBody.name
        delete requestBody.type
        delete requestBody.hasChild
        this.$api.PUT_METHOD(`cost_center`, id, requestBody).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.backValueMethod(nodeData)
          }
        })
      }
    }
  }

};
</script>
 