import vuetify from "../plugins/vuetify";
import i18n from "../plugins/i18n";

export default {
  namespaced: true,
  state: {
    language: localStorage.getItem("language") == "en" ? "en" : "ar",
    translation: localStorage.getItem("language") == "en" ? i18n.messages.en : i18n.messages.ar,
    windowSize: { x: 0, y: 0 },
    key_clicked : null,
    isRTL: vuetify.rtl,
    darkTheme: localStorage.getItem("darkTheme") == "true" ? true : false,
    favourites: localStorage.getItem("favourites")
      ? JSON.parse(localStorage.getItem("favourites"))
      : [],
  },
  mutations: {
    DARK_THEME(state, status) {
      state.darkTheme = status;
    },
    ACTIVE_LANGUAGE(state, lang) {
      state.language = lang;
      state.translation = lang == "ar" ? i18n.messages.ar : i18n.messages.en;
      (i18n.locale = lang);
    },
    SET_KEY_CLICKED(state, key) {
      state.key_clicked = key;
    },
    UPDATE_FAVOURITES(state, favourites) {
      state.favourites = favourites;
      setTimeout(() => {
        localStorage.setItem("favourites", JSON.stringify(state.favourites));
      }, 10);
    },
  },
  actions: {
    changeLanguage({ state }) {
      if (state.language == "en") {
        // commit("ACTIVE_LANGUAGE", "ar");
        // vuetify.framework.lang.current = ar;
        localStorage.setItem("language", "ar");
        // return (vuetify.framework.rtl = true);
        window.location.reload(true);
      } else {
        // commit("ACTIVE_LANGUAGE", "en");
        // vuetify.framework.lang.current = en;
        localStorage.setItem("language", "en");
        // return (vuetify.framework.rtl = false);
        window.location.reload(true);
      }
    },
    darkTheme({ commit, state }) {
      localStorage.setItem("darkTheme", !state.darkTheme);
      commit("DARK_THEME", !state.darkTheme);
      return (vuetify.framework.theme.dark = state.darkTheme);
    },
    addFavourites({ commit, state }, favourites) {
      if (!state.favourites.some((fav) => fav.url === favourites.url)) {
        return commit("UPDATE_FAVOURITES", state.favourites.concat(favourites));
      } else {
        const removeIndex = state.favourites.findIndex(
          (fav) => fav.url === favourites.url
        );
        state.favourites.splice(removeIndex, 1);
        return commit("UPDATE_FAVOURITES", state.favourites);
      }
    },
  },
};
