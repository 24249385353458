import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// Views :-
import DashboardERP from "@/views/ERP/DashboardERP";
import HomeModule from "@/views/HomeModule/HomeModule";
import SettingsModule from "@/views/SettingsModule/SettingsModule";
import AccountingModule from "@/views/AccountingModule/AccountingModule";
import InventoryModule from "@/views/InventoryModule/InventoryModule";
import SalesModule from "@/views/SalesModule/SalesModule";
import PurchasesModule from "@/views/PurchasesModule/PurchasesModule";
import DynamicModule from "@/views/DynamicModule/DynamicModule";
import WarehouseModule from "../views/WarehouseModule/WarehouseModule.vue";
import DynamicEntity from "@/views/DynamicModule/Dynamic/DynamicEntity";
import DynamicControl from "@/views/DynamicModule/Dynamic/DynamicControl";
import Auth from "@/views/Auth/Auth";
import LoginPage from "@/views/Auth/LoginPage";
import ForgetPassword from "@/views/Auth/ForgetPassword";
import Error500 from "@/views/Errors/Error500";
import SystemUpdate from "@/views/Errors/SystemUpdate";
import Error404 from "@/views/Errors/Error404";

// Modules Children Routes :-

import { HomeModuleChildrens } from "@/router/HomeModuleRoutes";
import { SettingsModuleChildrens } from "@/router/SettingsModuleRoutes";
import { AccountingModuleChildrens } from "@/router/AccountingModuleRoutes";
import { InventoryModuleChildrens } from "@/router/InventoryModuleRoutes";
import { SalesModuleChildrens } from "@/router/SalesModuleRoutes";
import { PurchaseModuleChildrens } from "@/router/PurchasesModuleRoutes";
import { WarehouseModuleChildrens } from "@/router/WarehouseModuleRoutes";
Vue.use(VueRouter);

// Routes :-
const routes = [
  // Authorized :-
  {
    path: "/",
    component: DashboardERP,
    beforeEnter: (to, from, next) => {
      store.dispatch("checkAppVersion").then();
      store.dispatch("checkAuth").then();
      console.log("beforeEnter and checkAuth", store.state.isAuth);
      if (store.state.isAuth === true) {
        store.dispatch("getUserData").then();
        next();
      } else {
        router.push("/login").then();
      }
    },
    children: [
      {
        path: "",
        component: HomeModule,
        children: [...HomeModuleChildrens],
      },
      {
        path: "/settings",
        name: "SettingsModule",
        component: SettingsModule,
        children: [...SettingsModuleChildrens],
      },
      {
        path: "/accounting",
        name: "AccountingModule",
        component: AccountingModule,
        children: [...AccountingModuleChildrens],
      },
      {
        path: "/inventory",
        name: "InventoryModule",
        component: InventoryModule,
        children: [...InventoryModuleChildrens],
      },
      {
        path: "/sales",
        name: "SalesModule",
        component: SalesModule,
        children: [...SalesModuleChildrens],
      },
      {
        path: "/purchase",
        name: "PurchasesModule",
        component: PurchasesModule,
        children: [...PurchaseModuleChildrens],
      },
      {
        path: "/warehouse",
        name: "WarehouseModule",
        component: WarehouseModule,
        children: [...WarehouseModuleChildrens],
      },
      {
        path: "/dynamic",
        name: "DynamicModule",
        component: DynamicModule,
        children: [
          {
            path: "/dynamic/test",
            name: "DynamicEntity",
            component: DynamicEntity,
            meta: {
              screen_code: "01-010",
              entity_name: "bank",
              entity_path: "/dynamic/test",
              control_route: "/dynamic/test-control",
            },
          },
          {
            path: "/dynamic/test-control",
            name: "DynamicControl",
            component: DynamicControl,
            meta: {
              screen_code: "01-010",
              entity_name: "bank",
              entity_path: "/dynamic/test",
              control_route: "/dynamic/test-control",
            },
          },
          {
            path: "/dynamic/test-control/:id",
            name: "DynamicControlEdit",
            component: DynamicControl,
            meta: {
              screen_code: "01-010",
              entity_name: "bank",
              entity_path: "/dynamic/test",
              control_route: "/dynamic/test-control",
            },
          },
        ],
      },
    ],
  },
  // Not Authorized :-
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "/",
        name: "LoginPageAuth",
        component: LoginPage,
        meta: {
          title: "login",
        },
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
        meta: {
          title: "Forget Password",
        },
      },
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
        meta: {
          title: "login",
        },
      },
    ],
  },
  // Error Pages  :-
  {
    path: "/error500",
    name: "Error500",
    component: Error500,
  },
  {
    path: "/system_update",
    name: "SystemUpdate",
    component: SystemUpdate,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to);
  next();
});

export default router;
