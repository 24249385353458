<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :showSearch="false" :getData="getData" actionBtnText="filter"
        :showDeleted="false" :createBtn="false" :actionBtn="!showFilter" :actionBtnValid="true"
        actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true"
        actionBtnClass="blue4--text" :getSearch="getSearch" />
      <!-- End Of Header -->

      <!-- Filter -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" justify="space-between" class="">
              <GenericInput type="select" solo :clearable="true" :lookups="warehouses" :value="warehouse_id"
                @input="warehouse_id = $event" label="warehouse" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.detailIsLoading)"
                :cols="[12, 6, 4]">
              </GenericInput>
              <GenericInput type="date" :value="date" :maxDate="today" @input="date = $event" label="date"
                :required="false" :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 4]">
              </GenericInput>
              <v-col cols="auto" md="6" lg="3" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" outlined :disabled="!(warehouse_id)"
                  :loading="(pageData.isLoading || pageData.detailIsLoading)" @click="getDetails"
                  class="mx-1 white--text">{{
                      $t("Search")
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!--  -->
      <!-- Summery -->
      <!-- <div class="mt-5">
        <ControlSummery :summery="summery" v-if="warehouse_balances" />
      </div> -->
      <!-- End of Summery -->

      <v-row v-if="warehouse_logs.length > 0">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="m_id" :data="warehouse_logs"
            :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
            :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" />
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
// import ControlSummery from "@/components/ui/ControlSummery.vue";
export default {
  name: "WarehouseBalancesEntity",

  data: () => ({
    pageData: {
      screen_code: "03-011",
      url: null,
      controlRoute: "inventory/warehouse_balances-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      detailIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    showFilter: true,
    date: null,
    today: null,
    warehouse_balances: null,
    warehouse_logs: [],
    summery: [],
    warehouses: [],
    warehouse_id: null,
  }),
  components: {
    EntityHeader,
    DynamicTable,
    GenericInput,
    // ControlSummery
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    warehouse_id() {
      this.warehouse_balances = null;
      this.warehouse_logs = [];
      if (this.warehouse_id) {
        this.getDetails();
      }
    }
  },
  computed: {

  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.date = this.$global.GetCurrentDate();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "#", key: "i_id", type: 'text', classes: "" },
        { text: "item number", key: "item_id", type: 'text', classes: "" },
        { text: "item", key: "item_name", type: 'text', classes: "" },
        { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
        { text: "balance", key: "stock", type: 'text', classes: "" },

      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`warehouse_balance`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.warehouses = response.data.warehouses;
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getDetails() {
      this.pageData.detailIsLoading = true;
      this.$api.POST_METHOD(`warehouse_balance`, { warehouse_id: this.warehouse_id, date: this.date }).then((response) => {
        this.pageData.detailIsLoading = false;
        if (response.check) {
          this.warehouse_balances = response.data.warehouse_balances || null;
          response.data.items.forEach((detail, index) => {
            detail.i_id = index + 1;
          });
          this.warehouse_logs = response.data.items || [];
          // this.summery = [
          //   {
          //     label: 'name',
          //     value: response.data.warehouse_balances.name,
          //     class: '',
          //     cols: null, sm: null, md: null, lg: 6, xl: null,
          //   },
          //   {
          //     label: 'sale price',
          //     value: response.data.warehouse_balances.sale_price,
          //     class: '',
          //     cols: null, sm: null, md: null, lg: 6, xl: null,
          //   },
          //   {
          //     label: 'min sale price',
          //     value: response.data.warehouse_balances.min_sale_price,
          //     class: '',
          //     cols: null, sm: null, md: null, lg: 6, xl: null,
          //   },
          //   {
          //     label: 'type',
          //     value: response.data.warehouse_balances.item_type_name,
          //     class: '',
          //     cols: null, sm: null, md: null, lg: 6, xl: null,
          //   },
          //   {
          //     label: 'category',
          //     value: response.data.warehouse_balances.category_name,
          //     class: '',
          //     cols: null, sm: null, md: null, lg: 6, xl: null,
          //   },
          // ];
          // this.showFilter = false
        }
      });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`warehouse_balance/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`warehouse_balance_toggle_active/${row.m_id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/warehouse_balances/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.m_id);
    },
  },
};
</script>
