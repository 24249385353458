<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :hasSteps="true" :steps="steps" :activeStep="activeStep" :createBtn="false" :hideDelete="true"
            :actionBtn="pageData.isEdit" actionBtnText="next" :actionBtnColor="'accent'" :actionBtnValid="valid"
            :actionBtnClick="actionBtnClick" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="shadow pa-5 pt-7 rounded-xl" :color="activeStep == 1 ? 'backgroundW' : 'blue12'">
               <v-row align="center" v-if="activeStep == 1">
                  <v-col cols="12" class="py-0">
                     <div class="subtitle-2 gray7--text">{{ $t("driver information") | capitalize }}</div>
                  </v-col>
                  <GenericInput type="date" :value="form.outgoing_date"
                     @input="form.outgoing_date = $event" label="date" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>
                  <GenericInput type="time" :value="form.outgoing_time"
                     @input="form.outgoing_time = $event" label="time" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>
                  <GenericInput type="text" :value="form.driver_name" @input="form.driver_name = $event"
                     :placeholder="'Ex:. Mohamed Ahmed'" :icon="'mdi-account-outline'" label="driver name"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>
                  <GenericInput type="number" :value="form.driver_id" @input="form.driver_id = $event"
                     :placeholder="'Ex:. 2451122123'" :icon="'mdi-card-account-details-outline'" label="driver id"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>
                  <GenericInput type="number" :value="form.driver_phone_code" @input="form.driver_phone_code = $event"
                     icon="mdi-phone-outline" label="" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 1]"></GenericInput>
                  <GenericInput type="number" :value="form.driver_phone_number"
                     @input="form.driver_phone_number = $event" :placeholder="'Ex:. 5012345678'" label="driver phone"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]"></GenericInput>
                  <v-col cols="12" class="py-0">
                     <div class="subtitle-2 gray7--text">{{ $t("car information") | capitalize }}</div>
                  </v-col>
                  <GenericInput type="number" :value="form.car_numbers" @input="form.car_numbers = $event"
                     :icon="'mdi-car-back'" :placeholder="'Ex:. xxx xxxx'" label="car number" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]"></GenericInput>
                  <GenericInput type="text" :value="form.car_digits" @input="form.car_digits = $event"
                     :icon="'mdi-car-back'" label="car digits" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 2]"></GenericInput>
                  <GenericInput type="switch" :value="form.has_seals_number"
                     @input="form.has_seals_number = $event" label="has seals" :required="false"
                     :labelInline="false" :hideDetails="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                  </GenericInput>
                  <GenericInput type="number" v-if="form.has_seals_number" :icon="'mdi-lock-outline'"
                     :value="form.seals_number" @input="form.seals_number = $event" label="seals number"
                     :required="true" :isLoading="pageData.editIsLoading" :placeholder="'add seals number'"
                     :cols="[12, 6, 2]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "OutgoingRequestControl",
   data: () => ({
      pageData: {
         screen_code: "08-003",
         url: null,
         controlRoute: "/warehouses/incoming-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      actionBtnValid: true,
      tableRows: [],
      selectedClient: null,
      outgoing_pallets: [],
      clients: [],
      types: [],
      activeStep: 1,
      steps: [{ id: 1, name: 'Add Car' }, { id: 2, name: 'Add Pallets' }],
      form: {
         driver_name: null,
         driver_id: null,
         driver_phone_code: "966",
         driver_phone_number: null,
         car_numbers: null,
         car_digits: null,
         outgoing_date: null,
         outgoing_time: null,
         has_seals_number: null,
         seals_number: null,
         pallets: []
      },
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   watch: {
      // 'form.customer_id'(){
      //    this.selectedClient = this.$global.FilterArrayOfObject(this.clients, 'id', this.form.customer_id, 'sale_price')[0];
      // }
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$i18n.t('make outgoing');
         this.tableHeader = [
            { text: "pallet no", key: "item_id", type: 'text', classes: "" },
            { text: "addition date", key: "unit_id", type: 'text', classes: "" },
            { text: "include case", key: "price", type: 'float', classes: "" },
            { text: "delete", key: "id", type: 'delete', classes: "" },
         ];
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`outgoing/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check && response.data.data) {
                     console.log("response", response);
                      this.types = response.data.types || [];
                      this.outgoing_pallets = response.data.outgoing_pallets || [];
                     this.form = response.data.data || {
                        driver_name: null,
                        driver_id: null,
                        driver_phone_code: "966",
                        driver_phone_number: null,
                        car_numbers: null,
                        car_digits: null,
                        outgoing_date: null,
                        outgoing_time: null,
                        has_seals_number: null,
                        seals_number: null,
                        pallets: []
                     }
                     this.$refs.form.validate();
                  }else{
                     this.types = response.data.types || [];
                     this.outgoing_pallets = response.data.outgoing_pallets || [];
                      this.form = {
                        driver_name: null,
                        driver_id: null,
                        driver_phone_code: "966",
                        driver_phone_number: null,
                        car_numbers: null,
                        car_digits: null,
                        outgoing_date: null,
                        outgoing_time: null,
                        has_seals_number: null,
                        seals_number: null,
                        pallets: []
                     }
                  }
               })
            } else {
               this.$router.push('/')
            }
         }




      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`outgoing_request/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      actionBtnClick() {
         // this.$router.push(`/warehouses/outgoing_pallet-control/${this.$route.params.id}`)
         this.$router.push({
            name: "OutgoingPalletControl",
            params: { data: this.form, id: this.$route.params.id , types : this.types , outgoing_pallets : this.outgoing_pallets},
         });
      },
      addItem() {
         this.tableRows.push(this.newItem);
         this.newItem = {
            item_id: null,
            unit_id: null,
            price: null,
            count: null,
         }
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`outgoing_request/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
