<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow overflow-hidden pa-0">
               <v-col cols="12" class="pa-0 px-10" v-if="viewForm">
                  <v-tabs color="secondary" active-class="green11">
                     <v-tab class="font-weight-bold" :value="1" @click="tab = 1"><strong>{{
                        $t('customer data')
                     }}</strong></v-tab>
                     <v-tab class="font-weight-bold" :value="2" @click="tab = 2"><strong>{{
                        $t('contact with customer')
                     }}</strong></v-tab>
                     <v-tab class="font-weight-bold" :value="3" @click="tab = 3"><strong>{{
                        $t('attachments')
                     }}</strong></v-tab>
                  </v-tabs>
               </v-col>
               <v-col cols="12" class="pa-0" v-if="viewForm">
                  <v-divider></v-divider>
               </v-col>
               <!-- Summery -->
               <ControlSummery :summery="summery" :shadow="false" :loading="pageData.isLoading"
                  v-if="pageData.isEdit && !viewForm" />
               <!-- End of Summery -->
               <v-sheet class="pa-5" v-if="viewForm || tab == 3">

                  <v-tabs-items v-model="tab">
                     <v-tab-item eager :value="1">
                        <v-row align="center">
                           <v-col cols="12" class="pb-0 d-flex justify-space-between">
                              <div class="body-2 gray7--text">{{ $t('customer details') | capitalize }}</div>
                              <div class="body-2">{{ $t('customer code') | capitalize }} {{ form.id || '--' }} </div>
                           </v-col>
                           <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                              :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>

                           <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                              :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>

                           <GenericInput type="radiogroup" :labelInline="false"
                              :lookups="[{ id: 1, name: $i18n.t('person') }, { id: 2, name: $i18n.t('company') }]"
                              :value="form.customer_invoice_type" @input="form.customer_invoice_type = $event" label=""
                              :multi="false" :required="false" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>

                           <!-- <GenericInput type="text" :value="form.job_title" @input="form.job_title = $event"
                              label="job title" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput> -->
                           <!-- 
                           <GenericInput type="number" v-if="form.customer_invoice_type == 1" :value="form.phone"
                              @input="form.phone = $event" label="phone"
                              :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput> -->

                           <GenericInput type="text" :value="form.address" @input="form.address = $event" label="address"
                              :required="(form.customer_invoice_type == 2)" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 8]">
                           </GenericInput>
                           <GenericInput type="text" :value="form.commercial_registeration_no"
                              @input="form.commercial_registeration_no = $event"
                              :label="form.customer_invoice_type == 2 ? 'commercial registration no' : 'idNumber'"
                              :required="(form.customer_invoice_type == 2)" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 4]"></GenericInput>



                           <GenericInput type="text" v-if="form.customer_invoice_type == 2"
                              :value="form.vat_register_number" @input="form.vat_register_number = $event"
                              label="vat register number" :required="true" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 4]"></GenericInput>

                           <GenericInput type="select" :lookups="sales_areas" :value="form.sales_area_id"
                              @input="form.sales_area_id = $event" label="sales area" :multi="false" :required="false"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="customer_types" :value="form.customer_type_id"
                              @input="form.customer_type_id = $event" label="customer type" :multi="false"
                              :required="false" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="currencies" :value="form.default_currency_id"
                              @input="form.default_currency_id = $event" label="default currency to deal" :multi="false"
                              :required="false" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="textarea" :value="form.notes" @input="form.notes = $event" label="notes"
                              :rowsNumber="3" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 8]">
                           </GenericInput>
                           <v-col cols="12" class="pa-0">
                              <v-divider></v-divider>
                           </v-col>
                           <v-col cols="12" class="pb-0">
                              <div class="body-2 gray7--text">{{ $t('virtual customer behavior') | capitalize }}</div>
                           </v-col>
                           <GenericInput type="radiogroup" :labelInline="false"
                              :lookups="[{ id: 1, name: $i18n.t('cash') }, { id: 2, name: $i18n.t('term') }]"
                              :value="form.customer_behaviour_type" @input="form.customer_behaviour_type = $event" label=""
                              :multi="false" :required="false" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>



                           <GenericInput type="number" :value="form.first_term_balance" :acceptZero="true"
                              @input="form.first_term_balance = $event" label="first time balance" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="select"
                              :lookups="[{ id: 1, name: $i18n.t('debit') }, { id: 2, name: $i18n.t('credit') }]"
                              :value="form.balance_nature" @input="form.balance_nature = $event"
                              label="nature of the balance" :multi="false"
                              :required="form.first_term_balance ? true : false" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="sales_agents" :value="form.sales_agent_id"
                              @input="form.sales_agent_id = $event" label="representatives" :multi="false"
                              :required="false" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="float" :value="form.maximum_term_value"
                              @input="form.maximum_term_value = $event" label="maximum term value" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>

                           <GenericInput type="number" :value="form.term_period" @input="form.term_period = $event"
                              label="term period" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>

                           <v-col cols="12" class="pa-0">
                              <v-divider></v-divider>
                           </v-col>
                           <v-col cols="12" class="pb-0">
                              <div class="body-2 gray7--text">{{ $t('relating with accounting') | capitalize }}</div>
                           </v-col>


                           <GenericInput type="select" :lookups="account_trees" :value="form.account_tree_id"
                              @input="form.account_tree_id = $event" label="linked account" :multi="false"
                              :required="false" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>


                        </v-row>
                     </v-tab-item>

                     <v-tab-item eager :value="2">
                        <div :key="contactKey">
                           <v-row align="center" class="py-4" v-for="(contact, index) in form.customer_contacts"
                              :key="index">
                              <GenericInput type="text" :value="contact.name" @input="contact.name = $event" label="name"
                                 :required="false" :isLoading="pageData.editIsLoading" :cols="[4, 4, 4]">
                              </GenericInput>
                              <GenericInput type="mobile" :value="contact.phone" @input="contact.phone = $event"
                                 label="phone" :required="false" :isLoading="pageData.editIsLoading" :cols="[4, 4, 4]">
                              </GenericInput>
                              <v-col cols="4" class="d-flex ">

                                 <v-btn depressed text color="error" small class="mx-6"
                                    v-if="form.customer_contacts.length !== 1" @click="removeContact(index); contactKey++">
                                    {{ $t('delete') }}
                                    <v-icon size="25" right>mdi-trash-can-outline</v-icon>
                                 </v-btn>
                                 <v-btn color="success" small class="mx-6 shadow"
                                    v-if="form.customer_contacts.length == index + 1"
                                    @click="form.customer_contacts.push({ phone: null, name: null }); contactKey++">
                                    {{ $t('add another phone') }}
                                    <v-icon size="25" right>mdi-plus</v-icon>
                                 </v-btn>
                              </v-col>

                           </v-row>
                           <v-row v-if="form.customer_contacts.length == 0">

                              <!-- <v-col cols="6">
                                 <v-alert type="info" outlined dense>
                                    {{ $t('no contact was added') }}
                                 </v-alert>
                              </v-col> -->
                              <v-col cols="12">
                                 <v-btn color="success" large class="d-block mi-start-auto shadow"
                                    @click="form.customer_contacts.push({ phone: null, name: null }); contactKey++">
                                    {{ $t('add contact') }}
                                    <v-icon size="25" right>mdi-plus</v-icon>
                                 </v-btn>
                              </v-col>
                           </v-row>
                        </div>
                     </v-tab-item>
                     <v-tab-item eager :value="3">

                        <!-- <GenericInput v-if="!this.pageData.isEdit" type="dropzone" :value="form.customer_attachments"
                           paddingY="py-5" paddingX="px-5" @input="form.customer_attachments = $event" :required="false"
                           :multi="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput> -->
                        <v-col cols="12" class="d-flex justify-end">
                           <v-btn color="secondary" depressed @click="attachmentDialog = true"
                              :loading="attachDownloadLoading">
                              {{ $t('add attachment') }}
                           </v-btn>
                           <v-btn color="primary" outlined class="blue12 mx-2" v-if="pageData.isEdit"
                              :disabled="(form.customer_attachments.length == 0)" @click="downloadAllAttachment" depressed
                              :loading="attachDownloadLoading">
                              {{ $t('download all attachment') }}
                              <v-icon size="20" right>mdi-download</v-icon>
                           </v-btn>
                        </v-col>
                        <div class="py-4">
                           <v-row align="center">
                              <v-col cols="12" md="6" lg="4" v-for="(attach, index) in form.customer_attachments"
                                 :key="index">
                                 <v-hover v-slot="{ hover }">
                                    <v-card height="280" class="overflow-hidden rounded-lg shadow pa-2">
                                       <div class=" my-2" :class="{ 'opacity-015': hover }">
                                          <div v-lazy-container="{ selector: 'img' }"
                                             v-if="attach.file_extension == 'jpeg' || attach.file_extension == 'jpg' || attach.file_extension == 'png' || attach.file_extension == 'gif' || attach.file_extension == 'svg'">
                                             <img class="d-block ma-auto" style="height: 200px;" :key="index"
                                                :data-src="String(attach.file_path).substring(0, 10) == 'data:image' ? attach.file_path : $api.serverUrl + attach.file_path"
                                                alt="qarat" />
                                          </div>

                                          <div v-else>
                                             <img v-if="attach.file_extension !== 'pdf'"
                                                :src="require(`@/assets/img/png/files/${attach.file_extension}.png`)"
                                                height="200" class="d-block ma-auto" alt="qarat" />
                                             <div v-if="attach.file_extension == 'pdf'">
                                                <iframe width='100%' height='200'
                                                   :src="String(attach.file_path).substring(0, 20) == 'data:application/pdf' ? attach.file_path : $api.serverUrl + attach.file_path" />
                                             </div>

                                          </div>
                                       </div>
                                       <div class="text-center subtitle-1" :class="{ 'd-none': hover }">
                                          {{ attach.file_name }}</div>

                                       <div class="absolute-center justify-end px-5" :class="{ 'd-none': !hover }">
                                          <v-btn icon color="primary" class="mx-3" large v-if="pageData.isEdit"
                                             @click="downloadAttachment(attach)" :loading="attachDownloadLoading">
                                             <v-icon size="40">mdi-download</v-icon>
                                          </v-btn>
                                          <v-btn icon color="error" class="mx-3" large
                                             @click="deleteAttachment(attach, index)" :loading="attachDownloadLoading">
                                             <v-icon size="40">mdi-trash-can-outline</v-icon>
                                          </v-btn>
                                       </div>
                                    </v-card>
                                 </v-hover>
                              </v-col>
                           </v-row>
                           <v-col v-if="form.customer_attachments.length == 0" cols="12">
                              <v-alert type="info" outlined dense>
                                 {{ $t('no attachment') }}
                              </v-alert>
                           </v-col>
                        </div>



                     </v-tab-item>
                  </v-tabs-items>



               </v-sheet>
            </v-card>
         </v-form>
         <!-- End of Form -->


         <AttachmentDialog :dialog="attachmentDialog" :entityName="form.name || '-'" :dataKey="'id'" :data="form"
            :uploadKey="'customer_attachments'" :multi="false" :uploadResource="'upload_customer_attachment'"
            :downloadResource="'download_customer_attachment'" :returnAttachOnly="!pageData.isEdit"
            :returnAttachMethod="(attach) => { attachmentDialog = false; this.form.customer_attachments.push(attach) }"
            :returnMethod="(reload) => { attachmentDialog = false; reload ? getData() : null }" />


      </v-container>
   </section>
</template>



<script>
export default {
   name: "CustomersControl",

   data: () => ({
      pageData: {
         screen_code: "06-003",
         url: null,
         controlRoute: "/sales/customer-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      attachmentDialog: false,
      summery: [],
      valid: false,
      attachDownloadLoading: false,
      tab: 1,
      sales_areas: [],
      sales_agents: [],
      account_trees: [],
      currencies: [],
      customer_types: [],
      contactKey: 1,
      form: {
         name_en: null,
         name_ar: null,
         address: null,
         sales_area_id: null,
         account_tree_id: null,
         commercial_registeration_no: null,
         vat_register_number: null,
         default_currency_id: null,
         customer_behaviour_type: 1,
         // phone: null,
         sales_agent_id: null,
         maximum_term_value: null,
         term_period: 0,
         customer_type_id: null,
         first_term_balance: 0,
         customer_invoice_type: 1,
         balance_nature: 1,
         customer_attachments: [],
         customer_contacts: [
            {
               name: null,
               phone: null,
            }
         ],
      },
   }),
   watch: {
      viewForm() {
         if (this.viewForm) {
            this.tab = 1
         }
      },
      'form.customer_invoice_type'() {
         if (this.form.customer_invoice_type == 1) {
            this.form.commercial_registeration_no = null;
            this.form.vat_register_number = null;
            this.form.address = null;
         } else {
            this.form.phone = null;
         }
      }
   },
   components: {},
   computed: {},
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`customer/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.form.customer_attachments = response.data.customer_attachments;
                     this.form.customer_contacts = response.data.customer_contacts;
                     this.account_trees = response.data.account_trees;
                     this.customer_types = response.data.customer_types;
                     this.sales_areas = response.data.sales_areas;
                     this.sales_agents = response.data.sales_agents;
                     this.currencies = response.data.currencys;
                     this.summery = [
                        {
                           label: 'customer data',
                           value: '',
                           class: 'secondary--text',
                           cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
                        },
                        {
                           label: 'name_ar',
                           value: response.data.data.name_ar || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'name_en',
                           value: response.data.data.name_en || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'commercial registration no',
                           value: response.data.data.commercial_registeration_no || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'address',
                           value: response.data.data.address || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'vat register number',
                           value: response.data.data.vat_register_number || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'default currency to deal',
                           value: response.data.data.default_currency_id_name || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'sales area',
                           value: response.data.data.sales_area_name || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'representatives',
                           value: response.data.data.sales_agent_name || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'maximum term value',
                           value: response.data.data.maximum_term_value || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 4, xl: null,
                        },
                        {
                           label: 'contact with customer',
                           value: '',
                           class: 'secondary--text',
                           cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
                        },

                     ]
                     response.data.customer_contacts.forEach((contact) => {
                        this.summery.push(
                           {
                              label: contact.name,
                              value: contact.phone || '-',
                              class: '',
                              cols: null, sm: null, md: null, lg: 4, xl: null,
                           },
                        )
                     });

                     this.viewSummery = true;
                     this.tab = 3
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.viewForm = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`customer/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.account_trees = response.data.account_trees;
                     this.customer_types = response.data.customer_types;
                     this.form.customer_type_id = response.data.customer_types[0].id;
                     this.sales_areas = response.data.sales_areas;
                     this.sales_agents = response.data.sales_agents;
                     this.currencies = response.data.currencys;
                     Object.keys(response.data.defaults || Object).forEach(key => {
                        this.form[key] = response.data.defaults[key]
                     })
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.form.customer_invoice_type == 1 && this.form.customer_contacts.length == 0) {
            this.$store.state.snackbarTitle = 'add at least one phone for customer';
            this.$store.state.snackbarType = "warning";
            this.$store.state.showSnackbar = true;
         } else {
            this.form.phone = this.form.customer_contacts[0].phone
            if (this.$refs.form.validate()) {
               this.pageData.isLoading = true;
               if (this.pageData.isEdit == true) {
                  this.form._method = 'PUT';
                  delete this.form.customer_attachments
                  this.$api.POST_METHOD(`customer/${this.form.id}`, this.form).then((response) => {
                     this.pageData.isLoading = false;
                     if (response.check) {
                        this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                     }
                  })
               }
               else {
                  this.$api.POST_METHOD(`customer`, this.form).then((response) => {
                     this.pageData.isLoading = false;
                     if (response.check) {
                        this.$router.push(this.pageData.url)
                     }
                  })
               }
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`customer/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      downloadAttachment(attach) {
         console.log(attach);
         this.attachDownloadLoading = true
         var src = `download_customer_attachment/${attach.customer_attachment_id}`;
         var download_file_name = attach.file_name + '.' + attach.file_extension
         this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
            this.attachDownloadLoading = false;
         })
      },
      deleteAttachment(attach, index) {
         if (this.pageData.isEdit) {
            console.log(attach);
            this.attachDownloadLoading = true
            this.$api.POST_METHOD(`delete_customer_attachment/${attach.customer_attachment_id}`).then((res) => {
               this.attachDownloadLoading = false;
               if (res.check) {
                  this.getData()
               }
            })
         } else {
            this.form.customer_attachments.splice(index, 1);
         }

      },
      downloadAllAttachment() {
         this.form.customer_attachments.forEach(attachment => {
            this.downloadAttachment(attachment, 0)
         });
      },
      removeContact(index) {
         this.form.customer_contacts.splice(index, 1);
      },

   },

};
</script>
