<template>
  <v-card class="shadow rounded-lg recipeChart my-2">
    <v-row justify="center">

      <v-col cols="12" class="py-0" id="chart" :key="key">
        <apexchart height="500" id="apexRecipeChart" type="area" :options="options" :series="series" />
      </v-col>
    </v-row>
  </v-card>
</template>







<script>
export default {
  name: "WeeklyChartCurve",
  computed: {
  },
  props: {
    summary: { default: [] },
    summary_categories: { default: [] },
    order_count: { default: [] },
    service_count: { default: [] },
  },
  watch: {
    summary_categories() {

    }
  },
  data: () => ({
    key: 1,
    width: 0,
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: 40,
          endingShape: 'rounded',
          borderRadius: 9,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      chart: {

        toolbar: {
          show: false,
        },

      },
      stroke: {
        show: true,
        width: 4,
        colors: ['transparent']
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val > 0 ? val : '';
        },
        offsetY: -18,
        style: {
          fontSize: '10px',
          colors: ["#334d6e", "#44abe1" ,"#1bd2a4"],
        }
      },

      colors: ["#334d6e", "#44abe1" ,"#1bd2a4"],
      xaxis: {
        categories: [],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      yaxis: {
        axisBorder: {
          show: false,

        },
        axisTicks: {
          show: false,

        },
        labels: {
          show: true,
          style: {
            fontSize: '10px',
            colors: ["#000"],
          }
        }
      },
    },

    series: [
      {
        name: "total price",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "services",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "orders",
        data: [0, 0, 0, 0, 0],
      },
    ],
  }),
  mounted() {

    this.width =
      document.getElementById("chart").offsetWidth < 490
        ? document.getElementById("chart").offsetWidth - 50
        : document.getElementById("chart").offsetWidth - 210;
    if (this.width > 0) {
      this.options.xaxis.categories = this.summary_categories;

      this.series = [
        {
          name: this.$i18n.t('total'),
          data: [...this.summary],
        },
        {
          name: this.$i18n.t('services'),
          data: [...this.service_count],
        },
        {
          name: this.$i18n.t('orders'),
          data: [...this.order_count],
        },
      ];
      this.key = this.key + 1

    }


  }

};
</script>
