<template>
  <!-- <transition :enter-active-class="$vuetify.rtl == true ? 'fadeIn' : 'fadeIn'"> -->
    <router-view appear :key="path"></router-view>
  <!-- </transition> -->
</template>


<script>
export default {
  name: "InventoryModule",
  components: {},
  data: () => ({
    path: null
  }),
};
</script>
