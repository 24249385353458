<template>

   <v-dialog v-model="dialog" overlay-opacity="0.75" content-class="overflow-hidden" persistent :max-width="1000">
      <v-card relative :key="modalKey">

         <v-form ref="detail" v-model="valid" class="pa-2">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row>
                  <v-col cols="12" class="pt-0">
                     <div class="subtitle-1 font-weight-bold d-flex align-center">
                        <v-icon right v-if="!isDetailEdit">mdi-plus</v-icon>
                        <img v-else src="@/assets/img/svg_icons/pen.svg" height="18" />
                        <span class="mx-2">
                           {{ isDetailEdit? $t('edit line'): $t('add line') }}
                        </span>
                     </div>
                  </v-col>
                  <GenericInput type="select" :lookups="items" :value="detail.item_id" @input="detail.item_id = $event"
                     label="item" :multi="false" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="false" :cols="[4, 4, 4]">
                  </GenericInput>

                  <GenericInput type="select" :disabled="!(detail.item_id)" :lookups="detail.item_units || []"
                     :value="detail.unit_id" @input="detail.unit_id = $event" label="unit" :multi="false"
                     :required="true" selected_label="name" selected_prop="id" :isLoading="false" :cols="[4, 4, 4]">
                  </GenericInput>

                  <GenericInput type="float" :value="detail.price" :disabled="!detail.item_id"
                     @input="detail.price = $event" label="price" :required="true" :isLoading="false" :cols="[4, 4, 4]">
                  </GenericInput>


                  <GenericInput type="select" :lookups="taxes" :value="detail.tax_percent_id"
                     @input="detail.tax_percent_id = $event" label="tax percentage" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[4, 4, 4]">
                  </GenericInput>


                  <GenericInput type="select" :lookups="warehouses" :value="detail.warehouse_id"
                     @input="detail.warehouse_id = $event" label="warehouse" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[4, 4, 4]">
                  </GenericInput>



                  <GenericInput type="number" v-if="!widthHeight || selectedItem.item_width_height == 0"
                     :value="detail.quantity" @input="detail.quantity = $event" label="quantity" :required="true"
                     :isLoading="false" :cols="[4, 4, 4]">
                  </GenericInput>


                  <v-col cols="12" class="py-0" v-if="widthHeight && selectedItem.item_width_height">
                     <DynamicTable :isLoading="false" :data="multi_line_detail" :header="tableHeader"
                        :tableHeight="$store.state.Settings.windowSize.y - 400">
                        <template v-slot:td="{ row, header, index }">
                           <div v-if="header.key == 'area_width' && widthHeight">
                              <GenericInput type="float" :value="row.area_width" @input="row.area_width = $event"
                                 label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                              </GenericInput>
                           </div>
                           <div v-if="header.key == 'area_height' && widthHeight">
                              <GenericInput type="float" :value="row.area_height" @input="row.area_height = $event"
                                 label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                              </GenericInput>
                           </div>
                           <div v-if="header.key == 'area_quantity' && widthHeight">
                              <GenericInput type="float" :value="row.area_quantity" @input="row.area_quantity = $event"
                                 label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                              </GenericInput>
                           </div>
                           <div v-if="header.key == 'description' && widthHeight">
                              <GenericInput type="textarea" :rowsNumber="1" :value="row.description"
                                 @input="row.description = $event" label="" :required="false" :isLoading="false"
                                 :cols="[12, 12, 12]" />
                           </div>
                           <div v-if="header.key == 'quantity' && !widthHeight">
                              <GenericInput type="number" :value="row.quantity" @input="row.quantity = $event" label=""
                                 :required="true" :isLoading="false" :cols="[12, 12, 12]" />
                           </div>
                           <div v-if="header.key == 'bulk'">
                              <v-btn icon color="success" @click="addItem" x-small>
                                 <v-icon>
                                    mdi-plus
                                 </v-icon>
                              </v-btn>
                              <v-btn icon color="error" v-if="index + 1 !== multi_line_detail.length"
                                 @click="removeItem(index)" x-small>
                                 <v-icon>
                                    mdi-minus
                                 </v-icon>
                              </v-btn>
                           </div>
                        </template>
                     </DynamicTable>
                  </v-col>

                  <!-- {{multi_line_detail}} -->

                  <v-col :cols="12" class="d-flex justify-end">
                     <v-btn text depressed color="error" class="mx-1" :min-width="120" @click="closeDialog"
                        :height="37">
                        <v-icon left class="mt-1">mdi-close</v-icon>
                        {{ $t('close') }}
                     </v-btn>
                     <v-btn outlined style="background-color:  #e6ecff ;" class="mx-1" :disabled="!(valid)" depressed
                        color="blue2" :min-width="120" @click="save" :loading="loading" :height="37">
                        <span v-if="isDetailEdit">
                           <v-icon left class="mt-1">mdi-pencil</v-icon>
                           {{ $t('edit line') }}
                        </span>
                        <span v-else>
                           <v-icon left class="mt-1">mdi-plus</v-icon>
                           {{ $t('add line') }}
                        </span>
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>





      </v-card>
   </v-dialog>

</template>

<script>
export default {
   name: "ItemDetail",
   props: {
      detailAction: { type: Function },
      updateDetail: { type: Function },
      dialog: { default: false },
      detail: { default: Object },
      isDetailEdit: { default: false },
      items: { default: [] },
      units: { default: [] },
      taxes: { default: [] },
      warehouses: { default: [] },
      widthHeight: { default: false },
   },
   computed: {
      selectedItem() {
         return this.detail.item_id ? this.$global.FilterArrayOfObject(this.items, 'id', this.detail.item_id)[0] : Object;
      },
      tableHeader() {
         if (this.isDetailEdit) {
            return [
               { text: "width", key: "area_width", type: 'slot', classes: "" },
               { text: "height", key: "area_height", type: 'slot', classes: "" },
               { text: "count", key: "area_quantity", type: 'slot', classes: "" },
               { text: "description", key: "description", type: 'slot', classes: "", width: 300 },
            ]
         } else {
            return [
               { text: "width", key: "area_width", type: 'slot', classes: "" },
               { text: "height", key: "area_height", type: 'slot', classes: "" },
               { text: "count", key: "area_quantity", type: 'slot', classes: "" },
               { text: "description", key: "description", type: 'slot', classes: "", width: 300 },
               { text: "", key: "bulk", type: 'slot', classes: "" },
            ]
         }
      }
   },
   components: {},
   watch: {
      "selectedItem.item_width_height"() {
         if (!this.selectedItem.item_width_height) {
            this.multi_line_detail = [];
            this.detail.area_width = 0;
            this.detail.area_height = 0;
            this.detail.area_quantity = 0;
         } else {
            this.multi_line_detail = [{ ...this.detail }]
         }
      },
      "detail.item_id"(newID, oldID) {
         this.detail.unit_id = null;
         if (this.detail.item_id) {
            var getItem = this.detail.item_id ? this.$global.FilterArrayOfObject(this.items, 'id', this.detail.item_id)[0] : Object;
            this.detail.item_units = [];
            this.detail.item_units.push(
               {
                  id: getItem.small_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.small_unit_id, 'name')
               },
               {
                  id: getItem.mid_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.mid_unit_id, 'name')
               },
               {
                  id: getItem.big_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.big_unit_id, 'name')
               },
            );
            switch (getItem.default_report_unit_type) {
               case 1:
                  this.detail.unit_id = this.detail.unit_id ?? getItem.small_unit_id;
                  break;
               case 2:
                  this.detail.unit_id = this.detail.unit_id ?? getItem.mid_unit_id;
                  break;
               case 3:
                  this.detail.unit_id = this.detail.unit_id ?? getItem.big_unit_id;
                  break;
               default:
                  break;
            }
            this.detail.price_include_vat = +getItem.price_include_vat;
            if (newID == oldID) {
               this.detail.price = this.detail.price ? this.detail.price : getItem.sale_price;
               this.detail.tax_percent = this.detail.tax_percent ?? getItem.tax_percent;
               this.detail.quantity = this.detail.quantity ?? 1;
               this.detail.tax_percent_id = this.detail.tax_percent_id ?? getItem.tax_id;
               this.detail.min_sale_price = this.detail.min_sale_price ?? getItem.min_sale_price;
               this.detail.warehouse_id = this.detail.warehouse_id ?? this.warehouses[0].id;
               this.detail.item_width_height = getItem.item_width_height;
            } else {
               this.detail.price = this.isDetailEdit && !oldID ? this.detail.price : getItem.sale_price;
               this.detail.tax_percent = this.isDetailEdit && !oldID ? this.detail.tax_percent : getItem.tax_percent;
               this.detail.quantity = this.isDetailEdit && !oldID ? this.detail.quantity : 1;
               this.detail.tax_percent_id = this.isDetailEdit && !oldID ? this.detail.tax_percent_id : getItem.tax_id;
               this.detail.min_sale_price = this.isDetailEdit && !oldID ? this.detail.min_sale_price : getItem.min_sale_price;
               this.detail.warehouse_id = this.isDetailEdit && !oldID ? this.detail.warehouse_id : this.warehouses[0].id;
               this.detail.item_width_height = getItem.item_width_height;
            }

         }
      },
      '$store.state.Settings.key_clicked'() {
         switch (this.$store.state.Settings.key_clicked) {
            case 'F10':
               if (this.valid && this.dialog) {
                  this.save()
               }
               break;
            case 'Escape':
               this.closeDialog()
               break;
            default:
               break;
         }
      },
      dialog() {
         if (this.dialog == false) {
            this.loading = false;
         }
         this.multi_line_detail = [{ ...this.detail }]
      }
   },
   data: () => ({
      isLoading: false,
      valid: false,
      loading: false,
      modalKey: 0,
      multi_line_detail: [],
   }),
   methods: {
      closeDialog() {
         this.detailAction(null, false, true, true);
         this.modalKey = this.modalKey + 1;
      },
      save() {
         this.modalKey = this.modalKey + 1;
         if (this.selectedItem.item_width_height) {
            for (let index = 0; index < this.multi_line_detail.length; index++) {
               let prepare_detail = { ...this.detail }
               prepare_detail.area_width = this.multi_line_detail[index].area_width
               prepare_detail.area_height = this.multi_line_detail[index].area_height
               prepare_detail.area_quantity = this.multi_line_detail[index].area_quantity
               prepare_detail.description = this.multi_line_detail[index].description
               prepare_detail.quantity = this.widthHeight && this.selectedItem.item_width_height ? this.multi_line_detail[index].area_width * this.multi_line_detail[index].area_height * this.multi_line_detail[index].area_quantity : this.detail.quantity
               this.detailAction(prepare_detail, this.isDetailEdit, (index + 1 == this.multi_line_detail.length), false);
            }
         } else {
            this.detailAction(this.detail, this.isDetailEdit, true, false);
         }
      },
      addItem() {
         this.multi_line_detail.push({ ...this.detail });
      },
      removeItem(index) {
         this.multi_line_detail.splice(index, 1);
      },
   },
   mounted() {

   },
};
</script>