<template>

   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="800">
      <v-card relative class="backgroundW">
         <v-form ref="form" lazy-validation>
            <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
               color="grey lighten-1" text @click="closeDialog">
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>


            <v-sheet class="py-10 px-3">
               <v-row class="justify-center align-center">
                  <v-col cols="12">
                     <div class="text-center text-h5 font-weight-bold">{{ $t("print pallet's QR code") | capitalize }}
                     </div>
                  </v-col>
                  <v-col cols="auto">
                     <img style="border: 1px solid #eee; " height="200"
                        src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz"
                        alt="wms">
                     <!-- {{printData.qrcode || '-'}} -->
                  </v-col>
                  <v-col cols="auto">
                     <v-divider vertical style="height: 8rem"></v-divider>
                  </v-col>
                  <v-col cols="auto">
                     <v-col class="d-flex justify-space-between align-center">
                        <div class="body-2 gray7--text">{{ $t('pallet number') }} : </div>
                        <div class="body-2 font-weight-bold gray7--text">#{{ printData.id || '-' }}</div>
                     </v-col>
                     <v-col class="d-flex justify-space-between align-center">
                        <div class="body-2 gray7--text">{{ $t('incoming no') }}</div>
                        <div class="body-2 font-weight-bold gray7--text">#{{ printData.incoming_id || '-' }}
                        </div>
                     </v-col>
                     <v-col class="d-flex justify-space-between align-center">
                        <div class="body-2 gray7--text">{{ $t('addition date') }}</div>
                        <div class="body-2 font-weight-bold gray7--text">{{ printData.addition_date || '-' }}</div>
                     </v-col>
                     <v-col class="d-flex justify-space-between align-center">
                        <v-btn color="gray3" depressed width="120" @click="print" large class="white--text mx-2">
                           {{ $t('print') }} <v-icon right>mdi-printer-outline</v-icon>
                        </v-btn>
                        <v-btn color="gray3" text depressed class="mx-2" @click="closeDialog">{{ $t('skip it for now')
                        }}
                           <v-icon right>mdi-close</v-icon>
                        </v-btn>
                     </v-col>
                  </v-col>
               </v-row>
            </v-sheet>

         </v-form>
      </v-card>
   </v-dialog>

</template>






<script>

export default {
   name: "PalletPrintModal",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      printData: { default: { id: null, incoming_shipment_no: null, addition_date: null, qrcode: null } },
   },
   computed: {

   },
   components: {

   },
   data: () => ({}),
   methods: {
      closeDialog() {
         this.dialog = false
         this.backValueMethod(false);
      },
      print() {
         this.closeDialog()
      }

   },
   mounted() {

   },
};
</script>
