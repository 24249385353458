<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save"
            :showCreate="!(pageData.isEdit && form.proforma_invoice_status == 2)"
            :showDelete="!(pageData.isEdit && form.proforma_invoice_status == 2)"
            :valid="valid && form.customer_id && tableRows.length > 0" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit && !viewForm && form.proforma_invoice_status == 1"
            :viewForm="() => viewForm = true" />
         <!-- End Of Header -->
         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-2 overflow-hidden" v-if="viewForm">
            <v-card class="backgroundW shadow pa-3">
               <v-fade-transition>
                  <div class="collapseForm" v-if="tableRows.length > 0">
                     <div class="d-flex">
                        <v-fade-transition>
                           <v-btn outlined style="background-color:  var(--blue12);"
                              v-if="!itemsFromQuotation && formCollapse" class="mx-2" depressed color="blue2" :width="127"
                              @click="detailDialog = true" :height="37">
                              {{ $t('add line') }}
                           </v-btn>
                        </v-fade-transition>
                        <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                           <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                           <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </v-fade-transition>
               <v-expand-transition>
                  <v-row align="center" v-show="!formCollapse">

                     <v-col cols="12">
                        <v-btn depressed color="blue4" outlined @click="customerDialog = true" class="position-relative"
                           style="z-index: 10">
                           {{ form.customer_id ? form.customer_name : $t('choose customer') }}
                           <span v-if="form.quotation_id"> - {{ $t('quotation no') }} : {{ form.quotation_id }} </span>
                           <v-icon size="18" right>mdi-open-in-new</v-icon>
                        </v-btn>
                     </v-col>

                     <GenericInput type="text" :value="form.purchase_order_id" @input="form.purchase_order_id = $event"
                        label="purchase order(po) no" :required="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 6, 4]"></GenericInput>

                     <GenericInput type="date" :value="form.proforma_invoice_date"
                        @input="form.proforma_invoice_date = $event" label="date" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>

                     <GenericInput type="select" :lookups="sales_agents" :value="form.sales_agent_id"
                        @input="form.sales_agent_id = $event" label="sales agent" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                     </GenericInput>

                     <GenericInput type="textarea" solo :value="form.proforma_invoice_description"
                        @input="form.proforma_invoice_description = $event" label="description" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>

                     <GenericInput type="checkbox" :disabled="itemsFromQuotation" :value="form.invoice_discount"
                        @input="form.invoice_discount = $event" label="discount" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="['auto', 'auto', 'auto']">
                     </GenericInput>
                     <v-fade-transition>
                        <GenericInput type="radiogroup" :disabled="itemsFromQuotation" :labelInline="false"
                           v-if="form.invoice_discount"
                           :lookups="[{ id: 1, name: $i18n.t('value') }, { id: 2, name: $i18n.t('percent') }]"
                           :value="form.discount_type" @input="form.discount_type = $event" label="" :multi="false"
                           :required="false" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 'auto']"></GenericInput>
                     </v-fade-transition>
                     <v-expand-x-transition>
                        <GenericInput :disabled="itemsFromQuotation"
                           :type="form.discount_type == 2 ? 'percentage' : 'float'" :value="form.discount"
                           v-if="form.invoice_discount" @input="form.discount = $event" label="discount" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 'auto']"></GenericInput>
                     </v-expand-x-transition>


                     <GenericInput v-for="dynamicInput in masterInputs" :key="dynamicInput.id" :name="dynamicInput.id"
                        :type="dynamicInput.type" :value="form[dynamicInput.key]" @input="form[dynamicInput.key] = $event"
                        :label="dynamicInput.label" :lookups="dynamicLookups[dynamicInput.lookups_id]"
                        :required="dynamicInput.required == 0 ? false : true"
                        :disabled="dynamicInput.disabled == 0 ? false : true"
                        :multi="dynamicInput.multi == 0 ? false : true"
                        :cols="dynamicInput.cols.split(',').map(i => Number(i))" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" />

                     <v-col cols="12" class="mi-start-auto" md="auto" v-if="!itemsFromQuotation">
                        <v-btn outlined style="background-color:  var(--blue12);" class="d-block mi-start-auto" depressed
                           color="blue2" :width="127" @click="detailDialog = true" :height="37">
                           {{ $t('add line') }}
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-expand-transition>
            </v-card>
         </v-form>


         <!-- Detail Form -->
         <ItemDetail :detailAction="detailAction" :items="items" :warehouses="warehouses" :units="units" :taxes="taxes"
            :dialog="detailDialog" :detail="detail" :updateDetail="updateDetail" :widthHeight="widthHeight"
            :isDetailEdit="isDetailEdit" :tableRows="tableRows" />
         <!-- Detail of Form -->

         <!-- Details Table -->
         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="computedItems.new_array || []" :header="tableHeader"
               :tableHeight="$store.state.Settings.windowSize.y - 230" :footerData="[computedItems]">
               <template v-slot:td="{ row, header, index }">
                  <div v-if="header.key == 'actions'">
                     <v-btn @click="editDetail(index, row)" x-small icon fab>
                        <img src="@/assets/img/svg_icons/pen.svg" height="20" />
                     </v-btn>
                     <v-btn @click="removeItem(index, row)" x-small icon fab>
                        <img src="@/assets/img/svg_icons/delete.svg" height="20" />
                     </v-btn>
                  </div>
               </template>
            </DynamicTable>
         </div>
      </v-container>


      <!-- customer dialog -->

      <v-dialog v-model="customerDialog" overlay-opacity="0.75" persistent :max-width="450">
         <v-card relative class="pa-7 py-10">
            <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
               color="grey lighten-1" text @click="customerDialog = false">
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-row align="center" justify="center">
               <GenericInput type="select" :lookups="customers" :value="form.customer_id"
                  @input="form.customer_id = $event" label="customer" :multi="false" :required="true" selected_label="name"
                  selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
               </GenericInput>
               <GenericInput type="select" :lookups="quotations" :value="form.quotation_id"
                  @input="form.quotation_id = $event" label="quotation no" :multi="false" :required="false"
                  selected_label="item_details" selected_prop="id" :isLoading="quotationIsLoading" :cols="[12, 12, 12]">
               </GenericInput>

               <v-col cols="auto" class="d-flex justify-center">
                  <v-btn class="mx-2" text depressed color="red4" @click="customerDialog = false" :height="37"
                     :width="120">
                     {{ $t('cancel') }}
                  </v-btn>
                  <v-btn class="mx-2 white--text" :loading="proformaIsLoading || quotationIsLoading"
                     @click="getProformaItems" depressed color="blue4" :disabled="!(form.customer_id)" :height="37"
                     :width="120">
                     {{ $t('choose') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
      </v-dialog>



   </section>
</template>



<script>
import ItemDetail from '../ItemDetail.vue';
export default {
   name: "ProformaInvoiceControl",
   data: () => ({
      pageData: {
         screen_code: "06-006",
         url: null,
         controlRoute: "/sales/proforma-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      formCollapse: false,
      summery: [],
      customerDialog: false,
      proformaIsLoading: false,
      itemsFromQuotation: false,
      quotationIsLoading: false,
      valid: false,
      // detail
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      tableHeader: [],
      tableRows: [],
      tableOption: {},

      widthHeight: false,

      // lockups
      sales_agents: [],
      customers: [],
      quotations: [],
      taxes: [],
      account_trees: [],
      items: [],
      units: [],
      warehouses: [],
      masterInputs: [],
      dynamicLookups: Object,
      form: {
         quotation_id: null,
         purchase_order_id: null,
         proforma_invoice_date: null,
         proforma_invoice_description: null,
         customer_id: null,
         sales_agent_id: null,
         invoice_discount: null,
         discount_type: null,
         discount: null,
         items: [],
      },
      detail: {
         item_id: null,
         unit_id: null,
         item_width_height: null,
         price: 0,
         price_include_vat: 0,
         quantity: 1,
         tax_percent: null,
         tax_percent_id: null,
         warehouse_id: null,
         area_width: 0,
         area_height: 0,
         area_quantity: 0,
         description: null,
      }
   }),
   components: {
      ItemDetail
   },
   computed: {
      computedDiscount() {
         return {
            type: this.form.discount_type || 1,
            value: this.form.discount || 0,
            discount_amount: 0,
            percent_value: 0,
            total_price: 0,
         }
      },
      computedItems() {
         if (this.tableRows.length > 0) {
            return this.$global.PRICE_CALCULATION(this.tableRows, this.computedDiscount)
         } else {
            return Object
         }
      }
   },
   watch: {
      "form.invoice_discount"() {
         if (!this.pageData.isEdit) {
            if (!this.itemsFromQuotation) {
               if (this.form.invoice_discount) {
                  this.form.discount_type = 1;
               } else {
                  this.form.discount_type = null;
               }
            }
         }
      },
      "form.discount_type"() {
         if (!this.pageData.isEdit) {
            if (!this.itemsFromQuotation) {
               this.form.discount = null;
            }
         }
      },
      "form.customer_id"() {
         if (this.pageData.isEdit == false) {
            this.form.sales_agent_id = this.customers.length > 0 ? this.$global.FilterArrayOfObjectElement(this.customers, 'id', this.form.customer_id, 'sales_agent_id') : null;
            this.form.customer_name = this.$global.FilterArrayOfObjectElement(this.customers, 'id', this.form.customer_id, 'name')
            this.getCustomerQuotations();
         }
      },
      "form.quotation_id"() {
         if (this.pageData.isEdit == false) {
            this.getProformaItems();
         }
      },
      "itemsFromQuotation"() {
         if (this.itemsFromQuotation == true) {
            this.tableHeader.pop()
         } else {
            this.tableHeader.push({ text: "delete", key: "id", type: 'delete', classes: "" })
         }
      },
      "form.proforma_invoice_status"() {
         if (this.form.proforma_invoice_status == 2) {
            this.tableHeader = [
               { text: "item", key: "item_title", type: 'text', classes: "" },
               { text: "unit", key: "unit_title", type: 'text', classes: "" },
               { text: "quantity", key: "quantity", type: 'float', classes: "" },
               { text: "price", key: "price_without_vat", type: 'float', classes: "" },
               { text: "discount", key: "total_discount", type: 'float', classes: "" },
               { text: "amount without vat", key: "total_price_without_vat", type: 'float', classes: "" },
               { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
               { text: "vat amount", key: "total_vat_after_discount", type: 'float', classes: "" },
               { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            ];

         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            row.item_title = this.$global.FilterArrayOfObjectElement(this.items, 'id', row.item_id, 'name');
            row.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', row.unit_id, 'name');
            row.warehouse_name = this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', row.warehouse_id, 'name');
            row.tax_percent = this.$global.FilterArrayOfObjectElement(this.taxes, 'id', row.tax_percent_id, 'tax_percentage');
         });
      }
   },
   mounted() {
      this.pageMainData();
      this.form.proforma_invoice_date = this.$global.GetCurrentDate()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
         this.tableHeader = [
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "quantity", key: "quantity", type: 'float', classes: "" },
            { text: "price", key: "price_without_vat", type: 'float', classes: "" },
            { text: "discount", key: "total_discount", type: 'float', classes: "" },
            { text: "amount without vat", key: "total_price_without_vat", type: 'float', classes: "" },
            { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
            { text: "vat amount", key: "total_vat_after_discount", type: 'float', classes: "" },
            { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            { text: "actions", key: "actions", type: 'slot', classes: "" },
         ];
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`proforma_invoice/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     setTimeout(() => {
                        this.form = response.data.master;
                     }, 1);
                     this.tableRows = response.data.details;
                     this.customers = response.data.customers || [];
                     this.items = response.data.items || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes = response.data.taxes || [];
                     this.warehouses = response.data.warehouses || [];
                     this.widthHeight = response.data.width_height;
                     this.masterInputs = response.data.master_inputs || []
                     this.dynamicLookups = response.data.dynamic_lookups || Object
                     this.summery = [
                        {
                           label: 'quotation no',
                           value: response.data.master.quotation_id,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'date',
                           value: response.data.master.proforma_invoice_date,
                           type: 'date', width: 120,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'description',
                           value: response.data.master.proforma_invoice_description,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'customer',
                           value: response.data.master.customer_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'sales agent',
                           value: response.data.master.sales_agent_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'total',
                           value: response.data.master.total_with_vat,
                           type: 'price',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'discount',
                           value: response.data.master.discount,
                           class: '',
                           type: 'float',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },

                     ]
                     this.viewSummery = true;
                     if (response.data.width_height) {
                        // insert two columns at index 2
                        this.tableHeader.splice(2, 0,
                           { text: "width", key: "area_width", type: 'float', classes: "" },
                           { text: "height", key: "area_height", type: 'float', classes: "" },
                           { text: "count", key: "area_quantity", type: 'float', classes: "" },
                           { text: "description", key: "description", type: 'text', classes: "" },
                        )
                     }
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`proforma_invoice/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.customers = response.data.customers || [];
                     this.items = response.data.items || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes = response.data.taxes || [];
                     this.warehouses = response.data.warehouses || [];
                     this.widthHeight = response.data.width_height;
                     Object.keys(response.data.defaults || Object).forEach(key => {
                        this.form[key] = response.data.defaults[key]
                     })
                     if (response.data.width_height) {
                        this.tableHeader.splice(2, 0,
                           { text: "width", key: "area_width", type: 'float', classes: "" },
                           { text: "height", key: "area_height", type: 'float', classes: "" },
                           { text: "count", key: "area_quantity", type: 'float', classes: "" },
                           { text: "description", key: "description", type: 'text', classes: "" },
                        )
                     }
                     if (response.data.master_inputs) {
                        response.data.master_inputs.forEach((input) => {
                           this.form[input.key] = input.value;
                        })
                        this.masterInputs = response.data.master_inputs || []
                        this.dynamicLookups = response.data.dynamic_lookups || Object
                        this.form = { ...this.form }
                     }

                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.form.items = this.tableRows || []
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`proforma_invoice/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_proforma_invoice/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`proforma_invoice`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_proforma_invoice/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      detailAction(detail, isEdit, isFinish, cancel) {
         console.log(detail, isEdit, isFinish, cancel);
         if (cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.resetDetail();
         }
         if (isEdit && !cancel) {
            this.updateDetail(detail);
         }
         if (!isEdit && !cancel) {
            this.tableRows.push(detail);
            if (isFinish) {
               this.detailDialog = false;
               this.isDetailEdit = false;
               this.resetDetail();
            }
         }
      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.resetDetail();
      },
      resetDetail() {
         this.detail = {
            item_id: null,
            unit_id: null,
            item_width_height: null,
            price: 0,
            price_include_vat: 0,
            quantity: 1,
            tax_percent: null,
            tax_percent_id: null,
            warehouse_id: null,
            area_width: 0,
            area_height: 0,
            area_quantity: 0,
            description: null,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
      getCustomerQuotations() {
         this.quotationIsLoading = true;
         this.$api.POST_METHOD(`get_customer_quotations`, { customer_id: this.form.customer_id }).then((response) => {
            this.quotationIsLoading = false;
            if (response.check) {
               response.data.items.forEach(item => {
                  item.item_details = `${item.id} - ${item.quotation_date} - ${this.$global.NumberWithCommas(item.total_with_vat.toFixed(2))}`
               });
               this.quotations = response.data.items;
            }
         })
      },
      getProformaItems() {
         if (this.form.quotation_id) {
            this.proformaIsLoading = true;
            this.$api.POST_METHOD(`get_quotation_data`, { quotation_id: this.form.quotation_id }).then((response) => {
               this.proformaIsLoading = false;
               this.itemsFromQuotation = true;
               if (response.check) {
                  this.tableRows = response.data.items
                  setTimeout(() => {
                     this.form.invoice_discount = response.data.master.invoice_discount
                     this.form.discount_type = response.data.master.discount_type
                     this.form.discount = response.data.master.discount
                  }, 1);
                  this.customerDialog = false;
               }
            })
         } else {
            this.customerDialog = false;
         }

      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`proforma_invoice/${this.form.proforma_invoice_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
   },
};
</script>
