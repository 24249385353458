<template>

   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="700">
      <v-card relative :key="modalKey">

         <v-form ref="detail" v-model="valid" class="pa-2">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row justify="space-around">
                  <v-col cols="12" class="pt-0">
                     <div class="subtitle-1 font-weight-bold d-flex align-center">
                        <v-icon right v-if="!isDetailEdit">mdi-plus</v-icon>
                        <img v-else src="@/assets/img/svg_icons/pen.svg" height="18" />
                        <span class="mx-2">
                           {{ isDetailEdit? $t('edit line'): $t('add line') }}
                        </span>
                     </div>
                  </v-col>
                  <GenericInput type="select" :lookups="items" :value="detail.item_id" @input="detail.item_id = $event"
                     label="item" :multi="false" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>

                  <GenericInput type="select" :disabled="!(detail.item_id)" :lookups="detail.item_units || []"
                     :value="detail.unit_id" @input="detail.unit_id = $event" label="unit" :multi="false"
                     :required="true" selected_label="name" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                  </GenericInput>

                  <GenericInput type="float" :value="detail.price" :disabled="!detail.item_id"
                     @input="detail.price = $event" label="price" :required="true" :isLoading="false" :cols="[6, 6, 6]">
                  </GenericInput>

                  <GenericInput type="number" :value="detail.quantity" @input="detail.quantity = $event"
                     label="quantity" :required="true" :isLoading="false" :cols="[6, 6, 6]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="taxes" :value="detail.tax_percent_id"
                     @input="detail.tax_percent_id = $event" label="tax percentage" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[6, 6, 6]">
                  </GenericInput>

                  <GenericInput type="select" :lookups="warehouses" :value="detail.warehouse_id"
                     @input="detail.warehouse_id = $event" label="warehouse" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>


                  <v-col cols="12" class="d-flex justify-end">
                     <v-btn text depressed color="error" class="mx-1" :min-width="120" @click="closeDialog"
                        :height="37">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('close') }}
                     </v-btn>
                     <v-btn outlined style="background-color:  #e6ecff ;" class="mx-1" :disabled="!(valid)" depressed
                        color="blue2" :min-width="120" @click="save" :height="37">
                        <span v-if="isDetailEdit">
                           <v-icon left>mdi-pencil</v-icon>
                           {{ $t('edit line') }}
                        </span>
                        <span v-else>
                           <v-icon left>mdi-plus</v-icon>
                           {{ $t('add line') }}
                        </span>
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>





      </v-card>
   </v-dialog>

</template>

<script>
export default {
   name: "DeliveryNoteDetail",
   props: {
      detailAction: { type: Function },
      updateDetail: { type: Function },
      dialog: { default: false },
      detail: { default: Object },
      isDetailEdit: { default: false },
      items: { default: [] },
      units: { default: [] },
      taxes: { default: [] },
      warehouses: { default: [] },
   },
   computed: {},
   components: {},
   watch: {
      "detail.item_id"(newID, oldID) {
         this.detail.unit_id = null;
         if (this.detail.item_id) {
            var getItem = this.detail.item_id ? this.$global.FilterArrayOfObject(this.items, 'id', this.detail.item_id)[0] : Object;
            this.detail.item_units = [];
            this.detail.item_units.push(
               {
                  id: getItem.small_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.small_unit_id, 'name')
               },
               {
                  id: getItem.mid_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.mid_unit_id, 'name')
               },
               {
                  id: getItem.big_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.big_unit_id, 'name')
               },
            );
            switch (getItem.default_report_unit_type) {
               case 1:
                  this.detail.unit_id = this.detail.unit_id ?? getItem.small_unit_id;
                  break;
               case 2:
                  this.detail.unit_id = this.detail.unit_id ?? getItem.mid_unit_id;
                  break;
               case 3:
                  this.detail.unit_id = this.detail.unit_id ?? getItem.big_unit_id;
                  break;
               default:
                  break;
            }
            this.detail.price_include_vat = +getItem.price_include_vat;
            if (newID == oldID) {
               this.detail.price = this.detail.price ? this.detail.price : getItem.sale_price;
               this.detail.tax_percent = this.detail.tax_percent ?? getItem.tax_percent;
               this.detail.quantity = this.detail.quantity ?? 1;
               this.detail.tax_percent_id = this.detail.tax_percent_id ?? getItem.tax_id;
               this.detail.min_sale_price = this.detail.min_sale_price ?? getItem.min_sale_price;
               this.detail.warehouse_id = this.detail.warehouse_id ?? this.warehouses[0].id;
            } else {
               this.detail.price = this.isDetailEdit && !oldID ? this.detail.price : getItem.sale_price;
               this.detail.tax_percent = this.isDetailEdit && !oldID ? this.detail.tax_percent : getItem.tax_percent;
               this.detail.quantity = this.isDetailEdit && !oldID ? this.detail.quantity : 1;
               this.detail.tax_percent_id = this.isDetailEdit && !oldID ? this.detail.tax_percent_id : getItem.tax_id;
               this.detail.min_sale_price = this.isDetailEdit && !oldID ? this.detail.min_sale_price : getItem.min_sale_price;
               this.detail.warehouse_id = this.isDetailEdit && !oldID ? this.detail.warehouse_id : this.warehouses[0].id;
            }

         }
      },
      '$store.state.Settings.key_clicked'() {
         switch (this.$store.state.Settings.key_clicked) {
            case 'F10':
               if (this.valid && this.dialog) {
                  this.save()
               }
               break;
            case 'Escape':
               this.closeDialog()
               break;
            default:
               break;
         }
      },
   },
   data: () => ({
      isLoading: false,
      valid: false,
      modalKey: 0,
   }),
   methods: {
      closeDialog() {
         this.detailAction(null, false, true);
         this.modalKey = this.modalKey + 1
      },
      save() {
         this.modalKey = this.modalKey + 1
         this.detailAction(this.detail, this.isDetailEdit, false);
      },
   },
   mounted() {
   },
};
</script>