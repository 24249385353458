<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="3"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader"  :maxPDFRows="38" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object" printTitle="اقرار الضريبي"
                :printSubTitle="' من ' + printStaticData.from + ' الي ' + (printStaticData.to ?  printStaticData.to : $global.GetCurrentDate())">
               <template v-slot:pdf_content="{ page, table_data }">

                  <TaxReturnView :is_view="page == 1" :tax_return="table_data" />

               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>


   </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import PrintPDF from '@/components/PrintPDF.vue';
import TaxReturnView from './TaxReturnView.vue';
export default {
   name: "TaxReturnPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
   },
   computed: {

   },
   components: {
      VueHtml2pdf,
      TaxReturnView,
      PrintPDF
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
.print_title {
   width: fit-content;
   text-align: center;
   font-size: 14px !important;
   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 3px 6px;
   margin: auto;

   div {
      font-size: 16px !important;
      font-weight: bold;
   }
}
</style>