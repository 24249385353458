<template>
  <div>
    <v-main v-if="$store.state.splashScreen == false" class="light">
      <v-container fluid class="pa-0">
        <v-app-bar @mousewheel="$store.state.active_menu = 0" class="shadow" app>
          <Appbar />
        </v-app-bar>

        <v-container fluid class="pa-0">
          <router-view></router-view>
        </v-container>

        <v-footer outlined min-width="100%" fixed padless v-if="networkConnection == false">
          <v-alert
            color="grey darken-1"
            icon="mdi-wifi-off"
            border="top"
            colored-border
            width="100%"
            dense
            elevation="0"
            class="ma-0"
            rounded="0"
          >
            <p class="text-center text--secondary font-weight-bold ma-0" style="width: 100%;">
              {{ $t("No Internet Connection") }}
              <v-icon class="mx-2">mdi-wifi-off</v-icon>
            </p>
          </v-alert>
        </v-footer>
      </v-container>

      <v-snackbar multi-line top :left="!$vuetify.rtl" color="gray3" :right="$vuetify.rtl"
      v-model="$store.state.showSnackbar" :timeout="4000">
      <div :class="`${$store.state.snackbarType}--text`">{{ $t($store.state.snackbarTitle) }}</div>
      <div class="body-1" v-for="(message, index) in $store.state.snackbarMessages" :key="index">{{ $t(message) }}
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn :color="$store.state.snackbarType" text v-bind="attrs" @click="$store.state.showSnackbar = false">
          {{ $t('hide') }}
        </v-btn>
      </template>
    </v-snackbar>
    </v-main>
  </div>
</template>


<script>
import Appbar from "@/components/ui/Appbar.vue";
export default {
  name: "Dashboard",
  components: {
    Appbar,
  },
  mounted() {
    this.darkTheme = this.$store.state.Settings.darkTheme;
    setInterval(() => {
      this.networkConnection = this.$api.CHECK_INTERNET();
      this.$store.state.networkConnection = this.$api.CHECK_INTERNET();
    }, 2000);

    window.addEventListener("keydown", e => {
      // console.log('key', e.key);
      this.$store.commit('Settings/SET_KEY_CLICKED', e.key);
      setTimeout(() => {
        this.$store.commit('Settings/SET_KEY_CLICKED', null);
      }, 100);
      switch (e.key) {
        case 'F1':
        case 'F3':
        case 'F5':
        case 'F6':
        case 'F7':
        case 'F10':
          e.preventDefault();
          break;
        default:
          break;
      }
    });  
  },
  data() {
    return {
      mini: false,
      darkTheme: false,
      networkConnection: Boolean
    }
  },
  computed: {
    showSnackbar() {
      return this.$store.state.showSnackbar
    }
  },
  watch: {
    showSnackbar() {
      if (!this.showSnackbar) {
        setTimeout(() => {
          this.$store.state.snackbarType = 'info';
          this.$store.state.snackbarTime = 4500;
          this.$store.state.snackbarTitle = null;
          this.$store.state.snackbarMessages = [];
        }, 100);
      }
    }
  },
  methods: {
    darkThemee() {
      this.$store.dispatch("Settings/darkTheme");
    },
  },

}
</script>
<style scoped>
</style>