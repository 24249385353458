<template>
  <v-card class="shadow rounded-lg recipeChart my-2">
    <v-row justify="center">

      <v-col cols="6" class="py-0 mb-3" id="chart" :key="key">
        <div class="pa-4">{{ $t('sales reports') | capitalize }} : </div>
        <apexchart type="donut" height="400" :options="chartOptions" :series="summary"></apexchart>
      </v-col>
      <v-col cols="6" class="py-0 mb-3" id="chart" :key="key">
        <div class="pa-4">{{ $t('quantity') | capitalize }} : </div>
        <apexchart type="donut" height="400" :options="chartOptions" :series="quantities"></apexchart>
      </v-col>
    </v-row>
  </v-card>
</template>







<script>
export default {
  name: "SalesChartDonut",
  computed: {
  },
  props: {
    summary: { default: [] },
    summary_categories: { default: [] },
    quantities: { default: [] },
  },
  watch: {
    summary_categories() {

    }
  },
  data: () => ({
    key: 1,
    width: 0,

    series: [],
    series2: [],
    chartOptions: {
      chart: {
        type: 'polarArea',
      },
      stroke: {
        colors: ['#fff']
      },
      fill: {
        opacity: 0.8
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      labels: [],
      colors: ["#334d6e" , "#44abe1","#f65354"],
    },


  }),
  mounted() {

    this.width =
      document.getElementById("chart").offsetWidth < 490
        ? document.getElementById("chart").offsetWidth - 50
        : document.getElementById("chart").offsetWidth - 210;
    if (this.width > 0) {
      this.chartOptions.labels = this.summary_categories;

      this.series = [
        {
          name: this.$i18n.t('sales reports'),
          data: [...this.summary],
        },
        {
          name: this.$i18n.t('quantity'),
          data: [...this.quantities],
        },
      ];
      this.key = this.key + 1

    }


  }

};
</script>
