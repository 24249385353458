<template>
  <section>
    <v-container fluid class="pa-0">
      <v-container class="d-flex justify-center" fluid style="transition : 0.35s">
        <v-col cols="12" sm="12" class="pb-0">
          <v-row justify="start"> 

            <v-col cols="12" xl="2" lg="3" md="4" sm="6" v-for="(menu) in $store.state.screens" :key="menu.id">
              <v-card class="mx-auto shadow rounded-lg" dark>
                <v-card-title>
                  <span class="text-h6">{{ menu.title }}</span>
                </v-card-title>
                <!-- <v-icon large class="float-icon">{{ menuList[2].icon }}</v-icon> -->
                <div class="float-icon">
                  <img v-if="menu.image" :src="$api.serverUrl + menu.image" :alt="menu.title" />
                  <v-icon v-if="!menu.image">mdi-file-image-outline</v-icon>
                </div>
                <v-list-item class="d-block" two-line>
                  <v-list-item-content v-for="(cats, y) in menu.cats" :key="y">
                    <v-list-item-title>{{ cats.title }}</v-list-item-title>
                    <v-list-item-subtitle class="d-flex align-center " v-for="(list) in cats.subs" :key="list.sub_id">
                      <v-btn text icon fab x-small class="mx-1" @click="addToFav(list)" :style="
                        favourites.some((fav) => fav.url === list.url) ? 'opacity: 1' : 'opacity: 0.3'
                      ">
                        <v-icon>mdi-star</v-icon>
                      </v-btn>
                      <v-btn plan text small class="py-0 ma-0 text-capitalize" :to="list.url" style="opacity: 0.7;">{{
                        list.sub_title
                      }}</v-btn>

                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </v-container>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
export default {
  name: "DashboardIndex",
  computed: {
    ...mapState(["favourites"]),
  },
  components: {

  },
  data() {
    return {
      menus: [],
    };
  },
  mounted() {
    this.menus = this.$store.state.screens
  },
  methods: {
    ...mapActions(["addFavourites"]),

    addToFav(page) {
      const fav = {
        screen_code: page.screen_code,
        name: page.sub_title,
        url: page.url,
      };
      this.addFavourites(fav).finally(() => { });
    },
  },
}
</script>

<style scoped  lang="scss">
.col-12:nth-of-type(1) .v-card {
  background-color: #4f6aef;
}

.col-12:nth-of-type(2) .v-card {
  background-color: #35c2fc;
}

.col-12:nth-of-type(3) .v-card {
  background-color: #fdba84;
}

.col-12:nth-of-type(4) .v-card {
  background-color: #50dfb3;
}
.col-12:nth-of-type(5) .v-card {
  background-color: #6e82c6;
}

.col-12:nth-of-type(6) .v-card {
  background-color: #11519b;
}

.col-12:nth-of-type(7) .v-card {
  background-color: #4f6aef;
}
.float-icon {
  position: absolute !important;
  top: 9px;
  right: 9px;
  font-size: 58px !important;
  height: 40px;
  width: 40px;
  padding: 1.5rem;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.35);
  display: none;

  img {
    position: relative;
    top: -3.09rem;
    height: 28px;
    left: 0.87rem;
  }

  i {
    position: relative;
    top: -3.09rem;
    height: 28px;
    left: 0.75rem;
  }
}

.v-application--is-rtl {
  .float-icon {
    right: unset;
    left: 9px;
  }
}
</style>