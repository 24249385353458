<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData"
        :createBtnText="$i18n.t('add') + ' ' + this.$store.getters.activeScreen.sub_title" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden">
              <v-col cols="12" class="pa-0 px-10">
                <v-tabs color="secondary" active-class="green11">
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold" :value="1" @click="tab = 1"><strong>
                      {{ $t('pending purchase order') }}</strong>
                  </v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold" :value="3" @click="tab = 3"><strong>
                      {{ $t('decline purchase order') }}</strong>
                  </v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold" :value="2" @click="tab = 2"><strong>
                      {{ $t('approved purchase order') }}</strong>
                  </v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold" :value="4" @click="tab = 4"><strong>
                      {{ $t('completed purchase order') }}</strong>
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
              <DynamicTable :isLoading="pageData.isLoading" deleteProperty="m_id" :data="pageData.rows"
                :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" cardClasses="shadow-none">
                <template v-slot:td="{ row,header, index }">

                  <span class="d-flex justify-center" style="width : 190px" v-if="header.key == 'status'">
                    <v-select @change="changeStatus(index)" v-if="row.purchases_order_status == 1"
                      v-model="row.change_status" :items="[
                        { id: 1, name: $i18n.t('accept') }, { id: 2, name: $i18n.t('not accept') }, { id: 3, name: $i18n.t('delete') }
                      ]" :label="$i18n.t('select')" dense hide-details item-text="name" item-value="id" outlined>
                    </v-select>
                    <span v-if="row.purchases_order_status == 2" class="success--text">
                      {{ $t('approved purchase order') }}</span>
                    <span v-if="row.purchases_order_status == 4" class="success--text">
                      {{ $t('completed purchase order') }}</span>
                    <span v-if="row.purchases_order_status == 3" class="error--text">
                      {{ $t('decline purchase order') }}</span>
                  </span>

                  <div v-if="header.key == 'actions'">
                    <PrintInvoice endpoint="print_purchases_order" :screenCode="pageData.screen_code" :id="row.m_id" />
                    <v-btn @click="edit(row)" x-small icon fab>
                      <img src="@/assets/img/svg_icons/fi_eye.svg" height="16" />
                    </v-btn>
                  </div>
                </template>
              </DynamicTable>
            </v-card>
          </v-col>

        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "PurchasesOrderEntity",

  data: () => ({
    pageData: {
      screen_code: "04-004",
      url: null,
      controlRoute: "purchase/purchases_order-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    tab: 1,
  }),
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      this.getData();
      switch (this.tab) {
        case 1:
          this.pageData.tableHeader = [
            { text: "invoice no", key: "m_id", type: 'text', classes: "" },
            { text: "date", key: "purchases_order_no_date", type: 'date',width: 120, classes: "" },
            { text: "supplier", key: "purchases_vendor_name", type: 'text', classes: "" },
            { text: "payment method", key: "payment_method_name", type: 'text', classes: "" },
            { text: "total without vat", key: "total_without_vat", type: 'price', classes: "" },
            { text: "total vat", key: "total_vat", type: 'price', classes: "" },
            { text: "total with vat", key: "total_with_vat", type: 'price', classes: "" },
            { text: "status", key: "status", type: 'slot', classes: "" },
            { text: "actions", key: "id", type: 'actions', classes: "" },
          ];
          break;

        default:
          this.pageData.tableHeader = [
            { text: "invoice no", key: "m_id", type: 'text', classes: "" },
            { text: "date", key: "purchases_order_no_date", type: 'date',width: 120, classes: "" },
            { text: "supplier", key: "purchases_vendor_name", type: 'text', classes: "" },
            { text: "payment method", key: "payment_method_name", type: 'text', classes: "" },
            { text: "total without vat", key: "total_without_vat", type: 'price', classes: "" },
            { text: "total vat", key: "total_vat", type: 'price', classes: "" },
            { text: "total with vat", key: "total_with_vat", type: 'price', classes: "" },
            { text: "status", key: "status", type: 'slot', classes: "" },
            { text: "actions", key: "actions", type: 'slot', classes: "" },
          ];
          break;
      }
    }
  },
  components: {},
  computed: {

  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "invoice no", key: "m_id", type: 'text', classes: "" },
        { text: "date", key: "purchases_order_no_date", type: 'date',width: 120, classes: "" },
        { text: "supplier", key: "purchases_vendor_name", type: 'text', classes: "" },
        { text: "payment method", key: "payment_method_name", type: 'text', classes: "" },
        { text: "total without vat", key: "total_without_vat", type: 'price', classes: "" },
        { text: "total vat", key: "total_vat", type: 'price', classes: "" },
        { text: "total with vat", key: "total_with_vat", type: 'price', classes: "" },
        { text: "status", key: "status", type: 'slot', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `purchases_order${this.pageData.queryParam}&status=${this.tab}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`purchases_order/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`purchases_order_toggle_active/${row.m_id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/purchases_order/${row.m_id}`)
    },
    changeStatus(index) {
      this.pageData.isLoading = true;
      if (this.pageData.rows[index].change_status == 1) {
        this.$api.POST_METHOD(`accept_purchase_order/${this.pageData.rows[index].m_id}`, null).then((response) => {
          if (response.check) {
            this.getData();
          }
        })
      }
      if (this.pageData.rows[index].change_status == 2) {
        this.$api.POST_METHOD(`decline_purchase_order/${this.pageData.rows[index].m_id}`, null).then((response) => {
          if (response.check) {
            this.getData();
          }
        })
      }
      if (this.pageData.rows[index].change_status == 3) {
        this.deleteMethod(this.pageData.rows[index].m_id)
      }

    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.m_id);
    },
  },
};
</script>
