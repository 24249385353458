// Codes
import AccountingTree from "@/views/AccountingModule/Codes/AccountingTree/AccountingTree";
import CostCenter from "@/views/AccountingModule/Codes/CostCenter/CostCenter";
import TaxEntity from "@/views/AccountingModule/Codes/Tax/TaxEntity";
import TaxControl from "@/views/AccountingModule/Codes/Tax/TaxControl";
import ExpensesCodeEntity from "@/views/AccountingModule/Codes/ExpensesCode/ExpensesCodeEntity.vue";
import ExpensesCodeControl from "@/views/AccountingModule/Codes/ExpensesCode/ExpensesCodeControl.vue";
import TreasuryEntity from "@/views/AccountingModule/Codes/Treasury/TreasuryEntity";
import TreasuryControl from "@/views/AccountingModule/Codes/Treasury/TreasuryControl";
// Transactions
import DailyJournalEntity from "@/views/AccountingModule/Transactions/DailyJournal/DailyJournalEntity";
import DailyJournalControl from "@/views/AccountingModule/Transactions/DailyJournal/DailyJournalControl";
import ReceiptVoucherEntity from "@/views/AccountingModule/Transactions/ReceiptVoucher/ReceiptVoucherEntity";
import ReceiptVoucherControl from "@/views/AccountingModule/Transactions/ReceiptVoucher/ReceiptVoucherControl";
import PaymentVoucherEntity from "@/views/AccountingModule/Transactions/PaymentVoucher/PaymentVoucherEntity";
import PaymentVoucherControl from "@/views/AccountingModule/Transactions/PaymentVoucher/PaymentVoucherControl";
import FollowIncomingChecksEntity from "@/views/AccountingModule/Transactions/ChequesIncomingOutgoing/FollowIncomingChecksEntity";
import FollowOutgoingChecksEntity from "@/views/AccountingModule/Transactions/ChequesIncomingOutgoing/FollowOutgoingChecksEntity";
import FinancialPeriodsEntity from "@/views/AccountingModule/Transactions/FinancialPeriods/FinancialPeriodsEntity";
import FinancialPeriodsControl from "@/views/AccountingModule/Transactions/FinancialPeriods/FinancialPeriodsControl";
import MultiVoucherEntity from "../views/AccountingModule/Transactions/MultiVoucher/MultiVoucherEntity.vue";
import MultiVoucherControl from "../views/AccountingModule/Transactions/MultiVoucher/MultiVoucherControl.vue";
// Reports
import AccountStatmentAnalReportEntity from "@/views/AccountingModule/Reports/AccountStatmentAnalReport/AccountStatmentAnalReportEntity";
import TrialBalanceEntity from "@/views/AccountingModule/Reports/TrialBalance/TrialBalanceEntity";
import CurrencyEntity from "@/views/AccountingModule/Codes/Currency/CurrencyEntity.vue";
import TaxReturnEntity from "@/views/AccountingModule/Reports/TaxReturn/TaxReturnEntity.vue";

// Accounting Module Routes:-
export const AccountingModuleChildrens = [
  // Accounting Tree
  {
    path: "/accounting/account_tree",
    name: "AccountingTree",
    component: AccountingTree,
    meta: {
      screen_code: "02-001",
    },
  },
  // Cost Center Tree
  {
    path: "/accounting/cost_center",
    name: "CostCenter",
    component: CostCenter,
    meta: {
      screen_code: "02-002",
    },
  },
  // Daily Journal
  {
    path: "/accounting/daily_journal",
    name: "DailyJournalEntityVue",
    component: DailyJournalEntity,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/accounting/daily_journal-control",
    name: "DailyRestrictionsControl",
    component: DailyJournalControl,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/accounting/daily_journal-control/:id",
    name: "DailyJournalControlVueEdit",
    component: DailyJournalControl,
    meta: {
      screen_code: "02-003",
    },
  },
  // Receipt Voucher
  {
    path: "/accounting/receipt_voucher",
    name: "ReceiptVoucherEntity",
    component: ReceiptVoucherEntity,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/accounting/receipt_voucher-control",
    name: "ReceiptVoucherControl",
    component: ReceiptVoucherControl,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/accounting/receipt_voucher-control/:id",
    name: "ReceiptVoucherControlEdit",
    component: ReceiptVoucherControl,
    meta: {
      screen_code: "02-007",
    },
  },
  // Payment Voucher
  {
    path: "/accounting/payment_voucher",
    name: "PaymentVoucherEntity",
    component: PaymentVoucherEntity,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/accounting/payment_voucher-control",
    name: "PaymentVoucherControl",
    component: PaymentVoucherControl,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/accounting/payment_voucher-control/:id",
    name: "PaymentVoucherControlEdit",
    component: PaymentVoucherControl,
    meta: {
      screen_code: "02-008",
    },
  },
  // Treasury
  {
    path: "/accounting/treasury",
    name: "TreasuryEntity",
    component: TreasuryEntity,
    meta: {
      screen_code: "02-006",
    },
  },
  {
    path: "/accounting/treasury-control",
    name: "TreasuryControl",
    component: TreasuryControl,
    meta: {
      screen_code: "02-006",
    },
  },
  {
    path: "/accounting/treasury-control/:id",
    name: "TreasuryControlVueEdit",
    component: TreasuryControl,
    meta: {
      screen_code: "02-006",
    },
  },
  // account statement anal report
  {
    path: "/accounting/account_statment_anal_report",
    name: "AccountStatmentAnalReport",
    component: AccountStatmentAnalReportEntity,
    meta: {
      screen_code: "02-004",
    },
  },
  //trial balance report
  {
    path: "/accounting/trial_balance_report",
    name: "TrialBalanceEntity",
    component: TrialBalanceEntity,
    meta: {
      screen_code: "02-005",
    },
  },
  // Follow incoming checks
  {
    path: "/accounting/cheque_incoming",
    name: "FollowIncomingChecksEntityVue",
    component: FollowIncomingChecksEntity,
    meta: {
      screen_code: "02-010",
    },
  },
  // Follow incoming checks
  {
    path: "/accounting/cheque_outgoing",
    name: "FollowOutgoingChecksEntity",
    component: FollowOutgoingChecksEntity,
    meta: {
      screen_code: "02-011",
    },
  },
  // Financial Periods
  {
    path: "/accounting/financial_periods",
    name: "FinancialPeriodsEntityVue",
    component: FinancialPeriodsEntity,
    meta: {
      screen_code: "02-009",
    },
  },
  {
    path: "/accounting/financial_period-control",
    name: "FinancialPeriodsControlVue",
    component: FinancialPeriodsControl,
    meta: {
      screen_code: "02-009",
    },
  },
  {
    path: "/accounting/financial_period-control/:id",
    name: "FinancialPeriodsControlVueEdit",
    component: FinancialPeriodsControl,
    meta: {
      screen_code: "02-009",
    },
  },

  // Financial Periods
  {
    path: "/accounting/tax",
    name: "TaxEntity",
    component: TaxEntity,
    meta: {
      screen_code: "02-012",
    },
  },
  {
    path: "/accounting/tax-control",
    name: "TaxControl",
    component: TaxControl,
    meta: {
      screen_code: "02-012",
    },
  },
  {
    path: "/accounting/tax-control/:id",
    name: "TaxControlEdit",
    component: TaxControl,
    meta: {
      screen_code: "02-012",
    },
  },
  {
    path: "/accounting/multi_voucher",
    name: "MultiVoucherEntity",
    component: MultiVoucherEntity,
    meta: {
      screen_code: "02-013",
    },
  },
  {
    path: "/accounting/multi_voucher-control",
    name: "MultiVoucherControl",
    component: MultiVoucherControl,
    meta: {
      screen_code: "02-013",
    },
  },
  {
    path: "/accounting/multi_voucher-control/:id",
    name: "MultiVoucherControlEdit",
    component: MultiVoucherControl,
    meta: {
      screen_code: "02-013",
    },
  },
  // Expenses Code
  {
    path: "/accounting/expense_code",
    name: "ExpensesCodeEntity",
    component: ExpensesCodeEntity,
    meta: {
      screen_code: "02-014",
    },
  },
  {
    path: "/accounting/expense_code-control",
    name: "ExpensesCodeControl",
    component: ExpensesCodeControl,
    meta: {
      screen_code: "02-014",
    },
  },
  {
    path: "/accounting/expense_code-control/:id",
    name: "ExpensesCodeControlVueEdit",
    component: ExpensesCodeControl,
    meta: {
      screen_code: "02-014",
    },
  },
  // Currency
  {
    path: "/accounting/currency",
    name: "CurrencyEntity",
    component: CurrencyEntity,
    meta: {
      screen_code: "02-015",
    },
  },
  // Tax return
  {
    path: "/accounting/tax_return",
    name: "TaxReturnEntity",
    component: TaxReturnEntity,
    meta: {
      screen_code: "02-016",
    },
  },
];
