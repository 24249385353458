<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :hasSteps="true" :steps="steps" :activeStep="activeStep" createBtnText="save shipment"
            :actionBtn="pageData.isEdit" actionBtnText="incoming details" :actionBtnColor="'blue5'"
            :actionBtnValid="actionBtnValid" :actionBtnClick="actionBtnClick" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="shadow pa-5 pt-7 rounded-xl" :color="activeStep == 1 ? 'backgroundW' : 'blue12'">
               <v-row align="center" v-if="activeStep == 1">
                  <v-col cols="12" class="py-0">
                     <div class="subtitle-2 gray7--text">{{ $t("client information") | capitalize }}</div>
                  </v-col>
                  <GenericInput type="autocomplete" :lookups="clients" :value="form.customer_id"
                     :placeholder="'search for client'" paddingY="py-md-10" @input="form.customer_id = $event"
                     :icon="'mdi-account-outline'" label="client name" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>


                  <v-col cols="6" md="auto" class="py-0" v-if="form.customer_id">
                     <v-card color="transparent" class="pa-7 elevation-0">
                        <div class="text-center subtitle-1 font-weight-regular blue4--text">
                           {{ $t('client transaction status') | capitalize }} <router-link :to="'/'">
                              <v-icon color="blue4">mdi-link</v-icon>
                           </router-link>
                        </div>
                        <div class="text-center font-weight-bold body-1 green1--text">
                           <span class="blue4--text"> {{ selectedClient.incoming_type | capitalize }}</span>
                           <v-icon color="blue1" size="15" class="mx-2">mdi-arrow-left-circle-outline</v-icon>
                           <span class="blue4--text"> {{ selectedClient.outgoing_type | capitalize }}</span>
                        </div>
                     </v-card>
                  </v-col>
                  <v-col cols="12" md="auto" class="d-flex py-0" v-if="form.customer_id">
                     <v-col cols="6" md="auto" class="py-0">
                        <v-card color="green12" class="pa-7 elevation-0">
                           <div class="text-center text-h6 font-weight-regular green1--text">
                              {{ $t('high score') | capitalize }}
                           </div>
                           <div class="text-center font-weight-bold body-1 green1--text">
                              {{ selectedClient.high_score || 0 }} {{ $t('pallet') | capitalize }}
                           </div>
                        </v-card>
                     </v-col>
                     <v-col cols="6" md="auto" class="pt-0">
                        <v-card color="yellow12" class="pa-7 elevation-0">
                           <div class="text-center text-h6 font-weight-regular yellow1--text">
                              {{ $t('inStock') | capitalize }}
                           </div>
                           <div class="text-center font-weight-bold body-1 yellow1--text">
                              {{ selectedClient.actual_score || 0 }} {{ $t('pallet') | capitalize }}
                           </div>
                        </v-card>
                     </v-col>
                  </v-col>


                  <v-col cols="12" class="pa-0 pb-5">
                     <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                     <div class="subtitle-2 gray7--text mb-5">{{ $t("driver information") | capitalize }}</div>
                  </v-col>
                  <GenericInput type="text" :value="form.driver_name" @input="form.driver_name = $event"
                     :placeholder="'Ex:. Mohamed Ahmed'" :icon="'mdi-account-outline'" label="driver name"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
                  <GenericInput type="number" :value="form.driver_id" @input="form.driver_id = $event"
                     :placeholder="'Ex:. 2451122123'" :icon="'mdi-card-account-details-outline'" label="driver id"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
                  <GenericInput type="number" :value="form.driver_phone_code" @input="form.driver_phone_code = $event"
                     icon="mdi-phone-outline" label="" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 1]"></GenericInput>
                  <GenericInput type="number" :value="form.driver_phone_number"
                     @input="form.driver_phone_number = $event" :placeholder="'Ex:. 5012345678'" label="driver phone"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>
                  <v-col cols="12" class="pa-0 pb-5">
                     <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                     <div class="subtitle-2 gray7--text mb-5">{{ $t("shipment & car information") | capitalize }}</div>
                  </v-col>
                  <GenericInput type="number" :value="form.car_numbers" @input="form.car_numbers = $event"
                     :icon="'mdi-car-back'" :placeholder="'Ex:. xxx xxxx'" label="car number" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]"></GenericInput>
                  <GenericInput type="text" :value="form.car_digits" @input="form.car_digits = $event"
                     :icon="'mdi-car-back'" label="car digits" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 2]"></GenericInput>
                  <GenericInput type="date" :value="form.incoming_date"
                     @input="form.incoming_date = $event" label="date" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]"></GenericInput>
                  <GenericInput type="time" :value="form.incoming_time"
                     @input="form.incoming_time = $event" label="time" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]"></GenericInput>
                  <GenericInput type="switch" :value="form.has_seals_number"
                     @input="form.has_seals_number = $event" label="has seals" :required="false"
                     :labelInline="false" :hideDetails="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                  </GenericInput>
                  <GenericInput type="number" v-if="form.has_seals_number" :icon="'mdi-lock-outline'"
                     :value="form.seals_number" @input="form.seals_number = $event" label="seals number"
                     :required="true" :isLoading="pageData.editIsLoading" 
                     :cols="[12, 6, 2]">
                  </GenericInput>
                  <GenericInput type="textarea" :value="form.damage_case" :rowsNumber="6"
                     @input="form.damage_case = $event" label="damage case" :hideDetails="true" :required="false"
                     :isLoading="pageData.editIsLoading" :placeholder="'describe the damage if available'"
                     :cols="[12, 6, 6]"></GenericInput>
                  <GenericInput type="dropzone" :value="form.incoming_photos"
                     @input="form.incoming_photos = $event" label="damage case" :required="false"
                     :labelInline="false" :isLoading="pageData.editIsLoading" :multi="true"
                     :placeholder="'describe the damage if available'" :cols="[12, 6, 6]"></GenericInput>
               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "InComingControl",
   data: () => ({
      pageData: {
         screen_code: "08-002",
         url: null,
         controlRoute: "/warehouses/incoming-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      actionBtnValid: true,
      tableRows: [],
      selectedClient: null,
      clients: [],
      activeStep: 1,
      steps: [{ id: 1, name: 'Add Car' }, { id: 2, name: 'Add Pallets' }],
      form: {
         customer_id: null,
         driver_name: null,
         driver_id: null,
         driver_phone_code: "966",
         driver_phone_number: null,
         car_numbers: null,
         car_digits: null,
         incoming_date: null,
         incoming_time: null,
         damage_case: null,
         incoming_photos: [],
         has_seals_number: null,
         seals_number: null,
      },
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   watch: {
      'form.customer_id'() {
         this.selectedClient = this.$global.FilterArrayOfObject(this.clients, 'id', this.form.customer_id, 'sale_price')[0];
      }
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('incoming') : this.$i18n.t('add incoming');
         this.tableHeader = [
            { text: "pallet no", key: "item_id", type: 'text', classes: "" },
            { text: "addition date", key: "unit_id", type: 'text', classes: "" },
            { text: "include case", key: "price", type: 'float', classes: "" },
            { text: "delete", key: "id", type: 'delete', classes: "" },
         ];
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`incoming/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.clients = response.data.customers;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`incoming/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.clients = response.data.customers;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`incoming/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)

                  }
               })
            }
            else {
               this.$api.POST_METHOD(`incoming`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(`/warehouses/incoming-details/${response.data.id}`)
                  }
               })
            }
         }
      },
      actionBtnClick() {
         this.$router.push(`/warehouses/incoming-details/${this.$route.params.id}`)
      },
      addItem() {
         this.tableRows.push(this.newItem);
         this.newItem = {
            item_id: null,
            unit_id: null,
            price: null,
            count: null,
         }
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`incoming/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
