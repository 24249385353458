<template>
   <section :class="{ 'is_view': is_view }">
      <table id="tax_return">
         <tr v-if="is_view">
            <td></td>
            <td>
               المبلغ
               <div>(ريال)</div>
            </td>
            <td>
               مبلغ التعديل
               <div>(ريال)</div>
            </td>
            <td>
               المبلغ الضريبه القيمه المضافة
               <div>(ريال)</div>
            </td>
         </tr>
         <tr v-for="(row, index) in tax_return" :key="index">
            <td  v-for="(td,tdIndex) in row" :key="tdIndex">
               <div v-if="tdIndex == 0" :style="row.length > 1 ? '' : 'font-weight : 900'">{{ td }}</div>
               <div v-else>{{ td |float }}</div>
            </td>
         </tr>
      </table>
   </section>
</template>

<script>
export default {
   name: "TaxReturnView",
   props: {
      tax_return: { default: [] },
      is_view: { default: false },
   },
   computed: {

   },
   components: {

   },
   watch: {

   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
#tax_return {
   width: 100%;
   margin: 20px 0;

   tr {
      background-color: transparent !important;

      &:nth-of-type(1) {
         td {
            font-size: 10px !important;
            font-weight: bold;
         }
      }

      &:not(&:nth-of-type(1)) {
         td {

            padding: 5px;
            background-color: transparent !important;
            border: 0 solid !important;
            text-align: start !important;
            font-size: 10px !important;
            font-weight: 500;

            &:not(&:nth-of-type(1)) {
               width: 20%;

               div {
                  border: 1px solid #000;
                  padding: 2px 6px;
               }
            }
         }
      }

   }


}
</style>