<template>
  <v-form ref="form" v-model="valid" class="pa-6" style="text-align: initial;">
    <v-row align="center" justify="space-around">
      <v-col cols="5">
        <span v-if="selectionType == 'add' && parent.name">{{ $t(selectionType) + " " + $t('in') + " " + parent.name
        }}</span>
        <span v-if="selectionType == 'edit' && form.name">{{ $t(selectionType) + " " + form.name }}</span>
      </v-col>
      <v-col cols="5">
        <v-btn color="green11" class="d-block rounded-lg green2--text mi-start-auto" @click="save" :loading="isLoading"
          :disabled="!valid" :width="100" depressed>{{ $t('save') }}</v-btn>
      </v-col>


      <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar" :required="true"
        :isLoading="false" :cols="[5, 5, 5]"></GenericInput>
      <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en" :required="true"
        :isLoading="false" :cols="[5, 5, 5]"></GenericInput>
      <v-col cols="4">
        <div class="body-2">{{ $t('category type') }} :</div>
      </v-col>

      <GenericInput type="radiogroup"
        :lookups="[{ name: $i18n.t('main'), id: 1 }, { name: $i18n.t('sub-parent'), id: 0 }]" selected_label="name"
        selected_prop="id" :value="form.category_type" @input="form.category_type = $event" label=" " :isLoading="false"
        :cols="[5, 5, 5]"></GenericInput>


    </v-row>
  </v-form>
</template>

 
<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
  name: "CategoryControl",
  data: () => ({
    valid: false,
    isLoading: false,
    form: {
      account_code: null,
      name_en: null,
      name_ar: null,
      category_type: 1,
      account_nature: null,
      protection: false,
      cost_center: null,
      cost_center_id: []
    },
    parent: {
      account_code: null,
      name_en: null,
      name_ar: null,
      category_type: 1,
      account_nature: 1,
      protection: false,
      cost_center: null,
      cost_center_id: []
    },

  }),
  props: {
    backValueMethod: { type: Function },
    selectionType: { default: "" },
    item: { default: {} },
    cost_centers: { default: {} },
  },
  components: {
    GenericInput,
  },
  mounted() {
    console.log(this.selectionType, this.item);

    if (this.selectionType == "edit") {
      this.form = this.item
    } else {
      this.parent = this.item;
      this.form.account_nature = this.item.account_nature
      this.form.parent_id = this.item.id
    }
  },
  methods: {
    save() {
      this.isLoading = true;
      this.form.protection = this.form.protection == 1 ? 1 : 0;
      if (this.selectionType == "add") {
        delete this.form.account_code;
        let nodeData = { ...this.form }
        this.$api.POST_METHOD(`category`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.backValueMethod(nodeData)
          }
        })
      } else {
        let nodeData = { ...this.form }
        let requestBody = this.form
        delete requestBody.children
        let id = requestBody.id
        delete requestBody.id
        delete requestBody.name
        delete requestBody.type
        delete requestBody.hasChild
        this.$api.PUT_METHOD(`category`, id, requestBody).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.backValueMethod(nodeData)
          }
        })
      }
    }
  }

};
</script>
 