<template>
   <section>
      <v-container fluid class="">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :showCreate="false" :valid="true" />
         <!-- End Of Header -->


         <v-row v-if="!pageData.isLoading" align="center">
            <v-col cols="12" class="">
               <v-btn color="secondary" x-small class="d-block mt-3 mi-start-auto" @click="exportPDF" outlined icon fab>
                  <v-icon>mdi-printer-outline</v-icon>
               </v-btn>
            </v-col>
            <v-col cols="3" class="subtitle-2 text-center gray5--text">
               {{ $t('start date') }} :<span class="gray8--text">{{ report.start_date }}</span>
            </v-col>
            <v-col cols="3" class="subtitle-2 text-center  gray5--text">
               {{ $t('end date') }} :<span class="gray8--text">{{ report.end_date }}</span>
            </v-col>
            <v-col cols="3" class="subtitle-2 text-center  gray5--text">
               {{ $t('branch') }} :<span class="gray8--text">{{ report.branch_name }}</span>
            </v-col>
            <v-col cols="3" class="subtitle-2 text-center  gray5--text">
               {{ $t('employee') }} :<span class="gray8--text">{{ report.user_full_name }}</span>
            </v-col>
            <v-col cols="12">
               <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="8">
               <v-card class="backgroundW shadow pa-9">
                  <table class="mini_table_two">
                     <tr>
                        <th>{{ $t("") }}</th>
                        <th>{{ $t("start") }}</th>
                        <th>{{ $t("Transactions") }}</th>
                        <th>{{ $t("end") }}</th>
                        <th>{{ $t("difference") }}</th>
                     </tr>
                     <tr>
                        <td>{{ $t('cash') }}</td>
                        <td>{{ report.start_cash || 0 | float }}</td>
                        <td>{{ report.total_cash || 0 | float }}</td>
                        <td>{{ report.end_cash || 0 | float }}</td>
                        <td>{{ report.diff_cash || 0 | float }}</td>
                     </tr>
                     <tr>
                        <td>{{ $t('visa') }}</td>
                        <td>{{ 0 | float }}</td>
                        <td>{{ report.total_sales_visa || 0 | float }}</td>
                        <td>{{ report.end_visa || 0 | float }}</td>
                        <td>{{ report.diff_visa || 0 | float }}</td>
                     </tr>
                     <tr>
                        <td>{{ $t('master card') }}</td>
                        <td>{{ 0 | float }}</td>
                        <td>{{ report.total_sales_master || 0 | float }}</td>
                        <td>{{ report.end_master || 0 | float }}</td>
                        <td>{{ report.diff_master || 0 | float }}</td>
                     </tr>
                     <tr>
                        <td>{{ $t('mada') }}</td>
                        <td>{{ 0 | float }}</td>
                        <td>{{ report.total_sales_mada || 0 | float }}</td>
                        <td>{{ report.end_mada || 0 | float }}</td>
                        <td>{{ report.diff_mada || 0 | float }}</td>
                     </tr>
                     <tr>
                        <td>{{ $t('other') }}</td>
                        <td>{{ 0 | float }}</td>
                        <td>{{ report.total_sales_other || 0 | float }}</td>
                        <td>{{ report.end_other || 0 | float }}</td>
                        <td>{{ report.diff_other || 0 | float }}</td>
                     </tr>
                     <tr class="font-weight-bold">
                        <td>{{ $t('total') }}</td>
                        <td>{{ calculatedTotals.total_start | float }}</td>
                        <td>{{ calculatedTotals.total_actions || 0 | float }}</td>
                        <td>{{ calculatedTotals.total_end || 0 | float }}</td>
                        <td>{{ calculatedTotals.total_difference || 0 | float }}</td>
                     </tr>
                  </table>
               </v-card>
            </v-col>
            <v-col cols="12" md="4">
               <v-card class="backgroundW shadow pa-5">
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("total sales") }}</div>
                     <div>{{ report.total_sales || 0 | float }}</div>
                  </div>
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("sales cash") }}</div>
                     <div>{{ report.total_sales_cash || 0 | float }}</div>
                  </div>
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("master card") }}</div>
                     <div>{{ report.total_sales_master || 0 | float }}</div>
                  </div>
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("visa") }}</div>
                     <div>{{ report.total_sales_visa || 0 | float }}</div>
                  </div>
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("mada") }}</div>
                     <div>{{ report.total_sales_mada || 0 | float }}</div>
                  </div>
                  <!-- <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("transition") }}</div>
                           <div>{{ report.total_sales_trans  || 0 | float }}</div>
                        </div> -->
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("other") }}</div>
                     <div>{{ report.total_sales_other || 0 | float }}</div>
                  </div>
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("notice debtor") }}</div>
                     <div>{{ report.total_nd || 0 | float }}</div>
                  </div>
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("notice creditor") }}</div>
                     <div>{{ report.total_nc || 0 | float }}</div>
                  </div>
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("expenses") }}</div>
                     <div>{{ report.total_expenses || 0 | float }}</div>
                  </div>
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("difference cash") }}</div>
                     <div>{{ report.diff_cash || 0 | float }}</div>
                  </div>
                  <div class="summary_row d-flex justify-space-between">
                     <div>{{ $t("difference network") }}</div>
                     <div>{{ calculatedTotals.total_network || 0 | float }}</div>
                  </div>
               </v-card>
            </v-col>

         </v-row>

         <v-col cols="auto" class="my-16" v-if="pageData.isLoading">
            <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
            </v-progress-circular>
            <p class="text-center">{{ $t("please wait") }}</p>
         </v-col>

         <div>
            <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
               :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="pageData.entityName"
               :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
               :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
               <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">

                  <PrintPDF :header="[]" :maxPDFRows="20" :data="[{}]" :header_slot="false" :content_slot="true"
                     :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
                     :customize_header_footer="true" :printStaticData="printStaticData">
                     <template v-slot:pdf_content>

                        <v-col id="print_title">
                           {{ `${$t('workday report')}` }}
                        </v-col>

                        <table width="100%" style="margin-top:10px" class="mt-5">
                           <tr>
                              <td width="15%" style="border-bottom:2px solid #eee !important; " class="pa-2  gray6--text">
                                 {{ $t('start date') | capitalize }}
                              </td>

                              <td width="10%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                                 :
                              </td>

                              <td width="25%" style="border-bottom:2px solid #eee !important;  text-align:start !important"
                                 class="pa-2 ">
                                 {{ report.start_date | capitalize }}
                              </td>


                              <td width="15%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                                 {{ $t('end date') | capitalize }}
                              </td>

                              <td width="10%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                                 :
                              </td>
                              <td width="25%" style="border-bottom:2px solid #eee !important;  text-align:start !important"
                                 class="pa-2 ">
                                 {{ report.end_date | capitalize }}
                              </td>
                           </tr>
                        </table>
                        <table width="100%" style="margin-top:10px" class="mt-5">
                           <tr>
                              <td width="15%" style="border-bottom:2px solid #eee !important; " class="pa-2  gray6--text">
                                 {{ $t('branch') | capitalize }}
                              </td>

                              <td width="10%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                                 :
                              </td>

                              <td width="25%" style="border-bottom:2px solid #eee !important;  text-align:start !important"
                                 class="pa-2 ">
                                 {{ report.branch_name | capitalize }}
                              </td>


                              <td width="15%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                                 {{ $t('employee') | capitalize }}
                              </td>

                              <td width="10%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                                 :
                              </td>
                              <td width="25%" style="border-bottom:2px solid #eee !important;  text-align:start !important"
                                 class="pa-2 ">
                                 {{ report.user_full_name | capitalize }}
                              </td>
                           </tr>
                        </table>

                        <v-row class="mt-5 align-center" style="margin-top : 30px">
                           <v-col cols="5" style="padding: 15px  margin-top : 10px">
                              <v-card class="backgroundW shadow pa-5 py-6">
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("total sales") }}</div>
                                    <div>{{ report.total_sales || 0 | float }}</div>
                                 </div>
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("sales cash") }}</div>
                                    <div>{{ report.total_sales_cash || 0 | float }}</div>
                                 </div>
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("master card") }}</div>
                                    <div>{{ report.total_sales_master || 0 | float }}</div>
                                 </div>
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("visa") }}</div>
                                    <div>{{ report.total_sales_visa || 0 | float }}</div>
                                 </div>
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("mada") }}</div>
                                    <div>{{ report.total_sales_mada || 0 | float }}</div>
                                 </div>
                                 <!-- <div class="summary_row d-flex justify-space-between">
                                          <div>{{ $t("transition") }}</div>
                                          <div>{{ report.total_sales_trans  || 0 | float }}</div>
                                       </div> -->
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("other") }}</div>
                                    <div>{{ report.total_sales_other || 0 | float }}</div>
                                 </div>
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("notice debtor") }}</div>
                                    <div>{{ report.total_nd || 0 | float }}</div>
                                 </div>
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("notice creditor") }}</div>
                                    <div>{{ report.total_nc || 0 | float }}</div>
                                 </div>
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("expenses") }}</div>
                                    <div>{{ report.total_expenses || 0 | float }}</div>
                                 </div>
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("difference cash") }}</div>
                                    <div>{{ report.diff_cash || 0 | float }}</div>
                                 </div>
                                 <div class="summary_row d-flex justify-space-between">
                                    <div>{{ $t("difference network") }}</div>
                                    <div>{{ calculatedTotals.total_network || 0 | float }}</div>
                                 </div>
                              </v-card>
                           </v-col>
                           <v-col cols="7" style="padding: 15px; margin-top : 10px">
                              <v-card class="backgroundW shadow pa-5">
                                 <table class="mini_table_two">
                                    <tr>
                                       <th>{{ $t("") }}</th>
                                       <th>{{ $t("start") }}</th>
                                       <th>{{ $t("Transactions") }}</th>
                                       <th>{{ $t("end") }}</th>
                                       <th>{{ $t("difference") }}</th>
                                    </tr>
                                    <tr>
                                       <td>{{ $t('cash') }}</td>
                                       <td>{{ report.start_cash || 0 | float }}</td>
                                       <td>{{ report.total_cash || 0 | float }}</td>
                                       <td>{{ report.end_cash || 0 | float }}</td>
                                       <td>{{ report.diff_cash || 0 | float }}</td>
                                    </tr>
                                    <tr>
                                       <td>{{ $t('visa') }}</td>
                                       <td>{{ 0 | float }}</td>
                                       <td>{{ report.total_sales_visa || 0 | float }}</td>
                                       <td>{{ report.end_visa || 0 | float }}</td>
                                       <td>{{ report.diff_visa || 0 | float }}</td>
                                    </tr>
                                    <tr>
                                       <td>{{ $t('master card') }}</td>
                                       <td>{{ 0 | float }}</td>
                                       <td>{{ report.total_sales_master || 0 | float }}</td>
                                       <td>{{ report.end_master || 0 | float }}</td>
                                       <td>{{ report.diff_master || 0 | float }}</td>
                                    </tr>
                                    <tr>
                                       <td>{{ $t('mada') }}</td>
                                       <td>{{ 0 | float }}</td>
                                       <td>{{ report.total_sales_mada || 0 | float }}</td>
                                       <td>{{ report.end_mada || 0 | float }}</td>
                                       <td>{{ report.diff_mada || 0 | float }}</td>
                                    </tr>
                                    <tr>
                                       <td>{{ $t('other') }}</td>
                                       <td>{{ 0 | float }}</td>
                                       <td>{{ report.total_sales_other || 0 | float }}</td>
                                       <td>{{ report.end_other || 0 | float }}</td>
                                       <td>{{ report.diff_other || 0 | float }}</td>
                                    </tr>
                                    <tr>
                                       <td>{{ $t('total') }}</td>
                                       <td>{{ calculatedTotals.total_start | float }}</td>
                                       <td>{{ calculatedTotals.total_actions || 0 | float }}</td>
                                       <td>{{ calculatedTotals.total_end || 0 | float }}</td>
                                       <td>{{ calculatedTotals.total_difference || 0 | float }}</td>
                                    </tr>
                                 </table>
                              </v-card>
                           </v-col>


                        </v-row>



                     </template>
                  </PrintPDF>

               </section>
            </vue-html2pdf>
         </div>


      </v-container>
   </section>
</template>



<script>
import PrintPDF from "@/components/PrintPDF.vue";
import VueHtml2pdf from 'vue-html2pdf';
export default {
   name: "ShiftReportControl",

   data: () => ({
      pageData: {
         screen_code: "06-020",
         url: null,
         controlRoute: "/sales/list_shifts-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: false,
         isEdit: false,
         editIsLoading: false,
      },
      report: Object,
      printStaticData: Object,
   }),
   watch: {
   },
   components: {
      PrintPDF,
      VueHtml2pdf,
   },
   computed: {
      calculatedTotals() {
         if (this.report) {
            const total_start = this.report.start_cash
            const total_actions = this.report.total_sales_master + this.report.total_sales_visa + this.report.total_sales_mada + this.report.total_sales_other
            const total_end = this.report.end_cash + this.report.end_visa + this.report.end_master + this.report.end_mada + this.report.end_other
            const total_difference = this.report.diff_cash + this.report.diff_visa + this.report.diff_master + this.report.diff_mada + this.report.diff_other
            const total_network = this.report.diff_visa + this.report.diff_master + this.report.diff_mada + this.report.diff_other
            return {
               total_start: total_start,
               total_actions: total_actions,
               total_end: total_end,
               total_difference: total_difference,
               total_network: total_network,
            }
         } else {
            return {
               total_start: 0,
               total_actions: 0,
               total_end: 0,
               total_difference: 0,
               total_network: 0,
            }
         }
      }
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
            this.viewForm = true;
            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`shift/${this.$route.params.id}`).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.report = response.data.data;
                  this.printStaticData = response.data.print;
               }
            })
         } else {
            this.$router.push('/')
         }
      },
      exportPDF() {
         this.$refs.html2Pdf.generatePdf();
      },

   },

};
</script>
<style lang="scss" scoped>
.summary_row {
   padding: 0.4rem 0.2rem;
   border-bottom: 2px solid #eee;
   font-size: 12px !important;
   display: flex !important;
   justify-content: space-between !important;
}

td {
   padding: 1.2rem 0.4rem;
   font-size: 12px !important;
}

td:nth-of-type(1),
td:nth-of-type(4) {
   color: var(--gray7) !important;
}

#print_title {
   width: fit-content;
   text-align: center;
   font-size: 12px !important;
   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 1.2rem;
   margin: 30px auto;
}

.mini_table_two {
   width: 100%;
   border-collapse: collapse !important;

   tr {
      border: 1px solid #f7f9fb !important;

      th {
         text-transform: capitalize;

      }

      td,
      th {
         border: 1px solid #eee !important;
         background: #fff !important;
         height: 34.5px !important;
         font-size: 12px !important;
         padding: 12px;

      }

      td {
         background-color: #f5f6fa;
      }

      &:nth-of-type(odd) {

         td,
         th {
            background: #f7f9fb !important;
         }
      }

      // &:last-of-type {
      //
      //    td,
      //    th {
      //       font-weight: bold;
      //    }
      // }
   }
}
</style>