<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form"
            :showCreate="!(pageData.isEdit &&form.purchases_note_status==2)"
            :showDelete="!(pageData.isEdit &&form.purchases_note_status==2)" :save="save"
            :valid="valid && tableRows.length > 0" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit && !viewForm && purchases_note_status ==2" :viewForm="() => viewForm = true" />
         <!-- End Of Header -->
         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-fade-transition>
                  <div class="collapseForm" v-if="tableRows.length > 0">
                     <div class="d-flex">
                        <v-fade-transition>
                           <v-btn outlined style="background-color:  var(--blue12);"
                              v-if="formCollapse && !purchases_order_disable && !detailsFromPO" class="mx-2" depressed
                              color="blue2" :width="127" @click="newLineDialog = true" :height="37">
                              {{ $t('add line') }}
                           </v-btn>
                        </v-fade-transition>
                        <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                           <v-icon v-if="formCollapse==true">mdi-chevron-up</v-icon>
                           <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </v-fade-transition>
               <v-expand-transition>
               <v-row align="center" v-show="!formCollapse">

                  <v-col cols="12" md="6" lg="4">
                     <v-btn depressed color="blue4" block outlined @click="vendorDialog = true">
                        {{ form.purchases_vendor_id ? $t('vendor') + ' : '
                         + form.purchases_vendor : $t('choose purchases vendor') }}
                        <span v-if="form.purchases_order_id"> - {{ $t('po no') }} : {{ form.purchases_order_id }}
                        </span>
                        <v-icon size="18" right>mdi-open-in-new</v-icon>
                     </v-btn>
                  </v-col>


                  <GenericInput type="date" :value="form.purchases_note_date" @input="form.purchases_note_date = $event"
                     label="note date" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 'auto']">
                  </GenericInput>

                  <GenericInput type="number" :value="form.purchases_note_no" @input="form.purchases_note_no = $event"
                     label="note number" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 'auto']">
                  </GenericInput>

                  <GenericInput type="number" :value="form.purchases_vendor_invoice_no"
                     @input="form.purchases_vendor_invoice_no = $event" label="purchases vendor invoice no"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 'auto']">
                  </GenericInput>


                  <GenericInput type="textarea" solo :value="form.purchases_note_description"
                     @input="form.purchases_note_description = $event" label="description" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 12]"></GenericInput>

                  <v-col cols="12" class="mi-start-auto" md="auto" v-if="!purchases_order_disable && !detailsFromPO">
                     <v-btn outlined style="background-color: var(--blue12);" class="d-block mi-start-auto" depressed
                        color="blue2" :width="127" @click="newLineDialog = true" :height="37">
                        {{ $t('add line') }}
                     </v-btn>
                  </v-col>
               </v-row>
               </v-expand-transition>
            </v-card>
         </v-form>
         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="computedData.new_array || []" :footerData="[totalsCalculations]"
               :header="tableHeader" :deleteValueMethod="removeItem">
               <template v-slot:td="{ row, header }">
                  <div style="max-width: 140px">
                     <GenericInput type="select" v-if="header.key == 'receiving_location_id'"
                        :lookups="receiving_locations" :value="row.receiving_location_id"
                        @input="row.receiving_location_id = $event;" label="receiving location" :multi="false"
                        :required="false" selected_label="name" selected_prop="id" :isLoading="false"
                        :cols="[12, 12, 12]">
                     </GenericInput>

                     <v-text-field class="ma-auto" :label="$i18n.t('received quantity')"
                        :rules="[rules.minValue(row.received_quantity, 0, $i18n.t('received')), rules.maxValue(row.received_quantity, Number(+row.remaining_quantity), $i18n.t('maximum'))]"
                        v-if="header.key == 'received_quantity'" min="0" :value="row.received_quantity"
                        v-model="row.received_quantity" rounded-md hide-details="auto" dense type="number" />
                  </div>
               </template>
            </DynamicTable>
         </div>
         <v-col cols="12" class="d-flex" v-if="totalsCalculations.total_price_after_discount_with_vat">
            <div class="subtitle-1 font-weight-bold">{{ $t('final total') }}
               {{ totalsCalculations.total_price_after_discount_with_vat | float }}
               <span class="mx-2 text--disabled">"{{ totalsCalculations.total_price_after_discount_with_vat | tafqeet
               }}"</span>
            </div>
         </v-col>



         <!-- vendor dialog -->

         <v-dialog v-model="vendorDialog" overlay-opacity="0.75" persistent :max-width="370">
            <v-card relative class="pa-7 py-10">
               <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
                  color="grey lighten-1" text @click="vendorDialog = false">
                  <v-icon color="grey lighten-1">mdi-close</v-icon>
               </v-btn>
               <v-row align="center" justify="center">
                  <GenericInput type="select" :lookups="purchases_vendors" :value="form.purchases_vendor_id"
                     @input="form.purchases_vendor_id = $event" label="supplier" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="purchase_order" :value="form.purchases_order_id"
                     @input="form.purchases_order_id = $event" label="po no" :multi="false" :required="false"
                     selected_label="id" selected_prop="id" :isLoading="poIsLoading" :cols="[12, 12, 12]">
                  </GenericInput>

                  <v-col cols="auto" class="d-flex justify-center">
                     <v-btn class="mx-2" text depressed color="red4" @click="vendorDialog = false" :height="37"
                        :width="120">
                        {{ $t('cancel') }}
                     </v-btn>
                     <v-btn class="mx-2 white--text" :loading="poIsLoading || detailsIsLoading"
                        @click="vendorDialog = false" depressed color="blue4" :disabled="!(form.purchases_vendor_id)"
                        :height="37" :width="120">
                        {{ $t('choose') }}
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-dialog>


         <PurchasesNoteAddItem :backValueMethod="addItem" :taxes="taxes" :receiving_locations="receiving_locations"
            :items="items" :units="units" :warehouses="warehouses" v-if="newLineDialog == true" :dialog="newLineDialog"
            :tableRows="tableRows" />
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import PurchasesNoteAddItem from './PurchasesNoteAddItem.vue';
export default {
   name: "PurchasesNoteControl",
   data: () => ({
      pageData: {
         screen_code: "04-005",
         url: null,
         controlRoute: "/sales/purchases_note-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      formCollapse: false,
      vendorDialog: false,
      poIsLoading: false,
      detailsIsLoading: false,
      detailsFromPO: false,
      summery: [],
      newLineDialog: false,
      valid: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      items: [],
      units: [],
      warehouses: [],
      taxes: [],
      purchases_vendors: [],
      payment_methods: [],
      purchase_order: [],
      receiving_locations: [],
      purchases_order_disable: false,
      rules: {
         minValue(value, min, input) {
            return (value || "") >= min || ` ${input}` + ':' + min;
         },
         maxValue(value, max, input) {
            return (value || "") <= max || ` ${input}` + ':' + max;
         }
      },
      form: {
         purchases_note_date: null,
         purchases_note_no: null,
         purchases_vendor_id: null,
         purchases_vendor_invoice_no: null,
         purchases_order_id: null,
         purchases_note_description: null,
         items: [],

      },
      footerData: [{
         item_title: null,
         unit_id: null,
         quantity_in_po: 0,
         received_quantity: 0,
         receiving_location: 0,
         warehouse_id: null
      }],
   }),
   components: {
      PurchasesNoteAddItem,
   },
   computed: {
      totalsCalculations() {
         if (this.computedData.new_array) {
            const received_quantity = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.received_quantity);
            }, 0);
            const quantity_in_po = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.quantity_in_po);
            }, 0);
            const remaining_quantity = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.remaining_quantity);
            }, 0);
            const totalPrice = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.price);
            }, 0);
            const totalQuantityPrice = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(newValue.quantity) * Number(newValue.price);
            }, 0);
            const totalQty = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.quantity);
            }, 0);
            const total_discount = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_discount);
            }, 0);
            const total_vat_after_discount = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_vat_after_discount);
            }, 0);
            const total_price_after_discount_with_vat = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_price_after_discount_with_vat);
            }, 0);
            return {
               item_title: this.$i18n.t('total'),
               quantity: totalQty,
               price: totalPrice,
               total_discount: total_discount,
               total_quantity_price: totalQuantityPrice,
               total_vat_after_discount: total_vat_after_discount,
               total_price_after_discount_with_vat: total_price_after_discount_with_vat,
               received_quantity: received_quantity,
               quantity_in_po: quantity_in_po,
               remaining_quantity: remaining_quantity,
            }
         } else {
            return {
               item_title: this.$i18n.t('total'),
               price: 0,
               quantity: 0,
               total_discount: 0,
               total_quantity_price: 0,
               total_vat_after_discount: 0,
               total_price_after_discount_with_vat: 0,
               received_quantity: 0,
               quantity_in_po: 0,
               remaining_quantity: 0,
            }
         }
      },
      computedData() {
         if (this.tableRows.length > 0) {
            return this.$global.PRICE_CALCULATION(this.tableRows, {
               type: 1,
               value: 0,
               discount_amount: 0,
               percent_value: 0,
               total_price: 0,
            })
         } else {
            return Object
         }
      }
   },
   watch: {
      "form.purchases_vendor_id"() {
         if (this.pageData.isEdit == false) {
            this.form.purchases_vendor = this.$global.FilterArrayOfObjectElement(this.purchases_vendors, 'id', this.form.purchases_vendor_id, 'name')
            this.getVendorPO();
         }
      },
      "form.purchases_order_id"() {
         if (this.pageData.isEdit == false) {
            this.getPOItems();
         }
      },
      "form.purchases_note_status"() {
         if (this.form.purchases_note_status == 2) {
            this.tableHeader = [
               { text: "item", key: "item_title", type: 'text', classes: "" },
               { text: "unit", key: "unit_title", type: 'text', classes: "" },
               { text: "quantity", key: "quantity", type: 'text', classes: "" },
               { text: "price piece", key: "price", type: 'float', classes: "" },
               { text: "total price", key: "total_quantity_price", type: 'float', classes: "" },
               { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
               { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            ];
         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            row.item_title = row.item_id ? this.$global.FilterArrayOfObjectElement(this.items, 'id', row.item_id, 'name') : null;
            row.unit_title = row.unit_id ? this.$global.FilterArrayOfObjectElement(this.units, 'id', row.unit_id, 'name') : null;
            row.receiving_location_name = row.receiving_location_id ? this.$global.FilterArrayOfObjectElement(this.receiving_locations, 'id', row.receiving_location_id, 'name') : null;
            row.tax_percent = row.tax_percent_id ? this.$global.FilterArrayOfObjectElement(this.taxes, 'id', row.tax_percent_id, 'tax_percentage') : null;
            row.total_quantity_price = Number(row.quantity) * Number(row.price);
            row.total_vat = row.total_quantity_price * ((Number(row.tax_percent) / 100));
            row.total_with_vat = row.total_vat + row.total_quantity_price;
         });
      }
   },
   mounted() {
      this.pageMainData();
      this.form.purchases_note_date = this.$global.GetCurrentDate();
      this.form.purchases_note_no_date = this.$global.GetCurrentDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "quantity", key: "quantity", type: 'text', classes: "" },
            { text: "price piece", key: "price", type: 'float', classes: "" },
            { text: "total price", key: "total_quantity_price", type: 'float', classes: "" },
            { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
            { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            { text: "delete", key: "id", type: 'delete', classes: "" },
         ];
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`purchases_note/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.master;
                     response.data.details.forEach(detail => {
                        detail.remaining_quantity = 0;
                     });
                     this.tableRows = response.data.details;
                     this.items = response.data.items || [];
                     this.units = response.data.unit_measurements || [];
                     this.purchases_vendors = response.data.purchases_vendors || [];
                     this.warehouses = response.data.warehouses || [];
                     this.receiving_locations = response.data.receiving_locations || [];
                     this.taxes = response.data.taxes || [];
                     this.summery = [
                        {
                           label: 'purchase order date',
                           value: response.data.master.purchases_note_date,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'po date no',
                           value: response.data.master.m_id,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'supplier',
                           value: response.data.master.purchases_vendor_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'total',
                           value: response.data.master.total_with_vat,
                           type: 'price',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'description',
                           value: response.data.master.purchases_note_description,
                           class: '',
                           cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
                        },
                     ]
                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`purchases_note/create`).then((response) => {
                  if (response.check) {
                     this.items = response.data.items || [];
                     this.units = response.data.unit_measurements || [];
                     this.purchases_vendors = response.data.purchases_vendors || [];
                     this.warehouses = response.data.warehouses || [];
                     this.taxes = response.data.taxes || [];
                     this.receiving_locations = response.data.receiving_locations || [];

                     this.pageData.isLoading = false;
                     if (this.$route.params.data) {

                        // let requestBody = {
                        //    purchases_order: this.$route.params.data.m_id,
                        // }
                        this.form.purchases_order_id = this.$route.params.data.m_id;
                        this.form.purchases_vendor_id = this.$route.params.data.purchases_vendor_id;
                        this.purchases_order_disable = true;
                        // this.getPOItems();
                        // this.$api.POST_METHOD(`get_purchases_order_data`, requestBody).then((response) => {
                        //    this.pageData.isLoading = false;
                        //    if (response.check) {
                        //       response.data.items.forEach(detail => {
                        //          detail.remaining_quantity = detail.remaining_quantity || 1;
                        //          detail.received_quantity = detail.received_quantity || 0;
                        //          detail.receiving_location_id = detail.receiving_location_id || this.receiving_locations[0].id;
                        //       });
                        //       this.tableRows = response.data.items;

                        //    }
                        // })
                     } else {
                        this.pageData.isLoading = false;
                     }


                  }
               })




            } else {
               this.$router.push('/')
            }

         }

      },
      getVendorPO() {
         this.poIsLoading = true;
         this.$api.POST_METHOD(`get_vendor_purchases_order`, { purchases_vendor_id: this.form.purchases_vendor_id }).then((response) => {
            this.poIsLoading = false;
            if (response.check) {
               this.purchase_order = response.data.items || [];
            }
         })

      },
      getPOItems() {
         this.detailsIsLoading = true;
         this.$api.POST_METHOD(`get_purchases_order_data`, { purchases_order_id: this.form.purchases_order_id }).then((response) => {
            this.detailsIsLoading = false;
            if (response.check) {
               this.detailsFromPO = true;
               this.tableHeader = [
                  { text: "item", key: "item_title", type: 'text', classes: "" },
                  { text: "unit", key: "unit_title", type: 'text', classes: "" },
                  { text: "quantity", key: "quantity", type: 'text', classes: "" },
                  { text: "price piece", key: "price", type: 'float', classes: "" },
                  { text: "total price", key: "total_quantity_price", type: 'float', classes: "" },
                  { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
                  { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
                  { text: "remaining quantity", key: "remaining_quantity", type: 'text', classes: "" },
                  { text: "received quantity", key: "received_quantity", type: 'slot', classes: "" },
                  { text: "receiving location", key: "receiving_location_id", type: 'slot', classes: "" },
                  { text: "delete", key: "id", type: 'delete', classes: "" },
               ];
               response.data.items.forEach(item => {
                  item.received_quantity = 0
               });
               this.tableRows = response.data.items
               this.vendorDialog = false;
            }
         })
      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`purchases_note/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_purchases_note/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`purchases_note`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_purchases_note/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
         }
      },
      addItem(item, status) {
         if (status == true) {
            this.tableRows.push(item);
         }
         this.newLineDialog = false
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`purchases_note/${this.form.purchases_note_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
