<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :showCreate="!(pageData.isEdit &&form.purchases_order_status==4)"
            :showDelete="!(pageData.isEdit &&form.purchases_order_status==4)" :form="form" :save="save"
            :valid="valid && tableRows.length > 0" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit && !viewForm && form.purchases_order_status !== 4"
            :viewForm="() => viewForm = true" />
         <!-- End Of Header -->
         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-fade-transition>
                  <div class="collapseForm" v-if="tableRows.length > 0">
                     <div class="d-flex">
                        <v-fade-transition>
                           <v-btn outlined style="background-color:  var(--blue12);"
                              v-if="formCollapse" class="mx-2" depressed
                              color="blue2" :width="127" @click="newLineDialog = true" :height="37">
                              {{ $t('add line') }}
                           </v-btn>
                        </v-fade-transition>
                        <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                           <v-icon v-if="formCollapse==true">mdi-chevron-up</v-icon>
                           <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </v-fade-transition>
               <v-expand-transition>
                  <v-row align="center" v-show="!formCollapse">

                     <GenericInput type="date" :value="form.purchases_order_date"
                        @input="form.purchases_order_date = $event" label="purchase order date" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>

                     <GenericInput type="date" :value="form.purchases_order_no_date"
                        @input="form.purchases_order_no_date = $event" label="po date no" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>

                     <GenericInput type="select" :lookups="purchases_vendors" :value="form.purchases_vendor_id"
                        @input="form.purchases_vendor_id = $event" label="supplier" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="select" :lookups="payment_methods" :value="form.payment_method_id"
                        @input="form.payment_method_id = $event" label="payment method" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="text" :value="form.receiving_palace" @input="form.receiving_palace = $event"
                        label="receiving place" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="text" :value="form.transportation" @input="form.transportation = $event"
                        label="transportation" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="select"
                        :lookups="[{ id: 1, name: $i18n.t('week') }, { id: 2, name: $i18n.t('month') }]"
                        :value="form.offer_due_term" @input="form.offer_due_term = $event" label="offer due term"
                        :multi="false" :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="textarea" solo :value="form.purchases_order_description"
                        @input="form.purchases_order_description = $event" label="description" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 12]"></GenericInput>

                     <v-col cols="12" class="mi-start-auto" md="auto">
                        <v-btn outlined style="background-color: var(--blue12);" class="d-block mi-start-auto" depressed
                           color="blue2" :width="127" @click="newLineDialog = true" :height="37">
                           {{ $t('add line') }}
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-expand-transition>
            </v-card>
         </v-form>
         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="computedData.new_array || []" :footerData="[totalsCalculations]"
               :header="tableHeader" :deleteValueMethod="removeItem" />
         </div>
         <v-col cols="12" class="d-flex" v-if="totalsCalculations.total_price_after_discount_with_vat">
            <div class="subtitle-1 font-weight-bold">{{ $t('final total') }}
               {{ totalsCalculations.total_price_after_discount_with_vat | float }}
               <span class="mx-2 text--disabled">"{{ totalsCalculations.total_price_after_discount_with_vat | tafqeet
               }}"</span>
            </div>
         </v-col>
         <PurchasesOrderAddItem :backValueMethod="addItem" :warehouses="warehouses" :items="items" :units="units"
            :taxes="taxes_percent" v-if="newLineDialog == true" :dialog="newLineDialog" :tableRows="tableRows" />
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import PurchasesOrderAddItem from './PurchasesOrderAddItem.vue';
export default {
   name: "PurchasesOrderControl",
   data: () => ({
      pageData: {
         screen_code: "04-004",
         url: null,
         controlRoute: "/sales/purchases_order-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      formCollapse: false,
      summery: [],
      newLineDialog: false,
      valid: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      taxes_percent: [],
      account_trees: [],
      items: [],
      units: [],
      purchases_vendors: [],
      payment_methods: [],
      warehouses: [],
      form: {
         purchases_order_date: null,
         purchases_order_no_date: null,
         purchases_order_description: null,
         purchases_vendor_id: null,
         payment_method_id: null,
         receiving_palace: null,
         transportation: null,
         offer_due_term: null,
         items: [],
      },
   }),
   components: {
      PurchasesOrderAddItem,
   },
   computed: {
      totalsCalculations() {
         if (this.computedData.new_array) {
            const totalPrice = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.price);
            }, 0);
            const totalQuantityPrice = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(newValue.quantity) * Number(newValue.price);
            }, 0);
            const totalQty = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.quantity);
            }, 0);
            const total_discount = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_discount);
            }, 0);
            const total_vat_after_discount = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_vat_after_discount);
            }, 0);
            const total_price_after_discount_with_vat = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_price_after_discount_with_vat);
            }, 0);
            return {
               item_title: this.$i18n.t('total'),
               quantity: totalQty,
               price: totalPrice,
               total_discount: total_discount,
               total_quantity_price: totalQuantityPrice,
               total_vat_after_discount: total_vat_after_discount,
               total_price_after_discount_with_vat: total_price_after_discount_with_vat,
            }
         } else {
            return {
               item_title: this.$i18n.t('total'),
               price: 0,
               quantity: 0,
               total_discount: 0,
               total_quantity_price: 0,
               total_vat_after_discount: 0,
               total_price_after_discount_with_vat: 0,
            }
         }
      },
      computedData() {
         if (this.tableRows.length > 0) {
            return this.$global.PRICE_CALCULATION(this.tableRows, {
               type: 1,
               value: 0,
               discount_amount: 0,
               percent_value: 0,
               total_price: 0,
            })
         } else {
            return Object
         }
      }
   },
   watch: {
      tableRows() {
         this.tableRows.forEach(row => {
            row.item_title = this.$global.FilterArrayOfObjectElement(this.items, 'id', row.item_id, 'name');
            row.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', row.unit_id, 'name');
            row.tax_percent = this.$global.FilterArrayOfObjectElement(this.taxes_percent, 'id', row.tax_percent_id, 'tax_percentage');
            row.warehouse_name = this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', row.warehouse_id, 'name');
            row.total_quantity_price = Number(row.quantity) * Number(row.price);
            row.total_vat = row.total_quantity_price * ((Number(row.tax_percent) / 100));
            row.total_with_vat = row.total_vat + row.total_quantity_price;
         });
      },
      'form.purchases_order_status'() {
         this.tableHeader = [
            { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "count pieces", key: "quantity", type: 'number', classes: "" },
            { text: "price piece", key: "price", type: 'float', classes: "" },
            { text: "total quantity price", key: "total_quantity_price", type: 'float', classes: "" },
            { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
            { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
            { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
         ];
      }
   },
   mounted() {
      this.pageMainData();
      this.form.purchases_order_no_date = this.$global.GetCurrentDate();
      this.form.purchases_order_date = this.$global.GetCurrentDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "count pieces", key: "quantity", type: 'number', classes: "" },
            { text: "price piece", key: "price", type: 'float', classes: "" },
            { text: "total quantity price", key: "total_quantity_price", type: 'float', classes: "" },
            { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
            { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
            { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            { text: "delete", key: "id", type: 'delete', classes: "" },
         ];
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`purchases_order/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.master;
                     this.tableRows = response.data.details;
                     this.items = response.data.items || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes_percent = response.data.taxes || [];
                     this.purchases_vendors = response.data.purchases_vendors || [];
                     this.payment_methods = response.data.payment_methods || [];
                     this.warehouses = response.data.warehouses || [];
                     this.tableHeader = [
                        { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
                        { text: "item", key: "item_title", type: 'text', classes: "" },
                        { text: "unit", key: "unit_title", type: 'text', classes: "" },
                        { text: "count pieces", key: "quantity", type: 'text', classes: "" },
                        { text: "received quantity", key: "received_quantity", type: 'text', classes: "" },
                        { text: "remaining quantity", key: "remaining_quantity", type: 'text', classes: "" },
                        { text: "price piece", key: "price", type: 'float', classes: "" },
                        { text: "total quantity price", key: "total_quantity_price", type: 'float', classes: "" },
                        { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
                        { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
                        { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
                        { text: "delete", key: "id", type: 'delete', classes: "" },
                     ];
                     this.summery = [
                        {
                           label: 'purchase order date',
                           value: response.data.master.purchases_order_date,
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'po date no',
                           value: response.data.master.m_id,
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'supplier',
                           value: response.data.master.purchases_vendor_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'payment method',
                           value: response.data.master.payment_method_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'receiving place',
                           value: response.data.master.receiving_palace,
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'transportation',
                           value: response.data.master.transportation,
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'offer due term',
                           value: response.data.master.offer_due_term == 1 ? 'week' : 'month',
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'description',
                           value: response.data.master.purchases_order_description,
                           class: '',
                           cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
                        },
                     ];
                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`purchases_order/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.items = response.data.items || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes_percent = response.data.taxes || [];
                     this.purchases_vendors = response.data.purchases_vendors || [];
                     this.payment_methods = response.data.payment_methods || [];
                     this.warehouses = response.data.warehouses || [];
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`purchases_order/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_purchases_order/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`purchases_order`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_purchases_order/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      addItem(item, status) {
         if (status == true) {
            this.tableRows.push(item);
         }
         this.newLineDialog = false
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`purchases_order/${this.form.purchases_order_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
