<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-0 overflow-hidden">
               <v-col cols="12" class="px-0">
                  <div class="caption">{{ $t('item details') | capitalize }}</div>
               </v-col>
               <v-row align="center">
                  <GenericInput type="select" :lookups="customers" :value="form.customer_id"
                     @input="form.customer_id = $event" label="client" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.barcode" @input="form.barcode = $event" label="barcode"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
               </v-row>
            </v-card>
            <v-card class="backgroundW shadow pa-5 mt-3 pt-0 overflow-hidden">
               <v-col cols="12" class="px-0">
                  <div class="caption">{{ $t('informations') | capitalize }}</div>
               </v-col>
               <v-row align="center">
                  <GenericInput type="select" :lookups="measureUnits" :value="form.unit_measurement_id"
                     @input="form.unit_measurement_id = $event" label="measurement unit" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="storage_temperature" :value="form.storage_temperature_id"
                     @input="form.storage_temperature_id = $event" label="storage temperature" :multi="false" :required="true"
                     selected_label="degree" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="switch" :value="form.has_expiration" @input="form.has_expiration = $event" label="expiration"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>

               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "ClientItemControl",
   data: () => ({
      pageData: {
         screen_code: "08-001",
         url: null,
         controlRoute: "/warehouses/customer_item-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      categories: [],
      measureUnits: [],
      storage_temperature: [],
      customers: [],
      form: {
         name_en: null,
         name_ar: null,
         customer_id: null,
         barcode: null,
         unit_measurement_id: null,
         storage_temperature_id: null,
         has_expiration: null,
      },
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`customer_item/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.customers = response.data.customers;
                     this.measureUnits = response.data.unit_measurements;
                     this.storage_temperature = response.data.storage_temperatures;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`customer_item/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.customers = response.data.customers;
                     this.measureUnits = response.data.unit_measurements;
                     this.storage_temperature = response.data.storage_temperatures;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`customer_item/${this.form.id}`, this.form).then((response) => {
                  if (response.check) {
                     this.pageData.isLoading = false;
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`customer_item`, this.form).then((response) => {
                  if (response.check) {
                     this.pageData.isLoading = false;
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`customer_item/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
