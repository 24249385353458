

// Home Module Routes:-
import Home from "@/views/HomeModule/Home";
import RoutesIndexVue from "@/views/HomeModule/RoutesIndex.vue";

export const HomeModuleChildrens = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "home",
    },
  },
  {
    path: "/routes",
    name: "RoutesIndex",
    component: RoutesIndexVue,
    meta: {
      title: "home",
    },
  },
];
