<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="910">
         <v-card relative>
            <v-card-title class="text-h6 font-weight-bold" style="width: 100%;">
               <v-row align="center" justify="space-between">
                  <v-col class="d-block" cols="6">
                     <div class="d-flex">
                        <v-icon color="success" left>mdi-receipt</v-icon>
                        <h5>
                           <span v-if="type == 1">{{ $t('Pay bills') }}</span>
                           <span v-if="type == 2">{{ $t('advance payment') }}</span>
                        </h5>
                     </div>
                     <p class="body-2">
                        <span>{{ $t('the amount paid') }} :</span>
                        <span class="font-weight-bold">{{ paid }}</span>
                     </p>
                  </v-col>
                  <v-col cols="4">
                     <v-row justify="end">
                        <v-col cols="auto" class="pb-0">
                           <v-btn depressed small color="light" class="error--text" @click="closeDialog">{{
                              $t('close')
                           }}</v-btn>
                        </v-col>
                        <v-col cols="auto" class="pb-0">
                           <v-btn depressed small v-if="type == 1" color="success" :disabled="!(totalPaid == paid)"
                              @click="save">{{ $t('save') }}</v-btn>
                           <v-btn depressed small color="success" v-if="type == 2" @click="save">{{
                              $t('save')
                           }}</v-btn>
                        </v-col>
                        <v-col cols="10" class="py-0">
                           <small class="error--text body-2">
                              <span v-if="selected.length == 0 && type == 1">{{
                                 $t('At least one invoice must be paid')
                              }}</span>
                           </small>
                        </v-col>
                     </v-row>
                  </v-col>
               </v-row>
            </v-card-title>
            <v-sheet class="py-2 px-5">
               <v-data-table v-model="selected" :headers="headers" :items="defaultValues" :single-select="singleSelect"
                  item-key="id" show-select class="shadow" hide-default-footer
                  :loading-text="$i18n.t('Loading... Please wait')" :no-data-text="$i18n.t('No data available')"
                  disable-pagination calculate-widths>
                  <!-- eslint-disable-next-line  -->
                  <template v-slot:item.paid="{ item, index }">
                     <v-text-field v-model="item.paid" hide-details dense type="number"
                        :disabled="($global.FilterArrayOfObject(selected, 'id', item.id).length == 0)"
                        :placeholder="$i18n.t('the amount paid')" outlined></v-text-field>
                  </template>
               </v-data-table>
            </v-sheet>
         </v-card>
      </v-dialog>
   </v-row>
</template>


<script>

export default {
   name: "BillsSelection",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      defaultValues: { default: [] },
      selected: { default: [] },
      type: { default: 0 },
      paid: { default: 0 },

   },
   computed: {
      totalPaid() {
         return this.selected.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(+newValue.paid);
         }, 0)
      },
   },
   components: {

   },
   data() {
      return {

         singleSelect: false,
         headers: [
            { text: this.$i18n.t('invoice number'), value: 'invoice_number', },
            { text: this.$i18n.t('invoice date'), value: 'invoice_date' },
            { text: this.$i18n.t('due date'), value: 'due_date' },
            { text: this.$i18n.t('value'), value: 'value' },
            { text: this.$i18n.t('previously paid'), value: 'previously_paid' },
            { text: this.$i18n.t('residual'), value: 'residual' },
            { text: this.$i18n.t('the amount paid'), value: 'paid' },
         ],
      };
   },
   methods: {
      closeDialog() {
         this.dialog = false
         this.backValueMethod(this.selected, false)
      },
      save() {
         this.backValueMethod(this.selected, true);
      },
   },
   mounted() {

   },
};
</script>

<style scoped lang="scss">

</style>