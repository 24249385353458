<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :showCreate="!(pageData.isEdit)" :form="form" :save="save" :valid="valid"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         <!-- End of Summery -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="date" :value="form.action_date" @input="form.action_date = $event" label="date"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                  <GenericInput type="float" :value="form.amount" @input="form.amount = $event" label="amount"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                  <GenericInput type="text" :value="form.pay_for" @input="form.pay_for = $event" label="pay for"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                  <GenericInput type="select" :lookups="expenses_codes" :value="form.expense_code_id"
                     @input="form.expense_code_id = $event" label="choose an expenses code" :multi="false"
                     :required="true" selected_label="name_ar" selected_prop="id" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="textarea" :value="form.description" @input="form.description = $event"
                     label="description" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
export default {
   name: "ExpenseInvoiceControl",
   data: () => ({
      pageData: {
         screen_code: "06-009",
         url: null,
         controlRoute: "/sales/expense-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      viewSummery: false,
      viewForm: true,
      expenses_codes: [],
      summery: [],
      form: {
         expense_code_id: null,
         amount: '',
         action_date: null,
         description: null,
         pay_for: null,
      },
   }),
   components: {},
   computed: {},
   mounted() {
      this.pageMainData();
      this.form.action_date = this.$global.GetCurrentDate()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`expense/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.expenses_codes = response.data.expenses_codes;
                     this.summery = [
                        {
                           label: 'date',
                           value: response.data.data.action_date,
                           type: 'date', width: 120,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'amount',
                           value: response.data.data.amount,
                           type: 'float',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'pay for',
                           value: response.data.data.pay_for,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'description',
                           value: response.data.data.description,
                           class: '',
                           cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
                        },
                     ]
                     this.viewSummery = true;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`expense/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.expenses_codes = response.data.expenses_codes;
                     Object.keys(response.data.defaults || Object).forEach(key => {
                        this.form[key] = response.data.defaults[key]
                     })
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`expense/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}export_expense/${response.data.id}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data.id);
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`expense`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}export_expense/${response.data.id}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data.id);
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`expense_code/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
   },
};
</script>