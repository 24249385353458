<template>
  <v-container fluid class="backgroundW">
    <v-row justify="space-between" align="center">
      <v-col col="12"  md="auto" sm="12" class="d-none d-md-block">
        <v-row align="center" justify="start">
          <v-col cols="auto" class="py-0">
            <img
              src="@/assets/logo.svg"
              height="50"
              @click="$router.push('/')"
              class="cursor_pointer py-2 d-block ma-auto"
              alt="WMS"
            />
          </v-col>
          <v-col cols="auto">
            <AppBarRoutesMenu />
          </v-col>
        </v-row>
      </v-col>
      <v-col col="12" lg="auto" md="auto" sm="auto" class="d-block d-md-none">
        <img
          src="@/assets/img/png/logo.png"
          style="max-height: 60px"
          class="d-block ma-auto"
          alt="WMS"
        />
      </v-col>
      <v-col col="12" sm="auto">
        <v-row align="center" justify="end">
          <!-- <v-col cols="3" md="auto" sm="auto">
            <v-btn x-small fab @click="darkTheme" class="shadow-lg backgroundW" depressed>
              <v-icon v-if="$store.state.Settings.darkTheme">mdi-white-balance-sunny</v-icon>
              <v-icon color="gray2" v-if="!$store.state.Settings.darkTheme">mdi-weather-night</v-icon>
            </v-btn>
          </v-col>-->

          <!-- <v-col cols="auto" class="d-none d-lg-block">
            <v-divider vertical style="height: 2rem"></v-divider>
          </v-col>-->
          <v-col cols="3" md="auto" sm="auto" class="d-none">
            <AppBarSearch />
          </v-col>
          <v-col cols="3" md="auto" sm="auto">
            <v-btn
              v-on:click="changeLanguage"
              x-small
              fab
              icon
              class="bg-white d-block ma-auto"
              elevation="0"
            >
              <img
                src="@/assets/img/svg_icons/USA.svg"
                height="25px"
                v-if="$vuetify.rtl == true"
                alt="WMS"
              />
              <img
                src="@/assets/img/svg_icons/KSA.svg"
                height="30px"
                v-if="$vuetify.rtl == false"
                alt="WMS"
              />
            </v-btn>
          </v-col>
          <v-col cols="3" md="auto" sm="auto">
            <FiscalYearMenu />
          </v-col>
          <v-col cols="3" md="auto" sm="auto">
            <JobTitlesMenu />
          </v-col>
          <v-col cols="3" md="auto" sm="auto">
            <BranchesMenu />
          </v-col>
          <v-col cols="3" md="auto" sm="auto">
            <UserMenu />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserMenu from "./UserMenu.vue";
import BranchesMenu from "./BranchesMenu.vue";
import AppBarSearch from "./AppBarSearch.vue";
import AppBarRoutesMenu from "./AppBarRoutesMenu.vue";
import JobTitlesMenu from "./JobTitlesMenu.vue";
import FiscalYearMenu from "./FiscalYearMenu.vue";
export default {
  name: "Appbar",
  computed: {
    options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
        target: ".directive-fullscreen-wrapper",
        pageOnly: this.pageOnly,
        teleport: this.teleport,
      };
    },
  },
  data() {
    return {
      fullscreen: false,
    };
  },
  components: {
    AppBarSearch,
    UserMenu,
    BranchesMenu,
    AppBarRoutesMenu,
    JobTitlesMenu,
    FiscalYearMenu
},
  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>
