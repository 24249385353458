<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showSearch="false" :createBtn="false" :showDeleted="false"
        actionBtnClass="blue4--text">
      </EntityHeader>
      <!-- End Of Header -->


      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden">
              <v-col cols="12" class="pa-0 px-10">
                <v-row align="center" justify="space-between">
                  <v-col cols="auto" class="">
                    <v-tabs v-model="tab" active-class="green11">
                      <v-tab>{{ $t('reports') }}</v-tab>
                      <v-tab :disabled="!pageData.rows.length" >{{ $t('statistics') }}</v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col cols="auto" class="mx-5" v-if="tab == 1">
                    <v-btn small @click="chartType = 1" fab :color="chartType == 1 ? 'primary' : 'gray12'" class="mx-1"
                      depressed>
                      <v-icon>mdi-chart-bell-curve</v-icon>
                    </v-btn>
                    <v-btn small @click="chartType = 2" fab :color="chartType == 2 ? 'primary' : 'gray12'" class="mx-1"
                      depressed>
                      <v-icon>mdi-chart-bar</v-icon>
                    </v-btn>
                    <v-btn small @click="chartType = 4" fab :color="chartType == 4 ? 'primary' : 'gray12'" class="mx-1"
                      depressed>
                      <v-icon>mdi-chart-timeline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-col cols="12" sm="12" class="py-0">
                    <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" :data="pageData.rows"
                      :tableHeight="$store.state.Settings.windowSize.y - 150" :header="pageData.tableHeader"
                      :fixedHeader="true" :option="pageData.options" :pageData="pageData" :showGroupHeader="true"
                      :groupHeader="groupHeader">
                    </DynamicTable>
                  </v-col>
                </v-tab-item>
                <v-tab-item v-if="pageData.rows.length > 0">

                  <v-col cols="12" sm="12" class="pa-0">
                    <v-card flat relative
                      :height="showFilter ? $store.state.Settings.windowSize.y - 280 : $store.state.Settings.windowSize.y - 120">


                      <WeaklyReportChart v-if="chartType == 2" :key="analysisKey" :summary="summary" :service_count="service_count"
                        :order_count="order_count" :summary_categories="summary_categories" />

                      <WeaklyReportChartCurve v-if="chartType == 1" :key="analysisKey" :summary="summary" :service_count="service_count"
                        :order_count="order_count" :summary_categories="summary_categories" />

                      <WeaklyReportChartBar v-if="chartType == 4" :key="analysisKey" :summary="summary" :service_count="service_count"
                        :order_count="order_count" :summary_categories="summary_categories" />


                    </v-card>
                  </v-col>
                </v-tab-item>
              </v-tabs-items>



            </v-card>
          </v-col>

        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import WeaklyReportChart from "./WeaklyReportChart.vue";
import WeaklyReportChartCurve from "./WeaklyReportChartCurve.vue";
import WeaklyReportChartBar from "./WeaklyReportChartBar.vue";
export default {
  name: "WeaklyReportEntity",

  data: () => ({
    pageData: {
      screen_code: "06-017",
      controlRoute: "sales/weekly_report-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      detailIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    tab: 0,
    rows: [],
    types: [],
    items: [],
    showFilter: true,
    printLoading: false,
    payment_types: [],
    full_report: [],
    payment_type: null,
    report_type: null,
    today: null,
    from_date: null,
    to_date: null,
    selectedItems: null,

    footerData: [{
      invoice_code: null,
      branch_title: null,
      customer_name: null,
      service_title: null,
      qty: 0,
      id: null,
    }],
    // chart
    analysisKey: 1,
    chartType: 1,
    branches: [],
    summary: [],
    summary_categories: [],
    quantities: [],
    service_count: [],
    order_count: [],
    groupHeader: [],
    comparisonItems: {
      from_date: null,
      to_date: null,
      items: []
    }
  }),
  components: {
    WeaklyReportChartCurve,
    WeaklyReportChart,
    WeaklyReportChartBar
  },
  computed: {
    totalGrandPrice() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.total_with_vat);
          case 2:
            return Number(+oldValue) - Number(+newValue.total_with_vat);
        }
      }, 0);
    },
    totalQTY() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_quantity);
      }, 0);
    },
    totalPrice() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_with_vat);
      }, 0);
    },
    totalDiscount() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.discount);
      }, 0);
    },
    rowsLength() {
      return this.rows.length
    },
    totalVAT() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_vat);
      }, 0);
    },
    totalPriceWithoutVat() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_without_vat);
      }, 0);
    },
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    rows() {
      this.footerData[0].invoice_code = this.$i18n.t('total');
      this.footerData[0].total_without_vat = this.totalPriceWithoutVat;
      this.footerData[0].payment_type = this.rowsLength;
      this.footerData[0].total_vat = this.totalVAT;
      this.footerData[0].total_quantity = this.totalQTY;
      this.footerData[0].total_price = this.totalPrice;
      this.footerData[0].discount = this.totalDiscount;
      this.footerData[0].total_with_vat = this.totalGrandPrice;
    },

  },
  mounted() {
    this.queryParams();
    this.today = this.$global.GetCurrentDate();
    this.to_date = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
    this.pageMainData();
    this.getData();

  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;


      this.pageData.tableHeader = [
        // current week
        { text: "orders", key: "order_count_0", type: 'number', classes: "" },
        { text: "services", key: "service_count_0", type: 'number', classes: "" },
        { text: "total", key: "total_price_0", type: 'float', classes: "" },
        // week 1
        { text: "orders", key: "order_count_1", type: 'number', classes: "" },
        { text: "services", key: "service_count_1", type: 'number', classes: "" },
        { text: "total", key: "total_price_1", type: 'float', classes: "" },
        // week 2
        { text: "orders", key: "order_count_2", type: 'number', classes: "" },
        { text: "services", key: "service_count_2", type: 'number', classes: "" },
        { text: "total", key: "total_price_2", type: 'float', classes: "" },
        // week 3
        { text: "orders", key: "order_count_3", type: 'number', classes: "" },
        { text: "services", key: "service_count_3", type: 'number', classes: "" },
        { text: "total", key: "total_price_3", type: 'float', classes: "" },
        // week 
        { text: "orders", key: "order_count_4", type: 'number', classes: "" },
        { text: "services", key: "service_count_4", type: 'number', classes: "" },
        { text: "total", key: "total_price_4", type: 'float', classes: "" },
      ];

      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getData() {
      this.groupHeader = []
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD('sales_weekly_report')
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.rows = [
                {
                  order_count_0: 0,
                  service_count_0: 0,
                  total_price_0: 0,

                  order_count_1: 0,
                  service_count_1: 0,
                  total_price_1: 0,

                  order_count_2: 0,
                  service_count_2: 0,
                  total_price_2: 0,

                  order_count_3: 0,
                  service_count_3: 0,
                  total_price_3: 0,

                  order_count_4: 0,
                  service_count_4: 0,
                  total_price_4: 0,
                }
              ]
              Object.keys(response.data).forEach((key, index) => {
                this.groupHeader.push({ text: key, colspan: 3, classes: "" },)
                this.pageData.rows[0][`order_count_${index}`] = +response.data[key].order_count || 0
                this.pageData.rows[0][`service_count_${index}`] = +response.data[key].service_count || 0
                this.pageData.rows[0][`total_price_${index}`] = +response.data[key].total_price || 0

                this.summary_categories.push(key);
                this.summary.push(+response.data[key]['total_price'].toFixed(2) || 0);
                this.service_count.push(+response.data[key]['service_count'] || 0);
                this.order_count.push(+response.data[key]['order_count'] || 0);
              })

              this.analysisKey = this.analysisKey + 1;
              this.showFilter = false

            }
          });
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
