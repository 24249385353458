<template>
  <section>
    <v-container fluid class="px-0">
      <!-- Header -->
      <ControlHeader :pageData="pageData" :form="form" :save="save"
        :valid="(valid == true && restrictionDailyTable.length > 0 && totalDifference == 0)" :deleteMethod="deleteMethod"
        :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true">
        <template v-slot:centerSide>
          <v-col cols="12" sm="auto" v-if="pageData.isEdit" class="d-flex align-center justify-end">
            <v-btn color="primary" width="90" :disabled="(lastJournal == 0)" @click="changeDailyJournal(lastJournal)"
              depressed class="mx-1 blue12 blue1--text" :height="37">
              <v-icon v-if="$vuetify.rtl" left>mdi-skip-next</v-icon>
              <v-icon v-if="!$vuetify.rtl" left>mdi-skip-previous</v-icon>
              {{ $t('last') }}
            </v-btn>
            <v-btn color="primary" width="90" :disabled="(nextJournal == 0)" @click="changeDailyJournal(nextJournal)"
              depressed class="mx-1 blue12 blue1--text" :height="37">
              <v-icon v-if="$vuetify.rtl" left>mdi-arrow-right-bold-outline</v-icon>
              <v-icon v-if="!$vuetify.rtl" left>mdi-arrow-left-bold-outline</v-icon>
              {{ $t('next') }}
            </v-btn>
            <v-btn color="primary" width="90" :disabled="(prevJournal == 0)" @click="changeDailyJournal(prevJournal)"
              depressed class="mx-1 blue12 blue1--text" :height="37">
              {{ $t('prev') }}
              <v-icon v-if="!$vuetify.rtl" right>mdi-arrow-right-bold-outline</v-icon>
              <v-icon v-if="$vuetify.rtl" right>mdi-arrow-left-bold-outline</v-icon>
            </v-btn>
            <v-btn color="primary" width="90" :disabled="(firstJournal == 0)" @click="changeDailyJournal(firstJournal)"
              depressed class="mx-1 blue12 blue1--text" :height="37">
              {{ $t('first') }}
              <v-icon v-if="!$vuetify.rtl" right>mdi-skip-next</v-icon>
              <v-icon v-if="$vuetify.rtl" right>mdi-skip-previous</v-icon>
            </v-btn>
          </v-col>
        </template>
      </ControlHeader>
      <!-- End Of Header -->
      <!-- Summery -->
      <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
      <!-- End of Summery -->
      <!-- Form -->
      <div class="pa-3 rounded">
        <v-form ref="form" v-model="valid" class="pa-0" v-if="viewForm">
          <v-card class="backgroundW shadow pa-5">
            <v-row align="center">

              <GenericInput type="date" :value="form.journal_date" @input="form.journal_date = $event"
                label="invoice date" :disabled="disabledInput" :required="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 4]"></GenericInput>
              <GenericInput type="autocomplete" :lookups="daily_types" :value="form.daily_type_id"
                @input="form.daily_type_id = $event" label="daily" :multi="false" :required="true" selected_label="name"
                :disabled="disabledInput" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
              </GenericInput>
              <GenericInput type="textarea" :value="form.description" @input="form.description = $event"
                label="Constraint description" :disabled="disabledInput" :required="true"
                :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>

            </v-row>
          </v-card>
        </v-form>
        <v-form ref="newLine" v-model="newLineValid" class="pa-0 my-3" v-if="viewForm">
          <v-card class="backgroundW shadow pa-5">
            <v-row align="end">

              <GenericInput type="autocomplete" :lookups="account_trees" :value="newLine.account_tree_id"
                @input="newLine.account_tree_id = $event" label="account code" :multi="false" :required="true"
                selected_label="account_name_code" selected_prop="id" :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 6]">
              </GenericInput>

              <v-col cols="12" sm="6" class="pa-0">
                <v-radio-group dense class="ma-0 pa-0 justify-space-between" hide-details row v-model="newLine.d_type">
                  <v-radio color="primary" class="mx-2 mb-1" v-for="(select, index) in [
                      { name: $i18n.t('debit'), id: 2 },
                      { name: $i18n.t('credit'), id: 1 },
                    ]" :key="index" :label="select.name" :value="select.id"></v-radio>
                </v-radio-group>



                <GenericInput type="float" v-if="newLine.d_type == 1" :value="newLine.credit"
                  @input="newLine.credit = $event" label="amount" :required="true" :isLoading="pageData.editIsLoading"
                  :cols="[12, 12, 12]"></GenericInput>

                <GenericInput type="float" v-if="newLine.d_type == 2" :value="newLine.debit"
                  @input="newLine.debit = $event" label="amount" :required="true" :isLoading="pageData.editIsLoading"
                  :cols="[12, 12, 12]"></GenericInput>



              </v-col>

              <GenericInput type="textarea" :value="newLine.description" @input="newLine.description = $event"
                label="jornal description" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 8]">
              </GenericInput>

              <v-col cols="12" lg="4">
                <v-col cols="12" class="pa-0 mb-3" v-if="newLine.d_type == 2">
                  <v-btn color="success" v-if="newLine.cost_centers.length == 0" class="mi-start-auto d-block" block
                    @click="pageData.costCenterDialog = true">
                    {{ $t("add") }} {{ $t("Cost Center Code") }}
                    <v-icon right>mdi-plus-box</v-icon>
                  </v-btn>
                  <v-btn color="primary" v-if="newLine.cost_centers.length > 0" class="mi-start-auto d-block" block
                    @click="pageData.costCenterDialog = true">
                    {{ $t("edit") }} {{ $t("Cost Center Code") }}
                    <v-icon right>mdi-square-edit-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-btn color="primary" :disabled="!(
                    newLineValid &&
                    ((newLine.d_type == 1 && newLine.credit > 0) ||
                      (newLine.d_type == 2 &&
                        newLine.debit > 0))
                  )
                  " @click="addLine" class="ma-auto d-block" block>
                  <v-icon dark left>mdi-plus-circle</v-icon>
                  {{ $t("new line") }}
                </v-btn>
              </v-col>



            </v-row>
          </v-card>
        </v-form>
        <v-card class="backgroundW shadow  pa-0" v-if="restrictionDailyTable.length > 0">
          <!-- Table -->
          <v-card class="backgroundW shadow  pa-3">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $t("account") }}</th>
                    <th>{{ $t("debit") }}</th>
                    <th>{{ $t("credit") }}</th>
                    <th>{{ $t("Cost Center") }}</th>
                    <th>{{ $t("jornal description") }}</th>
                    <th>{{ $t("actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in restrictionDailyTable" :key="index">
                    <td>{{
                      $global.FilterArrayOfObjectElement(account_trees, 'id', row.account_tree_id,
                        'account_name_code')
                      || ''
                    }}</td>
                    <td>{{ row.debit | float }}</td>
                    <td>{{ row.credit | float }}</td>
                    <td>
                      <p class="my-1 pa-0" v-for="(center, indexx) in row.cost_centers" :key="indexx">
                        # {{ center.name }}
                        <span v-if="center.value">- {{ center.value | float }} {{ $t("ryal") }}</span>

                      </p>
                      <p class="pa-0" v-if="row.cost_centers.length == 0">__________</p>
                    </td>
                    <td>{{ row.description }}</td>

                    <td>
                      <v-btn icon elevation="0" v-if="viewForm" @click="duplicateLine(row, index)" color="success">
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn icon elevation="0" v-if="viewForm" @click="removeLine(index)" color="error">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                      <v-btn icon color="primary" v-if="viewForm" @click="openEditDialog(row, index)">
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn icon color="primary" @click="openDetailAttachmentDialog(row, index)">
                        <v-icon v-if="!row.attach_ext">mdi-paperclip-plus</v-icon>
                        <v-icon v-if="row.attach_ext">mdi-paperclip</v-icon>
                      </v-btn>
                      <v-btn icon :loading="printLoading" v-if="pageData.isEdit && row.attach_ext" color="success"
                        @click="downloadDetailAttachment(row)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>

                    <th colspan="1">{{ $t("total") }}</th>
                    <th colspan="1">{{ totalDebtors | float }}</th>
                    <th colspan="1">{{ totalcredits | float }}</th>
                    <th colspan="1">{{ $t("difference") }}</th>
                    <th colspan="4">{{ totalDifference | float }}</th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card>
        </v-card>



        <!-- Master Attachments -->
        <v-col cols="12" class="px-0" v-if="pageData.isEdit">
          <v-card class="shadow px-3">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn color="secondary" depressed @click="attachmentDialog = true" :loading="attachDownloadLoading">
                {{ $t('add attachment') }}
              </v-btn>
              <v-btn color="primary" outlined class="blue12 mx-2" v-if="pageData.isEdit"
                :disabled="(attachments.length == 0)" @click="downloadAllAttachment" depressed
                :loading="attachDownloadLoading">
                {{ $t('download all attachment') }}
                <v-icon size="20" right>mdi-download</v-icon>
              </v-btn>
            </v-col>
            <div class="py-4">
              <v-row align="center">
                <v-col cols="12" md="6" lg="4" v-for="(attach, index) in attachments" :key="index">
                  <v-hover v-slot="{ hover }">
                    <v-card height="280" class="overflow-hidden rounded-lg shadow pa-2">
                      <div class=" my-2" :class="{ 'opacity-015': hover }">
                        <div v-lazy-container="{ selector: 'img' }"
                          v-if="attach.file_ext == 'jpeg' || attach.file_ext == 'jpg' || attach.file_ext == 'png' || attach.file_ext == 'gif' || attach.file_ext == 'svg'">
                          <img class="d-block ma-auto" style="height: 200px;" :key="index"
                            :data-src="String(attach.file_path).substring(0, 10) == 'data:image' ? attach.file_path : $api.serverUrl + attach.file_path"
                            alt="qarat" />
                        </div>

                        <div v-else>
                          <img v-if="attach.file_ext !== 'pdf'"
                            :src="require(`@/assets/img/png/files/${attach.file_ext}.png`)" height="200"
                            class="d-block ma-auto" alt="qarat" />
                          <div v-if="attach.file_ext == 'pdf'">
                            <iframe width='100%' height='200'
                              :src="String(attach.file_path).substring(0, 20) == 'data:application/pdf' ? attach.file_path : $api.serverUrl + attach.file_path" />
                          </div>

                        </div>
                      </div>
                      <div class="text-center subtitle-1" :class="{ 'd-none': hover }">
                        {{ attach.file_name }}</div>

                      <div class="absolute-center justify-end px-5" :class="{ 'd-none': !hover }">
                        <v-btn icon color="primary" class="mx-3" large v-if="pageData.isEdit"
                          @click="downloadAttachment(attach)" :loading="attachDownloadLoading">
                          <v-icon size="40">mdi-download</v-icon>
                        </v-btn>
                        <v-btn icon color="error" class="mx-3" large @click="deleteAttachment(attach, index)"
                          :loading="attachDownloadLoading">
                          <v-icon size="40">mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <v-col v-if="attachments.length == 0" cols="12">
                <v-alert type="info" outlined dense>
                  {{ $t('no attachment') }}
                </v-alert>
              </v-col>
            </div>
          </v-card>
        </v-col>



      </div>
      <CostCenterDialog v-if="pageData.costCenterDialog == true" :dialog="pageData.costCenterDialog"
        :cost_centers="cost_centers" :defaultValues="newLine.cost_centers" :backValueMethod="setCostCenter">
      </CostCenterDialog>
      <JornalEditDialog v-if="pageData.costEditCenterDialog == true" :dialog="pageData.costEditCenterDialog"
        :cost_centers="cost_centers" :account_trees="account_trees" :defaultValues="editCostCenters"
        :backValueMethod="setEditCostCenter">
      </JornalEditDialog>
      <!-- End of Form -->

      <!-- Detail Attach -->
      <AttachmentDialog :dialog="attachmentDetailDialog" entityName="daily journal" dataKey="d_id" :data="editCostCenters"
        uploadResource="add_daily_journal_attach" downloadResource="get_daily_journal_attach"
        :returnMethod="(reload) => { attachmentDetailDialog = false; reload ? getData() : null }" />

      <!-- Master Attach -->
      <AttachmentDialog :dialog="attachmentDialog" entityName="daily journal" dataKey="m_id" :data="form"
        uploadResource="add_daily_journal_master_attach" downloadResource="download_daily_journal_master_attach"
        :returnMethod="(reload) => { attachmentDialog = false; reload ? getData() : null }" :multiPart="true" />


    </v-container>
  </section>
</template>

<script>
import CostCenterDialog from "./CostCenterDialog.vue";
import JornalEditDialog from "./JornalEditDialog.vue";
export default {
  name: "DailyJournalControl",

  data: () => ({
    pageData: {
      screen_code: "02-003",
      url: null,
      controlRoute: "/accounting/daily_journal-control/",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isEdit: false,
      editIsLoading: false,
      costCenterDialog: false,
      costEditCenterDialog: false,
    },
    valid: false,
    firstJournal: 0,
    lastJournal: 0,
    nextJournal: 0,
    prevJournal: 0,
    viewSummery: false,
    viewForm: true,
    summery: [],
    printLoading: false,
    printID: null,
    attachmentDialog: false,
    attachmentDetailDialog: false,
    attachDownloadLoading: false,
    attachments: [],
    newLineValid: false,
    account_trees: [],
    cost_centers: [],
    daily_types: [],
    restrictionDailyTable: [],
    modalKey: 1,
    disabledInput: false,
    editCostCenters: [],
    form: {
      journal_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      daily_type_id: null,
      description: null,
      items: [],
    },
    newLine: {
      description: null,
      account_tree_id: null,
      d_type: 2,
      debit: 0,
      credit: 0,
      cost_centers: [],
    },
  }),
  components: {
    CostCenterDialog,
    JornalEditDialog,
  },
  watch: {
    $route() {
      this.pageMainData();
      this.editCostCenters = [];
      this.restrictionDailyTable = [];
      this.getData();
    },
    viewForm() {

    },
    account_trees() {
      this.account_trees.forEach(account => {
        account.account_name_code = account.account_code + ' - ' + account.name
      });
    }
  },
  computed: {
    totalDebtors() {
      return this.restrictionDailyTable.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.debit);
      }, 0);
    },
    totalcredits() {
      return this.restrictionDailyTable.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.credit);
      }, 0);
    },
    totalDifference() {
      return (Number(this.totalDebtors) - Number(this.totalcredits)).toFixed(2);
    },
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.entityName = this.$route.params.id
        ? this.$i18n.t("edit") + " " + this.$i18n.t("daily journal")
        : this.$i18n.t("add daily journal");
    },
    changeDailyJournal(id) {
      this.$router.push('/accounting/daily_journal-control/' + id)
    },
    getData() {
      if (this.$route.params.id) {
        this.viewForm = false;
        this.attachments = []
        if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
          this.pageData.isEdit = true;
          this.pageData.editIsLoading = true;
          this.pageData.isLoading = true;
          this.$api
            .GET_METHOD(`daily_journal/${this.$route.params.id}`)
            .then((response) => {
              this.pageData.editIsLoading = false;
              this.pageData.isLoading = false;
              if (response.check) {
                console.log("response", response);
                this.form = response.data.master;
                this.attachments = response.data.files || [];
                this.form.list = response.data.details;
                this.restrictionDailyTable = response.data.details;
                this.daily_types = response.data.daily_types;
                this.account_trees = response.data.account_trees;
                this.cost_centers = response.data.cost_centers;
                this.nextJournal = response.data.next_item;
                this.prevJournal = response.data.previous_item;
                this.firstJournal = response.data.first_item;
                this.lastJournal = response.data.last_item;
                this.summery = [
                  {
                    label: 'journal number',
                    value: response.data.master.journal_code,
                    class: '',
                    cols: null, sm: null, md: 6, lg: 4, xl: 4,
                  },
                  {
                    label: 'daily type',
                    value: response.data.master.daily_type,
                    class: '',
                    cols: null, sm: null, md: 6, lg: 4, xl: 4,
                  },
                  {
                    label: 'date',
                    value: response.data.master.journal_date,
                    class: '',
                    cols: null, sm: null, md: 6, lg: 4, xl: 4,
                  },
                  {
                    label: 'document number',
                    value: response.data.master.document_id,
                    class: '',
                    cols: null, sm: null, md: 6, lg: 4, xl: 4,
                  },
                  {
                    label: 'branch',
                    value: response.data.master.branch_name,
                    class: '',
                    cols: null, sm: null, md: 6, lg: 4, xl: 4,
                  },
                  {
                    label: 'description',
                    value: response.data.master.description,
                    class: '',
                    cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
                  },
                ]
                this.viewSummery = true;

                this.$refs.form.validate();
              }
            });
        } else {
          this.$router.push("/");
        }
      } else {
        if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`daily_journal/create`).then((response) => {
            if (response.check) {
              this.pageData.isLoading = false;
              this.daily_types = response.data.daily_types;
              this.account_trees = response.data.account_trees;
              this.cost_centers = response.data.cost_centers;
              this.viewSummery = false;
              this.viewForm = true;
              Object.keys(response.data.defaults || Object).forEach(key => {
                this.form[key] = response.data.defaults[key]
              })
            }
          });
        } else {
          this.$router.push("/");
        }
      }
    },
    setCostCenter(cost_centers, status) {
      if (status == true) {
        this.newLine.cost_centers = cost_centers;
      }
      this.pageData.costCenterDialog = false;
    },
    openEditDialog(row, index) {
      // row.index = index;
      console.log(index);
      this.modalKey = this.modalKey + 1;
      this.editCostCenters = row;
      this.pageData.costEditCenterDialog = true;
    },
    openDetailAttachmentDialog(row, index) {
      // row.index = index;
      console.log(index);
      this.modalKey = this.modalKey + 1;
      this.editCostCenters = row;
      this.attachmentDetailDialog = true;
    },
    addAttachmentMethod(attachment, index) {
      console.log(attachment, index);
      this.restrictionDailyTable[index] = attachment
    },
    downloadDetailAttachment(attachment) {
      this.printLoading = true;
      this.printID = attachment.d_id;
      const src = `get_daily_journal_attach/${attachment.d_id}`;
      console.log('src', src);
      const download_file_name = `${this.$i18n.t('attach')}_${this.$i18n.t('daily journal')}_${attachment.d_id}.${attachment.attach_ext}`
      this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
        this.printLoading = false;
      })
    },
    openAttachmentDialog(row) {
      this.selectedRow = row
      this.attachmentDialog = true;
    },
    downloadAttachment(attach) {
      console.log(attach);
      this.attachDownloadLoading = true
      var src = `download_daily_journal_master_attach/${attach.daily_journal_file_id}`;
      var download_file_name = `${attach.file_name}.${attach.file_ext}`
      this.$api.DOWNLOAD_METHOD_GET(src, download_file_name).then(() => {
        this.attachDownloadLoading = false;
      })
    },
    deleteAttachment(attach, index) {
      if (this.pageData.isEdit) {
        console.log(attach);
        this.attachDownloadLoading = true
        this.$api.POST_METHOD(`delete_daily_journal_master_attachment/${attach.daily_journal_file_id}`).then((res) => {
          this.attachDownloadLoading = false;
          if (res.check) {
            this.getData()
          }
        })
      } else {
        this.attachments.splice(index, 1);
      }

    },
    downloadAllAttachment() {
      this.attachments.forEach(attachment => {
        this.downloadAttachment(attachment, 0)
      });
    },
    setEditCostCenter(cost_centers, status) {
      console.log("edit_cost_centers", cost_centers, status);
      if (status == true) {
        // this.restrictionDailyTable[this.editCostCenters.index].cost_centers = cost_centers;
      }
      this.pageData.costEditCenterDialog = false;
    },
    save() {
      console.log("my form", this.form);
      console.log("restrictionDailyTable", this.restrictionDailyTable);
      this.form.items = [];
      this.restrictionDailyTable.forEach((jornal) => {
        jornal.cost_center_id = [];
        console.log("jornal", jornal);
        if (jornal.cost_centers) {
          jornal.cost_centers.forEach((center) => {
            jornal.cost_center_id.push(center.id);
          });
        }
        delete jornal.cost_centers;
        delete jornal.d_type;
        this.form.items.push(jornal);
      });
      if (this.$refs.form.validate()) {
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          this.form._method = "PUT";
          this.$api
            .POST_METHOD(`daily_journal/${this.form.m_id}`, this.form)
            .then((response) => {
              this.pageData.isLoading = false;
              if (response.check) {
                this.$router.push(this.pageData.url);
              }
            });
        } else {
          this.$api.POST_METHOD(`daily_journal`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.$router.push(this.pageData.url);
            }
          });
        }
      }
    },
    deleteMethod(status) {
      if (status == true) {
        this.pageData.isLoading = true;
        this.$api
          .POST_METHOD(`daily_journal/${this.form.m_id}`, { _method: "delete" })
          .then(() => {
            this.$router.push(this.pageData.url);
            this.pageData.isLoading = false;
          });
      }
    },
    addLine() {
      console.log("addLine");
      this.modalKey = this.modalKey + 1;
      this.newLine.credit = this.newLine.d_type == 1 ? this.newLine.credit : 0;
      this.newLine.debit = this.newLine.d_type == 2 ? this.newLine.debit : 0;
      if (this.newLine.d_type == 2) {
        if (this.newLine.cost_centers.length > 0) {
          this.newLine.cost_centers.forEach((center) => {
            var journal = {
              description: this.newLine.description,
              account_tree_id: this.newLine.account_tree_id,
              d_type: this.newLine.d_type,
              debit:
                this.newLine.d_type == 2
                  ? this.newLine.debit * (center.percentage / 100)
                  : this.newLine.debit,
              credit: this.newLine.credit,
              cost_centers: center.centers,
            };
            this.restrictionDailyTable.push(journal);
          });
        } else {
          var journalwithoutCost = {
            description: this.newLine.description,
            account_tree_id: this.newLine.account_tree_id,
            d_type: this.newLine.d_type,
            debit:
              this.newLine.d_type == 2
                ? this.newLine.debit
                : this.newLine.debit,
            credit: this.newLine.credit,
            cost_centers: [],
          };
          this.restrictionDailyTable.push(journalwithoutCost);
        }
      } else {
        var journal = {
          description: this.newLine.description,
          account_tree_id: this.newLine.account_tree_id,
          d_type: this.newLine.d_type,
          debit: this.newLine.debit,
          credit: this.newLine.credit,
          cost_centers: [],
        };
        this.restrictionDailyTable.push(journal);
      }
      this.newLine.description = null;
      this.newLine.account_tree_id = null;
      this.newLine.debit = 0;
      this.newLine.credit = 0;
      this.newLine.cost_centers = [];
      console.log(this.restrictionDailyTable, this.newLine);
    },
    removeLine(index) {
      console.log("index", index);
      this.restrictionDailyTable.splice(index, 1);
      this.totalDebtors = this.restrictionDailyTable.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.debit);
      }, 0);
      this.totalcredits = this.restrictionDailyTable.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.credit);
      }, 0);
      this.totalDifference = this.totalDebtors - this.totalcredits;
    },
    duplicateLine(row, index) {
      this.restrictionDailyTable.splice(index, 0, row)
    },
  },
};
</script>
