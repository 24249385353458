<template>

   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="800">
      <v-card relative class="backgroundW">

         <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
            color="grey lighten-1" text @click="closeDialog">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
         </v-btn>


         <v-sheet class="py-15 px-5">
            <DynamicTable :isLoading="false" :data="items" :header="tableHeader" >
               <template v-slot:td="{ row }">
                  <v-btn color="accent" depressed @click="addItem(row)">{{$t('add')}}</v-btn>
               </template>
            </DynamicTable>
         </v-sheet>

      </v-card>
   </v-dialog>

</template>






<script>
import DynamicTable from '@/components/DynamicTable.vue';

export default {
   name: "AddItemModal",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      items: { default: [] },
      itemsLoading: { default: [] },
   },
   computed: {

   },
   components: {
      DynamicTable
   },
   data: () => ({
      tableHeader: [
         { text: "item", key: "customer_item_name", type: 'search', classes: "" },
         { text: "barcode", key: "barcode", type: 'text', classes: "" },
         { text: "storage temperature", key: "storage_temperature_degree", type: 'text', classes: "" },
         { text: "unit measurement", key: "unit_measurement_name", type: 'text', classes: "" },
         { text: "", key: "add", type: 'slot', classes: "" },
      ]
   }),
   methods: {
      closeDialog() {
         // this.dialog = false
         this.backValueMethod(null, false);
      },
      addItem(item) {
        this.backValueMethod(item, false);
      }

   },
   mounted() {

   },
};
</script>
