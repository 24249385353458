<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :editForm="pageData.isEdit" :viewForm="() => viewForm = true" :form="form"
            :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-fade-transition>
                  <div class="collapseForm" v-if="tableRows.length > 0">
                     <div class="d-flex">
                        <v-fade-transition>
                           <v-btn outlined style="background-color:  var(--blue12);"
                              v-if="formCollapse && !(form.selected_purchases_notes || form.selected_purchases_orders)"
                              class="mx-2" depressed color="blue2" :width="127" @click="newLineDialog = true"
                              :height="37">
                              {{ $t('add line') }}
                           </v-btn>
                        </v-fade-transition>
                        <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                           <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                           <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </v-fade-transition>
               <v-expand-transition>
                  <v-row align="center" justify="start" v-show="!formCollapse">


                     <GenericInput type="radiogroup" :labelInline="false"
                        :lookups="[{ id: 1, name: $i18n.t('local invoice') }, { id: 2, name: $i18n.t('export invoice') }]"
                        :value="form.purchases_invoice_detail" @input="form.purchases_invoice_detail = $event"
                        label="invoice details" :multi="false" :required="false" selected_label="name"
                        selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>

                     <GenericInput type="date" :value="form.purchases_invoice_date"
                        @input="form.purchases_invoice_date = $event" label="date" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="purchases_invoice_types"
                        :value="form.purchases_invoice_type_id" @input="form.purchases_invoice_type_id = $event"
                        label="invoice type" :multi="false" :required="true" selected_label="title" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="purchases_vendors" :value="form.purchases_vendor_id"
                        @input="form.purchases_vendor_id = $event" label="vendor number" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>



                     <GenericInput type="select" :lookups="purchases_agents" :value="form.purchases_agent_id"
                        @input="form.purchases_agent_id = $event" label="purchase agent" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>



                     <GenericInput type="number" :value="form.purchases_vendor_invoice_no"
                        @input="form.purchases_vendor_invoice_no = $event" label="vendor invoice number"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <v-col cols="12" md="6" lg="3" v-if="form.selected_purchases_orders">
                        <div class="font-weight-bold body-2">{{ $t('purchase order number') }}:
                           <span class="font-weight-medium" v-for="(item, index) in form.selected_purchases_orders"
                              :key="index">{{ item || '-' }}
                              <span v-if="form.selected_purchases_orders.length > index + 1"> - </span>
                           </span>
                        </div>
                     </v-col>
                     <v-col cols="12" md="6" lg="3" v-if="form.selected_purchases_notes">
                        <div class="font-weight-bold body-2">{{ $t('purchase note number') }}:
                           <span class="font-weight-medium" v-for="(item, index) in form.selected_purchases_notes"
                              :key="index">{{ item || '-' }}
                              <span v-if="form.selected_purchases_notes.length > index + 1"> - </span>
                           </span>
                        </div>
                     </v-col>
                     <GenericInput type="select" :lookups="currencies" :value="form.currency_id"
                        @input="form.currency_id = $event" label="currency" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="textarea" solo :value="form.purchases_invoice_description"
                        @input="form.purchases_invoice_description = $event" label="description" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>

                     <v-col cols="12" class="mi-start-auto" md="auto"
                        v-if="!(form.selected_purchases_notes || form.selected_purchases_orders)">
                        <v-btn outlined style="background-color: var(--blue12);" class="d-block mi-start-auto" depressed
                           color="blue2" :width="127" @click="newLineDialog = true" :height="37">
                           {{ $t('add line') }}
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-expand-transition>
            </v-card>
         </v-form>

         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="computedData.new_array || []" :footerData="[totalsCalculations]"
               :header="tableHeader" :deleteValueMethod="removeItem" />
         </div>
         <v-col cols="12" class="d-flex" v-if="totalsCalculations.total_price_after_discount_with_vat">
            <div class="subtitle-1 font-weight-bold">{{ $t('final total') }}
               {{ totalsCalculations.total_price_after_discount_with_vat | float }}
               <span class="mx-2 text--disabled">"{{
                  totalsCalculations.total_price_after_discount_with_vat | tafqeet
               }}"</span>
            </div>
         </v-col>
         <!-- End of Form -->



         <PurchaseInvoiceAddItem :warehouses="warehouses" :backValueMethod="addItem" :items="items" :units="units"
            :taxes="taxes" v-if="newLineDialog == true" :dialog="newLineDialog" :tableRows="tableRows" />

      </v-container>
   </section>
</template>



<script>
import PurchaseInvoiceAddItem from './PurchaseInvoiceAddItem.vue';
export default {
   name: "PurchaseInvoiceAddItemControl",
   data: () => ({
      pageData: {
         screen_code: "04-006",
         url: null,
         controlRoute: "/purchase/purchases_invoice-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      formCollapse: false,
      newLineDialog: false,
      validItem: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      currencies: [],
      items: [],
      purchases_agents: [],
      purchases_vendors: [],
      taxes_percent: [],
      units: [],
      taxes: [],
      warehouses: [],
      purchases_invoice_types: [],
      receiving_locations: [],
      form: {
         purchases_invoice_date: null,
         purchases_invoice_type_id: null,
         purchases_invoice_detail: 1,
         purchases_vendor_id: null,
         purchases_agent_id: null,
         purchases_vendor_invoice_no: null,
         purchases_order_id: null,
         currency_id: null,
         purchases_invoice_description: null,
         items: []
      },
      viewForm: false,
      summery: [],
      footerData: [{
         item_title: null,
         unit_id: null,
         quantity: 0,
         total_quantity_price: 0,
         price: 0,
         total_vat: 0,
         total_with_vat: 0,
      }],
   }),
   components: {
      PurchaseInvoiceAddItem,
   },
   computed: {
      totalsCalculations() {
         if (this.computedData.new_array) {
            const totalPrice = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.price);
            }, 0);
            const totalQuantityPrice = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(newValue.quantity) * Number(newValue.price);
            }, 0);
            const totalQty = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.quantity);
            }, 0);
            const total_discount = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_discount);
            }, 0);
            const total_vat_after_discount = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_vat_after_discount);
            }, 0);
            const total_price_after_discount_with_vat = this.computedData.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.total_price_after_discount_with_vat);
            }, 0);
            return {
               item_title: this.$i18n.t('total'),
               quantity: totalQty,
               price: totalPrice,
               total_discount: total_discount,
               total_quantity_price: totalQuantityPrice,
               total_vat_after_discount: total_vat_after_discount,
               total_price_after_discount_with_vat: total_price_after_discount_with_vat,
            }
         } else {
            return {
               item_title: this.$i18n.t('total'),
               price: 0,
               quantity: 0,
               total_discount: 0,
               total_quantity_price: 0,
               total_vat_after_discount: 0,
               total_price_after_discount_with_vat: 0,
            }
         }
      },
      computedData() {
         if (this.tableRows.length > 0) {
            return this.$global.PRICE_CALCULATION(this.tableRows, {
               type: 1,
               value: 0,
               discount_amount: 0,
               percent_value: 0,
               total_price: 0,
            })
         } else {
            return Object
         }
      }
   },
   watch: {

      tableRows() {
         this.tableRows.forEach(row => {
            row.item_title = row.item_id ? this.$global.FilterArrayOfObjectElement(this.items, 'id', row.item_id, 'name') : null;
            row.unit_title = row.unit_id ? this.$global.FilterArrayOfObjectElement(this.units, 'id', row.unit_id, 'name') : null;
            row.tax_percent = row.tax_percent_id ? this.$global.FilterArrayOfObjectElement(this.taxes, 'id', row.tax_percent_id, 'tax_percentage') : null;
            row.warehouse_name = row.warehouse_id ? this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', row.warehouse_id, 'name') : null;
            row.receiving_location_name = row.receiving_location_id ? this.$global.FilterArrayOfObjectElement(this.receiving_locations, 'id', row.receiving_location_id, 'name') : null;
            row.total_quantity_price = Number(row.quantity) * Number(row.price);
            row.total_vat = row.total_quantity_price * ((Number(row.tax_percent) / 100));
            row.total_with_vat = row.total_vat + row.total_quantity_price;
         });
      },
   },
   mounted() {
      this.pageMainData();
      this.form.purchases_invoice_date = this.$global.GetCurrentDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "count pieces", key: "quantity", type: 'number', classes: "" },
            { text: "price piece", key: "price", type: 'float', classes: "" },
            { text: "total quantity price", key: "total_quantity_price", type: 'float', classes: "" },
            { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
            { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
            { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            { text: "delete", key: "id", type: 'delete', classes: "" },
         ];


      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`purchases_invoice/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.master;
                     this.tableRows = response.data.details;
                     this.items = response.data.items || [];
                     this.purchases_agents = response.data.purchases_agents || [];
                     this.purchases_vendors = response.data.purchases_vendors || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes = response.data.taxes || [];
                     this.warehouses = response.data.warehouses || [];
                     this.currencies = response.data.currencys || [];
                     this.receiving_locations = response.data.receiving_locations || [];
                     this.purchases_invoice_types = response.data.purchases_invoice_types || [];
                     this.summery = [
                        {
                           label: 'invoice number',
                           value: response.data.master.m_id,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'vendor invoice number',
                           value: response.data.master.purchases_vendor_invoice_no,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'date',
                           value: response.data.master.purchases_invoice_date,
                           type: 'date', width: 120,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'description',
                           value: response.data.master.purchases_invoice_description,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },

                        {
                           label: 'vendor',
                           value: response.data.master.purchases_vendor_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'purchase agent',
                           value: response.data.master.purchases_agent_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'total',
                           value: response.data.master.total_with_vat,
                           type: 'price',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },

                     ]
                     this.viewSummery = true;

                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`purchases_invoice/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.items = response.data.items || [];
                     this.purchases_agents = response.data.purchases_agents || [];
                     this.purchases_vendors = response.data.purchases_vendors || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes = response.data.taxes || [];
                     this.currencies = response.data.currencys || [];
                     this.receiving_locations = response.data.receiving_locations || [];
                     this.purchases_invoice_types = response.data.purchases_invoice_types || [];
                     this.warehouses = response.data.warehouses || [];
                     this.form.purchases_invoice_type_id = response.data.purchases_invoice_types.length ? response.data.purchases_invoice_types[0].id : null;
                     if (this.$route.params.data) {
                        this.pageData.isLoading = true;
                        const IDS = this.$route.params.data.map(object => object.m_id);
                        this.form.selected_purchases_notes = this.$route.params.selectedRowType == 2 ? IDS : null;
                        this.form.selected_purchases_orders = this.$route.params.selectedRowType == 3 ? IDS : null;
                        let requestBody = {
                           purchases_notes: this.$route.params.selectedRowType == 2 ? IDS : null,
                           purchases_orders: this.$route.params.selectedRowType == 3 ? IDS : null,
                        }

                        this.$api.POST_METHOD(`get_purchases_note_data`, requestBody).then((response) => {
                           this.pageData.isLoading = false;
                           if (response.check) {
                              this.tableHeader = [
                                 { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
                                 { text: "item", key: "item_title", type: 'text', classes: "" },
                                 { text: "unit", key: "unit_title", type: 'text', classes: "" },
                                 { text: "count pieces", key: "quantity", type: 'number', classes: "" },
                                 { text: "price piece", key: "price", type: 'float', classes: "" },
                                 { text: "total quantity price", key: "total_quantity_price", type: 'float', classes: "" },
                                 { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
                                 { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
                                 { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
                                 { text: "delete", key: "id", type: 'delete', classes: "" },
                              ];
                              response.data.items.forEach(detail => {
                                 detail.type = this.$route.params.selectedRowType == 2 ? 'PN' : "PO"
                              });
                              this.tableRows = response.data.items
                           }
                        })
                     }
                  }
               })

            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`purchases_invoice/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_sales_invoice/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`purchases_invoice`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_purchases_invoice/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url);

                  }
               })
            }
         }
      },
      addItem(item, status) {
         if (status == true) {
            this.tableRows.push(item);
         }
         this.newLineDialog = false
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`purchases_invoice/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
