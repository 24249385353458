<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :showSearch="false" :getData="getData" actionBtnText="filter"
        :showDeleted="false" :createBtn="false" :actionBtn="!showFilter" :actionBtnValid="true"
        actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true"
        actionBtnClass="blue4--text" :getSearch="getSearch" />
      <!-- End Of Header -->

      <!-- Filter -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" justify="space-between" class="">
              <GenericInput type="select" solo :clearable="true" :lookups="warehouses" :value="warehouse_id"
                @input="warehouse_id = $event" label="warehouse" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.detailIsLoading)"
                :cols="[12, 6, 4]">
              </GenericInput>
              <GenericInput type="date" :value="date" :maxDate="today" @input="date = $event" label="date"
                :required="false" :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 4]">
              </GenericInput>
              <v-col cols="auto" md="6" lg="3" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" outlined :disabled="!(warehouse_id)"
                  :loading="(pageData.isLoading || pageData.detailIsLoading)" @click="getDetails"
                  class="mx-1 white--text">{{
                  $t("Search")
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!--  -->
      <!-- Summery -->
      <!-- <div class="mt-5">
        <ControlSummery :summery="summery" v-if="evaluation_balance" />
      </div> -->
      <!-- End of Summery -->

      <v-row v-if="warehouse_logs.length > 0">
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="m_id" :data="warehouse_logs"
            :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
            :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" />
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
// import ControlSummery from "@/components/ui/ControlSummery.vue";
export default {
  name: "EvaluationBalancesEntity",

  data: () => ({
    pageData: {
      screen_code: "03-012",
      url: null,
      controlRoute: "inventory/evaluation_balance-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      detailIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    showFilter: true,
    date: null,
    today: null,
    evaluation_balance: null,
    warehouse_logs: [],
    unit_measurements: [],
    summery: [],
    warehouses: [],
    warehouse_id: null,
  }),
  components: {
    EntityHeader,
    DynamicTable,
    GenericInput,
    // ControlSummery
  },
  watch: {
    warehouse_id() {
      this.evaluation_balance = null;
      this.warehouse_logs = [];
      if (this.warehouse_id) {
        this.getDetails();
      }
    }
  },
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.date = this.$global.GetCurrentDate();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "#", key: "i_id", type: 'text', classes: "" },
        { text: "item number", key: "item_id", type: 'text', classes: "" },
        { text: "item", key: "item_name", type: 'text', classes: "" },
        { text: "unit", key: "unit_title", type: 'text', classes: "" },
        { text: "unit price", key: "sale_price", type: 'text', classes: "" },
        { text: "quantity", key: "stock_value", type: 'text', classes: "" },
        { text: "balance", key: "balance_value", type: 'text', classes: "" },

      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`warehouse_evaluation`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.warehouses = response.data.warehouses;
              this.unit_measurements = response.data.unit_measurements;
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getDetails() {
      this.pageData.detailIsLoading = true;
      this.$api.POST_METHOD(`warehouse_evaluation`, { warehouse_id: this.warehouse_id, date: this.date }).then((response) => {
        this.pageData.detailIsLoading = false;
        if (response.check) {
          this.evaluation_balance = response.data.evaluation_balance || null;
          response.data.items.forEach((detail, index) => {
            detail.i_id = index + 1;

            switch (detail.default_report_unit_type) {
            case 1:
                detail.stock_value = detail.stock_in_small;
                detail.balance_value = detail.balance_in_small;
                detail.unit_title = this.$global.FilterArrayOfObjectElement(this.unit_measurements, 'id', detail.small_unit_id, 'name');
              break;
            case 2:
                detail.stock_value = detail.stock_in_mid;
                detail.balance_value = detail.balance_in_mid;
                detail.unit_title = this.$global.FilterArrayOfObjectElement(this.unit_measurements, 'id', detail.mid_unit_id, 'name');
              break;
            case 3:
                detail.stock_value = detail.stock_in_big;
                detail.balance_value = detail.balance_in_big;
                detail.unit_title = this.$global.FilterArrayOfObjectElement(this.unit_measurements, 'id', detail.big_unit_id, 'name');
              break;
          
            default:
                detail.stock_value = 0;
                detail.balance_value = 0;
                detail.unit_title  = '-';
              break;
          }
          });
          this.warehouse_logs = response.data.items || [];
         

        }
      });
    },
    changePage(page, limit) {
      // this.$store.dispatch('setPagesRows', { rowsNumber: limit, pageNumber: page })
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`warehouse_evaluation/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`warehouse_evaluation_toggle_active/${row.m_id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/evaluation_balance/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.m_id);
    },
  },
};
</script>
