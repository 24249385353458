<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <v-col cols="12">
                     <div class="subtitle-1">{{ $t('details') | capitalize }}</div>
                  </v-col>
                  <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                     :required="true" :isLoading="pageData.editIsLoading" icon="mdi-account-outline" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                     :required="true" :isLoading="pageData.editIsLoading" icon="mdi-account-outline" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="mobile" :value="form.phone" @input="form.phone = $event" label="phone"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
                  <GenericInput type="text" :value="form.address" @input="form.address = $event" label="address"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
                  <GenericInput type="text" :value="form.contract_name" @input="form.contract_name = $event"
                     label="contract name" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.email" @input="form.email = $event" label="email"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>
                  <GenericInput type="number" :value="form.vat_number" @input="form.vat_number = $event"
                     label="vat number" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

               </v-row>
            </v-card>
            <v-card class="backgroundW shadow mt-2 pa-5 pt-7">
               <v-row align="center">
                  <v-col cols="12">
                     <div class="subtitle-1">{{ $t('transaction shipping process') | capitalize }}</div>
                  </v-col>
                  <GenericInput type="select" :required="true"
                     :lookups="types"
                     selected_label="name" selected_prop="id" :value="form.incoming_type_id"
                     @input="form.incoming_type_id = $event" label="incoming type" :isLoading="false" :cols="[12, 6, 3]">
                  </GenericInput>

                  <GenericInput type="select" :required="true"
                     :lookups="types"
                     selected_label="name" selected_prop="id" :value="form.outgoing_type_id"
                     @input="form.outgoing_type_id = $event" label="outgoing type" :isLoading="false" :cols="[12, 6, 3]">
                  </GenericInput>
                  <v-col cols="auto" class="d-none d-lg-block">
                     <v-divider vertical style="height: 2rem"></v-divider>
                  </v-col>
                  <GenericInput type="number" :value="form.high_score" @input="form.high_score = $event"
                     label="high score" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                  </GenericInput>
                  <GenericInput type="select" :required="true"
                     :lookups="types"
                     selected_label="name" selected_prop="id" :value="form.high_score_type_id"
                     @input="form.high_score_type_id = $event" label="type" :isLoading="false" :cols="[12, 6, 3]">
                  </GenericInput>
               </v-row>
            </v-card>
            <v-card class="backgroundW shadow mt-2 pa-5 pt-7">
               <v-row align="center">
                  <v-col cols="12">
                     <div class="subtitle-1">{{ $t('contract') | capitalize }}</div>
                  </v-col>
                  <GenericInput type="textarea" rowsNumber="6" :labelInline="false" :value="form.add_info"
                     @input="form.add_info = $event" label="description" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="dropzone" :value="form.contract_image" @input="form.contract_image = $event"
                     label="photo of this client contract" :labelInline="false" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
   name: "CustomersControl",

   data: () => ({
      pageData: {
         screen_code: "03-003",
         url: null,
         controlRoute: "/warehouses/client-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      types: [],
      delegates: [],
      form: {
         name_en: null,
         name_ar: null,
         phone: null,
         address: null,
         contract_name: null,
         email: null,
         vat_number: null,
         incoming_type_id: null,
         outgoing_type_id: null,
         high_score: null,
         high_score_type_id: null,
         contract_image: null,
         add_info: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },

      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`client/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.types = response.data.types;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`client/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.types = response.data.types;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`client/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`client`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`client/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
