<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData"
        :createBtnText="$i18n.t('add') + ' ' + this.$store.getters.activeScreen.sub_title" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden">
              <v-col cols="12" class="pa-0 px-10">
                <v-tabs color="secondary" active-class="green11">
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold" :value="1" @click="tab = 1"><strong>{{
                      $t('purchase orders')
                  }}</strong>
                  </v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold" :value="2" @click="tab = 2"><strong>{{
                      $t('pending purchase note')
                  }}</strong>
                  </v-tab>
                  <v-tab :disabled="pageData.isLoading" class="font-weight-bold" :value="3" @click="tab = 3"><strong>{{
                      $t('closed purchase note')
                  }}</strong>
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
              <DynamicTable :isLoading="pageData.isLoading" deleteProperty="m_id" :data="pageData.rows"
                :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" cardClasses="shadow-none">
                <template v-slot:td="{ row ,header }">
                  <span v-if="header.key == 'movements'" class="d-flex justify-center">
                    <v-btn icon color="success" @click="createPurchaseNote(row)">
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </span>
                  <div v-if="header.key == 'actions'">
                    <PrintInvoice endpoint="print_purchases_note" :screenCode="pageData.screen_code" :id="row.m_id" />
                    <v-btn @click="edit(row)" x-small icon fab>
                      <img src="@/assets/img/svg_icons/fi_eye.svg" height="16" />
                    </v-btn>
                  </div>
                </template>
              </DynamicTable>
            </v-card>
          </v-col>

        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "PurchasesNoteEntity",

  data: () => ({
    pageData: {
      screen_code: "04-005",
      url: null,
      controlRoute: "purchase/purchases_note-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    tab: 0,
    purchases_order: [],
    pending_purchases_note: [],
    completed_purchases_notes: [],
  }),
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    tab() {
      if (this.tab == 2 || this.tab == 3) {
        this.pageData.rows = this.tab == 2 ? this.pending_purchases_note : this.completed_purchases_notes;
        this.pageData.tableHeader = [
          { text: "#", key: "m_id", type: 'text', classes: "" },
          { text: "note number", key: "purchases_note_no", type: 'text', classes: "" },
          { text: "note date", key: "purchases_note_date", type: 'date',width: 120, classes: "" },
          { text: "supplier", key: "purchases_vendor_name", type: 'text', classes: "" },
          { text: "po no", key: "purchases_order_id", type: 'text', classes: "" },
          { text: "purchases vendor invoice no", key: "purchases_vendor_invoice_no", type: 'text', classes: "" },
          { text: "actions", key: "actions", type: 'slot', classes: "" },
          // { text: "movements", key: "m_id", type: 'actions', classes: "" },
        ];
      }
      else {
        this.pageData.rows = this.purchases_order;
        this.pageData.tableHeader = [
          { text: "po no", key: "m_id", type: 'text', classes: "" },
          { text: "po date", key: "purchases_order_date", type: 'date',width: 120, classes: "" },
          { text: "supplier", key: "purchases_vendor_name", type: 'text', classes: "" },
          { text: "payment method", key: "payment_method_name", type: 'text', classes: "" },
          { text: "total without vat", key: "total_without_vat", type: 'price', classes: "" },
          { text: "total vat", key: "total_vat", type: 'price', classes: "" },
          { text: "total with vat", key: "total_with_vat", type: 'price', classes: "" },
          { text: "movements", key: "movements", type: 'slot', classes: "" },
        ];
      }
    }
  },
  components: {},
  computed: {

  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "po no", key: "m_id", type: 'text', classes: "" },
        { text: "po date", key: "purchases_order_date", type: 'date',width: 120, classes: "" },
        { text: "supplier", key: "purchases_vendor_name", type: 'text', classes: "" },
        { text: "payment method", key: "payment_method_name", type: 'text', classes: "" },
        { text: "total without vat", key: "total_without_vat", type: 'price', classes: "" },
        { text: "total vat", key: "total_vat", type: 'price', classes: "" },
        { text: "total with vat", key: "total_with_vat", type: 'price', classes: "" },
        { text: "movements", key: "m_id", type: 'slot', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `purchases_note${this.pageData.queryParam}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pending_purchases_note = response.data.purchases_notes.data;
              this.completed_purchases_notes = response.data.completed_purchases_notes.data;
              this.purchases_order = response.data.purchases_orders.data;
              this.tab = 1
              this.pageData.page = response.data.purchases_notes.current_page;
              this.pageData.pagination = {
                page: response.data.purchases_notes.current_page,
                totalPages: response.data.purchases_notes.last_page,
                per_page: response.data.purchases_notes.per_page,
                totalRows: response.data.purchases_notes.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`purchases_note/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    createPurchaseNote(row) {
      this.$router.push({
        name: "PurchasesNoteControlADD",
        params: { data: row },
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`purchases_note_toggle_active/${row.m_id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/purchases_note/${row.m_id}`)
    },
    changeStatus(index) {
      this.pageData.isLoading = true;
      if (this.pageData.rows[index].change_status == 1) {
        this.$api.POST_METHOD(`accept_purchase_order/${this.pageData.rows[index].m_id}`, null).then((response) => {
          if (response.check) {
            this.getData();
          }
        })
      }
      if (this.pageData.rows[index].change_status == 2) {
        this.$api.POST_METHOD(`decline_purchase_order/${this.pageData.rows[index].m_id}`, null).then((response) => {
          if (response.check) {
            this.getData();
          }
        })
      }
      if (this.pageData.rows[index].change_status == 3) {
        this.deleteMethod(this.pageData.rows[index].m_id)
      }

    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.m_id);
    },
  },
};
</script>
