import WarehouseEntity from "@/views/InventoryModule/Warehouse/WarehouseEntity";
import ItemEntity from "@/views/InventoryModule/Item/ItemEntity";
import ItemControl from "@/views/InventoryModule/Item/ItemControl";
import ItemTypeEntity from "@/views/InventoryModule/ItemType/ItemTypeEntity";
import MeasurementUnitEntity from "@/views/InventoryModule/MeasurementUnit/MeasurementUnitEntity";
import StockInitialBalanceEntity from "@/views/InventoryModule/StockInitialBalance/StockInitialBalanceEntity";
import StockInitialBalanceControl from "@/views/InventoryModule/StockInitialBalance/StockInitialBalanceControl";
import StockTransferEntity from "@/views/InventoryModule/StockTransfer/StockTransferEntity";
import StockTransferControl from "@/views/InventoryModule/StockTransfer/StockTransferControl";
import StockTransactionEntityVue from "@/views/InventoryModule/StockTransaction/StockTransactionEntity.vue";
import StockTransactionControlVue from "@/views/InventoryModule/StockTransaction/StockTransactionControl.vue";
import Category from "@/views/InventoryModule/Categories/Category.vue";
import ItemCardEntityVue from "@/views/InventoryModule/ItemCard/ItemCardEntity.vue";
import StocktakingEntityVue from "@/views/InventoryModule/Stocktaking/StocktakingEntity.vue";
import StocktakingControl from "@/views/InventoryModule/Stocktaking/StocktakingControl.vue";
import WarehouseBalancesEntity from "@/views/InventoryModule/WarehouseBalances/WarehouseBalancesEntity.vue";
import EvaluationBalancesEntity from "@/views/InventoryModule/EvaluationBalances/EvaluationBalancesEntity.vue";
import ItemTrialBalanceEntity from "@/views/InventoryModule/ItemTrialBalance/ItemTrialBalanceEntity.vue";

// Inventory Module Routes:-

export const InventoryModuleChildrens = [
  // Warehouse
  {
    path: "/inventory/warehouse",
    name: "WarehouseEntity",
    component: WarehouseEntity,
    meta: {
      screen_code: "03-001",
    },
  },
  // Category
  {
    path: "/inventory/category",
    name: "Category",
    component: Category,
    meta: {
      screen_code: "03-002",
    },
  },
  // Item
  {
    path: "/inventory/item",
    name: "ItemEntity",
    component: ItemEntity,
    meta: {
      screen_code: "03-004",
    },
  },
  {
    path: "/inventory/item-control",
    name: "ItemControl",
    component: ItemControl,
    meta: {
      screen_code: "03-004",
    },
  },
  {
    path: "/inventory/item-control/:id",
    name: "ItemControlEdit",
    component: ItemControl,
    meta: {
      screen_code: "03-004",
    },
  },
  // Item Type
  {
    path: "/inventory/item_type",
    name: "ItemTypeEntity",
    component: ItemTypeEntity,
    meta: {
      screen_code: "03-003",
    },
  },
  // Measurement Unit
  {
    path: "/inventory/measurement_unit",
    name: "MeasurementUnitEntity",
    component: MeasurementUnitEntity,
    meta: {
      screen_code: "03-005",
    },
  },
  // Stock Initial Balance
  {
    path: "/inventory/stock_initial_balance",
    name: "StockInitialBalanceEntity",
    component: StockInitialBalanceEntity,
    meta: {
      screen_code: "03-006",
    },
  },
  {
    path: "/inventory/stock_initial_balance-control",
    name: "StockInitialBalanceControl",
    component: StockInitialBalanceControl,
    meta: {
      screen_code: "03-006",
    },
  },
  {
    path: "/inventory/stock_initial_balance-control/:id",
    name: "StockInitialBalanceControlEdit",
    component: StockInitialBalanceControl,
    meta: {
      screen_code: "03-006",
    },
  },
  // Stock Transfer
  {
    path: "/inventory/stock_transfer",
    name: "StockTransferEntity",
    component: StockTransferEntity,
    meta: {
      screen_code: "03-008",
    },
  },
  {
    path: "/inventory/stock_transfer-control",
    name: "StockTransferControl",
    component: StockTransferControl,
    meta: {
      screen_code: "03-008",
    },
  },
  {
    path: "/inventory/stock_transfer-control/:id",
    name: "StockTransferControlEdit",
    component: StockTransferControl,
    meta: {
      screen_code: "03-008",
    },
  },
  // Stock Transaction
  {
    path: "/inventory/stock_transaction",
    name: "StockTransactionEntity",
    component: StockTransactionEntityVue,
    meta: {
      screen_code: "03-007",
    },
  },
  {
    path: "/inventory/stock_transaction-control",
    name: "StockTransactionControl",
    component: StockTransactionControlVue,
    meta: {
      screen_code: "03-007",
    },
  },
  {
    path: "/inventory/stock_transaction-control/:id",
    name: "StockTransactionControlEdit",
    component: StockTransactionControlVue,
    meta: {
      screen_code: "03-007",
    },
  },
  // Stocktaking 
  {
    path: "/inventory/stocktaking",
    name: "StocktakingEntity",
    component: StocktakingEntityVue,
    meta: {
      screen_code: "03-009",
    },
  },
  {
    path: "/inventory/stocktaking-control",
    name: "StocktakingControl",
    component: StocktakingControl,
    meta: {
      screen_code: "03-009",
    },
  },
  {
    path: "/inventory/stocktaking-control/:id",
    name: "StocktakingControlEdit",
    component: StocktakingControl,
    meta: {
      screen_code: "03-009",
    },
  },
  // Item Card
  {
    path: "/inventory/item_card",
    name: "ItemCardEntity",
    component: ItemCardEntityVue,
    meta: {
      screen_code: "03-010",
    },
  },
  // Warehouse Balances
  {
    path: "/inventory/warehouse_balances",
    name: "WarehouseBalancesEntity",
    component: WarehouseBalancesEntity,
    meta: {
      screen_code: "03-011",
    },
  },
  // Evaluation Balances
  {
    path: "/inventory/evaluation_balance",
    name: "EvaluationBalancesEntity",
    component: EvaluationBalancesEntity,
    meta: {
      screen_code: "03-012",
    },
  },
  // Item Trial Balance 
  {
    path: "/inventory/item_trial_balance",
    name: "ItemTrialBalanceEntity",
    component: ItemTrialBalanceEntity,
    meta: {
      screen_code: "03-013",
    },
  },

];
