<template>
  <v-container fluid class="bgAuth light d-flex align-center justify-center pa-0" style="height: 100vh">
    <v-col cols="11" lg="4" md="6" sm="7">
      <img src="@/assets/logo.svg" class="mx-auto d-block mb-5" height="60" alt="wms" />
      <v-card class="login-card backgroundW pa-5">
        <h5 class="text-h5 text-center my-3">{{ $t("Welcome Back") }}</h5>
        <div class="subtitle-2 text-center text--disabled">{{ $t('Enter your credentials to access your account') }}
        </div>
        <v-form ref="form" @submit="login()" v-model="valid" class="mt-3" lazy-validation>
          <v-col cols="12">
            <h5 class="mb-3">{{ $t('userName') }}</h5>
            <v-text-field hide-details="auto" class="my-2 rounded-lg" filled :rules="$global.requiredRule"
              @keydown.enter="login()" v-model="username" rounded dense color="primary"
              prepend-inner-icon="mdi-account-circle-outline" type="text"></v-text-field>
          </v-col>
          <v-col cols="12">
            <h5 class="mb-3">{{ $t('password') }}</h5>
            <v-text-field hide-details="auto"  class="my-2 rounded-lg" filled required color="primary"
              :rules="passwordRule" v-model="password" dense rounded @keydown.enter="login()"
              :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
              prepend-inner-icon="mdi-key-outline" @click:append="show = !show"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-btn @click="login" :loading="loading"  block large
              class="d-block mi-start-auto rounded-lg shadow" color="primary">
              {{ $t("login") }}
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white" class="mdi-spin">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
        </v-form>
      </v-card>
      <div class="subtitle-2 my-5 text-center white--text">
        {{ $t('Forget your password?') }}
        <a class="blue5--text">
          <strong>{{ $t('Reset Password') }}</strong>
        </a>
      </div>
    </v-col>
  </v-container>
</template>
<style scoped lang="scss">
.bgAuth {
  background-image: url("../../assets/img/wms_png/authentication-bg.png");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  .login-card {
    //background: #fff;
    padding-bottom: 1.5rem;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 4px 28px rgb(0 0 0 / 5%) !important;
  }
}
</style>
<script>

export default {
  name: "LoginPage",
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  mounted() {
    this.$api.LOGOUT(false);
    localStorage.setItem('app_version', this.$store.state.app_version)
    this.$store.commit('UPDATE_CLIENT', "wms")
  },
  components: {

  },
  watch: {

  },
  data() {
    return {
      show: false,
      valid: false,
      checkbox: false,
      loading: false,
      connectionStatus: '',
      companyNumber: "trans",
      username: null,
      password: null,
      usernameRule: [
        v => !!v || this.$t('Username is required')
      ],
      passwordRule: [
        v => !!v || this.$t('Password is required'),
        v => (v && v.length >= 5) || this.$t('Password must be greater than 5 characters'),
      ],
      companyIDRule: [
        v => !!v || this.$i18n.t('Company number is required'),
        v => (v && v.length >= 3) || this.$i18n.t('Company ID not valid'),
      ],
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true && this.$store.state.clientID) {
        const requstBody = {
          username: this.username,
          password: this.password,
        }
        this.$api.LOGIN_AUTH(requstBody).then((response) => {
          this.loading = false;
          if (response.check == true) {
            this.$router.push('/');
            localStorage.setItem("clientID", this.$store.state.clientID)
          }
        })
      }
      else {
        this.loading = false;
      }

    },

  },
};
</script>
