<template>
  <section>
    <v-container fluid class="">
      <v-row>
        <v-col cols="12">
          <h1 class="error--text text-center font-weight-black">!!This Page for testing only !!</h1>
        </v-col>

        <v-col cols="12">
          <h3 class="primary--text font-weight-black">Accounting</h3>
        </v-col>
        <v-col cols="auto">
          <v-btn to="accounting/tax" :min-width="180" color="success" depressed>tax</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="accounting/financial_periods" :min-width="180" color="success" depressed>financial periods</v-btn>
        </v-col>

        <v-col cols="12">
          <h3 class="primary--text font-weight-black">Inventory</h3>
        </v-col>
        <v-col cols="auto">
          <v-btn to="inventory/warehouse" :min-width="180" color="success" depressed>warehouse</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="inventory/category" :min-width="180" color="success" depressed>category</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="inventory/item" :min-width="180" color="success" depressed>item</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="inventory/item_type" :min-width="180" color="success" depressed>item type</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/inventory/measurement_unit" :min-width="180" color="success" depressed> measurement unit</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/inventory/stock_initial_balance" :min-width="180" color="success" depressed> Stock Initial Balance
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/inventory/stock_transfer" :min-width="180" color="success" depressed> Stock transfer</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/inventory/stock_transaction" :min-width="180" color="success" depressed> stock transaction</v-btn>
        </v-col>

        <v-col cols="12">
          <h3 class="primary--text font-weight-black">Sales</h3>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/sales/sales_representatives" :min-width="180" color="success" depressed>Sales Representatives
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/sales/customer" :min-width="180" color="success" depressed>customers</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/sales/customer_type" :min-width="180" color="success" depressed>customers type</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/sales/sales_area" :min-width="180" color="success" depressed>sales area</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/sales/quotation" :min-width="180" color="success" depressed>quotation</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/sales/proforma" :min-width="180" color="success" depressed>proforma</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/sales/delivery_note" :min-width="180" color="success" depressed>delivery note</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/sales/invoices" :min-width="180" color="success" depressed>invoices</v-btn>
        </v-col>
        <v-col cols="12">
          <h3 class="primary--text font-weight-black">Purchase</h3>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/purchase/purchase_representative" :min-width="180" color="success" depressed>purchase
            representative</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/purchase/purchases_vendor_type" :min-width="180" color="success" depressed>purchase
            vendor type</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/purchase/purchases_vendor" :min-width="180" color="success" depressed>purchase
            vendor</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn to="/purchase/purchases_order" :min-width="180" color="success" depressed> Purchase order</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>

export default {
  name: "RoutesIndex",
  computed: {

  },
  components: {  
},
  data: () => ({

  }),
 
  methods: {

  },
};
</script>

<style scoped lang="scss">
</style>
