<template>
   <v-container fluid class="d-flex justify-center align-center backgroundW"
      style="height: 100vh;position: fixed; top: 0;left:0; width:100vw ; z-index: 1000;">

      <div>
         <AnimatedLogo :height="80" classes="justify-center mb-16 d-flex" />
         <!-- <v-progress-circular :size="40" :width="5" style="margin: auto; " class="d-block" color="primary"
            indeterminate></v-progress-circular> -->
            <img src="@/assets/img/png/files/loading.gif" class="mt-16 d-block ma-auto" :height="50" alt="">
      </div>

   </v-container>
</template>
<style  scoped>
</style>


<script>
import AnimatedLogo from './AnimatedLogo.vue';

export default {
   name: "SplashLoader",
   computed: {

   },
   components: { AnimatedLogo },
   data: () => ({

   }),
   mounted() {

   },

   methods: {

   },
};
</script>
