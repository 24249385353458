<template>
  <v-card class="shadow rounded-lg justify-center my-1 py-5">
    <v-col cols="12" sm="12" class="pt-0">
      <v-data-table :headers="header" :items="data" disable-pagination calculate-widths :loading="isLoading"
        :loading-text="$i18n.t('Loading... Please wait')" :no-data-text="$i18n.t('No data available')"
        :sort-by="options.sortBy" hide-default-footer class="elevation-0" :class="options.handleClickRow ? ' ' : ''"
        :search="searchValue ? searchValue : search" :show-select="options.selectRow" v-model="selected"
        @click:row="handleClickRow">
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.cheque_status_id="{ item, index }">
          <v-row align="center" justify="center" v-if="item.cheque_status_id == 3">
            <v-col cols="auto">
              <v-btn depressed small :loading="pageData.saveStatusLoading" class="success--text"
                @click="changeStatus(item, 1)" color="#B9DFCC">
                {{ $t("collected") }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn depressed small :loading="pageData.saveStatusLoading" class="error--text"
                @click="changeStatus(item, 2)" color="#FFC4C4">
                {{ $t("rejected") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-if="item.cheque_status_id == 0">
            <v-col cols="auto">
              <v-btn depressed small class="white--text" color="#056DE1" v-if="!item.addCollectedDate"
                v-on:click="addCollectedDate(item, index)">
                {{ $t("deposited") }}
              </v-btn>
              <v-row align="center" v-if="item.addCollectedDate == true">
                <v-col cols="auto" class="pa-0">
                  <v-dialog ref="dialog" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y :nudge-right="40" color="primary" max-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="item.deposit_date" prepend-inner-icon="mdi-calendar"
                        @click:prepend-inner="menu = true" readonly outlined :placeholder="$t('deposit date')" dense
                        hide-details color="primary" v-bind="attrs" clearable v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="item.deposit_date" :active-picker.sync="activePicker"
                      :locale="$vuetify.rtl == true ? 'ar' : 'en-UK'" color="primary" :max="
                        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                          .toISOString()
                          .substr(0, 10)
                      " min="1950-01-01" @change="save">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"> {{ $t('Cancel') }} </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(item.deposit_date)">
                        {{ $t('ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="auto" class="pa-0">
                  <v-btn depressed class="mx-1" :loading="pageData.saveStatusLoading" :disabled="!item.deposit_date"
                    @click="changeStatus(item, 3)" x-small fab>
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <p v-if="item.cheque_status_id == 2" class="error--text font-weight-bold pa-0 ma-0 text-center">
            {{ $t("rejected") }}
          </p>
          <p v-if="item.cheque_status_id == 1" class="success--text font-weight-bold pa-0 ma-0 text-center">
            {{ $t("collected") }}
          </p>
        </template>
      </v-data-table>
    </v-col>
  </v-card>
</template>

<script>
export default {
  name: "ChequesDataTable",
  computed: {},
  components: {},
  methods: {
    handleClickRow(row) {
      // console.log(row);
      this.backValueMethod(row);
    },
    addCollectedDate(item, index) {
      this.selected = item;
      // this.changeStatus(item , 3)
      return (this.data[index].addCollectedDate = !this.data[index].addCollectedDate);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
  props: {
    backValueMethod: { type: Function },
    changeStatus: { type: Function },
    pageData: { default: {} },
    isLoading: { default: false },
    value: { default: "" },
    data: { default: "" },
    header: { default: "" },
    options: { default: "" },
    searchValue: { default: "" },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  data() {
    return {
      search: "",
      selected: [],
      deleteDialog: false,
      dialogDeleteItem: null,
      activePicker: null,
      date: null,
      menu: false,
    };
  },
};
</script>

<style scoped lang="scss">
#printInvoice {
  @media screen {
    display: none;
  }

  @media print {
    display: block;
  }
}

thead {
  tr {
    &:nth-of-type(1) {
      background-color: #f2f2f2 !important;

      &:hover {
        background-color: #f2f2f2 !important;
      }
    }
  }
}

tr {
  &:hover {
    background: transparent !important;
  }
}

td,
th {
  border-bottom: 0 !important;
}
</style>
