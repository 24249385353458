<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && tableRows.length > 0"
            :showCreate="!(pageData.isEdit && form.quotation_status == 4)"
            :showDelete="!(pageData.isEdit && form.quotation_status == 4)"
            :actionBtn="pageData.isEdit && form.quotation_status !== 3 && form.quotation_status !== 4"
            :actionBtnValid="true" actionBtnColor="success" :actionBtnClick="acceptQuotation"
            actionBtnText="accept quotation" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit && !viewForm && form.quotation_status !== 4" :viewForm="() => viewForm = true">
            <template v-slot:left>
               <v-btn depressed @click="refuseQuotation"
                  v-if="pageData.isEdit && form.quotation_status !== 2 && form.quotation_status !== 4"
                  :loading="pageData.isLoading" class="my-2 mx-2 rounded-lg" color="error" outlined :height="37">{{
                     $t("refuse quotation")
                  }}
               </v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->
         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-fade-transition>
                  <div class="collapseForm" v-if="tableRows.length > 0">
                     <div class="d-flex">
                        <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                           <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                           <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </v-fade-transition>
               <v-expand-transition>
                  <v-row align="center" v-show="!formCollapse">
                     <GenericInput type="date" :value="form.quotation_date" @input="form.quotation_date = $event"
                        label="date" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="textarea" solo :value="form.quotation_description"
                        @input="form.quotation_description = $event" label="description" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 9]"></GenericInput>

                     <GenericInput type="select" :lookups="customers" :value="form.customer_id"
                        @input="form.customer_id = $event" label="customer" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="sales_agents" :value="form.sales_agent_id"
                        @input="form.sales_agent_id = $event" label="sales agent" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <v-col cols="8">
                        <v-row align="center">
                           <GenericInput type="checkbox" :value="form.invoice_discount" paddingY="my-3"
                              @input="form.invoice_discount = $event" label="discount" :disabled="false" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="['auto', 'auto', 'auto']">
                           </GenericInput>
                           <v-fade-transition>
                              <GenericInput type="radiogroup" :labelInline="false" v-if="form.invoice_discount"
                                 :lookups="[{ id: 1, name: $i18n.t('value') }, { id: 2, name: $i18n.t('percent') }]"
                                 :value="form.discount_type" @input="form.discount_type = $event" label="" :multi="false"
                                 :required="false" selected_label="name" selected_prop="id"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 'auto']"></GenericInput>
                           </v-fade-transition>
                           <v-expand-x-transition>
                              <GenericInput :type="form.discount_type == 2 ? 'percentage' : 'float'" :value="form.discount"
                                 v-if="form.invoice_discount" @input="form.discount = $event" label="total discount"
                                 :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 'auto']">
                              </GenericInput>
                           </v-expand-x-transition>
                        </v-row>
                     </v-col>


                     <GenericInput v-for="dynamicInput in masterInputs" :key="dynamicInput.id" :name="dynamicInput.id"
                        :type="dynamicInput.type" :value="form[dynamicInput.key]" @input="form[dynamicInput.key] = $event"
                        :label="dynamicInput.label" :lookups="dynamicLookups[dynamicInput.lookups_id]"
                        :required="dynamicInput.required == 0 ? false : true"
                        :disabled="dynamicInput.disabled == 0 ? false : true"
                        :multi="dynamicInput.multi == 0 ? false : true"
                        :cols="dynamicInput.cols.split(',').map(i => Number(i))" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" />


                     <v-col :cols="masterInputs.length ? 12 : 4">
                        <v-btn outlined class="d-block mi-start-auto" depressed color="blue2" :min-width="120"
                           @click="detailDialog = true" :height="37">
                           {{ $t('add line') }}
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-expand-transition>
            </v-card>
         </v-form>

         <!-- Detail Form -->
         <ItemDetail :detailAction="detailAction" :items="items" :warehouses="warehouses" :units="units" :taxes="taxes"
            :dialog="detailDialog" :detail="detail" :updateDetail="updateDetail" :widthHeight="widthHeight"
            :isDetailEdit="isDetailEdit" :tableRows="tableRows" />
         <!-- Detail of Form -->
         <!-- Details Table -->
         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="computedItems.new_array || []" :header="tableHeader"
               :tableHeight="$store.state.Settings.windowSize.y - 230" :footerData="[computedItems]">
               <template v-slot:td="{ row, header, index }">
                  <div v-if="header.key == 'actions'">
                     <v-btn @click="editDetail(index, row)" x-small icon fab>
                        <img src="@/assets/img/svg_icons/pen.svg" height="20" />
                     </v-btn>
                     <v-btn @click="removeItem(index, row)" x-small icon fab>
                        <img src="@/assets/img/svg_icons/delete.svg" height="20" />
                     </v-btn>
                  </div>
               </template>
            </DynamicTable>
         </div>
      </v-container>

      <CustomModal :width="500" :persistent="true" :dialog="approveDialog"
         :buttonTwoMethod="() => $router.push(pageData.url)" :buttonOneMethod="acceptQuotation"
         @close="approveDialog = $event" :dialogContent="{
            title: $i18n.t('are you want to approve quotation now?'),
            img: null,
            details: $i18n.t('you can approve quotation now, or can approve it at any time else'),
            buttonOneTitle: 'approve',
            buttonOneColor: 'blue12',
            buttonOneClass: 'blue1--text',
            buttonTwoTitle: 'not now',
            buttonTwoColor: 'white',
            buttonTwoLoading: false,
            buttonTwoClass: 'error--text',
         }" />
   </section>
</template>



<script>
import ItemDetail from '../ItemDetail.vue';
export default {
   name: "QuotationControl",
   data: () => ({
      pageData: {
         screen_code: "06-005",
         url: null,
         controlRoute: "/sales/quotation-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      formCollapse: false,
      approveDialog: false,
      viewForm: false,
      summery: [],
      valid: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      // lockups
      sales_agents: [],
      customers: [],
      taxes: [],
      account_trees: [],
      items: [],
      units: [],
      warehouses: [],
      widthHeight: false,
      masterInputs: [],
      dynamicLookups: Object,
      form: {
         quotation_date: null,
         quotation_description: null,
         customer_id: null,
         sales_agent_id: null,
         discount_type: null,
         discount: null,
         invoice_discount: null,
         items: [],
      },
      detail: {
         item_id: null,
         unit_id: null,
         item_width_height: null,
         price: 0,
         price_include_vat: 0,
         quantity: 1,
         tax_percent: null,
         tax_percent_id: null,
         warehouse_id: null,
         area_width: 0,
         area_height: 0,
         area_quantity: 0,
         description: null,
      },
   }),
   components: {
      ItemDetail
   },
   computed: {
      computedDiscount() {
         return {
            type: this.form.discount_type || 1,
            value: this.form.discount || 0,
            discount_amount: 0,
            percent_value: 0,
            total_price: 0,
         }
      },
      computedItems() {
         if (this.tableRows.length > 0) {
            return this.$global.PRICE_CALCULATION(this.tableRows, this.computedDiscount)
         } else {
            return Object
         }
      }
   },
   watch: {
      "form.customer_id"() {
         if (!this.pageData.isEdit && this.customers.length > 0) {
            this.form.sales_agent_id = this.$global.FilterArrayOfObjectElement(this.customers, 'id', this.form.customer_id, 'sales_agent_id');
         }
      },
      "form.invoice_discount"() {
         if (!this.pageData.isEdit) {
            if (this.form.invoice_discount) {
               this.form.discount_type = 1;
            } else {
               this.form.discount_type = null;
            }
         }
      },
      "form.discount_type"() {
         if (!this.pageData.isEdit) {
            this.form.discount = null;
         }
      },

      "form.quotation_status"() {
         if (this.form.quotation_status == 4) {
            this.tableHeader = [
               { text: "item", key: "item_title", type: 'text', classes: "" },
               { text: "unit", key: "unit_title", type: 'text', classes: "" },
               { text: "quantity", key: "quantity", type: 'float', classes: "" },
               { text: "price", key: "price_without_vat", type: 'float', classes: "" },
               { text: "discount", key: "total_discount", type: 'float', classes: "" },
               { text: "amount without vat", key: "total_price_without_vat", type: 'float', classes: "" },
               { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
               { text: "vat amount", key: "total_vat_after_discount", type: 'float', classes: "" },
               { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            ];

         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            row.item_title = this.$global.FilterArrayOfObjectElement(this.items, 'id', row.item_id, 'name');
            row.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', row.unit_id, 'name');
            row.warehouse_name = this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', row.warehouse_id, 'name');
            row.tax_percent = this.$global.FilterArrayOfObjectElement(this.taxes, 'id', row.tax_percent_id, 'tax_percentage');
         });
      },
      '$store.state.Settings.key_clicked'() {
         switch (this.$store.state.Settings.key_clicked) {
            case 'F3':
               this.detailDialog = true;
               break;
            case 'F10':
               if (this.valid && !this.detailDialog) {
                  this.save()
               }
               break;
            default:
               break;
         }
      },
   },
   mounted() {
      this.pageMainData();
      this.form.quotation_date = this.$global.GetCurrentDate()
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "quantity", key: "quantity", type: 'float', classes: "" },
            { text: "price", key: "price_without_vat", type: 'float', classes: "" },
            { text: "discount", key: "total_discount", type: 'float', classes: "" },
            { text: "amount without vat", key: "total_price_without_vat", type: 'float', classes: "" },
            { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
            { text: "vat amount", key: "total_vat_after_discount", type: 'float', classes: "" },
            { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            { text: "actions", key: "actions", type: 'slot', classes: "" },
         ];


      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`quotation/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     setTimeout(() => {
                        this.form = response.data.master;
                     }, 1);

                     this.tableRows = response.data.details;
                     this.customers = response.data.customers || [];
                     this.items = response.data.items || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes = response.data.taxes || [];
                     this.warehouses = response.data.warehouses || [];
                     this.masterInputs = response.data.master_inputs || []
                     this.dynamicLookups = response.data.dynamic_lookups || Object
                     this.summery = [
                        {
                           label: 'request status',
                           value: response.data.master.quotation_status == 1 ? this.$i18n.t('pending') : response.data.master.quotation_status == 2 ? this.$i18n.t('refused') : response.data.master.quotation_status == 3 ? this.$i18n.t('accepted') : this.$i18n.t('completed'),
                           class: response.data.master.quotation_status == 1 ? 'blue1--text' : response.data.master.quotation_status == 2 ? 'error--text' : 'success--text',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'purchase order date',
                           value: response.data.master.quotation_date,
                           type: 'date', width: 120,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'description',
                           value: response.data.master.quotation_description,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'customer',
                           value: response.data.master.customer_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'sales agent',
                           value: response.data.master.sales_agent_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'total',
                           value: response.data.master.total_with_vat,
                           type: 'price',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'discount',
                           value: response.data.master.discount,
                           class: '',
                           type: 'float',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },

                     ]
                     this.viewSummery = true;
                     this.widthHeight = response.data.width_height;
                     if (response.data.width_height) {
                        // insert two columns at index 2
                        this.tableHeader.splice(2, 0,
                           { text: "width", key: "area_width", type: 'float', classes: "" },
                           { text: "height", key: "area_height", type: 'float', classes: "" },
                           { text: "count", key: "area_quantity", type: 'float', classes: "" },
                           { text: "description", key: "description", type: 'text', classes: "" },
                        )
                     }
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`quotation/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.customers = response.data.customers || [];
                     this.items = response.data.items || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes = response.data.taxes || [];
                     this.warehouses = response.data.warehouses || [];
                     this.widthHeight = response.data.width_height;
                     Object.keys(response.data.defaults || Object).forEach(key => {
                        this.form[key] = response.data.defaults[key]
                     })

                     if (response.data.master_inputs) {
                        response.data.master_inputs.forEach((input) => {
                           this.form[input.key] = input.value;
                        })
                        this.masterInputs = response.data.master_inputs || []
                        this.dynamicLookups = response.data.dynamic_lookups || Object
                        this.form = { ...this.form }
                     }


                     if (response.data.width_height) {
                        // insert two columns at index 2
                        this.tableHeader.splice(2, 0,
                           { text: "width", key: "area_width", type: 'float', classes: "" },
                           { text: "height", key: "area_height", type: 'float', classes: "" },
                           { text: "count", key: "area_quantity", type: 'float', classes: "" },
                           { text: "description", key: "description", type: 'text', classes: "" },
                        )
                     }
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.form.items = this.tableRows || []
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`quotation/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_quotation/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     // this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                     this.form.m_id = response.data;
                     this.approveDialog = true
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`quotation`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_quotation/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     // this.$router.push(this.pageData.url)
                     this.form.m_id = response.data;
                     this.approveDialog = true
                  }
               })
            }
         }
      },
      detailAction(detail, isEdit, isFinish, cancel) {
         console.log(detail, isEdit, isFinish, cancel);
         if (cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.resetDetail();
         }
         if (isEdit && !cancel) {
            this.updateDetail(detail);
         }
         if (!isEdit && !cancel) {
            this.tableRows.push(detail);
            if (isFinish) {
               this.detailDialog = false;
               this.isDetailEdit = false;
               this.resetDetail();
            }
         }
      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.resetDetail();
      },
      resetDetail() {
         this.detail = {
            item_id: null,
            unit_id: null,
            item_width_height: null,
            price: 0,
            price_include_vat: 0,
            quantity: 1,
            tax_percent: null,
            tax_percent_id: null,
            warehouse_id: null,
            area_width: 0,
            area_height: 0,
            area_quantity: 0,
            description: null,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`quotation/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
      acceptQuotation() {
         this.pageData.isLoading = true
         this.$api.POST_METHOD(`accept_quotation/${this.form.m_id}`, null).then(() => {
            this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
            this.pageData.isLoading = false
         })
      },
      refuseQuotation() {
         this.pageData.isLoading = true
         this.$api.POST_METHOD(`decline_quotation/${this.form.m_id}`, null).then(() => {
            this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
            this.pageData.isLoading = false
         })
      },


   },
};
</script>
