<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :createBtnText="'add incoming'" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col  cols="12" lg="auto" sm="auto" class="px-8 pb-0 position-relative"
          style="z-index: 2;">
          <v-tabs active-class="white primary--text font-weight-bold " color="primary" >
            <v-tabs-slider></v-tabs-slider>

            <v-tab v-for="(item , index) in tabsItems" :key="item" class="rounded-lg rounded-b-0" @click="tab = index + 1">{{ $t(item) | capitalize }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" sm="12" class="pt-0" style="margin-top: -18px;">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            cardClasses="backgroundW primary--border" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
            :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" />
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import Pagination from "@/components/ui/Pagination.vue";
export default {
  name: "InComingEntity",

  data: () => ({
    pageData: {
      screen_code: "08-002",
      url: null,
      controlRoute: "warehouses/incoming-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    tab: 1,
    activeTab: "pending incoming",
    tabsItems: [
      'pending incoming', 'closed incoming'
    ]
  }),
  components: {
    EntityHeader,
    DynamicTable,
    Pagination,
  },
  computed: {},
  watch: {
    tab(){
      this.getData()
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "incoming no", key: "id", type: 'text', classes: "" },
        { text: "client name", key: "customer_name", type: 'text', classes: "" },
        { text: "pallets", key: "total_pallets", type: 'text', classes: "" },
        { text: "cases", key: "total_cases", type: 'text', classes: "" },
        { text: "pieces", key: "total_pieces", type: 'text', classes: "" },
        { text: "created_at", key: "add_date", type: 'date', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `incoming?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}&status=${this.tab}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + ' ' +  this.$global.FilterPermissions(this.pageData.screen_code).sub_title
                  : this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      console.log("page");
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`incoming/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`incoming_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/incoming/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>
