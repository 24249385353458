<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="saveIsValid"
            :showCreate="!(pageData.isEdit && form.sales_invoice_status == 1)"
            :showDelete="!(pageData.isEdit && form.sales_invoice_status == 1)" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit && !viewForm && !form.sales_invoice_status == 1"
            :viewForm="() => viewForm = true" />
         <!-- End Of Header -->
         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
            <v-card class="backgroundW shadow overflow-hidden pa-5 pt-7">
               <v-fade-transition>
                  <div class="collapseForm" v-if="tableRows.length > 0">
                     <div class="d-flex">
                        <v-fade-transition>
                           <v-btn outlined style="background-color:  var(--blue12);"
                              v-if="!data_from_delivery_note && formCollapse && !proformaFromItems" class="mx-2" depressed
                              color="blue2" :width="127" @click="newLineDialog = true" :height="37">
                              {{ $t('add line') }}
                           </v-btn>
                        </v-fade-transition>
                        <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                           <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                           <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </v-fade-transition>
               <v-expand-transition>
                  <v-row justify="space-between" v-show="!formCollapse">
                     <v-col cols="12" md="8" class="py-0">
                        <v-row>
                           <v-col :cols="form.customer_id ? 'auto' : 4">
                              <v-btn depressed color="blue4" block v-if="!data_from_delivery_note" outlined max-width="90%"
                                 style="z-index: 5" class="overflow-hidden position-relative"
                                 @click="proformaDialog = true">
                                 {{ form.customer_id ? form.customer_name : $t('choose customer') }}
                                 <span v-if="form.proforma_invoice_id"> - {{ $t('proforma') }} :
                                    {{ form.proforma_invoice_id }} </span>
                                 <span v-if="form.purchase_order_id"> ({{ form.purchase_order_id }})</span>
                                 <v-icon size="18" right>mdi-open-in-new</v-icon>
                              </v-btn>

                              <div v-if="data_from_delivery_note" class="font-weight-bold body-1">
                                 {{ form.customer_name }} -
                                 <span class="gray8--text subtitle-2">
                                    {{
                                       $t('delivery note number')
                                    }} :
                                    <span class="subtitle-2" v-for="(delivery_note, index) in form.delivery_notes"
                                       :key="index">
                                       {{ delivery_note }} <span v-if="form.delivery_notes.length > index + 1"> -
                                       </span>
                                    </span>
                                 </span>

                              </div>
                           </v-col>
                        </v-row>
                        <v-row align="center">
                           <!-- <GenericInput type="select" :lookups="customers" :value="form.customer_id"
                              @input="form.customer_id = $event" label="customer" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 6, 4]" /> -->

                           <GenericInput type="date" :value="form.sales_invoice_date"
                              @input="form.sales_invoice_date = $event" label="date" :required="true"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />
                           <GenericInput type="text" :value="form.supply_order" @input="form.supply_order = $event"
                              label="supply order" :required="true" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 4]" />
                           <GenericInput type="select" :lookups="sales_agents" :value="form.sales_agent_id"
                              @input="form.sales_agent_id = $event" label="sales agent" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 4]" />
                           <GenericInput type="select" :lookups="sales_invoice_types" :value="form.sales_invoice_type_id"
                              @input="form.sales_invoice_type_id = $event" label="invoice type" :multi="false"
                              :required="true" selected_label="type_title" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]" />

                           <GenericInput type="select" :lookups="treasuries" :value="form.treasury_id"
                              v-if="form.sales_invoice_type_id == 2 || form.sales_invoice_type_id == 4"
                              @input="form.treasury_id = $event" label="treasury" :multi="false" :required="false"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 4]" />
                           <GenericInput type="select" :lookups="networkMachines" :value="machine_id" v-if="isNetwork"
                              @input="machine_id = $event" label="machine" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :cols="[12, 6, 4]" />

                           <GenericInput type="select" :lookups="card_types" :value="cart_type" v-if="isNetwork"
                              @input="cart_type = $event" label="card type" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :cols="[12, 6, 4]" />

                           <GenericInput v-for="(payment, index) in payments" :key="index" type="float"
                              classes="rounded-lg" :value="payment.amount" @input="payment.amount = $event"
                              :label="payment.payment_text" :required="false" :hide-details="true" :cols="[12, 6, 4]" />


                        </v-row>
                     </v-col>
                     <v-col cols="12" md="4" class="py-0">
                        <v-row align="center">
                           <GenericInput type="checkbox" color="blue4" :value="form.link_cost_center" paddingY="my-3"
                              @input="form.link_cost_center = $event" label="linked to cost center" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
                           <v-col cols="12" md="6" class="pa-0">
                              <v-expand-x-transition>
                                 <GenericInput type="select" v-if="form.link_cost_center" :lookups="cost_centers"
                                    paddingY="py-0" :value="form.cost_center_id" @input="form.cost_center_id = $event"
                                    label="cost center" :multi="false" :required="(form.link_cost_center)"
                                    selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                                    :cols="[12, 12, 12]">
                                 </GenericInput>
                              </v-expand-x-transition>
                           </v-col>

                           <GenericInput type="textarea" :rowsNumber="4" solo :value="form.sales_invoice_description"
                              @input="form.sales_invoice_description = $event" label="description" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                           <!-- <v-col cols="12" class="" v-if="isNetwork">
                              <v-row>
                                 <v-col cols="6" lg="3" v-for="(card, index) in card_types" :key="index">
                                    <v-btn block depressed @click="cart_type = card.id" outlined 
                                       style="border:1px solid var(--secondary) !important" class="mx-2"
                                       :class="cart_type == card.id ? 'blue12' : ''" color="secondary">
                                       <v-icon left color="secondary" v-if="cart_type == card.id">mdi-check-circle</v-icon>
                                       <v-icon left color="secondary" v-else></v-icon>
                                       <img :src="card.image" height="30" style="max-width: 55px;" :alt="card.name">
                                    </v-btn>
                                 </v-col>
                              </v-row>
                           </v-col> -->

                        </v-row>
                     </v-col>
                     <v-col cols="12" class="pb-0">
                        <v-row align="center">
                           <GenericInput type="checkbox" :value="form.invoice_discount" paddingY="my-3"
                              @input="form.invoice_discount = $event" label="discount"
                              :disabled="(form.proforma_invoice_id || data_from_delivery_note)" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="['auto', 'auto', 'auto']">
                           </GenericInput>
                           <v-fade-transition>
                              <GenericInput type="radiogroup" :labelInline="false" v-if="form.invoice_discount"
                                 :disabled="(form.proforma_invoice_id || data_from_delivery_note)"
                                 :lookups="[{ id: 1, name: $i18n.t('value') }, { id: 2, name: $i18n.t('percent') }]"
                                 :value="form.discount_type" @input="form.discount_type = $event" label="" :multi="false"
                                 :required="false" selected_label="name" selected_prop="id"
                                 :isLoading="pageData.editIsLoading" :cols="[12, 6, 'auto']"></GenericInput>
                           </v-fade-transition>
                           <v-expand-x-transition>
                              <GenericInput :type="form.discount_type == 2 ? 'percentage' : 'float'"
                                 :disabled="(form.proforma_invoice_id || data_from_delivery_note)" :value="form.discount"
                                 v-if="form.invoice_discount" @input="form.discount = $event" label="total discount"
                                 :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 'auto']">
                              </GenericInput>
                           </v-expand-x-transition>


                           <GenericInput v-for="dynamicInput in masterInputs" :key="dynamicInput.id"
                              :name="dynamicInput.id" :type="dynamicInput.type" :value="form[dynamicInput.key]"
                              @input="form[dynamicInput.key] = $event" :label="dynamicInput.label"
                              :lookups="dynamicLookups[dynamicInput.lookups_id]"
                              :required="dynamicInput.required == 0 ? false : true"
                              :disabled="dynamicInput.disabled == 0 ? false : true"
                              :multi="dynamicInput.multi == 0 ? false : true"
                              :cols="dynamicInput.cols.split(',').map(i => Number(i))" selected_label="name"
                              selected_prop="id" :isLoading="pageData.editIsLoading" />


                           <v-col cols="12" class="mi-start-auto" md="auto">
                              <v-fade-transition>
                                 <v-btn outlined style="background-color:  var(--blue12);"
                                    v-if="!data_from_delivery_note && !formCollapse && !proformaFromItems"
                                    class="d-block mi-start-auto" depressed color="blue2" :width="127"
                                    @click="detailDialog = true" :height="37">
                                    {{ $t('add line') }}
                                 </v-btn>
                              </v-fade-transition>
                           </v-col>
                        </v-row>
                     </v-col>



                  </v-row>
               </v-expand-transition>
            </v-card>
         </v-form>


         <!-- Detail Form -->
         <ItemDetail :detailAction="detailAction" :items="items" :warehouses="warehouses" :units="units" :taxes="taxes"
            :dialog="detailDialog" :detail="detail" :updateDetail="updateDetail" :widthHeight="widthHeight"
            :isDetailEdit="isDetailEdit" :tableRows="tableRows" />
         <!-- Detail of Form -->

         <!-- Details Table -->

         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="computedItems.new_array || []" :header="tableHeader"
               :tableHeight="$store.state.Settings.windowSize.y - 230"
               :footerData="[pageData.isEdit || proformaFromItems ? computedTotals : computedItems]">
               <template v-slot:td="{ row, header, index }">
                  <div v-if="header.key == 'actions'">
                     <v-btn @click="editDetail(index, row)" x-small icon fab>
                        <img src="@/assets/img/svg_icons/pen.svg" height="20" />
                     </v-btn>
                     <v-btn @click="removeItem(index, row)" x-small icon fab>
                        <img src="@/assets/img/svg_icons/delete.svg" height="20" />
                     </v-btn>
                  </div>
               </template>
            </DynamicTable>

         </div>
         <!-- End of Form -->
         <v-dialog v-model="proformaDialog" overlay-opacity="0.75" persistent :max-width="550">
            <v-card relative class="pa-7 py-10">
               <v-btn absolute class="mt-n10 mx-1" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
                  color="grey lighten-1" text @click="proformaDialog = false">
                  <v-icon color="grey lighten-1">mdi-close</v-icon>
               </v-btn>
               <v-row align="center" justify="center">
                  <GenericInput type="select" :lookups="customers" :value="form.customer_id"
                     @input="form.customer_id = $event" label="customer" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>
                  <GenericInput type="select" :disabled="!(form.customer_id)" :lookups="customer_proforma_invoices"
                     :value="form.proforma_invoice_id" @input="form.proforma_invoice_id = $event" label="proforma invoice"
                     :multi="false" :required="false" selected_label="item_details" selected_prop="id"
                     :isLoading="proformaIsLoading" :cols="[12, 12, 12]">
                  </GenericInput>
                  <v-col cols="auto" class="d-flex justify-center">
                     <v-btn class="mx-2" text depressed color="red4" @click="proformaDialog = false" :height="37"
                        :width="120">
                        {{ $t('cancel') }}
                     </v-btn>
                     <v-btn class="mx-2 white--text" :loading="proformaItemsLoading || proformaIsLoading"
                        @click="proformaDialog = false" depressed color="blue4" :disabled="!(form.customer_id)"
                        :height="37" :width="120">
                        {{ $t('choose') }}
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-dialog>
      </v-container>
   </section>
</template>



<script>
import ItemDetail from '../ItemDetail.vue';
export default {
   name: "InvoicesControl",
   data: () => ({
      pageData: {
         screen_code: "06-008",
         url: null,
         controlRoute: "/sales/invoice-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      formCollapse: false,
      proformaDialog: false,
      proformaIsLoading: false,
      proformaItemsLoading: false,
      proformaFromItems: false,
      validItem: false,
      widthHeight: false,
      // lockups
      sales: [],
      account_trees: [],
      cost_centers: [],
      sales_agents: [],
      taxes_percent: [],
      customers: [],
      items: [],
      units: [],
      sales_invoice_types: [],
      customer_proforma_invoices: [],
      taxes: [],
      warehouses: [],
      treasuries: [],
      masterInputs: [],
      dynamicLookups: Object,
      payments: [],
      networkMachines: [],
      card_types: [],
      isNetwork: null,
      cart_type: 4,
      machine_id: null,
      form: {
         customer_id: null,
         proforma_invoice_id: null,
         purchase_order_id: null,
         link_cost_center: null,
         cost_center_id: null,
         sales_invoice_type_id: null,
         sales_invoice_date: null,
         sales_invoice_description: null,
         sales_agent_id: null,
         supply_order: null,
         invoice_discount: null,
         discount_type: null,
         discount: null,
         treasury_id: null,
         items: [],
         payments: [],
      },
      viewForm: false,
      data_from_delivery_note: false,
      summery: [],
      // detail
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      tableHeader: [],
      tableRows: [],
      tableOption: {},

      detail: {
         item_id: null,
         unit_id: null,
         item_width_height: null,
         price: 0,
         price_include_vat: 0,
         quantity: 1,
         tax_percent: null,
         tax_percent_id: null,
         warehouse_id: null,
         description: null,
         area_width: 0,
         area_height: 0,
         area_quantity: 0,
      }
   }),
   components: {
      ItemDetail
   },
   computed: {
      saveIsValid() {
         const total_paid_amount = this.$global.ARRAY_REDUCE(this.payments, 'amount')
         if (this.form.sales_invoice_type_id == 1) {
            return (this.valid && this.tableRows.length && this.form.customer_id)
         }
         else if (this.form.sales_invoice_type_id !== 1) {
            return (this.valid && this.tableRows.length && this.form.customer_id && total_paid_amount == this.computedItems.total_price_after_discount_with_vat)
         } else {
            return false
         }
      },
      computedDiscount() {
         return {
            type: this.form.discount_type || 1,
            value: this.form.discount || 0,
            discount_amount: 0,
            percent_value: 0,
            total_price: 0,
         }
      },
      computedTotals() {
         const quantity = this.$global.ARRAY_REDUCE(this.tableRows, 'quantity');
         const price = this.$global.ARRAY_REDUCE(this.tableRows, 'price');
         const total_discount = this.$global.ARRAY_REDUCE(this.tableRows, 'total_discount');
         const total_without_vat = this.$global.ARRAY_REDUCE(this.tableRows, 'total_without_vat');
         const total_vat = this.$global.ARRAY_REDUCE(this.tableRows, 'total_vat');
         const total_with_vat = this.$global.ARRAY_REDUCE(this.tableRows, 'total_with_vat');
         return {
            item_name: this.$i18n.t("total"),
            quantity: quantity,
            price: price || 0,
            total_discount: total_discount || 0,
            total_without_vat: total_without_vat || 0,
            total_vat: total_vat || 0,
            total_with_vat: total_with_vat || total_with_vat,
         }
      },
      computedItems() {
         if (this.tableRows.length > 0) {
            return this.pageData.isEdit || this.proformaFromItems ? { new_array: this.tableRows } : this.$global.PRICE_CALCULATION(this.tableRows, this.computedDiscount)
         } else {
            return Object
         }
      }
   },
   watch: {
      data_from_delivery_note() {
         if (this.data_from_delivery_note == true) {
            this.tableHeader.pop()
         }
      },
      'form.sales_invoice_type_id'() {
         this.form.treasury_id = null;
         if ((this.form.sales_invoice_type_id == 2 || this.form.sales_invoice_type_id == 4) && !this.pageData.isEdit) {
            this.form.treasury_id = this.$store.state.userData.default_treasury || this.treasuries.length ? this.treasuries.at(0).id : null;
         }
         if (this.form.sales_invoice_type_id === 3 || this.form.sales_invoice_type_id === 4) {
            this.isNetwork = true;
         } else {
            this.isNetwork = false;
         }
         this.setPayments()
      },
      "form.invoice_discount"() {
         if (!this.pageData.isEdit) {
            if (!this.proformaFromItems && !this.data_from_delivery_note) {
               if (this.form.invoice_discount) {
                  this.form.discount_type = 1;
               } else {
                  this.form.discount_type = null;
               }
            }

         }
      },
      "form.discount_type"() {
         if (!this.pageData.isEdit) {
            if (!this.proformaFromItems && !this.data_from_delivery_note) {
               this.form.discount = null;
            }
         }
      },
      "form.customer_id"() {
         this.form.sales_agent_id = this.customers.length > 0 ? this.$global.FilterArrayOfObjectElement(this.customers, 'id', this.form.customer_id, 'sales_agent_id') : null;
         if (this.pageData.isEdit == false) {
            this.form.customer_name = this.$global.FilterArrayOfObjectElement(this.customers, 'id', this.form.customer_id, 'name');
            this.tableRows = [];
            if (this.data_from_delivery_note == false) {
               this.getCustomerProforma();
            }
         }
      },
      "form.proforma_invoice_id"() {
         if (this.form.proforma_invoice_id && this.pageData.isEdit == false) {
            this.tableRows = [];
            this.form.purchase_order_id = null;
            if (this.data_from_delivery_note == false) {
               this.form.purchase_order_id = this.$global.FilterArrayOfObjectElement(this.customer_proforma_invoices, 'id', this.form.proforma_invoice_id, 'purchase_order_id')
               this.getProformaInvoice();
            }
            this.tableHeader.pop()
         }
      },
      tableRows() {
         if (!this.pageData.isEdit && !this.proformaFromItems) {
            this.tableRows.forEach(row => {
               row.item_title = this.$global.FilterArrayOfObjectElement(this.items, 'id', row.item_id, 'name');
               row.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', row.unit_id, 'name');
               row.warehouse_name = this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', row.warehouse_id, 'name');
               row.tax_percent = this.$global.FilterArrayOfObjectElement(this.taxes, 'id', row.tax_percent_id, 'tax_percentage');
            });
         }
      },
      'computedItems.total_price_after_discount_with_vat'() {
         if (this.computedItems.total_price_after_discount_with_vat) {
            this.setPayments();
         }
      }
   },
   mounted() {
      this.pageMainData();
      this.form.sales_invoice_date = this.$global.GetCurrentDate()
      this.getData();
      this.card_types = [
         {
            id: 4,
            name: this.$i18n.t('khaleg network'),
            image: require('@/assets/img/svg/khaleg_network.png'),
         },
         {
            id: 3,
            name: this.$i18n.t('mada'),
            image: require('@/assets/img/svg/mada.svg'),
         },
         {
            id: 2,
            name: this.$i18n.t('master card'),
            image: require('@/assets/img/svg/mastercard.svg'),
         },
         {
            id: 1,
            name: this.$i18n.t('visa'),
            image: require('@/assets/img/svg/visa.svg'),
         },
      ]
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
         this.tableHeader = [
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "quantity", key: "quantity", type: 'float', classes: "" },
            { text: "price", key: "price_without_vat", type: 'float', classes: "" },
            { text: "discount", key: "total_discount", type: 'float', classes: "" },
            { text: "amount without vat", key: "total_price_without_vat", type: 'float', classes: "" },
            { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
            { text: "vat amount", key: "total_vat_after_discount", type: 'float', classes: "" },
            { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
            { text: "actions", key: "actions", type: 'slot', classes: "" },
         ];
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`sales_invoice/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     setTimeout(() => {
                        this.form = response.data.master;
                        this.payments = response.data.payments || [];
                     }, 1);
                     this.tableRows = response.data.details;
                     this.items = response.data.items || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes = response.data.taxes || [];
                     this.account_trees = response.data.account_tree || [];
                     this.customers = response.data.customers || [];
                     this.cost_centers = response.data.cost_centers || [];
                     this.warehouses = response.data.warehouses || [];
                     this.sales_invoice_types = response.data.sales_invoice_types || [];
                     this.treasuries = response.data.treasurys || [];
                     this.summery = [
                        {
                           label: 'proforma invoice',
                           value: response.data.master.proforma_invoice_id,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'date',
                           value: response.data.master.sales_invoice_date,
                           type: 'date', width: 120,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'description',
                           value: response.data.master.sales_invoice_description,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'customer',
                           value: response.data.master.customer_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'sales agent',
                           value: response.data.master.sales_agent_name,
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'total',
                           value: response.data.master.total_with_vat,
                           type: 'price',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'discount',
                           value: response.data.master.discount,
                           class: '',
                           type: 'float',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                     ]
                     this.widthHeight = response.data.width_height || false;
                     this.viewSummery = true;
                     this.masterInputs = response.data.master_inputs || []
                     this.dynamicLookups = response.data.dynamic_lookups || Object
                     this.tableHeader = [
                        { text: "item", key: "item_name", type: 'text', classes: "" },
                        { text: "unit", key: "unit_name", type: 'text', classes: "" },
                        { text: "quantity", key: "quantity", type: 'float', classes: "" },
                        { text: "price", key: "price", type: 'float', classes: "" },
                        { text: "discount", key: "total_discount", type: 'float', classes: "" },
                        { text: "amount without vat", key: "total_without_vat", type: 'float', classes: "" },
                        { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
                        { text: "vat amount", key: "total_vat", type: 'float', classes: "" },
                        { text: "final total", key: "total_with_vat", type: 'float', classes: "" },
                        { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
                     ];


                     if (response.data.width_height) {
                        this.tableHeader.splice(2, 0,
                           { text: "width", key: "area_width", type: 'float', classes: "" },
                           { text: "height", key: "area_height", type: 'float', classes: "" },
                           { text: "count", key: "area_quantity", type: 'float', classes: "" },
                           { text: "description", key: "description", type: 'text', classes: "" },
                        )
                     }
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`sales_invoice/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.items = response.data.items || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes = response.data.taxes || [];
                     this.account_trees = response.data.account_tree || [];
                     this.customers = response.data.customers || [];
                     this.sales_invoice_types = response.data.sales_invoice_types || [];
                     this.form.sales_invoice_type_id = response.data.sales_invoice_types[0].id || null;
                     this.cost_centers = response.data.cost_centers || [];
                     this.warehouses = response.data.warehouses || [];
                     this.widthHeight = response.data.width_height || false;
                     this.treasuries = response.data.treasurys || [];
                     this.networkMachines = response.data.network_machines || [];
                     this.machine_id = response.data.network_machines.length ? response.data.network_machines[0].id : null;
                     Object.keys(response.data.defaults || Object).forEach(key => {
                        this.form[key] = response.data.defaults[key]
                     })
                     if (response.data.master_inputs) {
                        response.data.master_inputs.forEach((input) => {
                           this.form[input.key] = input.value;
                        })
                        this.masterInputs = response.data.master_inputs || []
                        this.dynamicLookups = response.data.dynamic_lookups || Object
                        this.form = { ...this.form }
                     }
                     if (response.data.width_height) {
                        this.tableHeader.splice(2, 0,
                           { text: "width", key: "area_width", type: 'float', classes: "" },
                           { text: "height", key: "area_height", type: 'float', classes: "" },
                           { text: "count", key: "area_quantity", type: 'float', classes: "" },
                           { text: "description", key: "description", type: 'text', classes: "" },
                        )
                     }
                     if (this.$route.params.data) {
                        const IDS = this.$route.params.data.map(object => object.m_id);
                        this.form.delivery_notes = IDS;
                        this.form.customer_id = this.$route.params.data[0].customer_id;
                        this.data_from_delivery_note = true;
                        let requestBody = {
                           delivery_notes: IDS
                        }
                        this.pageData.isLoading = true;
                        this.$api.POST_METHOD(`get_delivery_note_data`, requestBody).then((response) => {
                           this.pageData.isLoading = false;
                           if (response.check) {
                              this.tableRows = response.data.items;
                              this.form.invoice_discount = response.data.master ? response.data.master.invoice_discount : null;
                              setTimeout(() => {
                                 this.form.discount_type = response.data.master ? response.data.master.discount_type : null;
                                 this.form.discount = response.data.master ? response.data.master.discount : null;
                                 this.form.sales_agent_id = response.data.master ? response.data.master.sales_agent_id : null;
                                 this.form.customer_id = response.data.master ? response.data.master.customer_id : null;
                              }, 1);
                           }
                        })
                     }
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
      },
      save() {
         this.form.items = this.tableRows;
         this.form.payments = this.payments;
         this.payments.forEach(payment => {
            payment.machine_id = payment.payment_type == 2 ? this.machine_id : 0;
            payment.cart_type = payment.payment_type == 2 ? this.cart_type : 0;
            payment.treasury_id = payment.payment_type == 1 ? this.form.treasury_id : 0;
         });
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            this.form.items.forEach(detail => {
               detail.type = this.form.delivery_notes ? 'DN' : this.form.proforma_invoice_id ? "PI" : "";
            });
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`sales_invoice/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`sales_invoice`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_sales_invoice/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      detailAction(detail, isEdit, isFinish, cancel) {
         console.log(detail, isEdit, isFinish, cancel);
         if (cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.resetDetail();
         }
         if (isEdit && !cancel) {
            this.updateDetail(detail);
         }
         if (!isEdit && !cancel) {
            this.tableRows.push(detail);
            if (isFinish) {
               this.detailDialog = false;
               this.isDetailEdit = false;
               this.resetDetail();
            }
         }
      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.resetDetail();
      },
      resetDetail() {
         this.detail = {
            item_id: null,
            unit_id: null,
            item_width_height: null,
            price: 0,
            price_include_vat: 0,
            quantity: 1,
            tax_percent: null,
            tax_percent_id: null,
            warehouse_id: null,
            area_width: 0,
            area_height: 0,
            area_quantity: 0,
            description: null,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
      getCustomerProforma() {
         this.proformaIsLoading = true;
         this.form.proforma_invoice_id = null;
         this.$api.POST_METHOD(`get_customer_proforma_completed`, { customer_id: this.form.customer_id }).then((response) => {
            this.proformaIsLoading = false;
            if (response.check) {
               response.data.items.forEach(item => {
                  item.item_details = `${item.id} - ${item.purchase_order_id} - ${item.proforma_invoice_date}`
               });
               this.customer_proforma_invoices = response.data.items;
            }
         })
      },
      getProformaInvoice() {
         this.proformaItemsLoading = true
         this.$api.POST_METHOD(`get_delivery_note_data`, { proforma_invoice_id: this.form.proforma_invoice_id }).then((response) => {
            this.proformaItemsLoading = false;
            this.proformaFromItems = true;
            this.proformaDialog = false;
            this.form.invoice_discount = response.data.master ? response.data.master.invoice_discount : null;
            this.tableHeader = [
               { text: "item", key: "item_name", type: 'text', classes: "" },
               { text: "unit", key: "unit_name", type: 'text', classes: "" },
               { text: "quantity", key: "quantity", type: 'float', classes: "" },
               { text: "price", key: "price", type: 'float', classes: "" },
               { text: "discount", key: "total_discount", type: 'float', classes: "" },
               { text: "amount without vat", key: "total_without_vat", type: 'float', classes: "" },
               { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
               { text: "vat amount", key: "total_vat", type: 'float', classes: "" },
               { text: "final total", key: "total_with_vat", type: 'float', classes: "" },
               { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
            ];
            setTimeout(() => {
               this.form.discount_type = response.data.master ? response.data.master.discount_type : null
               this.form.discount = response.data.master ? response.data.master.discount : null
               this.form.sales_agent_id = response.data.master ? response.data.master.sales_agent_id : null
            }, 1);
            if (response.check) {
               this.tableRows = response.data.items
            }
         })
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`sales_invoice/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      setPayments() {
         switch (this.form.sales_invoice_type_id) {
            case 2:
               this.payments = [
                  {
                     payment_type: 1,
                     cart_type: this.cart_type,
                     machine_id: this.machine_id,
                     payment_text: 'cash',
                     treasury_id: this.form.treasury_id,
                     amount: this.computedItems.total_price_after_discount_with_vat || 0
                  }
               ]
               break;
            case 3:
               this.payments = [
                  {
                     payment_type: 2,
                     cart_type: this.cart_type,
                     machine_id: this.machine_id,
                     payment_text: 'network',
                     treasury_id: this.form.treasury_id,
                     amount: this.computedItems.total_price_after_discount_with_vat || 0
                  }
               ]
               break;
            case 4:
               this.payments = [
                  {
                     payment_type: 1,
                     cart_type: this.cart_type,
                     machine_id: this.machine_id,
                     payment_text: 'cash',
                     treasury_id: this.form.treasury_id,
                     amount: this.computedItems.total_price_after_discount_with_vat / 2 || 0
                  },
                  {
                     payment_type: 2,
                     cart_type: this.cart_type,
                     machine_id: this.machine_id,
                     payment_text: 'network',
                     treasury_id: this.form.treasury_id,
                     amount: this.computedItems.total_price_after_discount_with_vat / 2 || 0
                  }
               ]
               break;
            default:
               this.payments = []
               break;
         }
      }
   },
};
</script>
