<template>
    <v-dialog v-model="dialog" persistent max-width="350">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded-md class="green10 mb-1" block v-bind="attrs" v-on="on" depressed>
          <!-- <img class="mx-1" height="18" src="@/assets/img/svg/machine.svg" alt="Wms-POS" /> -->
          <span class="d-md-block d-none" v-if="treasury.id">
            {{ $t('treasury') }} :   <span class="font-weight-bold">{{ treasury.name }}</span>
          </span>
         <span class="d-md-block d-none" v-else>{{ $t('select treasury') }}</span>
        </v-btn>
      </template>
      <v-card relative>
        <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="dialog = false">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h6"> {{$t('change treasury')}}</v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-5">
          <v-col cols="12" sm="12">
            <v-btn-toggle v-model="toggle_treasury" tile color="gray lighten-5" class="d-flex mb-2 justify-center" group>
              <v-row>
                <v-col cols="12" v-for="(item, index) in treasuries" :key="index">
                  <v-btn v-on:click="treasury = item" :value="item" class="rounded ma-1" color="gray lighten-5"
                    elevation="0" style="width: 100%; text-align: start">
                    <v-icon color="blue accent-4" :disabled="treasury != item" left>mdi-check-circle </v-icon>
                    {{ item.name }}
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="treasuries.length == 0">
                  <v-alert class="d-block ma-auto" dense outlined text type="info">{{ $t('no data available') }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-btn-toggle>
          </v-col>
          <!-- <v-col cols="12" class="py-4">
            <v-btn color="success" large @click="returnData" class="shadow" style="width: 100%; text-align: start">
              {{$t('select') }}</v-btn>
          </v-col> -->
        </v-form>
      </v-card>
    </v-dialog>
</template>



<style scoped lang="scss">

</style>



<script>
export default {
  name: "TreasuryModal",
  props: {
    backValueMethod: { type: Function },
    treasuries: { default: [] },
    treasury: { default: {} },
  },
  computed: {
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  data() {
    return {
      dialog: false,
      toggle_treasury: 1,
    };
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.altKey == true && e.code == "KeyT") {
        this.dialog = true;
      }
      if (e.code == 'Escape') {
        this.dialog = false;
      }
    });
  },
  methods: {
    returnData() {
      this.backValueMethod(this.machine);
      this.dialog = false
    },
  },
  watch: {
    "treasury.id"(){
      this.dialog = false;
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F8':
          this.dialog = !this.dialog
          break;
        case 'Escape':
          this.dialog = false
          break;
        default:
          break;
      }
    },
  },
};
</script>
