import SalesManEntity from "@/views/SettingsModule/SalesMan/SalesManEntity";
import SalesManControl from "@/views/SettingsModule/SalesMan/SalesManControl";
import BanksEntity from "@/views/SettingsModule/Banks/BanksEntity";
import BanksControl from "@/views/SettingsModule/Banks/BanksControl";
import NetworkMachineEntity from "@/views/SettingsModule/NetworkMachine/NetworkMachineEntity";
import NetworkMachineControl from "@/views/SettingsModule/NetworkMachine/NetworkMachineControl";
import BranchesEntity from "@/views/SettingsModule/Branches/BranchesEntity";
import BranchesControl from "@/views/SettingsModule/Branches/BranchesControl";
import MainMenuEntity from "@/views/SettingsModule/MainMenu/MainMenuEntity";
import MainMenuControl from "@/views/SettingsModule/MainMenu/MainMenuControl";
import CategoriesMenuEntity from "@/views/SettingsModule/CategoriesMenu/CategoriesMenuEntity";
import CategoriesMenuControl from "@/views/SettingsModule/CategoriesMenu/CategoriesMenuControl";
import SubMenusEntity from "@/views/SettingsModule/SubMenus/SubMenusEntity";
import SubMenusControl from "@/views/SettingsModule/SubMenus/SubMenusControl";
import FinancialYearsEntity from "@/views/SettingsModule/FinancialYears/FinancialYearsEntity";
import FinancialYearsControl from "@/views/SettingsModule/FinancialYears/FinancialYearsControl";
import PartnersEntity from "@/views/SettingsModule/Partners/PartnersEntity";
import PartnersControl from "@/views/SettingsModule/Partners/PartnersControl";
import UserEntity from "@/views/SettingsModule/UserEntity/UserEntity";
import UserControl from "@/views/SettingsModule/UserEntity/UserControl";
import PermissionsEntity from "@/views/SettingsModule/PermissionsEntity/PermissionsEntity";
import PermissionsControl from "@/views/SettingsModule/PermissionsEntity/PermissionsControl";
import CloseTodayEntity from "@/views/SettingsModule/CloseToday/CloseTodayEntity";
import DailyTypesEntity from "@/views/SettingsModule/DailyTypes/DailyTypesEntity";
import DailyTypesControl from "@/views/SettingsModule/DailyTypes/DailyTypesControl";
import DelegatesEntity from "@/views/SettingsModule/Delegates/DelegatesEntity";
import DelegatesControl from "@/views/SettingsModule/Delegates/DelegatesControl";
import GeneralSettings from "@/views/SettingsModule/GeneralSettings/GeneralSettings";
import JobTitleControlVue from "../views/SettingsModule/JobTitle/JobTitleControl.vue";
import JobTitleEntityVue from "../views/SettingsModule/JobTitle/JobTitleEntity.vue";

// Setting Module Routes:-

export const SettingsModuleChildrens = [
  // Sales Man
  {
    path: "/main/sales_agent",
    name: "SalesManEntity",
    component: SalesManEntity,
    meta: {
      screen_code: "01-013",
    },
  },
  {
    path: "/main/sales_agent-control",
    name: "SalesManControl",
    component: SalesManControl,
    meta: {
      screen_code: "01-013",
    },
  },
  {
    path: "/main/sales_agent-control/:id",
    name: "SalesManControlEdit",
    component: SalesManControl,
    meta: {
      screen_code: "01-013",
    },
  },
  // Banks
  {
    path: "/main/bank",
    name: "BanksEntity",
    component: BanksEntity,
    meta: {
      screen_code: "01-010",
    },
  },
  {
    path: "/main/bank-control",
    name: "BanksControl",
    component: BanksControl,
    meta: {
      screen_code: "01-010",
    },
  },
  {
    path: "/main/bank-control/:id",
    name: "BanksControlEdit",
    component: BanksControl,
    meta: {
      screen_code: "01-010",
    },
  },
  // Network Machine
  {
    path: "/main/network_machine",
    name: "NetworkMachineEntity",
    component: NetworkMachineEntity,
    meta: {
      screen_code: "01-009",
    },
  },
  {
    path: "/main/network_machine-control",
    name: "NetworkMachineControlVue",
    component: NetworkMachineControl,
    meta: {
      screen_code: "01-009",
    },
  },
  {
    path: "/main/network_machine-control/:id",
    name: "NetworkMachineControlEdit",
    component: NetworkMachineControl,
    meta: {
      screen_code: "01-009",
    },
  },
  // Branches
  {
    path: "/main/branch",
    name: "BranchesEntityVue",
    component: BranchesEntity,
    meta: {
      screen_code: "01-006",
    },
  },
  {
    path: "/main/branch-control",
    name: "BranchesControlVue",
    component: BranchesControl,
    meta: {
      screen_code: "01-006",
    },
  },
  {
    path: "/main/branch-control/:id",
    name: "BranchesControlVueEdit",
    component: BranchesControl,
    meta: {
      screen_code: "01-006",
    },
  },
  // Main Menu
  {
    path: "/main/screen_main",
    name: "MainMenuEntity",
    component: MainMenuEntity,
    meta: {
      screen_code: "01-001",
    },
  },
  {
    path: "/main/screen_main-control",
    name: "MainMenuControl",
    component: MainMenuControl,
    meta: {
      screen_code: "01-001",
    },
  },
  {
    path: "/main/screen_main-control/:id",
    name: "MainMenuControlEdit",
    component: MainMenuControl,
    meta: {
      screen_code: "01-001",
    },
  },
  // Categories Menu
  {
    path: "/main/screen_cat",
    name: "CategoriesMenuEntity",
    component: CategoriesMenuEntity,
    meta: {
      screen_code: "01-002",
    },
  },
  {
    path: "/main/screen_cat-control",
    name: "CategoriesMenuControl",
    component: CategoriesMenuControl,
    meta: {
      screen_code: "01-002",
    },
  },
  {
    path: "/main/screen_cat-control/:id",
    name: "CategoriesMenuControlVueEdit",
    component: CategoriesMenuControl,
    meta: {
      screen_code: "01-002",
    },
  },
  // Submenu
  {
    path: "/main/screen_sub",
    name: "SubMenusEntity",
    component: SubMenusEntity,
    meta: {
      screen_code: "01-003",
    },
  },
  {
    path: "/main/screen_sub-control",
    name: "SubMenusControl",
    component: SubMenusControl,
    meta: {
      screen_code: "01-003",
    },
  },
  {
    path: "/main/screen_sub-control/:id",
    name: "SubMenusControlEdit",
    component: SubMenusControl,
    meta: {
      screen_code: "01-003",
    },
  },
  // Financial years
  {
    path: "/main/finance_year",
    name: "FinancialYearsEntity",
    component: FinancialYearsEntity,
    meta: {
      screen_code: "01-004",
    },
  },
  {
    path: "/main/finance_year-control",
    name: "FinancialYearsControl",
    component: FinancialYearsControl,
    meta: {
      screen_code: "01-004",
    },
  },
  {
    path: "/main/finance_year-control/:id",
    name: "FinancialYearsControlEdit",
    component: FinancialYearsControl,
    meta: {
      screen_code: "01-004",
    },
  },
  // Partners
  {
    path: "/main/partner",
    name: "PartnersEntityVue",
    component: PartnersEntity,
    meta: {
      screen_code: "01-012",
    },
  },
  {
    path: "/main/partner-control",
    name: "PartnersControl",
    component: PartnersControl,
    meta: {
      screen_code: "01-012",
    },
  },
  {
    path: "/main/partner-control/:id",
    name: "PartnersControlVueEdit",
    component: PartnersControl,
    meta: {
      screen_code: "01-012",
    },
  },
  // Users
  {
    path: "/main/user",
    name: "UserEntityVue",
    component: UserEntity,
    meta: {
      screen_code: "01-008",
    },
  },
  {
    path: "/main/user-control",
    name: "UserControl",
    component: UserControl,
    meta: {
      screen_code: "01-008",
    },
  },
  {
    path: "/main/user-control/:id",
    name: "UserControlEdit",
    component: UserControl,
    meta: {
      screen_code: "01-008",
    },
  },
  // permissions
  {
    path: "/main/user_permission",
    name: "PermissionsEntity",
    component: PermissionsEntity,
    meta: {
      screen_code: "01-014",
    },
  },
  {
    path: "/main/permissions-control/:id",
    name: "PermissionsControl",
    component: PermissionsControl,
    meta: {
      screen_code: "01-014",
    },
  },
  // Close Today
  {
    path: "/main/close_day",
    name: "CloseTodayEntityVue",
    component: CloseTodayEntity,
    meta: {
      screen_code: null,
    },
  },
  // Daily types
  {
    path: "/main/daily_type",
    name: "DailyTypesEntity",
    component: DailyTypesEntity,
    meta: {
      screen_code: "01-005",
    },
  },
  {
    path: "/main/daily_type-control",
    name: "DailyTypesControlADD",
    component: DailyTypesControl,
    meta: {
      screen_code: "01-005",
    },
  },
  {
    path: "/main/daily_type-control/:id",
    name: "DailyTypesControlEdit",
    component:DailyTypesControl,
    meta: {
      screen_code: "01-005",
    },
  },
  // Delegates
  {
    path: "/main/delegate",
    name: "DelegatesEntity",
    component: DelegatesEntity,
    meta: {
      screen_code: "01-015",
    },
  },
  {
    path: "/main/delegate-control",
    name: "DelegatesControlADD",
    component: DelegatesControl,
    meta: {
      screen_code: "01-015",
    },
  },
  {
    path: "/main/delegate-control/:id",
    name: "DelegatesControlEdit",
    component: DelegatesControl,
    meta: {
      screen_code: "01-015",
    },
  },
  // Job Title
  {
    path: "/main/title",
    name: "JobTitleEntity",
    component: JobTitleEntityVue,
    meta: {
      screen_code: "01-016",
    },
  },
  {
    path: "/main/title-control",
    name: "JobTitleControlADD",
    component: JobTitleControlVue,
    meta: {
      screen_code: "01-016",
    },
  },
  {
    path: "/main/title-control/:id",
    name: "JobTitleControlEdit",
    component: JobTitleControlVue,
    meta: {
      screen_code: "01-016",
    },
  },

  // General Settings
  {
    path: "/main/settings/:id",
    name: "GeneralSettingsVue",
    component: GeneralSettings,
    meta: {
      screen_code: "01-007",
    },
  },
];
