<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="outgoing_data" :save="save"
            :valid="this.tableRows.length > 0 && this.form" :hideDelete="true" :createBtn="save" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-sheet class="pa-3 transparent">
            <v-card class="shadow pa-5 pt-7 rounded-xl" color="white">
               <v-row>

                  <v-col cols="auto">
                     <div>
                        <div class="subtitle-1 gray6--text font-weight-normal mx-2">{{ $t("client") | capitalize }} :
                           {{ outgoing_data.customer_name || '' }}
                        </div>
                        <div class="subtitle-1 gray6--text font-weight-normal mx-2">{{ $t("outgoing no") | capitalize
                        }}: {{ outgoing_data.m_id || '' }}</div>
                     </div>
                     <v-col cols="auto" class="d-flex align-center">
                        <v-text-field v-model="pageData.search" @keydown.enter="(e) => getSearch(e.target.value)"
                           @click:append="getSearch(pageData.search)" append-icon="mdi-magnify"
                           :placeholder="$i18n.t('Search')" background-color="light" rounded solo flat single-line
                           clearable hide-details dense />
                        <!-- <v-btn color="accent" class="mx-2 gray11" height="40" :disabled="!(pageData.search)" @click="getSearch(pageData.search)" text>
                           {{ $t('search') }}</v-btn> -->
                     </v-col>
                  </v-col>
                  <v-col cols="auto" class="pa-0">
                     <v-divider vertical style="height: 2rem"></v-divider>
                  </v-col>
                  <v-col cols="auto">
                     <v-card color="blue12" width="250" elevation="0" class="pa-7 ">
                        <div class="text-center subtitle-1 font-weight-regular blue1--text">
                           {{ $t('quantity') | capitalize }}
                        </div>
                        <div class="text-center font-weight-bold text-h5 blue1--text">
                           {{ outgoing_data.requested_quantity || 0 }}
                        </div>
                     </v-card>
                  </v-col>
                  <v-col cols="auto">
                     <v-card color="green12" width="250" elevation="0" class="pa-7 ">
                        <div class="text-center subtitle-1 font-weight-regular green1--text">
                           {{ $t('quantity requested') | capitalize }}
                        </div>
                        <div class="text-center font-weight-bold text-h5 green1--text">
                           {{ outgoing_data.total_cases || 0 }}
                        </div>
                     </v-card>
                  </v-col>
                  <v-col cols="auto">
                     <v-card color="yellow12" width="250" elevation="0" class="pa-7">
                        <div class="text-center subtitle-1 font-weight-regular yellow1--text">
                           {{ $t('quantity remaining') | capitalize }}
                        </div>
                        <div class="text-center font-weight-bold text-h5 yellow1--text">
                           {{ outgoing_data.total_pieces || 0 }}
                        </div>
                     </v-card>
                  </v-col>

               </v-row>
            </v-card>

            <v-row align="center" class="my-5">
               <v-col cols="12">
                  <DynamicTable :isLoading="palletsLoading" :pageData="pageData"
                     :cardClasses="tableRows.length > 0 ? 'white' : 'transparent'" btnIcon="mdi-content-copy"
                     :data="tableRows" :header="tableHeader" :option="tableOption" :editValueMethod="edit"
                     :deleteValueMethod="removeItem">
                     <template v-slot:td="{ row, header }">
                        <span>
                           <GenericInput type="select" v-if="header.key == 'type_id'" :lookups="types"
                              :value="row[header.key]" @input="row[header.key] = $event" label="type" :multi="false"
                              :required="true" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]" />
                        </span>
                     </template>
                  </DynamicTable>
               </v-col>
            </v-row>
         </v-sheet>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "OutgoingRequestControl",
   data: () => ({
      pageData: {
         screen_code: "08-003",
         url: null,
         controlRoute: "/warehouses/outgoing-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         isTrashed: 1
      },
      valid: false,
      actionBtnValid: true,
      palletsLoading: false,
      printPalletDialog: false,
      tableRows: [],
      clients: [],
      incoming_shipment_pallets: [],
      incoming_shipment_photos: [],
      tableOption: null,
      tableHeader: [],
      activeStep: 1,
      outgoing_requests: [],
      types: [],
      form: null,
      unit_measurements: null,
      outgoing_data: {
         id: null,
         customer_id: null,
         customer_name: null,
         driver_name: null,
         driver_id: null,
         driver_phone_code: null,
         driver_phone_number: null,
         car_numbers: null,
         car_digits: null,
         incoming_shipment_date: null,
         incoming_shipment_time: null,
         has_container_seals: null,
         locker_number: null,
         damage_case: null,
         incoming_shipment_status: null,
         incoming_shipment_active: null,
         add_date: null,
         add_user: null,
         total_pallets: null,
         total_cases: null,
         total_pieces: null
      },
   }),
   components: {
      ControlHeader,
      DynamicTable,
      GenericInput
   },
   computed: {
   },
   watch: {
      tableRows() {
         this.tableRows.forEach(pallet => {
            pallet.quantity_pieces = pallet.number_of_cases * pallet.quantity_in_case
         });
      }
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$i18n.t('outgoing details');
         this.pageData.isTrashed = 1;
         this.tableHeader = [
            { text: "pallet no", key: "pallet_id", type: 'text', classes: "" },
            { text: "item", key: "customer_item_name", type: 'text', classes: "" },
            { text: "batch no", key: "batch_number", type: 'text', classes: "" },
            { text: "expiry date", key: "expiry_date", type: 'text', classes: "" },
            { text: "cases", key: "number_of_cases", type: 'text', classes: "" },
            { text: "quantity", key: "quantity_in_case", type: 'text', classes: "" },
            { text: "pieces", key: "quantity_pieces", type: 'text', classes: "" },
            { text: "unit", key: "type_id", type: 'slot', classes: "" },
            { text: "delete", key: "id", type: 'delete', classes: "" },
         ];

         this.tableOption = {
            print: false,
            delete: true,
            edit: true,
            restore: false,
            view: false,
         };
      },
      getData() {
         if (this.$route.params.id && this.$route.params.data) {
            this.form = this.$route.params.data || null;
            this.types = this.$route.params.types || [];
            this.tableRows = this.$route.params.outgoing_pallets || [];
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`outgoing_request/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.outgoing_data = response.data.master;
                     // this.tableRows = response.data.details;
                     this.unit_measurements = response.data.unit_measurements;
                  }
               })
            } else {
               this.$router.push(`/warehouses/outgoing_request-control/${this.$route.params.id}`)
            }
         } else {
            this.$router.push(`/warehouses/outgoing_request-control/${this.$route.params.id}`)
         }
      },
      save() {
         this.pageData.isLoading = true;
         this.form.pallets = this.tableRows;
         this.$api.POST_METHOD(`make_outgoing/${this.$route.params.id}`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
               this.$router.push(this.pageData.url)
            }
         })
      },
      actionBtnClick() {
         // this.$router.push(`/warehouses/incoming/`)
         this.pageData.isLoading = true;
         this.$api.POST_METHOD(`close_incoming/${this.outgoing_data.incoming_id}`, this.outgoing_data).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
               this.$router.push(this.pageData.url)
            }
         })
         // this.activeStep = 2;
         // this.actionBtnValid = false
      },


      edit(row) {
         this.$router.push(`/warehouses/pallet-control/${row.id}`)
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },

      getSearch(search) {
         this.pageData.search = search
         this.$api.GET_METHOD(`search_pallet/${this.pageData.search}`).then((response) => {
            this.pageData.editIsLoading = false;
            this.pageData.isLoading = false;
            if (response.check) {
               if (response.data.item) {
                  if (response.data.item.customer_id == this.outgoing_data.customer_id ) {
                     response.data.item.type_id = 1
                     this.tableRows.push(response.data.item);
                     this.pageData.search = null
                  }else{
                       this.$api.ShowAlert('info', this.$i18n.t('this pallet not for this client'), '')
                  }
               } else {
                  this.$api.ShowAlert('info', this.$i18n.t('no pallet found'), '')
               }
            }
         })
      }

   },
};
</script>
