<template>
   <div>

      <summary v-if="isParent" @contextmenu="(e) => show(e, item)" class="d-flex  align-center justify-space-between">
         <div @click="fetchTree(item, 'parent'); item.children = []" class="cursor_pointer">
            <v-icon class="mx-2 mdi-spin" v-if="isLoading && isLoadingIndex == 'parent'">mdi-loading</v-icon>
            <v-icon class="mx-2"
               v-if="isLoadingIndex !== 'parent' && item.isOpen == true && item.hasChild == 1 && $vuetify.rtl == true">
               mdi-chevron-left</v-icon>
            <v-icon class="mx-2"
               v-if="isLoadingIndex !== 'parent' && item.isOpen == true && item.hasChild == 1 && $vuetify.rtl == false">
               mdi-chevron-right</v-icon>
            <v-icon class="mx-2" v-if="isLoadingIndex !== 'parent' && !item.isOpen && item.hasChild == 1">
               mdi-chevron-down
            </v-icon>
            <v-icon color="gray5" v-if="item.hasChild == 1">{{ item.isOpen == true ? 'mdi-folder-open' : 'mdi-folder'
            }}
            </v-icon>
            <v-icon color="warning" :class="$vuetify.rtl == true ? 'mr-10' : 'ml-10'" v-else>mdi-file-document
            </v-icon>
            {{ item.account_code }} - {{ item.name }}
         </div>

         <!-- control -->
         <div class="actionsTreeControl">
            <v-tooltip bottom color="success">
               <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="add(item)" text v-bind="attrs" v-on="on" :outlined="
                     selectedItem.id === item.id && selectionType === 'add'
                  " elevation="0" class="text-capitalize" fab small color="success" v-if="item.hasChild == 1">
                     <v-icon class="mx-1" size="20">mdi-plus-box</v-icon>
                  </v-btn>
               </template>
               <span>{{ $t("Add New") }}</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
               <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="edit(item)" text v-bind="attrs" v-on="on" :outlined="
                     selectedItem.id === item.id && selectionType === 'edit'
                  " elevation="0" class="text-capitalize" fab small color="primary">
                     <v-icon class="mx-1" size="20">mdi-pencil-box</v-icon>
                  </v-btn>
               </template>
               <span>{{ $t("Edit") }}</span>
            </v-tooltip>
            <v-tooltip bottom color="error">
               <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="deleteItem(item)" text v-bind="attrs" v-on="on" :outlined="
                     selectedItem.id === item.id && selectionType === 'delete'
                  " elevation="0" class="text-capitalize" fab small color="error">
                     <v-icon class="mx-1" size="20">mdi-trash-can</v-icon>
                  </v-btn>
               </template>
               <span>{{ $t("Delete") }}</span>
            </v-tooltip>
         </div>

      </summary>

      <details class="mx-2" v-for="(child, index) in item.children" :key="index"
         :class="child.children ? 'mt-2 detailsHasChild' : 'transparent'">
         <summary class="d-flex align-center justify-space-between" @contextmenu="(e) => show(e, child)">
            <div @click="fetchTree(child, index);" class="cursor_pointer">
               <v-icon class="mx-2 mdi-spin" v-if="isLoading && isLoadingIndex == index">mdi-loading</v-icon>
               <v-icon class="mx-2"
                  v-if="isLoadingIndex !== index && child.isOpen == true && child.hasChild == 1 && $vuetify.rtl == true">
                  mdi-chevron-left</v-icon>
               <v-icon class="mx-2"
                  v-if="isLoadingIndex !== index && child.isOpen == true && child.hasChild == 1 && $vuetify.rtl == false">
                  mdi-chevron-right</v-icon>
               <v-icon class="mx-2" v-if="isLoadingIndex !== index && !child.isOpen && child.hasChild == 1">
                  mdi-chevron-down
               </v-icon>
               <v-icon color="gray5" v-if="child.hasChild == 1">{{ child.isOpen == true ? 'mdi-folder-open' :
                     'mdi-folder'
               }}
               </v-icon>
               <v-icon color="warning" :class="$vuetify.rtl == true ? 'mr-10' : 'ml-10'" v-else>mdi-file-document
               </v-icon>
               {{ child.account_code }} - {{ child.name }}
            </div>

            <!-- control -->
            <div class="actionsTreeControl">
               <v-tooltip bottom color="success">
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn @click="add(child, index)" text v-bind="attrs" v-on="on" :outlined="
                        selectedItem.id === child.id && selectionType === 'add'
                     " elevation="0" class="text-capitalize" fab small color="success" v-if="child.hasChild == 1">
                        <v-icon class="mx-1" size="20">mdi-plus-box</v-icon>
                     </v-btn>
                  </template>
                  <span>{{ $t("Add New") }}</span>
               </v-tooltip>
               <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn @click="edit(child, index)" text v-bind="attrs" v-on="on" :outlined="
                        selectedItem.id === child.id && selectionType === 'edit'
                     " elevation="0" class="text-capitalize" fab small color="primary">
                        <v-icon class="mx-1" size="20">mdi-pencil-box</v-icon>
                     </v-btn>
                  </template>
                  <span>{{ $t("Edit") }}</span>
               </v-tooltip>
               <v-tooltip bottom color="error">
                  <template v-slot:activator="{ on, attrs }">
                     <v-btn @click="deleteItem(child, index)" text v-bind="attrs" v-on="on" :outlined="
                        selectedItem.id === child.id && selectionType === 'delete'
                     " elevation="0" class="text-capitalize" fab small color="error">
                        <v-icon class="mx-1" size="20">mdi-trash-can</v-icon>
                     </v-btn>
                  </template>
                  <span>{{ $t("Delete") }}</span>
               </v-tooltip>
            </div>
         </summary>
         <CategoryTreeNode :item="child" :categorys="categorys"
            :contextMenuMethod="(item, position) => { contextMenuMethod(item, position) }"
            :activeControl="setActiveControl" />
      </details>


      <!-- Tree Control ////////////////////////////////////////////////////////////////////// -->


      <v-col cols="auto" xl="auto" lg="auto" style="position: fixed;top : 8rem;"
         :style="$vuetify.rtl == true ? 'left : 40px' : 'right : 40px'" class="d-flex text-center"
         v-if="selectedItem.id == activeControlID && selectionType && selectionType !== 'delete'" order="1"
         order-lg="2">
         <v-card relative :width="$store.state.Settings.windowSize.x > 1366 ? 550 : 480"
            :height="$store.state.Settings.windowSize.y - 200" v-if="selectedItem.id"
            class="light  rounded-xl overflow-hidden  overflow-y-auto overflow-x-hidden" elevation="0">
            <v-scroll-y-transition mode="out-in">
               <CategoryControl :key="selectedItem.id + selectionType" :selectionType="selectionType"
                  :backValueMethod="saveControl" :categorys="categorys" :item="selectedItem">
               </CategoryControl>
            </v-scroll-y-transition>
         </v-card>
      </v-col>



      <!-- Right Click Menu -->
      <v-menu v-model="showMenu" :position-x="x" :position-y="y" :key="contextMenuItem.id + x + y" absolute offset-y
         class>
         <v-list class="block contextTree py-3">
            <v-list-item dense>
               <v-list-item-title class="text-center pa-0">{{ contextMenuItem.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item dense v-if="contextMenuItem.hasChild == 1" class="pa-0">
               <v-list-item-title>
                  <v-btn @click="add(contextMenuItem)" text class="text-capitalize" block color="primary">
                     <v-icon class="mx-1" small>mdi-plus</v-icon>
                     {{ $t("Add New") }}
                  </v-btn>
               </v-list-item-title>
            </v-list-item>
            <v-list-item dense>
               <v-list-item-title>
                  <v-btn @click="edit(contextMenuItem)" text class="text-capitalize" block color="success">
                     <v-icon class="mx-1" small>mdi-pen</v-icon>
                     {{ $t("Edit") }}
                  </v-btn>
               </v-list-item-title>
            </v-list-item>
            <v-list-item dense>
               <v-list-item-title>
                  <v-btn @click="deleteItem(contextMenuItem)" text class="text-capitalize" block color="error">
                     <v-icon class="mx-1" small>mdi-trash-can-outline</v-icon>
                     {{ $t("Delete") }}
                  </v-btn>
               </v-list-item-title>
            </v-list-item>
         </v-list>
      </v-menu>
      <!-- End of Right Click Menu -->
      <DeleteConfirmation v-if="dialog" :dialog="dialog" :item="dialogDeleteTitle" :backValueMethod="deleteAction">
      </DeleteConfirmation>
   </div>
</template>


<script>
import CategoryControl from './CategoryControl.vue';
import DeleteConfirmation from "@/components/modals/DeleteConfirmation.vue";
export default {
   name: "CategoryTreeNode",
   data: () => ({
      isLoading: false,
      isLoadingIndex: null,
      selectedItem: {},
      selectionType: null,
      contextMenuItem: {},
      showMenu: false,
      dialog: false,
      selectedIndex: null,
      x: 0,
      y: 0,
   }),
   props: {
      item: { default: {} },
      isParent: { default: false },
      activeControlID: { default: null },
      categorys: { default: [] },
      contextMenuMethod: { type: Function },
      activeControl: { type: Function },
      refetchAllTree: { type: Function },
   },
   components: {
      CategoryControl,
      DeleteConfirmation
   },
   watch: {
      activeControlID() {
         console.log(this.activeControlID);
      }
   },
   mounted() {
      // window.addEventListener("click", e => {
      //    console.log(e);
      // });
   },
   methods: {
      async fetchTree(item, index, isOpened = false) {
         this.activeControlID = null;
         this.activeControl(null);
         this.selectedItem = {};
         if (index !== 'parent') {
            this.item.children[index].isOpen = this.item.children[index].isOpen != true ? true : false
         } else {
            this.item.isOpen = this.item.isOpen != true ? true : false
         }
         if (item.hasChild == 1 && (isOpened == true || item.isOpen)) {
            this.isLoading = true;
            this.isLoadingIndex = index;
            let request = this.$api.GET_METHOD(`category?parent=${item.id}`).then((response) => {
               this.isLoading = false;
               this.isLoadingIndex = null;
               if (response.check) {
                  let childs = response.data.items;
                  if (response.data.items.length > 0) {
                     if (item.children.length == 0) {
                        childs.forEach(child => {
                           if (!child.hasChild) {
                              return delete child.children
                           }
                        });
                        return item.children.push(...childs);
                     }
                  }
               }
            });
            await request;
         }
      },
      setActiveControl(id) {
         this.activeControlID = id;
      },
      edit(item, index) {
         this.selectedItem = {};
         this.selectionType = "edit";
         this.activeControlID = item.id;
         this.activeControl(item.id)
         this.selectedItem = { ...item };
         console.log("Edit", item, index);
         this.selectedIndex = index
      },
      deleteItem(item, index) {
         this.selectedItem = {};
         this.selectionType = "delete";
         (this.selectedItem = { ...item }), (this.dialogDeleteTitle = item.name);
         this.activeControlID = item.id;
         this.activeControl(item.id)
         this.dialog = true;
         this.selectedIndex = index
      },
      deleteAction(approved) {
         console.log("approved", this.selectedItem);
         this.dialog = false;
         if (approved == true) {
            this.isLoading = true;
            this.$api.DELETE_METHOD(`category`, this.selectedItem.id).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  this.fetchTree(this.item, 'parent');
                  this.item.children.splice(this.selectedIndex, 1)
                  if (this.isParent) {
                     this.refetchAllTree(true)
                  }
               }
            });
         }
      },
      add(item, index = 0) {
         this.selectedItem = {};
         this.selectionType = "add";
         this.selectedItem = { ...item };
         this.activeControl(item.id)
         this.activeControlID = item.id;
         console.log(index);
         this.selectedIndex = index
      },
      show(e, item) {
         console.log(item, "item");
         e.preventDefault();
         this.showMenu = false;
         this.x = e.clientX;
         this.y = e.clientY;
         this.contextMenuItem = item;
         this.contextMenuMethod(item, { x: e.clientX, y: e.clientY })
         this.$nextTick(() => {
            this.showMenu = true;
            this.contextMenuItem = item;
         });
      },
      saveControl(data) {
         this.activeControlID = null;
         if (this.selectionType == 'add') {
            console.log('data', data);
            this.item.children = []
            this.fetchTree(this.item, 'parent', true)
         } else {
            data.name = this.$vuetify.rtl == true ? data.name_ar : data.name_en;
            console.log(data, this.selectedIndex);
            if (data.hasChild == 0) {
               this.item.children[this.selectedIndex] = data
            }
            if (data.parent_id == 0) {
               // this.fetchTree(this.item, 'parent')
               this.refetchAllTree(true)
            } else {
               this.item.children[this.selectedIndex] = data
               this.fetchTree(this.item.children[this.selectedIndex], this.selectedIndex)
            }

         }
      }
   },
}
</script>
 
 <style lang="scss" scoped>
 summary {
    position: relative;
    //cursor: pointer;
    height: 35px;
    list-style: none; // Remove arrow
    margin-top: 0.25rem;
 
    &::-webkit-details-marker {
       display: none; // Remove arrow
 
 
    }
 
    //	&:hover {
    //	background-color: #f2f5f9;
    //}
    .actionsTreeControl {
       display: none;
    }
 
    &:hover {
       .actionsTreeControl {
          display: flex;
       }
    }
 }
 
 details {
    transition: 0.3s;
    border-radius: 9px;
    margin-top: 5px;
    margin-bottom: 5px;
 
    //	border-bottom: 1px solid #b5bfd9;
    &[open].detailsHasChild {
       background: rgba(51, 108, 251, 0.03) !important;
       margin: 1rem 0;
    }
 
 
 
 
 }
 </style>