<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <v-col cols="12" xs="12" class="pt-0">
                     <h4>{{ $t('branch details') }}</h4>
                  </v-col>
                  <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>
                  <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>
                  <!-- <GenericInput
                     type="select"
                     :lookups="stockTypes"
                     :value="form.stock_type"
                     @input="form.stock_type = $event"
                     label="stock type"
                     :multi="false"
                     :required="true"
                     selected_label="name"
                     selected_prop="id"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 3]"
                  ></GenericInput> -->
                  <GenericInput type="float" :value="form.first_cash" @input="form.first_cash = $event" label="first cash"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.vat_register_name" @input="form.vat_register_name = $event"
                     label="vat register name" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="number" :value="form.vat_number" @input="form.vat_number = $event" label="vat number"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <!-- <GenericInput
                     type="text"
                     :value="form.print_margin"
                     @input="form.print_margin = $event"
                     label="print margin"
                     :required="true"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 3]"
                  ></GenericInput>-->
                  <GenericInput type="select" :lookups="warehouses" :value="form.default_warehouse"
                     @input="form.default_warehouse = $event" label="warehouse" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="dropzone" :value="form.branch_image" @input="form.branch_image = $event"
                     v-if="pageData.isEdit == false" label="logo" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 12, 12]"></GenericInput>
                  <GenericInput type="dropzone" :value="form.branch_image_edit" @input="form.branch_image_edit = $event"
                     v-if="pageData.isEdit == true" label="logo" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 12, 12]"></GenericInput>

               </v-row>
            </v-card>

            <!-- branch Settings Form -->
            <v-card class="backgroundW shadow pa-0 mt-3">
               <v-col cols="12" class="pa-0">
                  <v-tabs active-class=" " color="primary" v-model="tab">
                     <v-tabs-slider></v-tabs-slider>

                     <v-tab v-for="item in tabsItems" :key="item" class="font-weight-bold" @click="activeTab = item">{{
                        $t(item)
                     }}</v-tab>
                  </v-tabs>
               </v-col>
               <!-- tab 1 -->
               <v-row class="pa-5" v-if="activeTab == 'Accounts Link'">
                  <GenericInput type="select" :lookups="cost_centers" :value="form.cost_center_id"
                     @input="form.cost_center_id = $event" label="Cost Center" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="account_trees" :value="form.account_tree_id"
                     @input="form.account_tree_id = $event" label="linked account" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="account_trees" :value="form.bank_account_id"
                     @input="form.bank_account_id = $event" label="bank account" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="account_trees" :value="form.treasury_account_id"
                     @input="form.treasury_account_id = $event" label="treasury account" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <!-- <GenericInput type="select" :lookups="account_trees" :value="form.gold_bank_account_id"
                     @input="form.gold_bank_account_id = $event" label="gold bank account" :multi="false"
                     :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]"></GenericInput> -->
                  <!-- <GenericInput
                     type="select"
                     :lookups="account_trees"
                     :value="form.gold_bank_account_id"
                     @input="form.gold_bank_account_id = $event"
                     label="debit account"
                     :multi="false"
                     :required="true"
                     selected_label="name"
                     selected_prop="id"
                     :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]"
                  ></GenericInput>-->
                  <GenericInput type="select" :lookups="account_trees" :value="form.settlement_account"
                     @input="form.settlement_account = $event" label="Deficit / Increase Calculation" :multi="false"
                     :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]"></GenericInput>
               </v-row>
               <!-- tab 2 -->
               <v-row class="pa-5" v-if="activeTab == 'Permissions'">
                  <v-col cols="12" xs="12">
                     <h4>{{ $t('Choose who will see the branch') }}</h4>
                  </v-col>
                  <GenericInput type="select" :lookups="users" :value="form.users" @input="form.users = $event"
                     label="choose" :multi="true" :required="true" selected_label="user_full_name" selected_prop="id"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
                  <v-col cols="12" md="4" id="userSelections">
                     <v-col cols="12" v-if="form.users">
                        <v-btn depressed @click="form.users = null">{{ $t('delete all') }}</v-btn>
                     </v-col>
                     <v-chip v-for="(select, index) in form.users" :key="index" class="ma-2" label close
                        @click:close="removeUser(select)">
                        <span v-for="(all, index) in users.filter((all) => all.id == select)" :key="index">{{
                           all.user_full_name
                        }}</span>
                     </v-chip>
                  </v-col>
               </v-row>
               <!-- tab 3 -->
               <v-row align="center" class="pa-5" v-if="activeTab == 'print'">
                  <GenericInput type="switch" :value="form.print_header_active" @input="form.print_header_active = $event"
                     label="print header" :isLoading="pageData.editIsLoading" :cols="[12, 12, 'auto']"></GenericInput>

                  <GenericInput type="radiogroup" v-if="form.print_header_active == 1"
                     :lookups="[{ name: $i18n.t('use text'), id: 'use text' }, { name: $i18n.t('use image'), id: 'use image' }]"
                     selected_label="name" selected_prop="id" :value="printTopType" @input="printTopType = $event" label
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]"></GenericInput>


                  <GenericInput type="texteditor" v-if="printTopType == 'use text' && form.print_header_active == 1"
                     :value="form.print_label_ar" @input="form.print_label_ar = $event" label="print label arabic"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]"></GenericInput>
                  <GenericInput type="texteditor" v-if="printTopType == 'use text' && form.print_header_active == 1"
                     :value="form.print_label_en" @input="form.print_label_en = $event" label="print label english"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]"></GenericInput>
                  <GenericInput type="dropzone"
                     v-if="printTopType == 'use image' && form.print_header_active == 1 && pageData.isEdit == false"
                     :value="form.header_image" @input="form.header_image = $event" label="header image" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                  <GenericInput type="dropzone"
                     v-if="printTopType == 'use image' && form.print_header_active == 1 && pageData.isEdit == true"
                     :value="form.header_image_edit" @input="form.header_image_edit = $event" label="header image"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]"></GenericInput>
                  <v-col cols="12" lg="6" sm="12"
                     v-if="printTopType == 'use image' && form.print_header_active == 1 && pageData.isEdit == true">
                     <img v-if="form.header_image" class="ma-auto d-block" height="180"
                        :src="$store.state.endpointURL + form.header_image" alt="wms" />
                  </v-col>
                  <v-col cols="12">
                     <v-divider class="my-3" style="border-color: #ddd;width:100%"></v-divider>
                  </v-col>

                  <GenericInput type="switch" :value="form.print_footer_active" @input="form.print_footer_active = $event"
                     label="print footer" :isLoading="pageData.editIsLoading" :cols="[12, 12, 'auto']"></GenericInput>
                  <GenericInput type="texteditor" v-if="form.print_footer_active == 1" :value="form.print_footer"
                     @input="form.print_footer = $event" label="print bottom page" :isLoading="pageData.editIsLoading"
                     :cols="[12, 12, 12]"></GenericInput>
               </v-row>
               <!-- tab 4 -->
               <v-row class="pa-5" v-if="activeTab == 'add margins'">
                  <GenericInput type="text" :value="form.top_margin" @input="form.top_margin = $event" label="top margin"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.right_margin" @input="form.right_margin = $event"
                     label="right margin" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.bottom_margin" @input="form.bottom_margin = $event"
                     label="bottom margin" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.left_margin" @input="form.left_margin = $event"
                     label="left margin" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <v-col cols="12" sm="6">
                     <label>{{ $t('print margin') }}</label>
                     <v-text-field class="mt-1" required outlined readonly disabled :loading="pageData.editIsLoading"
                        rounded-md dense
                        :value="form.print_margin ? form.print_margin : form.top_margin + ' ' + form.right_margin + ' ' + form.bottom_margin + ' ' + form.left_margin + ' '"
                        type="text"></v-text-field>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>

         <!-- End of Form -->
      </v-container>
   </section>
</template>

<style scoped lang="scss">
#userSelections {
   .v-chip.v-size--small {
      display: none !important;
   }
}
</style>

<script>

import ControlHeader from "../../../components/ui/ControlHeader.vue";
import GenericInput from '../../../components/ui/GenericInput.vue';
export default {
   name: "BranchesControl",

   data: () => ({
      pageData: {
         screen_code: "01-006",
         url: null,
         controlRoute: "/main/branch-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      account_trees: [],
      stockTypes: [],
      users: [],
      cost_centers: [],
      warehouses: [],
      printTopType: 'use text',
      printBottomType: 'use text',
      form: {
         // basic data
         name_en: null,
         name_ar: null,
         default_warehouse: null,
         // stock_type: null,
         first_cash: 0,
         vat_register_name: null,
         vat_number: null,
         branch_image: null,
         // Accounts Link tab
         bank_account_id: null,
         treasury_account_id: null,
         settlement_account: null,
         account_tree_id: null,
         cost_center_id: null,
         // Permissions tab
         users: null,
         // print tab
         print_header_active: 0,
         print_footer_active: 0,
         print_label_ar: null,
         print_label_en: null,
         header_image: null,
         print_footer: null,
         // margins tab
         top_margin: 1,
         right_margin: 1,
         bottom_margin: 1,
         left_margin: 1,
      },
      tab: null,
      activeTab: "Accounts Link",
      tabsItems: [
         'Accounts Link', 'Permissions', 'print', 'add margins'
      ]
   }),
   components: {
      GenericInput,
      ControlHeader
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {

      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`branch/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.form.users = this.form.users.split(',').map(i => Number(i));
                     this.form.branch_image_edit = response.data.data.branch_image
                     // this.form.stock_type = this.form.stock_type == 1 ? { id: 1, name: 'النرصيد بالقطعة' } : { id: 2, name: 'النرصيد بالقطعة' }
                     this.account_trees = response.data.account_trees;
                     this.cost_centers = response.data.cost_centers;
                     this.users = response.data.users;
                     this.warehouses = response.data.warehouses;
                     this.stockTypes = [
                        { 'id': 1, name: 'النرصيد بالقطعة' },
                        { 'id': 2, name: 'النرصيد بالكمية' }
                     ];
                     this.printTopType = this.form.print_header_active == 1 ? 'use text' : 'use image';
                     this.form.print_header_active = this.form.print_header_active == 2 ? 1 : this.form.print_header_active
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`branch/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.account_trees = response.data.account_trees;
                     this.cost_centers = response.data.cost_centers;
                     this.users = response.data.users;
                     this.warehouses = response.data.warehouses;
                     this.stockTypes = [
                        { 'id': 1, name: 'النرصيد بالقطعة' },
                        { 'id': 2, name: 'النرصيد بالكمية' }
                     ];
                     Object.keys(response.data.defaults || Object).forEach(key => {
                        this.form[key] = response.data.defaults[key]
                     })
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.form.print_header_active = this.form.print_header_active == 0 ? 0 : this.printTopType == 'use text' ? 1 : 2;
            if (this.form.branch_image_edit) {
               this.form.branch_image = this.form.branch_image_edit;
               delete this.form.branch_image_edit;
            } else {
               delete this.form.branch_image;
               delete this.form.branch_image_edit;
            }
            if (this.form.header_image_edit) {
               this.form.header_image = this.form.header_image_edit;
               delete this.form.header_image_edit;
            } else {
               delete this.form.header_image;
               delete this.form.header_image_edit;
            }
            this.pageData.isLoading = true;
            delete this.form.header_image_edit
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`branch/${this.form.branch_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check == true) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`branch`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check == true) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`branch/${this.form.branch_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url)
               this.pageData.isLoading = false
            })
         }
      },
      removeUser(item) {
         const index = this.form.users.indexOf(item)
         if (index >= 0) this.form.users.splice(index, 1)
      },

   },
};
</script>
