<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="incoming_data" :save="save" :valid="valid" :hideDelete="true"
            :actionBtn="(incoming_data.incoming_status == 1)" :createBtn="false" actionBtnText="close incoming" :actionBtnColor="'error'"
            :actionBtnValid="actionBtnValid" :actionBtnClick="actionBtnClick" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-sheet class="pa-3 transparent">
            <v-card class="shadow pa-5 pt-7 rounded-xl" color="blue12">
               <v-row>
                  <v-col cols="auto">
                     <img src="@/assets/img/wms_svg/truck.svg" class="d-block ma-auto" alt="wms">
                     <div class="subtitle-1 blue6--text font-weight-normal">{{ $t("truck has been added") | capitalize
                     }}
                     </div>
                  </v-col>
                  <v-col cols="auto" class="pa-0">
                     <v-divider vertical style="height: 2rem"></v-divider>
                  </v-col>
                  <v-col cols="auto">
                     <div>
                        <div class="subtitle-1 blue6--text font-weight-normal mx-2">{{ $t("client") | capitalize }} :
                        </div>
                        <div class="subtitle-1 blue6--text font-weight-normal mx-2">{{ $t("incoming no") | capitalize
                        }}:</div>
                        <div class="subtitle-1 blue6--text font-weight-normal mx-2">{{ $t("truck no") | capitalize }}:
                        </div>
                        <div class="subtitle-1 blue6--text font-weight-normal mx-2">{{ $t("date") | capitalize }}:</div>
                     </div>
                  </v-col>
                  <v-col cols="auto">
                     <div>
                        <div class="subtitle-1 blue4--text mx-2"> {{ incoming_data.customer_name || '-' }}</div>
                        <div class="subtitle-1 blue4--text mx-2"> #{{ incoming_data.id || '-' }}</div>
                        <div class="subtitle-1 blue4--text mx-2"> {{ incoming_data.car_numbers ||
                              ''
                        }}-{{ incoming_data.car_digits || '' }}</div>
                        <div class="subtitle-1 blue4--text mx-2"> {{ incoming_data.incoming_date || '' }} -
                           {{ incoming_data.incoming_time || '' }}</div>
                     </div>
                  </v-col>
                  <v-col cols="auto" class="pa-0">
                     <v-divider vertical class="primary-hr"></v-divider>
                  </v-col>
                  <v-col cols="auto">
                     <v-card color="yellow12" width="200" class="pa-7 shadow">
                        <div class="text-center subtitle-1 font-weight-regular yellow1--text">
                           {{ $t('inclued pallets') | capitalize }}
                        </div>
                        <div class="text-center font-weight-bold body-1 yellow1--text">
                           {{ incoming_data.total_pallets || 0 }}
                        </div>
                     </v-card>
                  </v-col>
                  <v-col cols="auto">
                     <v-card color="yellow12" width="200" class="pa-7 shadow">
                        <div class="text-center subtitle-1 font-weight-regular yellow1--text">
                           {{ $t('cases') | capitalize }}
                        </div>
                        <div class="text-center font-weight-bold body-1 yellow1--text">
                           {{ incoming_data.total_cases || 0 }}
                        </div>
                     </v-card>
                  </v-col>
                  <v-col cols="auto">
                     <v-card color="yellow12" width="200" class="pa-7 shadow">
                        <div class="text-center subtitle-1 font-weight-regular yellow1--text">
                           {{ $t('pieces') | capitalize }}
                        </div>
                        <div class="text-center font-weight-bold body-1 yellow1--text">
                           {{ incoming_data.total_pieces || 0 }}
                        </div>
                     </v-card>
                  </v-col>
               </v-row>
            </v-card>

            <v-row align="center" class="my-5">
               <v-col cols="auto">
                  <div class="gray6--text subtitle-1 font-weight-bold">{{ $t('pallets') | capitalize }}</div>
               </v-col>
               <v-col cols="auto">
                  <v-text-field v-model="pageData.search" @keydown.enter="(e) => getSearch(e.target.value)"
                     @input="getSearch(pageData.search)" @click:append="getSearch(pageData.search)"
                     append-icon="mdi-magnify" :placeholder="$i18n.t('Search')" rounded solo flat single-line clearable
                     hide-details dense />
               </v-col>
               <v-col cols="auto">
                  <v-btn color="yellow12" :disabled="(incoming_data.incoming_status == 2)"
                     @click="$router.push(`/warehouses/pallet-control/${incoming_data.id}/${incoming_data.customer_id}`)"
                     depressed :height="40" class="yellow1--text">{{ $t('add a pallet')
                     }}</v-btn>
               </v-col>
               <v-col cols="12">
                  <DynamicTable :isLoading="palletsLoading" :pageData="pageData"
                     :cardClasses="incoming_pallets.length > 0 ? 'white' : 'transparent'"
                     :data="pageData.search ? filteredData : incoming_pallets" :header="tableHeader" :option="tableOption"
                     :editValueMethod="edit" :deleteValueMethod="removeItem">
                     <template v-slot:td="{ row, header }">
                        <span>
                           <v-btn v-if="header.key == 'duplicate'" :loading="palletsLoading" min-width="120"
                              @click="duplicate(row)" :disabled="(incoming_data.incoming_status == 2)" small class="white--text" depressed color="black">
                              {{ $t('duplicate') }} <v-icon right>mdi-content-copy</v-icon>
                           </v-btn>
                        </span>
                     </template>
                  </DynamicTable>
               </v-col>
            </v-row>
         </v-sheet>
         <PalletPrintModal :dialog="printPalletDialog" :printData="printData"
            :backValueMethod="() => { printPalletDialog = false; }" />
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import PalletPrintModal from "./PalletPrintModal.vue";
export default {
   name: "IncomingDetails",
   data: () => ({
      pageData: {
         screen_code: "08-002",
         url: null,
         controlRoute: "/warehouses/incoming-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
         isTrashed: 1
      },
      valid: false,
      actionBtnValid: true,
      palletsLoading: false,
      printData: null,
      printPalletDialog: false,
      tableRows: [],
      clients: [],
      incoming_pallets: [],
      incoming_photos: [],
      filteredData: [],
      tableOption: null,
      activeStep: 1,
      incoming_data: {
         id: null,
         customer_id: null,
         customer_name: null,
         driver_name: null,
         driver_id: null,
         driver_phone_code: null,
         driver_phone_number: null,
         car_numbers: null,
         car_digits: null,
         incoming_shipment_date: null,
         incoming_shipment_time: null,
         has_container_seals: null,
         locker_number: null,
         damage_case: null,
         incoming_shipment_status: null,
         incoming_shipment_active: null,
         add_date: null,
         add_user: null,
         total_pallets: null,
         total_cases: null,
         total_pieces: null
      },
   }),
   components: {
      ControlHeader,
      DynamicTable,
      PalletPrintModal
   },
   computed: {
   },
   watch: {
      incoming_pallets() {
         this.incoming_pallets.forEach(pallet => {
            pallet.quantity_pieces = pallet.number_of_cases * pallet.quantity_in_case
         });
      }
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$i18n.t('incoming details');
         this.pageData.isTrashed = 1;
         this.tableHeader = [
            { text: "serial", key: "batch_number", type: 'text', classes: "" },
            { text: "pallet no", key: "pallet_id", type: 'text', classes: "" },
            { text: "item", key: "customer_item_name", type: 'text', classes: "" },
            // { text: "patch no", key: "batch_number", type: 'text', classes: "" },
            { text: "include case", key: "number_of_cases", type: 'text', classes: "" },
            { text: "quantity in case", key: "quantity_in_case", type: 'text', classes: "" },
            { text: "pieces", key: "quantity_pieces", type: 'text', classes: "" },
            { text: "temperature degree", key: "temperature_degree", type: 'text', classes: "" },
            { text: "expiry date", key: "expiry_date", type: 'text', classes: "" },
            // { text: "addition date", key: "add_date", type: 'text', classes: "" },
            // { text: "delete", key: "id", type: 'delete', classes: "" },
            // { text: "settings", key: "id", type: 'actions', classes: "" },
            { text: "duplicate", key: "duplicate", type: 'slot', buttonClass: 'white--text', classes: "" },
         ];

      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`incoming/${this.$route.params.id}`).then((response) => {
                  if (response.check) {
                     this.pageData.editIsLoading = false;
                     this.pageData.isLoading = false;
                     console.log("response", response);
                     this.incoming_data = response.data.data;
                     this.clients = response.data.customers;
                     this.incoming_pallets = response.data.incoming_pallets;
                     this.incoming_photos = response.data.incoming_photos;
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
      },
      save() {
         this.pageData.isLoading = true;
         this.incoming_data._method = 'PUT';
         this.$api.POST_METHOD(`incoming/${this.incoming_data.incoming_id}`, this.incoming_data).then((response) => {
            if (response.check) {
               this.pageData.isLoading = false;
               this.$router.push(this.pageData.url)
            }
         })
      },
      actionBtnClick() {
         // this.$router.push(`/warehouses/incoming/`)
         this.pageData.isLoading = true;
         this.$api.POST_METHOD(`close_incoming/${this.incoming_data.id}`, this.incoming_data).then((response) => {
            if (response.check) {
               this.pageData.isLoading = false;
               this.$router.push(this.pageData.url)
            }
         })
         // this.activeStep = 2;
         // this.actionBtnValid = false
      },

      duplicate(row) {
         this.palletsLoading = true;
         this.$api.POST_METHOD(`add_incoming_pallets`, row).then((response) => {
            this.palletsLoading = false;
            if (response.check) {
               response.data.data.id = response.data.id;
               this.printData = response.data.data
               this.printPalletDialog = true;
               this.getData()
            }
         })

      },
      edit(row) {
         this.$router.push(`/warehouses/pallet-control/${row.id}/${this.incoming_data.customer_id}`)
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },

      getSearch(search) {
         this.pageData.search = search
         this.filteredData = this.incoming_pallets.filter((obj) => Number(obj.pallet_id) == Number(this.pageData.search));
      }

   },
};
</script>
