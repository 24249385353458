<template>
  <div
    class="search-container cursor_pointer light rounded-pill shadow-lg"
    :class="focus == true? 'activeSearch' : ''"
    @mouseover="$refs.appBarSearch.focus()"
    @click="$refs.appBarSearch.focus(); focus = !focus"
  >
    <input type="text" ref="appBarSearch" v-model="appBarSearch" :placeholder="$i18n.t('Search')" />
    <img src="../../assets/img/wms_svg/search.svg"  class="search-icon light" alt="wms" />
  </div>
</template>


<script>

export default {
  name: "AppBarSearch",
  data() {
    return {
      appBarSearch: null,
      focus : false
    }
  },
  watch: {

  },
  methods: {

  },
}


</script>

<style scoped lang="scss">
.search-container {
  width: 34px;
  height: 34px;
  position: relative;
  transition: 0.7s;
  display: flex;
  align-items: center;
  input {
    height: 34px;
    width: 0;
    padding: 0 0.5rem;
    position: relative;
    z-index: 0;
    display: none;
    &:focus {
      outline: none;
    }
  }
  .search-icon {
    position: absolute;
    right: 0.5rem;
    z-index: 1;
  }
  &:hover {
    width: 150px;
    input {
      width: inherit;
      display: block;
    }
  }
}
.activeSearch{
  width: 150px !important;
      input {
      width: inherit;
      display: block;
    }
}
.v-application--is-rtl {
  .search-container {
    .search-icon {
      position: absolute;
      right: unset;
      left: 0.5rem;
      z-index: 1;
    }
  }
}
</style>