<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
      <!-- End Of Header -->

      <!-- Form -->
      <div class="pa-3 rounded">
        <v-form ref="form" v-model="valid" class="pa-0">
          <v-card class="backgroundW shadow pa-5 pt-7 rounded-lg">
            <v-row align="center">
              <GenericInput v-if="pageData.isEdit == true" type="number" :value="form.treasury_id"
                @input="form.treasury_id = $event" label="treasury code" :disabled="true" :required="false"
                :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
              <GenericInput type="select" :lookups="users" :value="form.users" @input="form.users = $event"
                label="permissions" :multi="true" :required="true" selected_label="username" :disabled="false"
                selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
              <GenericInput type="text" :value="form.treasury_name_en" @input="form.treasury_name_en = $event"
                label="name_en" :disabled="false" :required="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 6]"></GenericInput>
              <GenericInput type="text" :value="form.treasury_name_ar" @input="form.treasury_name_ar = $event"
                label="name_ar" :disabled="false" :required="true" :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 6]"></GenericInput>
              <GenericInput type="select" :lookups="account_trees" :value="form.account_tree_id"
                @input="form.account_tree_id = $event" label="account guide" :multi="false" :required="true"
                selected_label="name" :disabled="false" selected_prop="id" :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 6]"></GenericInput>
            </v-row>
          </v-card>
          <v-card class="backgroundW shadow pa-5 rounded-lg mt-3">
            <v-row align="center">
              <GenericInput type="number" :value="form.first_balance" @input="form.first_balance = $event"
                label="first duration balance" :disabled="pageData.isEdit == true ? true : false" :required="false"
                :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
              <GenericInput v-if="pageData.isEdit == true" type="number" :value="form.current_balance"
                @input="form.current_balance = $event" label="current balance" :disabled="true" :required="false"
                :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
              <GenericInput type="date" :value="form.first_balance_date" @input="form.first_balance_date = $event"
                label="balance date" :disabled="pageData.isEdit == true ? true : false" :required="true"
                :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
              <GenericInput type="date" v-if="pageData.isEdit == true" :value="form.current_balance_date"
                @input="form.current_balance_date = $event" label="current balance date" :disabled="true"
                :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
              <GenericInput type="radiogroup" :lookups="[
                { id: 1, name: $i18n.t('credit') },
                { id: 2, name: $i18n.t('debit') },
              ]" :value="form.first_balance_type" @input="form.first_balance_type = $event" label="balance type"
                :multi="false" :required="true" selected_label="name" :disabled="pageData.isEdit == true ? true : false"
                selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
              <!-- <GenericInput
                type="radiogroup"
                :lookups="[
                  { id: 1, name: 'credit' },
                  { id: 2, name: 'debit' },
                ]"
                :value="form.current_balance_type"
                @input="form.current_balance_type = $event"
                label="current balance type"
                :multi="false"
                :required="true"
                selected_label="name"
                :disabled="true"
                selected_prop="id"
                :isLoading="pageData.editIsLoading"
                :cols="[12, 6, 6]"
              ></GenericInput>-->
            </v-row>
          </v-card>
        </v-form>
      </div>
      <!-- End Form -->
    </v-container>
  </section>
</template>

<script>
export default {
  name: "TreasuryControl",

  data: () => ({
    pageData: {
      screen_code: "02-006",
      url: null,
      controlRoute: "/accounting/treasury-control/",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isEdit: false,
      editIsLoading: false,
    },
    payBillsDialog: false,
    valid: false,
    account_trees: [],
    users: [],
    modalKey: 1,

    form: {
      users: null,
      treasury_name_ar: null,
      treasury_name_en: null,
      account_tree_id: null,

      first_balance: null,
      first_balance_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      first_balance_type: 1,
    },
  }),
  components: {},
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.entityName = this.$route.params.id
        ? this.$i18n.t("edit") + " " + this.$i18n.t("treasury")
        : this.$i18n.t("treasury");
    },
    getData() {
      if (this.$route.params.id) {
        if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
          this.pageData.isEdit = true;
          this.pageData.editIsLoading = true;
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`treasury/${this.$route.params.id}`).then((response) => {
            this.pageData.editIsLoading = false;
            this.pageData.isLoading = false;
            if (response.check) {
              console.log("response", response);
              this.form = response.data.item;
              this.form.users = this.form.users.split(",").map((i) => Number(i));
              this.form.first_balance_type = Number(this.form.first_balance_type);
              this.users = response.data.users;
              this.account_trees = response.data.account_trees;
              this.$refs.form.validate();
            }
          });
        } else {
          this.$router.push("/");
        }
      } else {
        if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`treasury/create`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.users = response.data.users;
              this.account_trees = response.data.account_trees;
            }
          });
        } else {
          this.$router.push("/");
        }
      }
    },

    setSelections(bills, status) {
      if (status == true) {
        this.pageData.selectedBills = bills;
      }
      this.payBillsDialog = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          this.form._method = "PUT";
          this.$api
            .POST_METHOD(`treasury/${this.form.treasury_id}`, this.form)
            .then((response) => {
              this.pageData.isLoading = false;
              if (response.check) {
                this.$router.push(this.pageData.url);
              }
            });
        } else {
          this.$api.POST_METHOD(`treasury`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.$router.push(this.pageData.url);
            }
          });
        }
      }
    },
    deleteMethod(status) {
      this.deleteDialog = false;
      if (status == true) {
        this.pageData.isLoading = true;
        this.$api
          .POST_METHOD(`accounting/treasury/${this.form.treasury_id}`, {
            _method: "delete",
          })
          .then(() => {
            this.$router.push(this.pageData.url);
            this.pageData.isLoading = false;
          });
      }
    },
  },
};
</script>
