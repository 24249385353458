<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" actionBtnText="add a main category"
        :actionBtnClick="openDialog" :actionBtn="true" :actionBtnValid="true" actionBtnColor="success" actionBtnClass=""
        :createBtn="false" :showSearch="false" :showDeleted="false" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12" class="pa-0 ">
          <v-card class="ma-4 rounded-lg backgroundW overflow-y-auto overflow-x-hidden"
            :height="$store.state.Settings.windowSize.y - 160" elevation="0">
            <v-row class="pa-4" justify="space-between">
              <v-col style="min-width: 480px ;"
                :style="'max-width :' + ($store.state.Settings.windowSize.x > 1366 ? ($store.state.Settings.windowSize.x - 1000) : ($store.state.Settings.windowSize.x - 900)) + 'px !important'"
                order="2" order-lg="1">
                <v-card class="pa-2  backgroundW " elevation="0">
                  <div class="searchInTree backgroundW"
                    :style="'width :' + ($store.state.Settings.windowSize.x > 1366 ? ($store.state.Settings.windowSize.x - 1000) : ($store.state.Settings.windowSize.x - 900)) + 'px !important'">
                    <v-text-field class="sticky_search mt-3" v-model="pageData.search"
                      @keydown.enter="(e) => getSearch(e.target.value)" @click:clear="(e) => getSearch(e.target.value)"
                      @click:append="getSearch(pageData.search)" append-icon="mdi-magnify" :placeholder="
                        pageData.isTrashed == 0
                          ? $i18n.t('Search in deleted')
                          : $i18n.t('Search')
                      " single-line clearable hide-details outlined dense />
                  </div>
                  <v-progress-circular v-if="pageData.isLoading == true" :size="40" :width="5" style="margin: auto"
                    class="d-block my-8" color="primary" indeterminate></v-progress-circular>
                  <!-- Tree View ////////////////////////////////////////////////////////////////////// -->
                  <div class="pt-8">
                    <CategoryTreeNode :item="item" :refetchAllTree="getData" :isParent="true"
                      :cost_centers="cost_centers" :activeControlID="activeControlID"
                      :contextMenuMethod="(item, position) => { contextMenuItem = item; showMenu = true; x = position.x; y = position.y }"
                      v-for="(item, index) in items" :key="index" :activeControl="setActiveControl"
                      :setAction="(item, type) => { selectedItem = item; selectionType = type }" />
                  </div>
                  <!-- End Of Tree View ////////////////////////////////////////////////////////////////////// -->
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <DeleteConfirmation v-if="dialog" :dialog="dialog" :item="dialogDeleteTitle" :backValueMethod="deleteAction">
      </DeleteConfirmation>
      <CategoryModal v-if="newMainDialog" :dialog="newMainDialog" :backValueMethod="closeMainAccountDialog">
      </CategoryModal>
    </v-container>
  </section>
</template>

<script>

import DeleteConfirmation from "@/components/modals/DeleteConfirmation.vue";
import CategoryModal from "./CategoryModal.vue";
import EntityHeader from "@/components/ui/EntityHeader.vue";
import CategoryTreeNode from "./CategoryTreeNode.vue";
export default {
  name: "Category",

  data: () => ({
    pageData: {
      screen_code: "03-002",
      url: null,
      controlRoute: "inventory/category-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    selectionType: "",
    selectedItem: {},
    showMenu: false,
    parent_ID: 0,
    contextMenuItem: {},
    categorys: [],
    x: 0,
    y: 0,
    dialog: false,
    items: [],
    search: null,
    newMainDialog: false,
    active: null,
    activeControlID: null,
  }),
  components: {
    DeleteConfirmation,
    CategoryModal,
    CategoryTreeNode,
    EntityHeader
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },

    selected() {
      console.log("this.active", this.active);
      if (!this.active.length) return undefined;
      const id = this.active[0];
      return this.users.find((user) => user.id === id);
    },
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    openDialog() {
      this.newMainDialog = true
    },
    checkOpen(item) {
      if (this.open.some((node) => node.id == item.id) == true) {
        const objIndex = this.open.findIndex((node) => node.id == item.id);
        this.open.splice(objIndex, 1);
      } else {
        this.open.push(item);
      }
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        {
          text: "#",
          sortable: true,
          value: "id",
        },
        {
          text: this.$i18n.t("name"),
          sortable: true,
          value: "name",
        },
        {
          text: this.$i18n.t("created_at"),
          sortable: true,
          value: "add_date",
        },
        { text: this.$i18n.t("actions"), value: "actions", sortable: false },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.refetchKey = this.refetchKey + 1;
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.selectedItem.id = null;
        this.pageData.isLoading = true;
        var param = this.pageData.search
          ? `word=${this.pageData.search}`
          : `parent=${this.parent_ID}`;
        this.$api.GET_METHOD(`category?${param}`).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.entityName =
              this.pageData.isTrashed == 0
                ? this.$i18n.t("deleted trees")
                : this.$store.state.activeScreen.sub_title;
            response.data.items.forEach(child => {
              if (!child.hasChild) {
                return delete child.children
              }
            });
            this.items = response.data.items;
            this.$api.GET_METHOD(`category?type=2`).then((costCenterResponse) => {
              if (costCenterResponse.check) {
                this.categorys = costCenterResponse.data.items;
              }
            });
          }
        });
      } else {
        this.$router.push("/");
      }
    },
    refetch(item) {
      console.log("refetch", item);
      this.refetchKey = this.refetchKey + 1;
      this.getData();
    },


    edit(item) {
      this.selectedItem = {};
      this.selectionType = "edit";
      this.selectedItem = item;
      console.log("Edit", item);
    },
    deleteItem(item) {
      this.selectedItem = {};
      this.selectionType = "delete";
      (this.selectedItem = item), (this.dialogDeleteTitle = item.name);
      this.dialog = true;
      console.log("deleteItem", item);
    },
    deleteAction(approved) {
      console.log("approved", this.selectedItem);
      this.dialog = false;
      if (approved == true) {
        this.pageData.isLoading = true;
        this.$api.DELETE_METHOD(`category`, this.selectedItem.id).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.refetch(0);
          }
        });
      }
    },
    add(item) {
      this.selectedItem = {};
      this.selectionType = "add";
      this.selectedItem = item;
    },
    closeMainAccountDialog() {
      this.newMainDialog = false;
      this.refetch(0);
    },
    setActiveControl(id) {
      this.activeControlID = id;
    },
  },
};
</script>
<style lang="scss">
.searchInTree {
  position: fixed;
  z-index: 55;
  top: 7.7rem;
  background: #fff;
  height: 50px;
}

.portrait.v-card {
  margin: 0 auto;

  .portrait.v-card {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
  }

  .theme--dark.v-list {
    background: #121212;
  }
}
</style>