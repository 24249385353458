<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <v-card elevation="0" class="ma-0 pa-0 px-5 transparent">
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-row align="center">
              <v-col cols="12" sm="auto" class="d-flex justify-center align-center">
                <div class="subtitle-2 gray9--text  font-weight-medium">
                  {{ pageData.main }} > {{ pageData.category }} >
                </div>
                <div class="text-h6 mx-2 gray6--text  text-capitalize">
                  <strong v-text="pageData.entityName" />
                </div>
              </v-col>

            </v-row>
          </v-col>
          <v-col cols="12" md="5" xl="4">
            <div class="text-h5 text-center gray6--text  mb-2 font-weight-bold">
              {{ $t('search by request id') | capitalize }}
            </div>

            <div class="d-flex">
              <v-text-field v-model="pageData.search" @keydown.enter="(e) => getSearch(e.target.value)"
                @click:clear="(e) => getSearch(e.target.value)" class="rounded-pill" full-width
                @click:append="getSearch(pageData.search)" append-icon="mdi-magnify" :placeholder="$i18n.t('Search')"
                single-line clearable hide-details solo dense :height="37"></v-text-field>
              <v-btn color="blue4" class="rounded-xl mx-2 white--text" :loading="pageData.isLoading" @click="getData"
                width="120">{{ $t('search') }}</v-btn>
            </div>

          </v-col>
          <v-col cols="auto">
            <v-btn depressed class="d-block mi-start-auto" color="error" text :height="37"
              @click="() => { $router.push('/warehouses/outgoing') }">
              <v-icon>mdi-close</v-icon>
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <!-- End Of Header -->
      <v-row v-if="pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
              :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
              :restoreValueMethod="restoreMethod" cardClasses="">
              <template v-slot:td="{ row }">
                <span>
                  <v-chip color="red12" class="error--text" v-if="row.outgoing_status == 1"  
                    @click="closeRequest(row)">{{ $t('close') }}
                  </v-chip>
                  <v-chip color="error" v-if="row.outgoing_status == 2" >{{ $t('closed') | capitalize }}</v-chip>
                </span>
              </template>
            </DynamicTable>
          </v-col>

        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>

import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
export default {
  name: "OutgoingCloseRequest",

  data: () => ({
    pageData: {
      screen_code: "08-003",
      url: null,
      controlRoute: "warehouses/outgoing-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    tab: 0,
  }),
  components: {
    Pagination,
    DynamicTable
  },
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "outgoing request", key: "outgoing_request_id", type: 'text', classes: "" },
        { text: "client name", key: "customer_name", type: 'text', classes: "" },
        { text: "date", key: "outgoing_date", type: 'text', classes: "" },
        { text: "time", key: "outgoing_time", type: 'text', classes: "" },
        // { text: "qty requested", key: "requested_quantity", type: 'text', classes: "" },
        // { text: "qty requested", key: "requested_quantity", type: 'text', classes: "" },
        // { text: "qty out", key: "total_vat", type: 'price', classes: "" },
        // { text: "qty remaining", key: "total_with_vat", type: 'price', classes: "" },
        { text: "", key: "m_id", type: 'slot', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `outgoing?&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&request_id=${this.pageData.search}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      // this.$store.dispatch('setPagesRows', { rowsNumber: limit, pageNumber: page })
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`outgoing/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`outgoing_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    closeRequest(row) {
      this.$api.POST_METHOD(`close_outgoing/${row.id}`, { outgoing_request_id: row.outgoing_request_id }).then((response) => {
        if (response.check) {
          this.getData();
          //  this.$router.push(`/warehouses/outgoing`)
        }
      });
    },
    setClickRow() {
      // this.$router.push(`/main/outgoing/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>
