<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && form.proforma_invoice_id"
            :deleteMethod="deleteMethod" :showCreate="form.delivery_note_status !== 3">
            <template v-slot:left>
               <v-btn v-if="form.delivery_note_status !==2 &&  form.m_id" depressed @click="closeDeliveryDialog = true"
                   :loading="pageData.isLoading" class="my-2 mx-2 rounded-lg"
                  :color="form.delivery_note_status == 1 ? 'error' : 'blue4'" outlined :min-width="120">
                  <span v-if="form.delivery_note_status == 1">{{ $t("close") }}</span>
                  <span v-if="form.delivery_note_status == 3">{{ $t("return to pending") }}</span>
               </v-btn>
            </template>
         </ControlHeader>
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-fade-transition>
                  <div class="collapseForm" v-if="tableRows.length > 0">
                     <div class="d-flex">
                        <v-btn color="secondary" icon @click="formCollapse = !formCollapse">
                           <v-icon v-if="formCollapse == true">mdi-chevron-up</v-icon>
                           <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </v-fade-transition>
               <v-expand-transition>
                  <v-row align="center" justify="space-between" v-show="!formCollapse">

                     <v-col cols="12" md="6" lg="6">
                        <v-btn depressed color="blue4" outlined :disabled="(pageData.isEdit && form.proforma_invoice_id)"
                           @click="proformaDialog = true">
                           {{ form.customer_id ? form.customer_name : $t('choose customer proforma invoice') }}
                           <span v-if="form.proforma_invoice_id"> - {{ $t('proforma invoice no') }} :
                              {{ form.proforma_invoice_id }} </span>
                           <span v-if="form.purchase_order_id"> ({{ form.purchase_order_id }})</span>
                           <v-icon size="18" right>mdi-open-in-new</v-icon>
                        </v-btn>
                     </v-col>


                     <v-col cols="12" md="6" class="d-flex justify-end">
                        <v-card color="blue12" :width="120" class="pa-3 mx-1 elevation-0">
                           <div class="text-center subtitle-1 blue1--text">
                              {{ $t('requested') | capitalize }}
                           </div>
                           <div class="text-center font-weight-bold body-1 blue2--text">
                              {{ totalsCalculations.quantity | float }}
                           </div>
                        </v-card>
                        <v-card color="green12" :width="120" class="pa-3 mx-1 elevation-0">
                           <div class="text-center subtitle-1 green1--text">
                              {{ $t('received') | capitalize }}
                           </div>
                           <div class="text-center font-weight-bold body-1 green1--text">
                              {{ totalsCalculations.pervious_delivered_quantity | float }}
                           </div>
                        </v-card>
                        <v-card color="yellow12" :width="120" class="pa-3 mx-1 elevation-0">
                           <div class="text-center subtitle-1 yellow1--text">
                              {{ $t('change') | capitalize }}
                           </div>
                           <div class="text-center font-weight-bold body-1 yellow1--text">
                              {{ totalsCalculations.remaining_quantity | float }}
                           </div>
                        </v-card>
                     </v-col>
                     <GenericInput type="date" :value="form.delivery_note_date" @input="form.delivery_note_date = $event"
                        label="date" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                     </GenericInput>
                     <GenericInput type="time" :value="form.delivery_note_time" @input="form.delivery_note_time = $event"
                        label="time" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                     </GenericInput>
                     <GenericInput type="select" :lookups="sales_agents" :value="form.sales_agent_id"
                        @input="form.sales_agent_id = $event" label="sales agent" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                     </GenericInput>
                     <GenericInput type="number" :value="form.driver_id" @input="form.driver_id = $event" label="driver id"
                        :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="number" :value="form.car_number" @input="form.car_number = $event" label="car no"
                        :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>


                     <GenericInput type="textarea" solo :value="form.delivery_note_description"
                        @input="form.delivery_note_description = $event" label="description" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]"></GenericInput>


                     <GenericInput v-for="dynamicInput in masterInputs" :key="dynamicInput.id" :name="dynamicInput.id"
                        :type="dynamicInput.type" :value="form[dynamicInput.key]" @input="form[dynamicInput.key] = $event"
                        :label="dynamicInput.label" :lookups="dynamicLookups[dynamicInput.lookups_id]"
                        :required="dynamicInput.required == 0 ? false : true"
                        :disabled="dynamicInput.disabled == 0 ? false : true"
                        :multi="dynamicInput.multi == 0 ? false : true"
                        :cols="dynamicInput.cols.split(',').map(i => Number(i))" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" />


                     <!-- <v-col cols="12" class="mi-start-auto" md="auto" v-if="!form.proforma_invoice_id">
                        <v-btn outlined style="background-color:  var(--blue12);" class="d-block mi-start-auto"
                           depressed color="blue2" :width="127" @click="detailDialog = true" :height="37">
                           {{ $t('add line') }}
                        </v-btn>
                     </v-col> -->

                  </v-row>
               </v-expand-transition>
            </v-card>
         </v-form>



         <!-- Detail Form -->
         <DeliveryNoteDetail :detailAction="detailAction" :items="items" :warehouses="warehouses" :units="units"
            :taxes="taxes" :dialog="detailDialog" :detail="detail" :updateDetail="updateDetail"
            :isDetailEdit="isDetailEdit" :tableRows="tableRows" />
         <!-- Detail of Form -->

         <!-- Details Table -->
         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="computedItems.new_array || []" :header="tableHeader"
               :tableHeight="$store.state.Settings.windowSize.y - 230" :footerData="[computedItems]">
               <template v-slot:td="{ row, header, index }">
                  <div style="max-width: 140px" v-if="header.key == 'delivered_quantity'">
                     <v-text-field class="ma-auto" :label="$i18n.t('delivered quantity')"
                        v-if="+row.quantity > +row.pervious_delivered_quantity"
                        :rules="[rules.minValue(Number(row.delivered_quantity || 0), 0, $i18n.t('required')), rules.maxValue(Number(row.delivered_quantity || 0), Number(row.remaining_quantity || 0), $i18n.t('remaining'))]"
                        min="0" :value="Number(row.delivered_quantity)" v-model="row.delivered_quantity" rounded-md
                        hide-details="auto" dense type="number" />
                  </div>
                  <div v-if="header.key == 'actions'">
                     <v-btn @click="editDetail(index, row)" x-small icon fab>
                        <img src="@/assets/img/svg_icons/pen.svg" height="20" />
                     </v-btn>
                     <v-btn @click="removeItem(index, row)" x-small icon fab>
                        <img src="@/assets/img/svg_icons/delete.svg" height="20" />
                     </v-btn>
                  </div>
               </template>
            </DynamicTable>
         </div>

         <!-- End of Form -->


         <v-dialog v-model="proformaDialog" overlay-opacity="0.75" persistent :max-width="370">
            <v-card relative class="pa-7 py-10">
               <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
                  color="grey lighten-1" text @click="proformaDialog = false">
                  <v-icon color="grey lighten-1">mdi-close</v-icon>
               </v-btn>
               <v-row align="center" justify="center">
                  <GenericInput type="select" :lookups="customers" :value="form.customer_id"
                     @input="form.customer_id = $event" label="customer" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="customer_proforma_invoices" :value="form.proforma_invoice_id"
                     @input="form.proforma_invoice_id = $event" label="proforma invoice" :multi="false" :required="false"
                     selected_label="item_details" selected_prop="id" :isLoading="proformaIsLoading" :cols="[12, 12, 12]">
                  </GenericInput>
                  <v-col cols="auto" class="d-flex justify-center">
                     <v-btn class="mx-2" text depressed color="red4" @click="proformaDialog = false" :height="37"
                        :width="120">
                        {{ $t('cancel') }}
                     </v-btn>
                     <v-btn class="mx-2 white--text" :loading="proformaIsLoading" @click="proformaDialog = false" depressed
                        color="blue4" :disabled="!(form.customer_id)" :height="37" :width="120">
                        {{ $t('choose') }}
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-dialog>



         <CustomModal :width="500" :persistent="true" :dialog="closeDeliveryDialog" :buttonOneLoading="pageData.isLoading"
            :buttonTwoMethod="() => closeDeliveryDialog = false" :buttonOneMethod="changeStatus"
            @close="closeDeliveryDialog = $event" :dialogContent="{
               title: form.delivery_note_status == 1 ? $i18n.t('are you sure to close delivery note?') : $i18n.t('are you sure to return delivery note to pending?'),
               img: null,
               details:  $i18n.t('delivery note number') + ' ' + form.m_id ,
               buttonOneTitle: 'yes',
               buttonOneColor: 'blue12',
               buttonOneClass: 'blue1--text',
               buttonTwoTitle: 'no',
               buttonTwoColor: 'white',
               buttonTwoLoading: false,
               buttonTwoClass: 'error--text',
            }" />



      </v-container>
   </section>
</template>



<script>
import DeliveryNoteDetail from './DeliveryNoteDetail.vue';
export default {
   name: "DeliveryNoteControl",
   data: () => ({
      pageData: {
         screen_code: "06-007",
         url: null,
         controlRoute: "/sales/delivery_note-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      formCollapse: false,
      closeDeliveryDialog: false,
      proformaDialog: false,
      proformaIsLoading: false,
      itemsFromProforma: false,
      validItem: false,
      // detail
      detailTableHeader: [],
      detailDialog: false,
      isDetailEdit: false,
      detailIndex: null,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      masterInputs: [],
      dynamicLookups: Object,
      detail: {
         item_id: null,
         unit_id: null,
         price_include_vat: 0,
         price: 0,
         quantity: 1,
         tax_percent: null,
         tax_percent_id: null,
         warehouse_id: null,
         delivered_quantity: null,
         remaining_quantity: null,
      },
      // lockups
      sales_agents: [],
      customers: [],
      warehouses: [],
      taxes: [],
      account_trees: [],
      customer_proforma_invoices: [],
      items: [],
      units: [],

      rules: {
         minValue(value, min, input) {
            return (value || "") >= min || ` ${input}` + ':' + min;
         },
         maxValue(value, max, input) {
            return (value || "") <= max || ` ${input}` + ':' + max;
         }
      },
      form: {
         customer_id: null,
         proforma_invoice_id: null,
         driver_id: null,
         car_number: null,
         delivery_note_date: null,
         delivery_note_time: null,
         delivery_note_description: null,
         sales_agent_id: null,
      },

   }),
   components: {
      DeliveryNoteDetail
   },
   watch: {
      itemsFromProforma() {
         if (this.itemsFromProforma == true) {
            this.tableHeader.pop()
         } else {
            this.tableHeader.push({ text: "delete", key: "id", type: 'delete', classes: "" })
         }
      },
      "pageData.isEdit"() {
         this.tableHeader = [
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "quantity", key: "quantity", type: 'text', classes: "" },
            { text: "count pieces", key: "quantity", type: 'number', classes: "" },
            { text: "warehouse", key: "warehouse_name", type: 'text', classes: "" },
         ];
      },
      "form.proforma_invoice_id"() {
         if (this.pageData.isEdit == false) {
            this.tableRows = [];
            this.tableHeader = [
               { text: "item", key: "item_title", type: 'text', classes: "" },
               { text: "unit", key: "unit_title", type: 'text', classes: "" },
               { text: "price piece", key: "price_without_vat", type: 'float', classes: "" },
               { text: "total quantity price", key: "total_price_without_vat", type: 'float', classes: "" },
               { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
               { text: "total discount", key: "total_discount", type: 'float', classes: "" },
               { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
               { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
               { text: "quantity", key: "quantity", type: 'text', classes: "" },
               { text: "pervious delivered", key: "pervious_delivered_quantity", type: 'number', classes: "" },
               { text: "remaining quantity", key: "remaining_quantity", type: 'number', classes: "" },
               { text: "delivered quantity", key: "delivered_quantity", type: 'slot', classes: "" },
               { text: "delete", key: "id", type: 'delete', classes: "" },
            ];
            this.getProformaItems();
         }
      },
      "form.customer_id"() {
         if (this.pageData.isEdit == false) {
            this.form.customer_name = this.$global.FilterArrayOfObjectElement(this.customers, 'id', this.form.customer_id, 'name')
            this.getCustomerProforma();
         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            row.item_title = this.$global.FilterArrayOfObjectElement(this.items, 'id', row.item_id, 'name');
            row.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', row.unit_id, 'name');
            row.tax_percent = this.$global.FilterArrayOfObjectElement(this.taxes, 'id', row.tax_percent_id, 'tax_percentage');
            row.warehouse_name = this.$global.FilterArrayOfObjectElement(this.warehouses, 'id', row.warehouse_id, 'name');
            row.total_quantity_price = Number(row.quantity) * Number(row.price);
            row.total_vat = row.total_quantity_price * ((Number(row.tax_percent) / 100));
            row.total_with_vat = row.total_vat + row.total_quantity_price;
         });
      }
   },
   computed: {
      totalsCalculations() {
         if (this.computedItems.new_array) {
            const totalQty = this.computedItems.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.quantity);
            }, 0);
            const delivered_quantity = this.computedItems.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.delivered_quantity || 0);
            }, 0);

            const pervious_delivered_quantity = this.computedItems.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.pervious_delivered_quantity || 0);
            }, 0);

            const remaining_quantity = this.computedItems.new_array.reduce((oldValue, newValue) => {
               return Number(+oldValue) + Number(+newValue.remaining_quantity || 0);
            }, 0);
            return {
               quantity: totalQty,
               delivered_quantity: delivered_quantity,
               pervious_delivered_quantity: pervious_delivered_quantity,
               remaining_quantity: remaining_quantity,
            }
         } else {
            return {
               quantity: 0,
               delivered_quantity: 0,
               pervious_delivered_quantity: 0,
               remaining_quantity: 0,
            }
         }
      },
      computedDiscount() {
         return {
            type: this.form.discount_type || 1,
            value: this.form.discount || 0,
            discount_amount: 0,
            percent_value: 0,
            total_price: 0,
         }
      },
      computedItems() {
         if (this.tableRows.length > 0) {
            console.log('PRICE_CALCULATION', this.$global.PRICE_CALCULATION(this.tableRows, this.computedDiscount));
            return this.$global.PRICE_CALCULATION(this.tableRows, this.computedDiscount)
         } else {
            return Object
         }
      },
   },
   mounted() {
      this.pageMainData();
      this.form.delivery_note_time = this.$global.GetCurrentTime();
      this.form.delivery_note_date = this.$global.GetCurrentDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "count pieces", key: "quantity", type: 'text', classes: "" },
            { text: "price piece", key: "price_without_vat", type: 'float', classes: "" },
            { text: "total quantity price", key: "total_price_without_vat", type: 'float', classes: "" },
            { text: "tax percentage", key: "tax_percent", type: 'percentage', classes: "" },
            { text: "total discount", key: "total_discount", type: 'float', classes: "" },
            { text: "total vat", key: "total_vat_after_discount", type: 'float', classes: "" },
            { text: "final total", key: "total_price_after_discount_with_vat", type: 'float', classes: "" },
            { text: "actions", key: "actions", type: 'slot', classes: "" },
         ];


      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`delivery_note/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.master;
                     this.tableRows = response.data.details;
                     this.customers = response.data.customers || [];
                     this.items = response.data.items || [];
                     this.warehouses = response.data.warehouses || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.units = response.data.unit_measurements || [];
                     this.taxes = response.data.taxes || [];
                     this.masterInputs = response.data.master_inputs || []
                     this.dynamicLookups = response.data.dynamic_lookups || Object
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`delivery_note/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.customers = response.data.customers || [];
                     this.items = response.data.items || [];
                     this.sales_agents = response.data.sales_agents || [];
                     this.units = response.data.unit_measurements || [];
                     this.warehouses = response.data.warehouses || [];
                     this.taxes = response.data.taxes || [];
                     Object.keys(response.data.defaults || Object).forEach(key => {
                        this.form[key] = response.data.defaults[key]
                     })
                     if (response.data.master_inputs) {
                        response.data.master_inputs.forEach((input) => {
                           this.form[input.key] = input.value;
                        })
                        this.masterInputs = response.data.master_inputs || []
                        this.dynamicLookups = response.data.dynamic_lookups || Object
                        this.form = { ...this.form }
                     }
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`delivery_note/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_delivery_note/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`delivery_note`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     const src = `${this.$api.serverUrl}print_delivery_note/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
                     this.$api.PrintInvoiceURL(src, response.data);
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      detailAction(detail, isEdit, cancel) {
         console.log(detail, isEdit, cancel);
         if (cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.detailIndex = null;
            this.resetDetail();
         }
         if (isEdit && !cancel) {
            this.updateDetail(detail);
         }
         if (!isEdit && !cancel) {
            this.detailDialog = false;
            this.isDetailEdit = false;
            this.tableRows.push(detail);
            this.resetDetail();
         }
      },
      editDetail(index) {
         this.detail = { ...this.tableRows[index] };
         this.detailIndex = index;
         this.detailDialog = true;
         this.isDetailEdit = true;
      },
      updateDetail(updatedDetail) {
         this.tableRows[this.detailIndex] = { ...updatedDetail }; // update object
         this.tableRows = [...this.tableRows]; // to update computed totals 
         this.detailDialog = false;
         this.isDetailEdit = false;
         this.detailIndex = null;
         this.resetDetail();
      },
      resetDetail() {
         this.detail = {
            item_id: null,
            unit_id: null,
            price: 0,
            price_include_vat: 0,
            quantity: 1,
            tax_percent: null,
            tax_percent_id: null,
            warehouse_id: null,
            delivered_quantity: null,
            remaining_quantity: null,
         }
      },
      removeItem(index) {
         this.tableRows.splice(index, 1);
         this.formCollapse = this.tableRows.length == 0 ? false : this.formCollapse;
      },
      getCustomerProforma() {
         this.proformaIsLoading = true;
         this.$api.POST_METHOD(`get_customer_proforma`, { customer_id: this.form.customer_id }).then((response) => {
            this.proformaIsLoading = false;
            if (response.check) {
               response.data.items.forEach(item => {
                  item.item_details = `${item.id} - ${item.purchase_order_id} - ${item.proforma_invoice_date}`
               });
               this.customer_proforma_invoices = response.data.items;
            }
         })
      },
      getProformaItems() {
         this.proformaIsLoading = true;
         this.$api.POST_METHOD(`get_proforma_data`, { proforma_invoice_id: this.form.proforma_invoice_id }).then((response) => {
            this.proformaIsLoading = false;
            if (response.check) {
               response.data.items.forEach(details => {
                  details.pervious_delivered_quantity = details.delivered_quantity;
                  details.delivered_quantity = 0
               });
               this.tableRows = response.data.items;
               this.form.has_discount = response.data.master.has_discount
               this.form.discount_type = response.data.master.discount_type
               this.form.discount = response.data.master.discount
               this.form.sales_agent_id = response.data.master.sales_agent_id
               this.itemsFromProforma = true;
               console.log(this.tableRows);
               this.proformaDialog = false;
            }
         })
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`delivery_note/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },

      changeStatus() {
         this.pageData.isLoading = true
         this.$api.POST_METHOD(`update_delivery_note_status/${this.form.m_id}`, { status: this.form.delivery_note_status == 1 ? 3 : 1 }).then(() => {
            this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
            this.pageData.isLoading = false
         })
      },
   },
};
</script>
