
import InComingEntity from "@/views/WarehouseModule/InComing/InComingEntity";
import InComingControl from "@/views/WarehouseModule/InComing/InComingControl";
import IncomingDetails from "@/views/WarehouseModule/InComing/IncomingDetails.vue";
import PalletControl from "@/views/WarehouseModule/InComing/PalletControl.vue";
import ItemEntity from "@/views/WarehouseModule/Item/ItemEntity";
import ItemControl from "@/views/WarehouseModule/Item/ItemControl";
import ClientItemEntity from "@/views/WarehouseModule/ClientItem/ClientItemEntity";
import ClientItemControl from "@/views/WarehouseModule/ClientItem/ClientItemControl";
import CustomersEntity from "@/views/WarehouseModule/Customers/CustomersEntity.vue";
import CustomersControl from "@/views/WarehouseModule/Customers/CustomersControl.vue";
import OutgoingEntity from "@/views/WarehouseModule/Outgoing/OutgoingEntity.vue";
import OutgoingControl from "@/views/WarehouseModule/Outgoing/OutgoingControl.vue";
import OutgoingCloseRequestVue from "@/views/WarehouseModule/Outgoing/OutgoingCloseRequest.vue";
import OutgoingRequestControlVue from "../views/WarehouseModule/Outgoing/OutgoingRequestControl.vue";
import OutgoingPalletControl from "../views/WarehouseModule/Outgoing/OutgoingPalletControl.vue";


// warehouse Module Routes:-

export const WarehouseModuleChildrens = [
  // InComing
  {
    path: "/warehouses/incoming",
    name: "InComingEntity",
    component: InComingEntity,
    meta: {
      screen_code: "08-002",
    },
  },
  {
    path: "/warehouses/incoming-control",
    name: "InComingControl",
    component: InComingControl,
    meta: {
      screen_code: "08-002",
    },
  },
  {
    path: "/warehouses/incoming-control/:id",
    name: "InComingControlEdit",
    component: InComingControl,
    meta: {
      screen_code: "08-002",
    },
  },
  {
    path: "/warehouses/incoming-details/:id",
    name: "IncomingDetails",
    component: IncomingDetails,
    meta: {
      screen_code: "08-002",
    },
  },
  {
    path: "/warehouses/pallet-control/",
    name: "PalletControl",
    component: PalletControl,
    meta: {
      screen_code: "08-002",
    },
  },
  {
    path: "/warehouses/pallet-control/:id/:customer",
    name: "PalletControlEdit",
    component: PalletControl,
    meta: {
      screen_code: "08-002",
    },
  },
  // Outgoing
  {
    path: "/warehouses/outgoing",
    name: "OutgoingEntity",
    component: OutgoingEntity,
    meta: {
      screen_code: "08-003",
    },
  },
  {
    path: "/warehouses/outgoing_request-control/:id",
    name: "OutgoingRequestControlVue",
    component: OutgoingRequestControlVue,
    meta: {
      screen_code: "08-003",
    },
  },
  {
    path: "/warehouses/outgoing_pallet-control/:id",
    name: "OutgoingPalletControl",
    component: OutgoingPalletControl,
    meta: {
      screen_code: "08-003",
    },
  },
  {
    path: "/warehouses/outgoing/close-request",
    name: "OutgoingCloseRequest",
    component: OutgoingCloseRequestVue,
    meta: {
      screen_code: "08-003",
    },
  },
  {
    path: "/warehouses/outgoing-control",
    name: "OutgoingControl",
    component: OutgoingControl,
    meta: {
      screen_code: "08-003",
    },
  },
  {
    path: "/warehouses/outgoing-control/:id",
    name: "OutgoingControlEdit",
    component: OutgoingControl,
    meta: {
      screen_code: "08-003",
    },
  },
  // Clients
  {
    path: "/warehouses/clients",
    name: "CustomersEntity",
    component: CustomersEntity,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/warehouses/client-control",
    name: "CustomersControl",
    component: CustomersControl,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/warehouses/client-control/:id",
    name: "CustomersControlEdit",
    component: CustomersControl,
    meta: {
      screen_code: "03-003",
    },
  },
  // Item
  {
    path: "/warehouses/items",
    name: "ItemEntity",
    component: ItemEntity,
    meta: {
      screen_code: "03-004",
    },
  },
  {
    path: "/warehouses/items-control",
    name: "ItemControl",
    component: ItemControl,
    meta: {
      screen_code: "03-004",
    },
  },
  {
    path: "/warehouses/items-control/:id",
    name: "ItemControlEdit",
    component: ItemControl,
    meta: {
      screen_code: "03-004",
    },
  },
  // Client Item
  {
    path: "/warehouses/customer_item",
    name: "ClientItemEntity",
    component: ClientItemEntity,
    meta: {
      screen_code: "08-001",
    },
  },
  {
    path: "/warehouses/customer_item-control",
    name: "ClientItemControl",
    component: ClientItemControl,
    meta: {
      screen_code: "08-001",
    },
  },
  {
    path: "/warehouses/customer_item-control/:id",
    name: "ClientItemControlEdit",
    component: ClientItemControl,
    meta: {
      screen_code: "08-001",
    },
  },
];
