<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" :data="pageData.rows"
            :header="pageData.tableHeader" :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
            :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod" :printValueMethod="printMethod">
            <template v-slot:td="{ row, header }">
              <v-hover v-if="header.key == 'journal_code'" v-slot="{ hover }" close-delay="50" open-delay="50">
                <div style="width: 100%;height: 100%;">
                  <v-btn color="secondary" min-width="80" text v-if="!hover" small>
                    {{ row[header.key] || '-' }}
                  </v-btn>
                  <v-btn @click="openJournal(row)" v-if="hover" class="mx-1" x-small icon>
                    <img src="@/assets/img/svg_icons/fi_eye.svg" height="16" />
                  </v-btn>
                  <v-btn @click="printJournal(row)" class="mx-1"
                    :loading="pageData.printLoading.loading && pageData.printLoading.id == row.m_id" v-if="hover" x-small
                    icon>
                    <img src="@/assets/img/svg_icons/fi_printer.svg" height="16" />
                  </v-btn>
                </div>
              </v-hover>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
    <DailyJournalPrint :printAction="() => print = false" :print="print" :printData="printData"
      :printStaticData="printStaticData" />
  </section>
</template>

<script>
export default {
  name: "ReceiptVoucherEntity",

  data: () => ({
    pageData: {
      screen_code: "02-007",
      url: null,
      controlRoute: "accounting/receipt_voucher-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      printLoading: {
        loading: false,
        id: null,
        key: 'id'
      },
    },
    printData: [],
    printStaticData: Object,
    print: false,
  }),
  components: {},
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "the bond number", key: "id", type: 'text', classes: "" },
        { text: "journal", key: "journal_code", type: 'slot', classes: "", width: 80 },
        { text: "the entity", key: "entity", type: 'text', classes: "" },
        { text: "name", key: "from_type_name", type: 'text', classes: "" },
        { text: "value", key: "amount", type: 'float', classes: "" },
        { text: "description", key: "description", type: 'text', classes: "" },
        { text: "receipt voucher type", key: "receipt_type", type: 'text', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `receipt_voucher?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted receipt_voucher")
                  : this.$store.state.activeScreen.sub_title;
              response.data.items.data.forEach(row => {
                switch (Number(row.from_type)) {
                  case 1:
                    row.entity = this.$i18n.t('account')
                    row.entity_name = row.to_account_tree_name
                    break;

                  case 2:
                    row.entity = this.$i18n.t('treasury')
                    row.entity_name = row.to_treasury_name
                    break;

                  case 3:
                    row.entity = this.$i18n.t('transfer')
                    row.entity_name = row.to_bank_name
                    break;
                  case 4:
                    row.entity = this.$i18n.t('cheq')
                    row.entity_name = row.to_bank_name
                    break;

                  default:
                    break;
                }
              });
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      this.$api
        .POST_METHOD(`receipt_voucher/${id}`, { _method: "delete" })
        .then((response) => {
          if (response.check) {
            this.getData();
          }
        });
    },
    restoreMethod(row) {
      this.$api
        .POST_METHOD(`receipt_voucher_toggle_active/${row.id}`, null)
        .then((response) => {
          if (response.check) {
            this.getData();
          }
        });
    },
    setClickRow() {
      // this.$router.push(`/main/receipt_voucher/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    printMethod(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.id;
      this.$api.PrintPOST('export_receipt_voucher', row.id, 'print').then(() => {
        this.pageData.printLoading.loading = false;
      })
    },
    openJournal(row) {
      window.open('/accounting/daily_journal-control/' + row.daily_journal_m_id, '_blank')
    },
    printJournal(row) {
      this.pageData.printLoading.loading = true;
      this.pageData.printLoading.id = row.m_id;
      this.$api
        .GET_METHOD(`daily_journal/${row.daily_journal_id}`).then((response) => {
          this.pageData.printLoading.loading = false;
          if (response.check) {
            this.printData = response.data.details;
            this.printStaticData = response.data.master;
            this.print = true;
            setTimeout(() => {
              this.printData = [];
              this.printStaticData = Object;
              this.print = false;
              this.pageData.printLoading.loading = false;
            }, 1000);
          }
        });
    },
  },
};
</script>
