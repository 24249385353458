import { render, staticRenderFns } from "./JornalEditDialog.vue?vue&type=template&id=06cf0722&scoped=true&"
import script from "./JornalEditDialog.vue?vue&type=script&lang=js&"
export * from "./JornalEditDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06cf0722",
  null
  
)

export default component.exports