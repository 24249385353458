<template>
   <v-row justify="center">
      <v-dialog v-model="pageData.controlDialog" persistent max-width="788">
         <v-card relative class="pa-5">
            <v-form ref="form" v-model="valid">
               <div v-if="!getDataLoading">
                  <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 20px' : 'right: 20px'" small fab
                     color="grey lighten-1" text @click="closeDialog">
                     <v-icon color="grey lighten-1">mdi-close</v-icon>
                  </v-btn>
                  <v-card-title class="text-h6 text-center font-weight-bold">
                     <h5 class="d-block font-weight-medium ma-auto text--disabled">
                        {{ pageData.controlID ? $t("edit") : $t("add") }} {{ pageData.entityName | capitalize }}
                     </h5>
                  </v-card-title>
                  <v-col cols="12" class="py-4">
                     <v-row align="center" justify="center">
                        <v-col cols="auto">
                           <v-btn depressed @click="closeDialog" text :width="120"
                              class="rounded-lg font-weight-bold white--text" color="error" :loading="saveDataLoading">
                              {{ $t('cancel') }} <v-icon class="mx-1" small left>mdi-close</v-icon>
                           </v-btn>
                        </v-col>

                        <v-col cols="auto">
                           <v-btn color="success" depressed :width="120" :disabled="!valid" @click="save"
                              class="rounded-lg font-weight-bold white--text" :loading="saveDataLoading">{{
                                    pageData.controlID ? $t("save") : $t("create")
                              }}</v-btn>
                        </v-col>
                     </v-row>
                     <v-row align="center" class="mt-6">
                        <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                           :required="true" :isLoading="false" :cols="[12, 6, 6]"></GenericInput>
                        <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                           :required="true" :isLoading="false" :cols="[12, 6, 6]"></GenericInput>
                        <GenericInput type="text" :value="form.location" @input="form.location = $event"
                           label="company location" :required="false" :isLoading="false" :cols="[12, 6, 6]">
                        </GenericInput>

                     </v-row>
                  </v-col>
               </div>
               <v-row justify="center" v-if="getDataLoading">
                  <v-col cols="auto" class="my-10">
                     <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
                        indeterminate>
                     </v-progress-circular>
                     <p class="text-center">{{ $t("please wait") | capitalize }}</p>
                  </v-col>
               </v-row>
            </v-form>


         </v-card>
      </v-dialog>
   </v-row>
</template>



<style scoped lang="scss">
</style>



<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "SalesAreasControl",
   props: {
      backValueMethod: { type: Function },
      isLoading: { default: false },
      pageData: { default: {} },
   },
   computed: {
      isEdit() {
         return this.pageData.controlID ? true : false
      }
   },
   components: {
      GenericInput
   },
   watch: {
      isEdit() {


      }
   },
   data: () => ({
      valid: false,
      getDataLoading: false,
      saveDataLoading: false,
      form: {
         name_en: null,
         name_ar: null,
         location: null,
      }
   }),
   methods: {
      closeDialog() {
         this.pageData.controlDialog = false;
         this.$refs.form.reset()
         // this.backValueMethod(false);
      },
      save() {
         if (this.$refs.form.validate()) {
            this.saveDataLoading = true;
            if (this.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`sales_area/${this.form.id}`, this.form).then((response) => {
                  this.saveDataLoading = false;
                  if (response.check) {
                     this.pageData.controlID = null;
                     this.pageData.controlDialog = false
                     this.$refs.form.reset();
                     this.backValueMethod();
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`sales_area`, this.form).then((response) => {
                  this.saveDataLoading = false;
                  if (response.check) {
                     this.pageData.controlID = null;
                     this.pageData.controlDialog = false;
                     this.$refs.form.reset();
                     this.backValueMethod();
                  }
               })
            }
         }



      },
      getData() {
         switch (this.isEdit) {
            case true:
               if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
                  this.getDataLoading = true;
                  this.$api.GET_METHOD(`sales_area/${this.pageData.controlID}`).then((response) => {
                     this.getDataLoading = false;
                     if (response.check) {
                        this.form = response.data.data;
                        this.$refs.form.validate();
                     }
                  })
               } else {
                  this.pageData.controlDialog = false;
               }
               break;
            default:
               if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
                  this.getDataLoading = true;
                  this.$api.GET_METHOD(`sales_area/create`).then(() => {
                     this.getDataLoading = false;
                  })
               } else {
                  this.pageData.controlDialog = false;
               }

               break;
         }
      }

   },
   mounted() {
      this.getData()
   },
};
</script>
