<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <v-card elevation="0" class="ma-0 pa-0 px-5 transparent rounded-lg">
            <v-row align="center" justify="space-between">
               <v-col cols="auto">
                  <v-breadcrumbs :items="headerData.breadcramb" divider="." class="pa-0"></v-breadcrumbs>
               </v-col>
               <v-col cols="auto">
                  <v-btn
                     small
                     @click="addToFav"
                     :color="favourites.some((fav) => fav.link === 'close-today-view') ? 'accent' : ''"
                     text
                  >
                     <v-icon left>mdi-star</v-icon>
                     <span
                        v-if="!favourites.some((fav) => fav.link === 'close-today-view')"
                     >{{ $t("add this page to favourites") }}</span>
                     <span
                        v-if="favourites.some((fav) => fav.link === 'close-today-view')"
                     >{{ $t("in your favourites") }}</span>
                  </v-btn>
               </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row justify="space-between" align="center">
               <v-col cols="12" sm="auto" class="d-flex justify-center align-center">
                  <h5 class="text-h5 mx-2">
                     <strong>{{ $t(headerData.title) }}</strong>
                     <div
                        class="subtitle-1 text--disabled"
                        v-if="headerData.subTitle"
                     >{{ $t(headerData.subTitle) }}</div>
                  </h5>
               </v-col>
               <v-col cols="12" lg="5" sm="auto" class="d-flex justify-center align-center">
                  <v-text-field
                     v-model="search"
                     append-icon="mdi-magnify"
                     :label="$t('Search')"
                     @click="activeTab = 'all'"
                     single-line
                     hide-details
                     outlined
                     dense
                  ></v-text-field>

                  <v-btn
                     v-if="headerData.auth.addNewAuth"
                     :to="'/' + headerData.auth.controlRoute"
                     class="my-2 mx-2 shadow"
                     color="success"
                  >
                     <v-icon dark left>mdi-plus-circle</v-icon>
                     {{ $t('create') }}
                  </v-btn>
               </v-col>
            </v-row>
         </v-card>
         <!-- End Of Header -->
         <v-row>
            <v-col cols="12" sm="12">
               <DataTableVue
                  :isLoading="isLoading"
                  :options="options"
                  :data="rows"
                  :header="header"
                   :backValueMethod="setClickRow"
                   :editValueMethod="edit"
                   :deleteValueMethod="deleteMethod"
               ></DataTableVue>
            </v-col>
         </v-row>
         <v-row v-if="!isLoading">
            <v-col cols="12" sm="12">
               <Pagination></Pagination>
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>



<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
import DataTableVue from "../../../components/DataTable.vue";
import Pagination from "../../../components/ui/Pagination.vue";
export default {
   name: "CloseTodayEntity",

   data: () => ({
      isLoading: true,
      header: [],
      headerData: {},
      rows: [],
      options: {},
   }),
   components: {
      DataTableVue,
      Pagination,
   },
   computed: {
      ...mapState(["favourites"]),
      translation() {
         return this.$store.state.Settings.translation;
      },
   },
   mounted() {
      this.headerData = {
         title: 'Close Today',
         link: 'close-today-view',
         screen_code: '',
         auth: {
            addNewAuth: false,
            controlRoute: 'close-today-control',
            importExcel: true,
            exportExcel: true,
            importExcelAPI: '',
            exportExcelAPI: '',
         },
         breadcramb: [
            {
               text: this.$i18n.t('Settings'),
               disabled: true,
               href: 'breadcrumbs_dashboard',
            },
            {
               text: this.$i18n.t('Close Today'),
               disabled: true,
               href: '',
            },
         ],
      }
      setTimeout(() => {
         this.isLoading = false;
         this.header = [
            {
               text: this.$i18n.t('code'),
               sortable: true,
               value: 'id',
            },
            {
               text: this.$i18n.t('cache balance'),
               sortable: true,
               value: 'name_en',
            },
            {
               text: this.$i18n.t('total cache'),
               sortable: true,
               value: 'name_ar',
            },
            {
               text: this.$i18n.t('current cache'),
               sortable: true,
               value: 'name_ar',
            },
            {
               text: this.$i18n.t('created_at'),
               sortable: true,
               value: "created_at",
            },

            // { text: this.$i18n.t('actions'), value: 'actions', sortable: false },
         ]
         this.rows = [
            {
               id: 1,
               name_en: "name name 1",
               name_ar: "الاسم الاسم 1",
               created_at: "2021-11-20",
            },
            {
               id: 2,
               name_en: "name name 2",
               name_ar: "الاسم الاسم 2",
               created_at: "2021-11-20",
            },
            {
               id: 3,
               name_en: "name name 3",
               name_ar: "الاسم الاسم 3",
               created_at: "2021-11-20",
            },


         ]
         this.options = {
            print: false,
            delete: false,
            edit: false,
            restore: false,
            view: false,
            switch: false,
            selectRow: false,
            sortBy: 'sort',
            tableHeader: false,
            searchInput: false,
            printButton: false, handleClickRow: false,
         }

         return (this.header, this.rows)
      }, 2000);
   },
   methods: {
      ...mapActions(["addFavourites"]),
      addToFav() {
         const fav = {
            screen_code: "",
            name: "Close Today",
            link: "close-today-view",
         };
         this.addFavourites(fav).finally(() => { });
      },
      setClickRow(){
         // console.log(row);
         // this.$router.push('/')
      },
      deleteMethod(row){
          console.log('delete',row);
      },
      edit(row){
          console.log('edit',row);
          this.$router.push('/close-today-control/'+ row.id)
      }
   },
};
</script>
