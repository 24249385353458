<template>
   <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" :filename="'multi_voucher'" :pdf-quality="3"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="10" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object">
               <template v-slot:pdf_content="{ page, table_data, isLastPage }">

                  <div id="title"  v-if="page == 1">
                     {{ $t('journal number') }} ({{ printStaticData.journal_code  }}) / {{ printStaticData.finance_year_name }}
                  </div>

                  <table width="100%" v-if="page == 1" id="master_table">
                     <tr>
                        <td>{{ $t('date') }} : <span>{{ printStaticData.journal_date }}</span> </td>
                        <td>{{ $t('daily type') }} : <span>{{ printStaticData.daily_type }}</span> </td>
                        <td>{{ $t('document number') }} : <span>{{ printStaticData.document_id }}</span> </td>
                     </tr>
                     <tr>
                        <td colspan="3">{{ $t('description') }} : <span>{{ printStaticData.description }}</span> </td>
                     </tr>
                  </table>
                  <table width="100%" id="details_table">
                     <tr>
                        <td>{{ $t('account number') }} </td>
                        <td>{{ $t('account name') }} </td>
                        <td>{{ $t('debit') }} </td>
                        <td>{{ $t('credit') }} </td>
                     </tr>
                     <tr v-for="(detail) in table_data" :key="detail.m_id">
                        <td width="15%">{{ detail.account_code || '-' }} </td>
                        <td width="55%">
                           <div style="color : blue">{{ detail.account_tree || '-' }}</div>
                           <div style="color : red" v-for="(cost_center, index) in detail.cost_centers" :key="index">
                              {{ cost_center.cost_center_code }} - {{ cost_center.name }}
                           </div>
                           <div> {{ detail.description }}</div>
                        </td>
                        <td width="15%">{{ detail.debit | nonZero }}</td>
                        <td width="15%">{{ detail.credit | nonZero }} </td>
                     </tr>
                     <tr id="total_tr" v-if="isLastPage">
                        <td colspan="2">{{ $t('total') }} </td>
                        <td>{{ computedTotals.debit | nonZero}} </td>
                        <td>{{ computedTotals.credit | nonZero }} </td>
                     </tr>
                  </table>

                  <div id="signature" v-if="isLastPage">
                     {{ $t('an accountant') }}
                  </div>
               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>


   </div>
</template>

<script>
import PrintPDF from "@/components/PrintPDF.vue";
import VueHtml2pdf from 'vue-html2pdf'
export default {
   name: "DailyJournalPrint",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
   },
   computed: {
      computedTotals() {
         if (this.printData.length) {
            const debit = this.printData.reduce((oldValue, newValue) => +oldValue + +newValue.debit, 0);
            const credit = this.printData.reduce((oldValue, newValue) => +oldValue + +newValue.credit, 0);
            return {
               debit: debit,
               credit: credit,
            }
         } else {
            return {
               debit: 0,
               credit: 0,
            }
         }
      }
   },
   components: {
      PrintPDF,
      VueHtml2pdf,
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
#master_table {
   border: 1px solid #000;
   margin: 10px 0 0 0;
   -webkit-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;

   tr {
      background: #fff !important;

      &:last-of-type {
         td {
            border-top: 1px solid #000 !important;
         }
      }
   }

   td {

      text-align: start !important;
      padding: 5px !important;
      font-weight: 800;
      background: #fff !important;

      span {
         color: blue !important;
      }

   }
}

#details_table {
   border: 1px solid #000;
   margin: 10px 0;
   -webkit-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;
   box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.75) !important;


   td {
      text-align: center !important;
      padding: 5px !important;
      background: #fff !important;
      font-weight: 600;
      border-left: 1px solid #000 !important;
      border-bottom: 1px dashed #000 !important;

      span {
         color: blue !important;
      }


      &:nth-of-type(4) {
       //  color: red !important;
         border-left: 0px solid #000 !important;
      }
   }

   tr {
      border-bottom: 1px solid #000 !important;

      &:nth-of-type(1) {
         border-bottom: 1px solid #000 !important;

         td {
            color: #000 !important;
            font-weight: 800;

         }
      }

   }
}

#total_tr {
   border: 1px solid #000 !important;
   border-top: 2px solid #000 !important;

   td {
      color: #000 !important;
      font-weight: 800 !important;
      border-left: 1px solid #000 !important;
      border-bottom: 0px dashed #000 !important;

      &:nth-of-type(2) {
      }

      &:nth-of-type(3) {
         border-left: 0px solid #000 !important;
      }
   }
}
#title{
   text-align: center;
   font-weight: bold;
}
#signature {
   border-top: 1px solid #000;
   width: 300px;
   text-align: center;
   margin-top: 30px;
   display: block;
   margin-inline-start: auto;
   font-size: 12px !important;
   padding: 5px;
}
</style>