<template>
   <section @click="renderUpdate">
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="(valid && rowsValid)"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="end">
                  <v-col cols="12" md="8">
                     <v-row align="center">
                        <GenericInput type="date" :value="form.outgoing_request_date"
                           @input="form.outgoing_request_date = $event" label="date" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
                           <GenericInput type="select" :lookups="clients" :value="form.customer_id"
                           @input="form.customer_id = $event" label="client" :multi="false" :required="true"
                           selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                        </GenericInput>
                        <GenericInput type="textarea" :value="form.outgoing_request_description" :rowsNumber="3"
                           @input="form.outgoing_request_description = $event" label="description" :required="true"
                           :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                        <!-- <v-col cols="12" md="auto">
                           <v-btn color="primary" outlined width="120" :disabled="!(form.customer_id)" @click="searchForItems">{{ $t('Search') }}</v-btn>
                        </v-col> -->
                        <!-- <v-col cols="auto" class="pa-0">
                           <v-divider vertical class="primary-hr"></v-divider>
                        </v-col> -->
      
                     </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-btn color="blue2" class="white--text d-block mi-start-auto" depressed
                     :loading="pageData.searchIsLoading" :disabled="!(form.customer_id)" width="120"
                     @click="itemDialog = true">{{ $t('add item') }}</v-btn>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>
         <!-- <v-form ref="newItem" v-model="validItem" class="pa-2">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <v-col cols="12" md="9">
                     <v-row align="center" class="justify-xl-start" justify="space-between">
                        <GenericInput type="select" :lookups="items" :value="newItem.item_id"
                           @input="newItem.item_id = $event" label="item" :multi="false" :required="true"
                           selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 4]">
                        </GenericInput>

                        <GenericInput type="select" :lookups="[{ id: 1, name: '0-15' }, { id: 2, name: '15-30' },{ id: 3, name: '45-50' }]"
                           :value="newItem.batch_number" @input="newItem.batch_number = $event" label="batch number"
                           :multi="false" :required="true" selected_label="name" selected_prop="id"
                           :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>

                        <GenericInput type="date" :value="newItem.expiry_date" @input="newItem.expiry_date = $event"
                           label="expiry date" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                        </GenericInput>
                        <GenericInput type="select" :lookups="units" :value="newItem.unit_id"
                           @input="newItem.unit_id = $event" label="unit" :multi="false" :required="true"
                           selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                           :cols="[12, 6, 4]">
                        </GenericInput>
                        <GenericInput type="number" :value="newItem.count" @input="newItem.count = $event" label="qty"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]"></GenericInput>

                        <v-col cols="12" md="4">
                           <v-btn block class="d-block white--text mi-start-auto" :disabled="!validItem" depressed
                              color="blue3" @click="addItem" :height="37">
                              {{ $t('add item') }}
                           </v-btn>
                        </v-col>
                     </v-row>
                  </v-col>
                  <v-col cols="12" md="">
                     <v-card class="shadow-none yellow12 pa-5">
                        <div class="subtitle-1 text-center yellow1--text">{{ $t('inStock') | capitalize }}</div>
                        <div class="d-flex justify-center">
                           <div class="subtitle-1 mx-2 font-weight-bold yellow1--text">{{ 1000 | float }}</div>
                           <div class="subtitle-1 mx-2 font-weight-bold yellow1--text">{{ $t('pallet') | capitalize }}</div>
                        </div>
                        <div class="d-flex justify-center">
                           <div class="subtitle-1 mx-2 font-weight-bold yellow1--text">{{ 1100 | float }}</div>
                           <div class="subtitle-1 mx-2 font-weight-bold yellow1--text">{{ $t('case') | capitalize }}</div>
                        </div>
                        <div class="d-flex justify-center">
                           <div class="subtitle-1 mx-2 font-weight-bold yellow1--text">{{ 1200 | float }}</div>
                           <div class="subtitle-1 mx-2 font-weight-bold yellow1--text">{{ $t('pieces') | capitalize }}</div>
                        </div>
                     </v-card>
                  </v-col>
               </v-row>
            </v-card>
         </v-form> -->

         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="tableRows" :header="tableHeader" :deleteValueMethod="removeItem">
               <template v-slot:td="{ row, header }">
                  <span @click="renderUpdate">

                     <GenericInput type="select" v-if="header.key == 'unit_id'" :lookups="unit_measurements"
                        :value="row[header.key]" @input="row[header.key] = $event" label="unit measurement"
                        :multi="false" :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]" />
                     <GenericInput type="select" v-if="header.key == 'type_id'" :lookups="types"
                        :value="row[header.key]" @input="row[header.key] = $event" label="type" :multi="false"
                        :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]" />
                     <GenericInput type="number" v-if="header.key == 'quantity'" :value="row[header.key]"
                        @input="row[header.key] = $event" label="quantity" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                  </span>
               </template>
            </DynamicTable>
         </div>
         <AddItemModal :items="items" :dialog="itemDialog" :itemsLoading="pageData.editIsLoading"
            :backValueMethod="addItem" />
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
import AddItemModal from './AddItemModal.vue';
export default {
   name: "OutgoingControl",
   data: () => ({
      pageData: {
         screen_code: "08-003",
         url: null,
         controlRoute: "/warehouses/outgoing-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         searchIsLoading: false,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      rowsValid: false,
      validItem: false,
      itemDialog: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      clients: [],
      unit_measurements: [],
      types: [],
      items: [],
      form: {
         customer_id: null,
         outgoing_request_date: null,
         outgoing_request_description: null,
         items: [],
      },
      newItem: {
         item_id: null,
         unit_id: null,
         barcode: null,
         quantity: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
      DynamicTable,
      AddItemModal
   },
   computed: {
   },
   watch: {
      'form.customer_id'() {
         if (this.pageData.isEdit == false) {
            this.tableRows = []
         }
         this.searchForItems()
      },
      tableRows() {
         this.rowsValid = this.tableRows.every(row => {
            return (row.unit_id && row.type_id && row.quantity)
         });
      }
   },
   mounted() {
      this.pageMainData()
      this.getData();
   },
   methods: {
      renderUpdate() {
         this.rowsValid = this.tableRows.every(row => {
            return (row.unit_id && row.type_id && row.quantity)
         });
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$i18n.t("outgoing request") : this.$i18n.t("add") + " " + this.$i18n.t("outgoing request");
         if (this.$route.params.id) {
            this.tableHeader = [
               { text: "item", key: "customer_item_name", type: 'text', classes: "" },
               { text: "barcode", key: "barcode", type: 'text', classes: "" },
               { text: "quantity", key: "quantity", type: 'slot', classes: "" },
               { text: "unit measurement", key: "unit_id", type: 'slot', classes: "" },
               { text: "type", key: "type_id", type: 'slot', classes: "" },
               { text: "delete", key: "id", type: 'delete', classes: "" },
            ];

         } else {
            this.tableHeader = [
               { text: "item", key: "customer_item_name", type: 'text', classes: "" },
               { text: "barcode", key: "barcode", type: 'text', classes: "" },
               { text: "storage temperature", key: "storage_temperature_degree", type: 'text', classes: "" },
               { text: "quantity", key: "quantity", type: 'slot', classes: "" },
               { text: "unit measurement", key: "unit_id", type: 'slot', classes: "" },
               { text: "type", key: "type_id", type: 'slot', classes: "" },
               { text: "delete", key: "id", type: 'delete', classes: "" },
            ];
         }
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`outgoing_request/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.master;
                     this.tableRows = response.data.details;
                     this.clients = response.data.customers || [];
                     this.unit_measurements = response.data.unit_measurements || [];
                     this.types = response.data.types || [];
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`outgoing_request/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.clients = response.data.customers || [];
                     this.unit_measurements = response.data.unit_measurements || [];
                     this.types = response.data.types || [];
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.form.customer_items = this.tableRows;
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`outgoing_request/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`outgoing_request`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      addItem(row, status) {
         if (row) {
            row.quantity = 1;
            row.type_id = 1;
            row.item_id = row.id;
            row.unit_id = row.unit_measurement_id;
            this.tableRows.push(row);
         }
         this.itemDialog = status;
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },
      searchForItems() {
         this.pageData.searchIsLoading = true;
         this.$api.GET_METHOD(`get_customer_items?customer_id=${this.form.customer_id}`).then((response) => {
            this.pageData.searchIsLoading = false;
            if (response.check) {
               this.items = response.data.items || [];
               if (this.pageData.isEdit == false) {
                  this.itemDialog = true;
               }
            }
         })
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`outgoing_request/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
