<template>
  <div class="text-center" id="appBarMenu">
    <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition" bottom
      max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon height="34" width="34" class="shadow-lg">
          <img src="@/assets/img/svg_icons/money.svg"  height="22" alt="wms">
        </v-btn>

      </template>
      <v-card width="300" class="py-0 mt-5 rounded-lg">
        <v-list-item-content class="justify-center">
          <div>
            <v-btn block depressed rounded-sm text v-for="(year, index) in $store.state.years"
              :color="year.id == $store.state.current_year ? 'primary' : 'secondary'" :key="index"
              @click="setActiveYear(year.id)">
              {{ year.name }}
              <v-icon v-if="year.id == $store.state.current_year" small right>mdi-check</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </div>
</template>
<style  scoped>
.v-menu__content {
  box-shadow: 0px 21px 32px rgba(0, 0, 0, 0.05) !important;
  top: 3rem !important;
  border-radius: 0px 0px 8px 8px;
}

#appBarMenu .v-menu__content {
  box-shadow: none !important;
  top: 64px !important;
}

#appBarMenu.v-menu__content .v-card {
  background-color: #fafafa !important;
}
</style>


<script>

export default {
  name: "FiscalYearMenu",
  computed: {

  },
  data: () => ({
    activeYear: null
  }),
  mounted() {
    this.getActiveYear()
  },

  methods: {
    getActiveYear() {
      this.$store.state.years.forEach(year => {
        if (year.id == this.$store.state.current_year) {
          this.activeYear = year.name
        }
      });
    },
    setActiveYear(id) {
      localStorage.setItem('yearid', id)
      this.$store.state.current_year = id;
      this.$api.SET_USER_DEFAULTS()
      this.getActiveYear()
    }
  },
};
</script>
