<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :showSearch="false" :getData="getData" :createBtnText="$i18n.t('update')"
        actionBtnText="filter" :showDeleted="false" :createBtn="false" :actionBtn="!showFilter" :actionBtnValid="true"
        actionBtnColor="transparent" :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true"
        actionBtnClass="blue4--text" :getSearch="getSearch">
        <template v-slot:rightSide>
          <v-btn depressed @click="cancelControl" class="my-2 mx-2 rounded-lg error--text" :height="37" color="white">
            {{ $t('cancel') }} <v-icon right>
              mdi-close</v-icon>
          </v-btn>
          <v-btn color="green1" v-if="allItems.length > 0 && !pageData.isEdit" class="white--text mx-2"
            @click="saveStocktaking" :loading="saveStocktakingLoading" depressed>{{ $t('update') }}</v-btn>
        </template>
      </EntityHeader>
      <!-- End Of Header -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0" v-if="!pageData.isLoading">
          <v-card class="shadow pa-7">
            <v-row align="center" class="" v-if="pageData.isEdit">
              <v-col cols=auto>
                {{ $t('warehouse') }} : {{ warehouse_name }}
              </v-col>
              <v-col cols=auto>
                {{ $t('stocktaking date') }} : {{ stocktaking_date }}
              </v-col>
            </v-row>
            <v-row align="center" justify="space-between" class="" v-if="!pageData.isEdit">
              <GenericInput type="date" :value="stocktaking_date" :maxDate="today" @input="stocktaking_date = $event"
                label="date" :required="false" :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 4]">
              </GenericInput>

              <GenericInput type="select" :clearable="true" :lookups="warehouses" :value="warehouse_id"
                @input="warehouse_id = $event;" :multi="false" :required="false" selected_label="name" label="warehouse"
                selected_prop="id" :hide-details="true" :isLoading="pageData.isLoading" :cols="[12, 6, 4]" />
              <v-col cols="auto">
                <v-btn depressed width="120" color="success" :disabled="!(allItems.length > 0)" class="mx-1"
                  @click="newItemDialog = true">
                  {{ $t("add item") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" :loading="pageData.isLoading"
                  :disabled="!(warehouse_id && stocktaking_date)" outlined class="mx-1 white--text" @click="getSearch">
                  {{ $t("Search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" deleteProperty="id" :data="stockItems"
            :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData">
            <template v-slot:td="{ row, header, index }">
              <div style="max-width: 140px">
                <v-text-field class="ma-auto" :label="$i18n.t('new stock value')"
                  v-if="header.key == 'stocktaking_balance'" min="0" :value="Number(row.stocktaking_balance)"
                  v-model="row.stocktaking_balance" @input="checkChange(row, index)" rounded-md hide-details="auto"
                  dense type="number" />
              </div>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>



      <v-dialog v-model="newItemDialog" overlay-opacity="0.75" persistent :max-width="370">
        <v-card relative class="pa-7 py-10">
          <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
            color="grey lighten-1" text @click="newItemDialog = false">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
          </v-btn>
          <v-form ref="newItem" v-model="newItemValid">
            <v-row align="center" justify="center">
              <GenericInput type="select" :lookups="balanceZeroItems" :value="newItem.item_id"
                @input="newItem.item_id = $event" label="item" :multi="false" :required="true"
                selected_label="item_name" selected_prop="item_id" :isLoading="false" :cols="[12, 12, 12]">
              </GenericInput>
              <GenericInput type="select" :disabled="!(newItem.item_id)" v-if="newItem.item_id"
                :lookups="$global.FilterArrayOfObjectElement(balanceZeroItems, 'item_id', newItem.item_id, 'item_units')"
                :value="newItem.unit_id" @input="newItem.unit_id = $event" label="unit" :multi="false" :required="true"
                selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
              </GenericInput>
              <GenericInput type="number" :value="newItem.stocktaking_balance"
                @input="newItem.stocktaking_balance = $event" label="new stock value" :required="true"
                :isLoading="false" :cols="[12, 12, 12]">
              </GenericInput>
              <v-col cols="auto" class="d-flex justify-center">
                <v-btn class="mx-2" text depressed color="red4" @click="newItemDialog = false" :height="37"
                  :width="120">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn class="mx-2 white--text" @click="addItem" depressed color="blue4" :disabled="!(newItemValid)"
                  :height="37" :width="120">
                  {{ $t('add') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>

    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
  name: "StocktakingControl",

  data: () => ({
    pageData: {
      screen_code: "03-009",
      url: null,
      controlRoute: "inventory/stocktaking-control",
      entityRouteName: "StocktakingEntity",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
    showFilter: true,
    newItemDialog: false,
    saveStocktakingLoading: false,
    newItemValid: false,
    warehouse_id: null,
    stocktaking_date: null,
    today: null,
    warehouses: [],
    warehouse_name: null,
    units: [],
    allItems: [],
    balanceZeroItems: [],
    stockItems: [],
    newItem: {
      item_id: null,
      item_title: null,
      unit_id: null,
      unit_title: null,
      stock: null,
      stocktaking_balance: null,
      remaining: null,
    },
  }),
  components: {
    EntityHeader,
    DynamicTable,
    GenericInput
  },
  watch: {
    // stockItems(){
    //   this.stockItems.forEach(item => {
    //    return item.remaining =  Number(item.balance) - Number(item.stocktaking_balance)
    //   });
    // }
  },
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.stocktaking_date = this.$global.GetCurrentDate();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.pageData.entityName = this.$i18n.t("show") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "#", key: "stock_id", type: 'text', classes: "" },
        { text: "item", key: "item_name", type: 'text', classes: "" },
        { text: "unit", key: "unit_title", type: 'text', classes: "" },
        { text: "balance", key: "stock", type: 'text', classes: "" },
        { text: "new stock value", key: "stocktaking_balance", type: 'slot', classes: "" },
        { text: "changes", key: "remaining", type: 'text', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch() {
      this.allItems = [];
      this.balanceZeroItems = [];
      this.stockItems = [];
      this.pageData.isLoading = true;
      this.$api.GET_METHOD(`get_stock_items?warehouse=${this.warehouse_id}&stocktaking_date=${this.stocktaking_date}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            response.data.items.forEach(item => {
              console.log('item', item);
              item.item_units = []
              item.item_units.push(
                {
                  id: item.small_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', item.small_unit_id, 'name')
                },
                {
                  id: item.mid_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', item.mid_unit_id, 'name')
                },
                {
                  id: item.big_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', item.big_unit_id, 'name')
                },
              );
              switch (item.stock) {
                case 0:
                  this.balanceZeroItems.push(item)
                  break;
                default:

                  switch (item.default_report_unit_type) {
                    case 1:
                      item.stock = item.stock_in_small.toFixed(2);
                      item.stocktaking_balance = item.stock_in_small.toFixed(2);
                      item.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', item.small_unit_id, 'name');
                      break;
                    case 2:
                      item.stock = item.stock_in_mid.toFixed(2);
                      item.stocktaking_balance = item.stock_in_mid.toFixed(2);
                      item.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', item.mid_unit_id, 'name');
                      break;
                    case 3:
                      item.stock = item.stock_in_big.toFixed(2);
                      item.stocktaking_balance = item.stock_in_big.toFixed(2);
                      item.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', item.big_unit_id, 'name');
                      break;
                    default:
                      break;
                  }
                  this.stockItems.push(item)
                  break;
              }

            });


            this.allItems = response.data.items;
          }
        });
    },

    getData() {
      if (this.$route.params.id) {
        if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
          this.pageData.isEdit = true;
          this.pageData.editIsLoading = true;
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`stocktaking/${this.$route.params.id}`)
            .then((response) => {
              this.pageData.isLoading = false;
              if (response.check) {
                this.warehouses = response.data.warehouses || [];
                this.units = response.data.unit_measurements || [];
                this.stockItems = response.data.details || [];
                this.stocktaking_date = response.data.master.stocktaking_date;
                this.warehouse_id = response.data.master.warehouse_id;
                this.warehouse_name = response.data.master.warehouse_name;
                this.pageData.tableHeader = [
                  { text: "#", key: "d_id", type: 'text', classes: "" },
                  { text: "item", key: "item_name", type: 'text', classes: "" },
                  { text: "unit", key: "unit_name", type: 'text', classes: "" },
                  { text: "balance", key: "current_balance", type: 'text', classes: "" },
                  { text: "new stock value", key: "stocktaking_balance", type: 'text', classes: "" },
                  { text: "changes", key: "remaining", type: 'text', classes: "" },
                ];
                response.data.details.forEach(item => {
                  item.remaining = Number(item.current_balance) - Number(item.stocktaking_balance);
                  if (item.remaining > 0) {
                    item.rowClass = 'red12';
                    item.remaining = Math.abs(item.remaining) + ' ' + this.$i18n.t('missing')
                  }
                  else if (item.remaining == 0) {
                    item.rowClass = '';
                    item.remaining = this.$i18n.t('no change')
                  }
                  else {
                    item.rowClass = 'green11';
                    item.remaining = Math.abs(item.remaining) + ' ' + this.$i18n.t('pluse')
                  }
                });
                response.data.items.forEach(item => {
                  item.remaining = Number(item.current_balance) - Number(item.stocktaking_balance);

                  switch (item.stock) {
                    case 0:
                      this.balanceZeroItems.push(item)
                      break;
                    default:

                      break;
                  }
                });

                this.allItems = response.data.items;
              }
            });
        } else {
          this.$router.push('/')
        }
      }
      else {
        if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`stocktaking/create`)
            .then((response) => {
              this.pageData.isLoading = false;
              if (response.check) {
                this.warehouses = response.data.warehouses || [];
                this.units = response.data.unit_measurements || [];
              }
            });
        } else {
          this.$router.push('/')
        }

      }

    },

    addItem() {
      this.newItem.item_name = this.$global.FilterArrayOfObjectElement(this.balanceZeroItems, 'item_id', this.newItem.item_id, 'item_name');
      this.newItem.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', this.newItem.unit_id, 'name');
      this.newItem.stock = this.newItem.stocktaking_balance;
      this.stockItems.push(this.newItem);
      this.newItemDialog = false;
      this.newItem = {
        item_id: null,
        item_title: null,
        unit_id: null,
        unit_title: null,
        stocktaking_balance: null,
        stock: null,
        remaining: null,
      }
    },
    checkChange(row, index) {
      console.log(row);
      this.stockItems[index].remaining = Number(this.stockItems[index].stock) - Number(this.stockItems[index].stocktaking_balance)
      if (this.stockItems[index].remaining > 0) {
        this.stockItems[index].rowClass = 'red12';
        this.stockItems[index].remaining = Math.abs(this.stockItems[index].remaining) + ' ' + this.$i18n.t('missing')
      }
      else if (this.stockItems[index].remaining == 0) {
        this.stockItems[index].rowClass = '';
        this.stockItems[index].remaining = this.stockItems[index].remaining = this.$i18n.t('no change')
      }
      else {
        this.stockItems[index].rowClass = 'green11';
        this.stockItems[index].remaining = Math.abs(this.stockItems[index].remaining) + ' ' + this.$i18n.t('pluse')
      }
    },
    cancelControl() {
      this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
    },
    saveStocktaking() {
      this.saveStocktakingLoading = true;
      // this.stockItems.forEach(item => {
      // });
      const requestBody = {
        items: this.stockItems,
        warehouse_id: this.warehouse_id,
        stocktaking_date: this.stocktaking_date,
      };
      this.$api.POST_METHOD(`stocktaking`, requestBody).then((response) => {
        this.saveStocktakingLoading = false;
        if (response.check) {
          this.$router.push(this.pageData.url);
          // this.allItems = [];
          // this.balanceZeroItems = [];
          // this.stockItems = [];
          // this.getData();
          // this.getSearch();
        }
      })
    }
  },
};
</script>
