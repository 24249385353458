<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="select" :lookups="stock_transactions_types"
                     :value="form.stock_transaction_type_id" @input="form.stock_transaction_type_id = $event"
                     label="transaction type" :multi="false" :required="true" selected_label="title"
                     selected_prop="stock_transaction_type_id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="date" :value="form.stock_transaction_date"
                     @input="form.stock_transaction_date = $event" label="date" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="warehouses" :value="form.warehouse_id"
                     @input="form.warehouse_id = $event" label="warehouse" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <v-col cols="12" md="6" lg="3" class="d-flex justify-center">
                     <div class="body-1 font-weight-regular gray7--text  mx-1">{{ $t('transaction number') | capitalize
                     }} :
                     </div>
                     <div class="body-1 font-weight-bold mx-1"> {{ form.m_id || '--' }} </div>
                  </v-col>
                  <GenericInput type="textarea" solo :value="form.stock_transaction_description"
                     @input="form.stock_transaction_description = $event" label="description" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>


               </v-row>
            </v-card>
         </v-form>
         <v-form ref="newItem" v-model="validItem" class="pa-2">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="select" :lookups="items" :value="newItem.item_id"
                     @input="newItem.item_id = $event" label="item" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                  </GenericInput>
                  <GenericInput type="select" :disabled="!(newItem.item_id)" :lookups="newItem.item_units || []"
                     :value="newItem.unit_id" @input="newItem.unit_id = $event" label="unit" :multi="false"
                     :required="true" selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 6, 2]">
                  </GenericInput>

                  <GenericInput type="number" :value="newItem.price" :disabled="!newItem.item_id"
                     @input="newItem.price = $event" label="price" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 2]"></GenericInput>
                  <GenericInput type="number" :value="newItem.quantity" @input="newItem.quantity = $event" label="qty"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 'auto']"></GenericInput>

                  <v-col cols="12" md="12" lg="3" xl="5">
                     <v-btn outlined style="background-color:  #e6ecff ;" class="d-block mi-start-auto"
                        :disabled="!(validItem && newItem.price >= newItem.min_sale_price)" depressed color="blue2"
                        :width="80" @click="addItem" :height="37">
                        {{ $t('add') }}
                     </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="newItem.min_sale_price > newItem.price">
                     <v-alert type="error" dense class="rounded-lg" outlined>
                        {{ $t('item min sale price') }} {{ newItem.min_sale_price | float }} {{ $t('ryal') }}
                     </v-alert>
                  </v-col>
               </v-row>
            </v-card>
         </v-form>

         <div class="pa-2">
            <DynamicTable :isLoading="false" :data="tableRows" :header="tableHeader" :footerData="footerData"
               :deleteValueMethod="removeItem" />
         </div>

         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
import DynamicTable from '@/components/DynamicTable.vue';
export default {
   name: "StockTransactionControl",
   data: () => ({
      pageData: {
         screen_code: "03-007",
         url: null,
         controlRoute: "/inventory/stock_transaction-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      validItem: false,
      tableHeader: [],
      tableRows: [],
      tableOption: {},
      warehouses: [],
      items: [],
      units: [],
      stock_transactions_types: [],
      footerData: [{
         item_title: null,
         unit_title: null,
         quantity: 0,
         price: 0,
         total_price: 0,
      }],
      form: {
         stock_transaction_date: null,
         stock_transaction_description: null,
         stock_transaction_type_id: null,
         warehouse_id: null,
      },
      newItem: {
         item_id: null,
         unit_id: null,
         price: null,
         quantity: null,
      }
   }),
   watch: {
      "newItem.item_id"() {
         this.newItem.unit_id = null;
         if (this.newItem.item_id) {
            var getItem = this.$global.FilterArrayOfObject(this.items, 'id', this.newItem.item_id)[0];

            this.newItem.item_units = [];
            this.newItem.item_units.push(
               {
                  id: getItem.small_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.small_unit_id, 'name')
               },
               {
                  id: getItem.mid_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.mid_unit_id, 'name')
               },
               {
                  id: getItem.big_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.big_unit_id, 'name')
               },
            );
            this.newItem.price = getItem.sale_price;
            this.newItem.min_sale_price = getItem.min_sale_price;
            console.log('this.newItem', this.newItem);
         } else {
            this.newItem.price = null;
         }
      },
      tableRows() {
         this.tableRows.forEach(row => {
            row.item_title = this.$global.FilterArrayOfObjectElement(this.items, 'id', row.item_id, 'name');
            row.unit_title = this.$global.FilterArrayOfObjectElement(this.units, 'id', row.unit_id, 'name');
            row.total_price = row.price * row.quantity;


            this.footerData[0].item_title = this.$i18n.t('total');
            this.footerData[0].quantity = this.totalQty;
            this.footerData[0].price = this.totalPrice;
            this.footerData[0].total_price = this.totalQuantityPrice;
         });
      }
   },
   components: {
      ControlHeader,
      GenericInput,
      DynamicTable
   },
   computed: {
      totalPrice() {
         return this.tableRows.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(+newValue.price);
         }, 0);
      },
      totalQuantityPrice() {
         return this.tableRows.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(newValue.quantity) * Number(newValue.price);
         }, 0);
      },
      totalQty() {
         return this.tableRows.reduce((oldValue, newValue) => {
            return Number(+oldValue) + Number(+newValue.quantity);
         }, 0);
      },
   },
   mounted() {
      this.pageMainData();
      this.form.stock_transaction_date = this.$global.GetCurrentDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;

         this.tableHeader = [
            { text: "item", key: "item_title", type: 'text', classes: "" },
            { text: "unit", key: "unit_title", type: 'text', classes: "" },
            { text: "price", key: "price", type: 'float', classes: "" },
            { text: "count pieces", key: "quantity", type: 'pieces', classes: "" },
            { text: "total price", key: "total_price", type: 'price', classes: "" },
            { text: "delete", key: "id", type: 'delete', classes: "" },

         ];
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`stock_transaction/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.form = response.data.master;
                     this.tableRows = response.data.details;
                     this.items = response.data.items;
                     this.units = response.data.unit_measurements;
                     this.warehouses = response.data.warehouses;
                     this.stock_transactions_types = response.data.stock_transactions_types;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`stock_transaction/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.items = response.data.items;
                     this.units = response.data.unit_measurements;
                     this.warehouses = response.data.warehouses;
                     this.stock_transactions_types = response.data.stock_transactions_types;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },

      save() {
         this.form.items = this.tableRows;
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`stock_transaction/${this.form.m_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`stock_transaction`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      addItem() {
         this.tableRows.push(this.newItem);
         this.newItem = {
            item_id: null,
            unit_id: null,
            price: null,
            count: null,
         }
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`stock_transaction/${this.form.m_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
