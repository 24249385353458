// Store Module Routes:-
import CustomersEntity from "@/views/SalesModule/Codes/Customers/CustomersEntity";
import CustomersControl from "@/views/SalesModule/Codes/Customers/CustomersControl";
import CustomerTypeEntity from "@/views/SalesModule/Codes/CustomerType/CustomerTypeEntity";
import SalesRepresentativesEntity from "@/views/SalesModule/Codes/SalesRepresentatives/SalesRepresentativesEntity";
import SalesAreasEntity from "@/views/SalesModule/Codes/SalesAreas/SalesAreasEntity";
import QuotationEntity from "@/views/SalesModule/Transactions/Quotation/QuotationEntity";
import QuotationControl from "@/views/SalesModule/Transactions/Quotation/QuotationControl";
import ProformaInvoiceEntity from "@/views/SalesModule/Transactions/ProformaInvoice/ProformaInvoiceEntity";
import ProformaInvoiceControl from "@/views/SalesModule/Transactions/ProformaInvoice/ProformaInvoiceControl";
import DeliveryNoteEntity from "@/views/SalesModule/Transactions/DeliveryNote/DeliveryNoteEntity";
import DeliveryNoteControl from "@/views/SalesModule/Transactions/DeliveryNote/DeliveryNoteControl";
import SalesInvoicesEntity from "@/views/SalesModule/Transactions/SalesInvoices/SalesInvoicesEntity";
import SalesInvoicesControl from "@/views/SalesModule/Transactions/SalesInvoices/SalesInvoicesControl";
import ExpenseInvoiceEntity from "@/views/SalesModule/Transactions/ExpenseInvoice/ExpenseInvoiceEntity.vue";
import ExpenseInvoiceControl from "@/views/SalesModule/Transactions/ExpenseInvoice/ExpenseInvoiceControl.vue";
import NoticeCreditorEntity from "@/views/SalesModule/Transactions/NoticeCreditor/NoticeCreditorEntity.vue";
import NoticeCreditorControl from "@/views/SalesModule/Transactions/NoticeCreditor/NoticeCreditorControl.vue";
import NoticeDebtorEntity from "@/views/SalesModule/Transactions/NoticeDebtor/NoticeDebtorEntity.vue";
import NoticeDebtorControl from "@/views/SalesModule/Transactions/NoticeDebtor/NoticeDebtorControl.vue";
import SalesReportEntity from "@/views/SalesModule/Reports/SalesReport/SalesReportEntity.vue";
import FullReportEntity from "@/views/SalesModule/Reports/FullReport/FullReportEntity.vue";
import CustomerStatementEntity from "@/views/SalesModule/Reports/CustomerStatement/CustomerStatementEntity.vue";
import CustomersBalancesEntity from "@/views/SalesModule/Reports/CustomersBalances/CustomersBalancesEntity.vue";
import DebtAgeEntity from "@/views/SalesModule/Reports/DebtAge/DebtAgeEntity.vue";
import WeaklyReportEntity from "@/views/SalesModule/Reports/WeaklyReport/WeaklyReportEntity.vue";
import MonthlyReportEntity from "@/views/SalesModule/Reports/MonthlyReport/MonthlyReportEntity.vue";
import PurchaseVendorStatementEntity from "@/views/SalesModule/Reports/PurchaseVendorStatement/PurchaseVendorStatementEntity.vue";
import ShiftReportEntity from "@/views/SalesModule/Reports/ShiftReport/ShiftReportEntity.vue";
import ShiftReportControl from "@/views/SalesModule/Reports/ShiftReport/ShiftReportControl.vue";

export const SalesModuleChildrens = [
  // Customers
  {
    path: "/sales/customer",
    name: "CustomersEntity",
    component: CustomersEntity,
    meta: {
      screen_code: "06-003",
    },
  },
  {
    path: "/sales/customer-control",
    name: "CustomersControlADD",
    component: CustomersControl,
    meta: {
      screen_code: "06-003",
    },
  },
  {
    path: "/sales/customer-control/:id",
    name: "CustomersControlEdit",
    component: CustomersControl,
    meta: {
      screen_code: "06-003",
    },
  },
  // Customer Type
  {
    path: "/sales/customer_type",
    name: "CustomerTypeEntity",
    component: CustomerTypeEntity,
    meta: {
      screen_code: "06-002",
    },
  },
  // Sales Representatives
  {
    path: "/sales/sales_representatives",
    name: "SalesRepresentativesEntity",
    component: SalesRepresentativesEntity,
    meta: {
      screen_code: "06-001",
    },
  },
  // Sales Area
  {
    path: "/sales/sales_area",
    name: "SalesAreasEntity",
    component: SalesAreasEntity,
    meta: {
      screen_code: "06-004",
    },
  },
  // Quotation
  {
    path: "/sales/quotation",
    name: "QuotationEntity",
    component: QuotationEntity,
    meta: {
      screen_code: "06-005",
    },
  },
  {
    path: "/sales/quotation-control",
    name: "QuotationControlADD",
    component: QuotationControl,
    meta: {
      screen_code: "06-005",
    },
  },
  {
    path: "/sales/quotation-control/:id",
    name: "QuotationControlEdit",
    component: QuotationControl,
    meta: {
      screen_code: "06-005",
    },
  },
  // Proforma Invoice
  {
    path: "/sales/proforma",
    name: "ProformaInvoiceEntity",
    component: ProformaInvoiceEntity,
    meta: {
      screen_code: "06-006",
    },
  },
  {
    path: "/sales/proforma-control",
    name: "ProformaInvoiceControlADD",
    component: ProformaInvoiceControl,
    meta: {
      screen_code: "06-006",
    },
  },
  {
    path: "/sales/proforma-control/:id",
    name: "ProformaInvoiceControlEdit",
    component: ProformaInvoiceControl,
    meta: {
      screen_code: "06-006",
    },
  },
  // Delivery Note
  {
    path: "/sales/delivery_note",
    name: "DeliveryNoteEntity",
    component: DeliveryNoteEntity,
    meta: {
      screen_code: "06-007",
    },
  },
  {
    path: "/sales/delivery_note-control",
    name: "DeliveryNoteControlADD",
    component: DeliveryNoteControl,
    meta: {
      screen_code: "06-007",
    },
  },
  {
    path: "/sales/delivery_note-control/:id",
    name: "DeliveryNoteControlEdit",
    component: DeliveryNoteControl,
    meta: {
      screen_code: "06-007",
    },
  },
  //  SalesInvoices
  {
    path: "/sales/invoices",
    name: "SalesInvoicesEntity",
    component: SalesInvoicesEntity,
    meta: {
      screen_code: "06-008",
    },
  },
  {
    path: "/sales/invoice-control",
    name: "SalesInvoicesControlADD",
    component: SalesInvoicesControl,
    meta: {
      screen_code: "06-008",
    },
  },
  {
    path: "/sales/invoice-control/:id",
    name: "SalesInvoicesControlEdit",
    component: SalesInvoicesControl,
    meta: {
      screen_code: "06-008",
    },
  },
  // Expenses
  {
    path: "/sales/expense",
    name: "ExpenseInvoiceEntity",
    component: ExpenseInvoiceEntity,
    meta: {
      screen_code: "06-009",
    },
  },
  {
    path: "/sales/expense-control",
    name: "ExpensesCodeControl",
    component: ExpenseInvoiceControl,
    meta: {
      screen_code: "06-009",
    },
  },
  {
    path: "/sales/expense-control/:id",
    name: "ExpensesCodeControlVueEdit",
    component: ExpenseInvoiceControl,
    meta: {
      screen_code: "06-009",
    },
  },

  // debtor notice
  {
    path: "/sales/notice_debtor",
    name: "NoticeDebtorEntity",
    component: NoticeDebtorEntity,
    meta: {
      screen_code: "06-010",
    },
  },
  {
    path: "/sales/notice_debtor-control",
    name: "NoticeDebtorControl",
    component: NoticeDebtorControl,
    meta: {
      screen_code: "06-010",
    },
  },
  // creditor notice
  {
    path: "/sales/notice_creditor",
    name: "NoticeCreditorEntity",
    component: NoticeCreditorEntity,
    meta: {
      screen_code: "06-011",
    },
  },
  {
    path: "/sales/notice_creditor-control",
    name: "NoticeCreditorControl",
    component: NoticeCreditorControl,
    meta: {
      screen_code: "06-011",
    },
  },
  // Full Report
  {
    path: "/sales/full_report",
    name: "FullReportEntity",
    component: FullReportEntity,
    meta: {
      screen_code: "06-012",
    },
  },
  // Sales Report
  {
    path: "/sales/sales_report",
    name: "SalesReportEntity",
    component: SalesReportEntity,
    meta: {
      screen_code: "06-013",
    },
  },

  // Customer Statement
  {
    path: "/sales/customer_statement",
    name: "CustomerStatementEntity",
    component: CustomerStatementEntity,
    meta: {
      screen_code: "06-014",
    },
  },
  {
    // Customer Balances
    path: "/sales/customers_balances",
    name: "CustomersBalancesEntity",
    component: CustomersBalancesEntity,
    meta: {
      screen_code: "06-015",
    },
  },
  // Debt age
  {
    path: "/sales/debt_age",
    name: "DebtAgeEntity",
    component: DebtAgeEntity,
    meta: {
      screen_code: "06-016",
    },
  },
  // Weekly Report
  {
    path: "/sales/weekly_report",
    name: "WeaklyReportEntity",
    component: WeaklyReportEntity,
    meta: {
      screen_code: "06-017",
    },
  },
  // Monthly Report
  {
    path: "/sales/monthly_report",
    name: "MonthlyReportEntity",
    component: MonthlyReportEntity,
    meta: {
      screen_code: "06-018",
    },
  },
  // Purchase Vendor Statement
  {
    path: "/sales/purchase_vendor_statement_report",
    name: "PurchaseVendorStatementEntity",
    component: PurchaseVendorStatementEntity,
    meta: {
      screen_code: "06-019",
    },
  },
  // WorkDay Report
  {
    path: "/sales/list_shifts",
    name: "ShiftReportEntity",
    component: ShiftReportEntity,
    meta: {
      screen_code: "06-020",
    },
  },
  {
    path: "/sales/list_shifts/:id",
    name: "ShiftReportControl",
    component: ShiftReportControl,
    meta: {
      screen_code: "06-020",
    },
  },
];
