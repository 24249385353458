<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod" />
        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default{
  name: "CustomersEntity",

  data: () => ({
    pageData: {
      screen_code: "06-003",
      url: null,
      controlRoute: "sales/customer-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: new URLSearchParams(),
    },
  }),
  components: {},
  computed: {},
  watch: {
    $route() {
      this.pageData.queryParam = new URLSearchParams();
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.requestParam || '');
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "name_ar", key: "name_ar", type: 'text', classes: "" },
        { text: "name_en", key: "name_en", type: 'text', classes: "" },
        { text: "category", key: "customer_invoice_type_title", type: 'text', classes: "" },
        { text: "type", key: "customer_type_name", type: 'text', classes: "" },
        { text: "relative account", key: "account_name", type: 'text', classes: "" },
        { text: "sales agent", key: "sales_agent_name", type: 'text', classes: "" },
        { text: "sales area", key: "sales_area_name", type: 'text', classes: "" },
        { text: "term period", key: "term_period", type: 'text', classes: "" },
        { text: "maximum term value", key: "maximum_term_value", type: 'text', classes: "" },
        { text: "default currency", key: "default_currency_name", type: 'text', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
      };
    },
    getSearch(word) {
      this.pageData.queryParam.set("word", word || '')
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString())
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`customer?${this.pageData.queryParam.toString()}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              response.data.items.data.forEach(customer => {
                customer.customer_invoice_type_title = customer.customer_invoice_type == 1 ? this.$i18n.t('person') : this.$i18n.t('company')
              });
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam.set("page", page || 1);
      this.pageData.queryParam.set("rows", limit || 15);
      this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString())
    },
    deleteMethod(id) {
      this.$api.POST_METHOD(`customer/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`customer_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    edit(row) {
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>
