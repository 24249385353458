<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="(valid && form.tax_id)"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-col cols="12" class="pa-0 px-10 position-relative" style="z-index: 2;">
               <v-tabs active-class="white primary--text font-weight-bold " color="primary" v-model="tab">
                  <v-tab class="font-weight-bold"><strong>{{ $t('item details') }}</strong></v-tab>
                  <v-tab class="font-weight-bold"><strong>{{ $t('relative account') }}</strong></v-tab>
               </v-tabs>
            </v-col>
            <v-card class=" shadow py-0 overflow-hidden primary--border" style="margin-top: -2px;">
               <v-col cols="12" class="pa-0">
                  <v-form ref="form" v-model="valid">
                     <!-- Tab 1 -->
                     <v-sheet v-if="tab == 0" class="pa-5">
                        <v-row align="center">
                           <v-col cols="8">
                              <v-row>
                                 <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event"
                                    label="name_ar" :required="true" :isLoading="pageData.editIsLoading"
                                    :cols="[12, 6, 4]">
                                 </GenericInput>
                                 <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event"
                                    label="name_en" :required="true" :isLoading="pageData.editIsLoading"
                                    :cols="[12, 6, 4]">
                                 </GenericInput>
                                 <GenericInput type="select" :lookups="storage_temperature"
                                    :value="form.storage_temperature_id" @input="form.storage_temperature_id = $event"
                                    label="storage temperature" :multi="false" :required="true" selected_label="degree"
                                    selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                                 </GenericInput>
                                 <GenericInput type="select" :lookups="categories" :value="form.category_id"
                                    @input="form.category_id = $event" label="category" :multi="false" :required="true"
                                    selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                                    :cols="[12, 6, 4]">
                                 </GenericInput>
                                 <GenericInput type="select" :lookups="item_types" :value="form.item_type_id"
                                    @input="form.item_type_id = $event" label="item type" :multi="false"
                                    :required="true" selected_label="name" selected_prop="id"
                                    :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                                 </GenericInput>
                                 <GenericInput type="file" :value="form.image" @input="form.image = $event"
                                    label="image" :required="false" :isLoading="pageData.editIsLoading"
                                    :cols="[12, 6, 4]">
                                 </GenericInput>
                                 <GenericInput type="text" :value="form.barcode" @input="form.barcode = $event"
                                    label="barcode" :required="true" :isLoading="pageData.editIsLoading"
                                    :cols="[12, 6, 4]">
                                 </GenericInput>
                                 <GenericInput type="switch" :value="form.has_expiration"
                                    @input="form.has_expiration = $event" label="expiration" :required="false"
                                    :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                                 </GenericInput>
                              </v-row>
                           </v-col>
                           <v-col cols="4">
                              <v-row>
                                 <GenericInput type="textarea" :rowsNumber="5" :value="form.description"
                                    @input="form.description = $event" label="description" :required="false"
                                    :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>
                              </v-row>
                           </v-col>

                           <v-col cols="12">
                              <v-divider></v-divider>
                           </v-col>
                           <v-col cols="12" class="py-0">
                              <div class="body-2 gray7--text">{{ $t('measuring unit') | capitalize }}</div>
                           </v-col>
                           <GenericInput type="select" :lookups="unit_measurements" :value="form.big_unit_id"
                              @input="form.big_unit_id = $event" label="large" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 'auto']">
                           </GenericInput>
                           <GenericInput type="number" :value="form.big_unit_coefficient"
                              @input="form.big_unit_coefficient = $event" label="conversion factor" :required="true"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="unit_measurements" :value="form.mid_unit_id"
                              @input="form.mid_unit_id = $event" label="medium" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 'auto']">
                           </GenericInput>
                           <GenericInput type="number" :value="form.mid_unit_coefficient"
                              @input="form.mid_unit_coefficient = $event" label="conversion factor" :required="true"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="unit_measurements" :value="form.small_unit_id"
                              @input="form.small_unit_id = $event" label="small" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 'auto']">
                           </GenericInput>
                           <GenericInput type="number" :value="form.small_unit_coefficient"
                              @input="form.small_unit_coefficient = $event" label="conversion factor" :required="true"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 2]">
                           </GenericInput>

                           <GenericInput type="select"
                              :lookups="[{ id: 1, name: $i18n.t('large') }, { id: 2, name: $i18n.t('medium') }, { id: 3, name: $i18n.t('small') }]"
                              :value="form.default_report_unit_type" @input="form.default_report_unit_type = $event"
                              label="report unit of measure" :multi="false" :required="true" selected_label="name"
                              selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 'auto']">
                           </GenericInput>
                           <v-col cols="12">
                              <v-divider></v-divider>
                           </v-col>
                           <v-col cols="12" class="py-0">
                              <div class="body-2 gray7--text">{{ $t('price') | capitalize }}</div>
                           </v-col>
                           <GenericInput type="number" :value="form.sale_price" @input="form.sale_price = $event"
                              label="sale price" :required="true" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 4]">
                           </GenericInput>

                           <GenericInput type="number" :value="form.min_sale_price"
                              @input="form.min_sale_price = $event" label="minimum sales price" :required="true"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>

                           <v-col cols="12">
                              <v-divider></v-divider>
                           </v-col>
                           <v-col cols="12" class="pt-0">
                              <div class="body-2 gray7--text">{{ $t('vat') | capitalize }}</div>
                           </v-col>

                           <GenericInput type="select" :lookups="taxes" :value="form.tax_id"
                              @input="form.tax_id = $event" label="tax percentage" :multi="false" :required="true"
                              selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                              :cols="[12, 6, 'auto']">
                           </GenericInput>
                           <GenericInput type="checkbox" :value="form.price_include_vat"
                              @input="form.price_include_vat = $event" label="price include vat" :required="false"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <v-col cols="12" md="6" lg="4" class="d-flex justify-end" v-if="pageData.isEdit">
                              <div class="body-1 font-weight-regular  mx-1">{{ $t('last buy price') | capitalize }}
                              </div>
                              <div class="body-1 font-weight-bold mx-1">{{ 0 | float }} {{ $t('sar') }}</div>
                           </v-col>
                        </v-row>
                     </v-sheet>
                     <!-- Tab 2 -->
                     <v-sheet v-if="tab == 1" class="pa-5">
                        <v-row align="center">
                           <GenericInput type="select" :lookups="account_trees" :value="form.sales_revenue_id"
                              @input="form.sales_revenue_id = $event" label="Sales Revenue" :multi="false"
                              :required="true" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="account_trees" :value="form.sales_return_id"
                              @input="form.sales_return_id = $event" label="Sales Refond" :multi="false"
                              :required="true" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="account_trees" :value="form.purchases_revenue_id"
                              @input="form.purchases_revenue_id = $event" label="Purchase Revenue" :multi="false"
                              :required="true" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="account_trees" :value="form.purchases_return_id"
                              @input="form.purchases_return_id = $event" label="Purchase Refond" :multi="false"
                              :required="true" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="account_trees" :value="form.sales_discount_id"
                              @input="form.sales_discount_id = $event" label="Sales Discount" :multi="false"
                              :required="true" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                           <GenericInput type="select" :lookups="account_trees" :value="form.purchases_discount_id"
                              @input="form.purchases_discount_id = $event" label="Purchase Discount" :multi="false"
                              :required="true" selected_label="name" selected_prop="id"
                              :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                           </GenericInput>
                        </v-row>
                     </v-sheet>
                  </v-form>
               </v-col>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "ItemControl",
   data: () => ({
      pageData: {
         screen_code: "03-004",
         url: null,
         controlRoute: "/inventory/item-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      categories: [],
      item_types: [],
      storage_temperature: [],
      unit_measurements: [],
      account_trees: [],
      taxes: [],
      tab: 0,
      form: {
         name_ar: null,
         name_en: null,
         category_id: null,
         item_type_id: null,
         big_unit_id: null,
         big_unit_coefficient: null,
         mid_unit_id: null,
         mid_unit_coefficient: null,
         small_unit_id: null,
         small_unit_coefficient: null,
         default_report_unit_type: null,
         sale_price: null,
         min_sale_price: null,
         purchases_revenue_id: null,
         purchases_return_id: null,
         purchases_discount_id: null,
         sales_revenue_id: null,
         sales_return_id: null,
         sales_discount_id: null,
         tax_id: null,
         image: null,
         storage_temperature_id: null,
         has_expiration: null,
      },
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`item/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // console.log("response", response);
                     this.form = response.data.data;
                     this.account_trees = response.data.account_trees || [];
                     this.categories = response.data.categorys || [];
                     this.item_types = response.data.item_types || [];
                     this.unit_measurements = response.data.unit_measurements || [];
                     this.storage_temperature = response.data.storage_temperatures || [];
                     this.taxes = response.data.taxes || [];
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`item/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.account_trees = response.data.account_trees || [];
                     this.categories = response.data.categorys || [];
                     this.item_types = response.data.item_types || [];
                     this.unit_measurements = response.data.unit_measurements || [];
                     this.storage_temperature = response.data.storage_temperatures || [];
                     this.taxes = response.data.taxes || [];
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`item/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`item`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`item/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
