<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showSearch="false" :createBtn="false" :showDeleted="false"
        actionBtnText="research" :actionBtn="!showFilter" :actionBtnValid="true" actionBtnColor="transparent"
        :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true" actionBtnClass="blue4--text">
        <template v-slot:rightSide>
          <v-btn color="success" v-if="rows.length > 0" depressed @click="printValueMethod()" :loading="printLoading"
            class="">{{ $t('download report') }}</v-btn>
        </template>
      </EntityHeader>
      <!-- End Of Header -->


      <!-- Filter -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" justify="space-between" class="">
              <GenericInput type="select" :clearable="true" :lookups="types" :value="report_type"
                @input="report_type = $event" label="invoice type" :multi="false" :required="false" selected_label="title"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.detailIsLoading)"
                :cols="[12, 6, 2]">
              </GenericInput>
              <GenericInput type="select" :clearable="true" :lookups="payment_types" :value="payment_type"
                @input="payment_type = $event" label="payment type" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.detailIsLoading)"
                :cols="[12, 6, 2]">
              </GenericInput>
              <GenericInput type="date" :clearable="true" :isPickerOpened="() => to_date = null" :value="from_date"
                :maxDate="to_date || today" @input="from_date = $event" label="from" :required="false"
                :hide-details="true" :isLoading="(pageData.isLoading || pageData.detailIsLoading)" :cols="[12, 6, 2]">
              </GenericInput>
              <GenericInput type="date" :clearable="true" :value="to_date" :minDate="from_date" :maxDate="today"
                @input="to_date = $event" label="to" :required="false" :hide-details="true"
                :isLoading="(pageData.isLoading || pageData.detailIsLoading)" :cols="[12, 6, 2]">
              </GenericInput>
              <v-col cols="auto" md="6" lg="3" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" outlined
                  :loading="(pageData.isLoading || pageData.detailIsLoading)" @click="getReports"
                  class="mx-1 white--text">
                  {{ $t("Search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-col cols="12" class="pa-0">
            <v-card class="elevation-0 justify-center my-2 pa-0 overflow-hidden">
              <v-col cols="12" class="pa-0">
                <v-row align="center" justify="space-between">
                  <v-col cols="auto" class="">
                    <v-tabs v-model="tab" active-class="green11">
                      <v-tab>{{ $t('reports') }}</v-tab>
                      <v-tab :disabled="rows.length == 0">{{ $t('statistics') }}</v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col cols="auto" class="mx-5" v-if="tab == 1">
                    <v-col cols="auto" class="mx-5" v-if="tab == 1">
                      <v-btn small @click="chartType = 1" fab :color="chartType == 1 ? 'primary' : 'gray12'" class="mx-1"
                        depressed>
                        <v-icon>mdi-chart-bell-curve</v-icon>
                      </v-btn>
                      <v-btn small @click="chartType = 2" fab :color="chartType == 2 ? 'primary' : 'gray12'" class="mx-1"
                        depressed>
                        <v-icon>mdi-chart-bar</v-icon>
                      </v-btn>
                      <v-btn small @click="chartType = 3" fab :color="chartType == 3 ? 'primary' : 'gray12'" class="mx-1"
                        depressed>
                        <v-icon>mdi-chart-donut</v-icon>
                      </v-btn>
                      <v-btn small @click="chartType = 4" fab :color="chartType == 4 ? 'primary' : 'gray12'" class="mx-1"
                        depressed>
                        <v-icon>mdi-chart-timeline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>



              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-col cols="12" sm="12" class="py-0">
                    <DynamicTable :isLoading="pageData.isLoading" :option="pageData.options" :data="rows"
                      :header="pageData.tableHeader" :footerData="footerData" noDataText="please select to view report"
                      cardClasses="shadow-none" 
                      :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 320 : $store.state.Settings.windowSize.y - 220">
                      <template v-slot:td="{ row, header }">
                        <span class="d-flex justify-center" v-if="header.key !== 's_m_id' && header.key !== 'r_m_id'">
                          <span v-if="row.sumtion == 2"> ({{ row[header.key] || 0 | float }})</span>
                          <span v-else> {{ row[header.key] || 0 | float }}</span>
                        </span>
                        <span class="d-flex justify-center" v-if="header.key == 's_m_id'">
                          <v-hover v-slot="{ hover }" close-delay="150" open-delay="150">
                            <v-btn color="primary" min-width="90" :text="!hover" depressed small
                              @click="printInvoice(row,'s_m_id')" v-if="row[header.key]"> {{hover ? $t('print') :
                              row[header.key] || '-'}}</v-btn>
                          </v-hover>
                        </span>
                        <span class="d-flex justify-center" v-if="header.key == 'r_m_id'">
                          <v-hover v-slot="{ hover }" close-delay="150" open-delay="150">
                            <v-btn color="primary" min-width="90" :text="!hover" depressed small
                              @click="printInvoice(row,'r_m_id')" v-if="row[header.key]"> {{hover ? $t('print') :
                              row[header.key] || '-'}}</v-btn>
                          </v-hover>
                        </span>
                      </template>
                    </DynamicTable>
                  </v-col>
                </v-tab-item>
                <v-tab-item v-if="rows.length > 0">

                  <v-col cols="12" sm="12" class="pa-0">
                    <v-card flat relative
                      :height="showFilter ? $store.state.Settings.windowSize.y - 310 : $store.state.Settings.windowSize.y - 210">
                      <SalesChart v-if="chartType == 2" :key="analysisKey" :quantities="quantities" :summary="summary"
                        :summary_categories="summary_categories" />

                      <SalesChartCurve v-if="chartType == 1" :key="analysisKey" :quantities="quantities"
                        :summary="summary" :summary_categories="summary_categories" />

                      <SalesChartDonut v-if="chartType == 3" :key="analysisKey" :quantities="quantities"
                        :summary="summary" :summary_categories="summary_categories" />

                      <SalesChartBar v-if="chartType == 4" :key="analysisKey" :quantities="quantities" :summary="summary"
                        :summary_categories="summary_categories" />
                    </v-card>
                  </v-col>
                </v-tab-item>
              </v-tabs-items>







            </v-card>
          </v-col>

        </v-col>
      </v-row>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import SalesChart from "./SalesChart.vue";
import SalesChartCurve from "./SalesChartCurve.vue";
import SalesChartDonut from "./SalesChartDonut.vue";
import SalesChartBar from "./SalesChartBar.vue";
export default {
  name: "SalesReportEntity",

  data: () => ({
    pageData: {
      screen_code: "06-013",
      url: null,
      controlRoute: "sales/sales_report-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      detailIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    tab: 0,
    rows: [],
    types: [],
    showFilter: true,
    printLoading: false,
    payment_types: [],
    sales_report: [],
    payment_type: null,
    report_type: null,
    today: null,
    from_date: null,
    to_date: null,

    footerData: [{
      s_m_id: null,
      branch_title: null,
      customer_name: null,
      service_title: null,
      qty: 0,
      id: null,
    }],
    // chart
    analysisKey: 1,
    chartType: 1,
    branches: [],
    summary: [],
    summary_categories: [],
    quantities: [],
  }),
  components: {
    SalesChartCurve,
    SalesChart,
    SalesChartDonut,
    SalesChartBar,
  },
  computed: {
    totalGrandPrice() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.total_with_vat);
          case 2:
            return Number(+oldValue) - Number(+newValue.total_with_vat);
        }
      }, 0);
    },
    totalQTY() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_quantity);
      }, 0);
    },
    totalPrice() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_with_vat);
      }, 0);
    },
    totalDiscount() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.discount);
      }, 0);
    },
    rowsLength() {
      return this.rows.length
    },
    totalVAT() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_vat);
      }, 0);
    },
    totalPriceWithoutVat() {
      return this.rows.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.total_without_vat);
      }, 0);
    },
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    rows() {
      this.footerData[0].s_m_id = this.$i18n.t('total');
      this.footerData[0].total_without_vat = this.totalPriceWithoutVat;
      this.footerData[0].payment_type = this.rowsLength;
      this.footerData[0].total_vat = this.totalVAT;
      this.footerData[0].total_quantity = this.totalQTY;
      this.footerData[0].total_price = this.totalPrice;
      this.footerData[0].discount = this.totalDiscount;
      this.footerData[0].total_with_vat_text = this.totalGrandPrice < 0 ? `(${this.$global.NumberWithCommas(this.totalGrandPrice.toFixed(2))})` :  `${this.$global.NumberWithCommas(this.totalGrandPrice.toFixed(2))}`;
    },
  },
  mounted() {
    this.queryParams();
    this.today = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
    this.to_date = this.$global.GetCurrentDate();
    this.pageMainData();
    this.payment_types = [
      {
        id: 0,
        name: this.$i18n.t('all')
      },
      {
        id: 1,
        name: this.$i18n.t('cash')
      },
      {
        id: 2,
        name: this.$i18n.t('network')
      },
    ]
    this.getData();

  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "invoice number", key: "s_m_id", type: 'slot', classes: "", },
        { text: "notice invoice", key: "r_m_id", type: 'slot', classes: "" },
        { text: "invoice date", key: "invoice_date", type: 'date', width: 120, classes: "" },
        { text: "type", key: "type_title", type: 'text', classes: "" },
        { text: "payment type", key: "payment_type", type: 'text', classes: "" },
        { text: "cash", key: "paid_cash", type: 'float', classes: "" },
        { text: "bank", key: "paid_bank", type: 'float', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "discount", key: "discount", type: 'float', classes: "" },
        { text: "quantity", key: "total_quantity", type: 'text', classes: "" },
        { text: "price without vat", key: "total_without_vat", type: 'float', classes: "" },
        { text: "vat", key: "total_vat", type: 'float', classes: "" },
        { text: "total with vat", key: "total_with_vat_text", type: 'text', classes: ""  , dir: 'ltr'},
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD('sales_report')
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;

              this.categories = response.data.categorys;
              this.branches = response.data.branchs;
              this.types = response.data.types;

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    getReports() {
      this.summary_categories = [];
      this.summary = [];
      this.quantities = [];
      this.tab = 0;
      this.rows = [];
      this.pageData.isLoading = true;
      const body = {
        from: this.from_date,
        to: this.to_date,
        report_type: this.report_type,
        payment_type: this.payment_type,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`sales_report`, body, false)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            response.data.data.forEach(row => {
              row.type_title = this.types[+row.type].title;
              switch (+row.type) {
                case 1:
                  row.rowClass = 'gray12'
                  row.total_with_vat_text = this.$global.NumberWithCommas(row.total_with_vat.toFixed(2))
                  break;
                case 2:
                  row.rowClass = 'red11'
                  row.total_with_vat_text = `(${this.$global.NumberWithCommas(row.total_with_vat.toFixed(2))})`
                  break;
                case 3:
                  row.rowClass = 'green11'
                  row.total_with_vat_text = this.$global.NumberWithCommas(row.total_with_vat.toFixed(2))
                  break;
                default:
                  break;
              }
            })
            this.reportsRow = response.data.data;
            Object.keys(response.data.chart).forEach(date => {
              this.summary_categories.push(
                new Date(date).toLocaleDateString("en-UK", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              );
              this.summary.push(+response.data.chart[date]['total_with_vat'].toFixed(2));
              this.quantities.push(+response.data.chart[date]['quantity']);
            });
            this.rows = response.data.data;
            this.analysisKey = this.analysisKey + 1;
            this.showFilter = false
            // this.pageData.account_tree = response.data.account_tree;
          }
        });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    printInvoice(row , type) {
      switch (type) {
        case 's_m_id':
          this.$api.PrintInvoiceURL(`${this.$api.serverUrl}print_sales_invoice/${row.s_m_id}?type=print&clientID=${this.$store.state.clientID}`, row.s_m_id)
          break;
        case 'r_m_id':
          this.$api.PrintInvoiceURL(`${this.$api.serverUrl}${+row.type == 3 ? 'export_notice_debtor' : 'export_notice_creditor'}/${row.r_m_id}?type=print&clientID=${this.$store.state.clientID}`, row.r_m_id)
          break;
        default:
          break;
      }
    },
    printValueMethod() {
      this.printLoading = true;
      const body = {
        from: this.from_date,
        to: this.to_date,
        report_type: this.report_type,
        payment_type: this.payment_type,
        type: 'pdf'
      }
      this.$api
        .DOWNLOAD_METHOD_BODY(`sales_report`, body, `sales_report.pdf`)
        .then(() => { this.printLoading = false; });


      // const src = `src="${this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/sales_report/` + row.id}?type=image"`;
      // this.$api.PrintInvoice(src, row.id)
      // window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + row.id);
    },

  },
};
</script>
