<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <div class="sticky_headerss">


        <EntityHeader :pageData="pageData" :showSearch="false" :showRefresh="false" :getData="getData" :showDeleted="false"
          :createBtn="false" />
          
        <v-col cols="12" class="px-0">
          <v-row align="center" justify="center">

            <GenericInput type="date" :solo="true" :dense="true" classes="rounded-lg"
              :isPickerOpened="() => pageData.to = null" :maxDate="pageData.to || pageData.today" :value="pageData.from"
              @input="pageData.from = $event" label="from" :required="false" :hide-details="true"
              :cols="[12, 'auto', 'auto']">
            </GenericInput>
            <GenericInput type="date" :solo="true" :dense="true" classes="rounded-lg" :value="pageData.to"
              @input="pageData.to = $event" label="to" :minDate="pageData.from" :maxDate="pageData.today"
              :required="false" :hide-details="true" :cols="[12, 'auto', 'auto']"></GenericInput>

            <v-col cols="12" md="auto">
              <v-btn depressed :loading="pageData.isLoading" color="primary" width="120" :height="37" @click="getReports">
                {{ $t('view report') }}</v-btn>
            </v-col>
            <v-col cols="12" md="auto" v-if="showReport">
              <v-btn depressed :loading="pageData.isLoading" class="backgroundW black--text shadow" :height="37"
                @click="printMethod">
                <v-icon dark left>mdi-cloud-print-outline</v-icon>
                {{ $t('print') }} {{ pageData.entityName }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <!-- End Of Header -->

      <v-row>
        <v-col cols="12" sm="12" v-if="showReport">
          <TaxReturnView :tax_return="printData" :is_view="true" />

        </v-col>
        <TaxReturnPrint :printAction="() => print = false" :print="print" :printData="printData"
          :printStaticData="printStaticData" />
      </v-row>



    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
import TaxReturnPrint from "./TaxReturnPrint.vue";
import TaxReturnView from "./TaxReturnView";

export default {
  name: "TaxReturnEntity",

  data: () => ({
    pageData: {
      screen_code: "02-016",
      url: null,
      controlRoute: "accounting/tax_return-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      from: null,
      to: null,
      today: null,
      customer_id: null,
      account_tree: null,
      first: [],
      printLoading: false
    },
    printData: [],
    printStaticData: Object,
    showReport: false,
    print: false,
  }),
  components: {
    EntityHeader,
    GenericInput,
    TaxReturnView,
    TaxReturnPrint
  },
  computed: {

  },
  watch: {
    actionDialog() {
      if (!this.actionDialog) {
        this.selectedRow = Object
      }
    }
  },
  mounted() {
    this.pageMainData();
    this.pageData.today = this.$global.GetCurrentDate();
    this.pageData.to = this.$global.GetCurrentDate();
    this.pageData.from = this.$global.GetFirstDayOfMonth();
    this.getData();
  },
  methods: {

    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [];
      this.groupHeader = [];
      this.pageData.options = Object;
    },
    getData() {


    },
    getReports() {
      this.pageData.isLoading = true;
      this.showReport = false;
      const body = {
        from: this.pageData.from,
        to: this.pageData.to,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`tax_declaration`, body, false)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.printStaticData.from = this.pageData.from;
            this.printStaticData.to = this.pageData.to;
            response.data.report.splice(0, 0, ['ضريبه القيمة المضافة علي المبيعات'])
            response.data.report.splice(7, 0, ['ضريبة القيمة المضافة على المشتريات'])
            this.printData = response.data.report
            this.showReport = true
          }
        });
    },
    printMethod() {
      this.showReport = true
      this.print = true;
      setTimeout(() => {
        this.print = false;
      }, 1000);
    }
  },
};
</script>
