<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->
      <v-col cols="12" md="12" class="ma-auto">
        <v-progress-circular v-if="pageData.isLoading == true" :size="40" :width="5" style="margin: auto"
          class="d-block mt-8" color="primary" indeterminate></v-progress-circular>

        <v-card class="pa-5 shadow" v-if="pageData.isLoading == false">
          <v-row>
            <v-col cols="12" lg="4" md="2" v-for="(row, index) in pageData.rows" :key="index">
              <div style="border: 1px solid #18ba92" class="position-relative pa-3 rounded-lg  blue12">
                <div class="d-flex" style="position: absolute"
                  :style="$vuetify.rtl == true ? 'left: 0.5rem' : 'right: 0.5rem'">
                  <v-btn x-small icon fab class="mx-1" color="primary" @click="edit(row)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn x-small icon fab class="mx-1" color="error" @click="deleteMethod(row.id)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <div class="subtitle-1 font-weight-bold">{{ row.name }}</div>
                <div class="body-1 font-weight-normal">
                  {{ $t("treasury code") | capitalize }} : {{ row.id || '-' }}
                </div>
                <div class="body-1 font-weight-normal">
                  {{ $t("balance date") | capitalize}} : {{ row.current_balance_date || '-' }}
                </div>
                <div class="body-1 font-weight-normal">
                  {{ $t("current balance") | capitalize}} : {{ row.current_balance | float  }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-row v-if="!pageData.isLoading">
        <v-col cols="12" sm="12" class="">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
    </section>
</template>

<script>
export default {
  name: "TreasuryEntity",

  data: () => ({
    pageData: {
      screen_code: "02-006",
      url: null,
      controlRoute: "accounting/treasury-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
    },
  }),
  components: {},
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {

    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        {
          text: this.$i18n.t("movement code"),
          sortable: true,
          value: "description",
        },
        {
          text: this.$i18n.t("the entity"),
          sortable: true,
          value: "finance_year_name",
        },
        {
          text: this.$i18n.t("the bond number"),
          sortable: true,
          value: "journal_date",
        },
        {
          text: this.$i18n.t("value"),
          sortable: true,
          value: "daily_type",
        },
        {
          text: this.$i18n.t("description"),
          sortable: true,
          value: "description",
        },
        {
          text: this.$i18n.t("payment method"),
          sortable: true,
          value: "add_date",
        },
        { text: this.$i18n.t("actions"), value: "actions", sortable: false },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.search = word ? word : "";
      this.getData();
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(
            `treasury?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search}`
          )
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted treasury")
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.page = page;
      this.pageData.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(id) {
      this.$api.POST_METHOD(`treasury/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api
        .POST_METHOD(`treasury_toggle_active/${row.m_id}`, null)
        .then((response) => {
          if (response.check) {
            this.getData();
          }
        });
    },
    setClickRow() {
      // this.$router.push(`/main/treasury/${row.id}`)
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>
