<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->

      <!-- Table -->
      <v-row justify="center">
        <v-col cols="12" sm="12" v-if="!pageData.isLoading">
          <DynamicTable :printURL="'export_expense'" :isLoading="pageData.isLoading" :option="pageData.options"
            :data="pageData.rows" :header="pageData.tableHeader">
            <template v-slot:td="{ row, header }">
              <div  v-if="header.key == 'print'">
                <PrintInvoice endpoint="export_expense" :screenCode="pageData.screen_code" :id="row.id" />
                <v-btn @click="edit(row)" x-small icon fab>
                  <img src="@/assets/img/svg_icons/fi_eye.svg" height="16" />
                </v-btn>
              </div>
           </template>
          </DynamicTable>
          <!-- <SkeletonTableLoader v-if="pageData.isLoading"></SkeletonTableLoader> -->
        </v-col>
        <v-col cols="auto" class="my-15" v-if="pageData.isLoading">
          <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
          </v-progress-circular>
          <p class="text-center">{{ $t("Loading data") }}</p>
        </v-col>
      </v-row>
      <!-- End of table -->

      <!-- Pagination -->
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :pageData="pageData" :options="pageData.options"
            :limit="pageData.rowsNumber" :backValueMethod="changePage" :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
      <!-- End of Pagination -->

    </v-container>
  </section>
</template>

<script>
export default {
  name: "ExpenseInvoice",
  data: () => ({
    pageData: {
      screen_code: "06-009",
      url: null,
      controlRoute: "sales/expense-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      date: null,
      printLoading: false,
      queryParam: '?'
    },
    invoiceDate: null,
    today: null,
    suppliers: [],
    categories: [],
    subcategories: [],
  }),
  watch: {
    invoiceDate() {
      this.getData();
    },
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
  },
  components: {},
  computed: {
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "invoice number", key: "id", type: 'text', classes: "" },
        { text: "pay for", key: "pay_for", type: 'text', classes: "" },
        { text: "description", key: "description", type: 'text', classes: "" },
        { text: "expenses amount", key: "amount", type: 'price', classes: "" },
        { text: "date", key: "action_date", type: 'date',width: 120, classes: "" },
        { text: "print", key: "print", type: 'slot', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getData() {
      this.pageData.isLoading = true;
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.$api
          .GET_METHOD(`expense${this.pageData.queryParam}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.isLoading = false;
              if (response.check) {
                this.pageData.rows = response.data.items.data;
                this.pageData.page = response.data.items.current_page;
                this.pageData.pagination = {
                  page: response.data.items.current_page,
                  totalPages: response.data.items.last_page,
                  per_page: response.data.items.per_page,
                  totalRows: response.data.items.total,
                };
              }
            }
          });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    edit(row) {
      console.log("edit", row);
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
  },
};
</script>