<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true" />
         <!-- End Of Header -->
         <!-- Summery -->
         <ControlSummery :summery="summery" :loading="pageData.isLoading" v-if="pageData.isEdit && !viewForm" />
         <!-- End of Summery -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">
            <v-card class="backgroundW shadow overflow-hidden pa-0">
               <v-col cols="12" class="pa-0 px-10">
                  <v-tabs color="secondary" active-class="green11" v-model="tab">
                     <v-tab class="font-weight-bold"><strong>{{ $t('vendor data') }}</strong></v-tab>
                  </v-tabs>
               </v-col>
               <v-col cols="12" class="pa-0">
                  <v-divider></v-divider>
               </v-col>
               <v-sheet class="pa-5">

                  <v-row align="center">
                     <v-col cols="12" class="pb-0 d-flex justify-space-between">
                        <div class="body-2 gray7--text">{{ $t('vendor details') | capitalize }}</div>
                        <div class="body-2" v-if="pageData.isEdit">{{ $t('vendor code') | capitalize }} {{ form.id ||
      '--'
}} </div>
                     </v-col>
                     <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>
                     <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_en"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]"></GenericInput>

                     <GenericInput type="text" :value="form.address" @input="form.address = $event" label="address"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                     <GenericInput type="select" :lookups="purchases_vendor_types"
                        :value="form.purchases_vendor_type_id" @input="form.purchases_vendor_type_id = $event"
                        label="vendor type" :multi="false" :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="number"  :acceptZero="true" :value="form.grace_period" @input="form.grace_period = $event"
                        label="grace period" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="number"  :acceptZero="true" :value="form.credit_limit" @input="form.credit_limit = $event"
                        label="credit limit" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <!-- 
                     <GenericInput type="select" :lookups="warning" :value="form.warning" @input="form.warning = $event"
                        label="warning" :multi="false" :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput> -->
                     <GenericInput type="select" :lookups="countries" :value="form.country_id"
                        @input="form.country_id = $event" label="countries" :multi="false" :required="false"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="select" :lookups="cities" :value="form.city_id" @input="form.city_id = $event"
                        label="cities" :multi="false" :required="false" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="checkbox" :value="form.warning" @input="form.warning = $event" label="warning"
                        :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <v-col cols="12" class="pa-0">
                        <v-divider></v-divider>
                     </v-col>
                     <v-col cols="12" class="pb-0">
                        <div class="body-2 gray7--text">{{ $t('financial data') | capitalize }}</div>
                     </v-col>
                     <GenericInput type="select" :lookups="account_trees" :value="form.account_tree_id"
                        @input="form.account_tree_id = $event" label="linked account" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="number" :value="form.first_term_balance" :acceptZero="true"
                        @input="form.first_term_balance = $event" label="first time balance" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="select"
                     :lookups="[{ id: 1, name: $i18n.t('debit') }, { id: 2, name: $i18n.t('credit') }]"
                        :value="form.balance_nature" @input="form.balance_nature = $event" label="nature of the balance"
                        :multi="false" :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                     <GenericInput type="date" :value="form.credit_date" @input="form.credit_date = $event"
                        label="credit date" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="select" :lookups="currencies" :value="form.currency_id"
                        @input="form.currency_id = $event" label="currency" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>
                     <GenericInput type="checkbox" :value="form.is_taxable" @input="form.is_taxable = $event"
                        label="taxable" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>


                     <GenericInput type="select" v-if="form.is_taxable" :lookups="taxes" :value="form.tax_id"
                        @input="form.tax_id = $event" label="tax" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                     </GenericInput>

                  </v-row>
               </v-sheet>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
export default {
   name: "PurchaseVendorControl",

   data: () => ({
      pageData: {
         screen_code: "04-002",
         url: null,
         controlRoute: "/sales/purchases_vendor-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      viewForm: false,
      summery: [],
      valid: false,
      tab: 0,
      sales_areas: [],
      sales_agents: [],
      account_trees: [],
      currencies: [],
      countries: [],
      cities: [],
      taxes: [],
      purchases_vendor_types: [],
      form: {
         name_en: null,
         name_ar: null,
         address: null,
         purchases_vendor_type_id: null,
         grace_period: 0,
         credit_limit: 0,
         warning: 0,
         country_id: null,
         city_id: null,
         account_tree_id: null,
         credit_date: null,
         first_term_balance: 0,
         balance_nature: null,
         currency_id: null,
         is_taxable: null,
         tax_id: null,

      },
   }),
   components: {
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`purchases_vendor/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.account_trees = response.data.account_trees  || [];
                     this.purchases_vendor_types = response.data.purchases_vendor_types  || [];
                     this.cities = response.data.citys  || [];
                     this.countries = response.data.countrys || [];
                     this.currencies = response.data.currencys || [];
                     this.taxes = response.data.taxes || [];
                     this.summery = [
                        {
                           label: 'name_ar',
                           value: response.data.data.name_ar || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'name_en',
                           value: response.data.data.name_en || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'address',
                           value: response.data.data.address || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: 6, xl: null,
                        },
                        {
                           label: 'grace period',
                           value: response.data.data.grace_period || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'credit limit',
                           value: response.data.data.credit_limit || '-',
                           class: '',
                           type: 'float',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'first time balance',
                           value: response.data.data.first_term_balance || '-',
                           class: '',
                           type: 'float',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'countries',
                           value: response.data.data.country_name || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'cities',
                           value: response.data.data.city_name || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                        {
                           label: 'cities',
                           value: response.data.data.city_name || '-',
                           class: '',
                           cols: null, sm: null, md: null, lg: null, xl: null,
                        },
                     ]
                     this.viewSummery = true;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`purchases_vendor/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.account_trees = response.data.account_trees || [];
                     this.purchases_vendor_types = response.data.purchases_vendor_types || [];
                     this.cities = response.data.citys || [];
                     this.countries = response.data.countrys || [];
                     this.currencies = response.data.currencys || [];
                     this.taxes = response.data.taxes || [];
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`purchases_vendor/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`purchases_vendor`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`purchases_vendor/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
