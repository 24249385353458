<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :showSearch="false" :createBtn="false" :showDeleted="false"
        actionBtnText="research" :actionBtn="!showFilter" :actionBtnValid="true" actionBtnColor="transparent"
        :actionBtnClick="() => { showFilter = true }" :actionBtnOutline="true" actionBtnClass="blue4--text">

        <template v-slot:leftSide>
          <div class="subtitle-1 font-weight-bold" v-if="selectedUser">( {{ selectedUser }} )</div>
          <div class="subtitle-1 font-weight-bold  mx-1" v-if="selectedBranch">( {{ selectedBranch }} )</div>
        </template>
      </EntityHeader>
      <!-- End Of Header -->


      <!-- Filter -->

      <v-row justify="center" v-if="showFilter">
        <v-col cols="12" class="mt-1 pb-0">
          <v-card class="shadow pa-7">
            <v-row align="center" justify="space-between" class="">
              <GenericInput type="date" :clearable="true" :isPickerOpened="() => to_date = null" :value="from_date"
                :maxDate="to_date || today" @input="from_date = $event" label="from" :required="false"
                :hide-details="true" :isLoading="(pageData.isLoading || pageData.detailIsLoading)" :cols="[12, 6, 3]">
              </GenericInput>
              <GenericInput type="date" :clearable="true" :value="to_date" :minDate="from_date" :maxDate="today"
                @input="to_date = $event" label="to" :required="false" :hide-details="true"
                :isLoading="(pageData.isLoading || pageData.detailIsLoading)" :cols="[12, 6, 3]">
              </GenericInput>

              <GenericInput type="select" :clearable="true" :lookups="branches" :value="branch_id"
                @input="branch_id = $event" label="branch" :multi="false" :required="false" selected_label="name"
                selected_prop="id" :hide-details="true" :isLoading="(pageData.isLoading || pageData.detailIsLoading)"
                :cols="[12, 6, 3]">
              </GenericInput>

              <GenericInput type="select" :clearable="true" :lookups="users" :value="user_id" @input="user_id = $event"
                label="employee" :multi="false" :required="false" selected_label="user_full_name" selected_prop="id"
                :hide-details="true" :isLoading="(pageData.isLoading || pageData.detailIsLoading)" :cols="[12, 6, 3]">
              </GenericInput>

              <v-col cols="12" class="d-flex justify-end">
                <v-btn depressed color="error" class="mx-1" @click="() => { showFilter = false }" text>
                  <v-icon size="18" left>mdi-close</v-icon>{{ $t("cancel") }}
                </v-btn>
                <v-btn depressed width="120" color="blue4" outlined
                  :loading="(pageData.isLoading || pageData.detailIsLoading)" @click="getData" class="mx-1 white--text">
                  {{ $t("Search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-col cols="12" sm="12" class="mt-3">
        <DynamicTable :isLoading="pageData.isLoading" :option="pageData.options" :data="pageData.rows"
          :header="pageData.tableHeader" noDataText="please select to view report"
          :tableHeight="showFilter ? $store.state.Settings.windowSize.y - 320 : $store.state.Settings.windowSize.y - 220">
          <template v-slot:td="{ row, header }">
            <div v-if="header.key == 'actions'">
              <v-btn @click="show(row)" x-small icon fab>
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </DynamicTable>
      </v-col>
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "ShiftReportEntity",

  data: () => ({
    pageData: {
      screen_code: "06-020",
      url: null,
      controlRoute: "sales/list_shifts",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      detailIsLoading: false,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    showFilter: true,

    // lockups :
    branches: [],
    branch_id: null,

    payment_types: [],
    payment_type_id: null,

    cart_types: [],
    cart_type_id: null,

    users: [],
    user_id: null,

    today: null,
    from_date: null,
    to_date: null,

  }),
  components: {},
  computed: {
    selectedUser() {
      if (this.user_id)
        return this.$global.FilterArrayOfObjectElement(this.users || [], 'id', this.user_id, 'user_full_name') || null
      else
        return null
    },
    selectedBranch() {
      if (this.branch_id)
        return this.$global.FilterArrayOfObjectElement(this.branches || [], 'id', this.branch_id, 'name') || null
      else
        return null
    }
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
      // this.getReports();
    },
    'pageData.rows'() {


    },
    selectedUser(){
      this.getData()
    },
    selectedBranch(){
      this.getData()
    },
  },
  mounted() {
    this.queryParams();
    this.today = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
    this.to_date = this.$global.GetCurrentDate();
    this.pageMainData();
    this.payment_types = [
      {
        id: 0,
        name: this.$i18n.t('all')
      },
      {
        id: 1,
        name: this.$i18n.t('cash')
      },
      {
        id: 2,
        name: this.$i18n.t('network')
      },
    ]
    this.cart_types = [
      {
        id: 0,
        name: this.$i18n.t('all')
      },
      {
        id: 1,
        name: this.$i18n.t('visa')
      },
      {
        id: 2,
        name: this.$i18n.t('master card')
      },
      {
        id: 3,
        name: this.$i18n.t('mada')
      },
    ]
    this.getData();
    // this.getReports();

  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$store.getters.activeScreen.main_title || null;
      this.pageData.category = this.$store.getters.activeScreen.cat_title || null;
      this.pageData.entityName = this.$store.getters.activeScreen.sub_title || null;
      this.pageData.url = this.$store.getters.activeScreen.url || null;
      this.pageData.tableHeader = [
        { text: "#", key: "id", type: 'text', classes: "", },
        { text: "employee", key: "user_full_name", type: 'text', classes: "", },
        { text: "start date", key: "start_date", type: 'date', classes: "", },
        { text: "end date", key: "end_date", type: 'text', classes: "", },
        { text: "branch", key: "branch_name", type: 'text', classes: "", },
        { text: "start cash", key: "start_cash", type: 'float', classes: "", },
        { text: "sales cash", key: "total_sales_cash", type: 'float', classes: "", },
        { text: "credit notice", key: "total_nc", type: 'float', classes: "", },
        { text: "debit notice", key: "total_nd", type: 'float', classes: "", },
        { text: "expenses", key: "total_expenses", type: 'float', classes: "", },
        { text: "total cash", key: "total_cash", type: 'float', classes: "", },
        { text: "end cash", key: "end_cash", type: 'float', classes: "", },
        { text: "difference", key: "total_diff", type: 'float', classes: "error--text font-weight-bold", },
        { text: "actions", key: "actions", type: 'slot', classes: "", },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`shift${this.pageData.queryParam}&user_id=${this.user_id || ''}&branch_id=${this.branch_id || ''}&from_date=${this.from_date || ''}&to_date=${this.to_date || ''}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.users = response.data.users;
              this.branches = response.data.branchs;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
      }
    },

    show(row) {
      this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
  },

};
</script>
