<template>
  <v-container style="height: 100vh" class="d-flex align-center justify-center bgError">
    <div>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <div class="text-h1 primary--text text-center">404</div>
        </v-col>
        <v-col cols="12" class="text-center my-10">
          <h1>{{ $t('Page not Found') }}</h1>
          <v-btn
            color="primary"
            @click="$router.go(-1)"
            class="mt-2"
            elevation="0"
            text
          >{{ $t('Back previous page') }}</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
 // background-image: url("../../assets/img/svg/errorBg.svg");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  img {
    margin: auto;
    display: block;
    max-height: 200px;
  }
  .text-h1{
    font-size: 15rem !important;
    font-weight: bold;
    opacity: 0.5;
  }
}
</style>

<script>
export default {
  name: "Error404",
  computed: {},
};
</script>
