import PurchaseRepresentativesEntityVue from "../views/PurchasesModule/Codes/PurchaseRepresentatives/PurchaseRepresentativesEntity.vue";
import PurchaseVendorTypeEntity from "../views/PurchasesModule/Codes/PurchaseVendorType/PurchaseVendorTypeEntity.vue";
import PurchaseVendorEntity from "../views/PurchasesModule/Codes/PurchaseVendor/PurchaseVendorEntity.vue";
import PurchaseVendorControl from "../views/PurchasesModule/Codes/PurchaseVendor/PurchaseVendorControl.vue";

import PurchasesOrderEntityVue from "../views/PurchasesModule/Transactions/PurchasesOrder/PurchasesOrderEntity.vue";
import PurchasesOrderControlVue from "../views/PurchasesModule/Transactions/PurchasesOrder/PurchasesOrderControl.vue";
import PurchasesNoteEntityVue from "../views/PurchasesModule/Transactions/PurchasesNote/PurchasesNoteEntity.vue";
import PurchasesNoteControlVue from "../views/PurchasesModule/Transactions/PurchasesNote/PurchasesNoteControl.vue";
import PurchaseInvoiceEntityVue from "../views/PurchasesModule/Transactions/PurchaseInvoice/PurchaseInvoiceEntity.vue";
import PurchaseInvoiceControlVue from "../views/PurchasesModule/Transactions/PurchaseInvoice/PurchaseInvoiceControl.vue";

// Purchases Module Routes:-
export const PurchaseModuleChildrens = [
  // Purchase Representative
  {
    path: "/purchase/purchase_representative",
    name: "PurchaseRepresentativesEntity",
    component: PurchaseRepresentativesEntityVue,
    meta: {
      screen_code: "04-001",
    },
  },
  // Purchase vendor Type
  {
    path: "/purchase/purchases_vendor_type",
    name: "PurchaseVendorTypeEntity",
    component: PurchaseVendorTypeEntity,
    meta: {
      screen_code: "04-003",
    },
  },

  // Purchase vendor
  {
    path: "/purchase/purchases_vendor",
    name: "PurchaseVendorEntity",
    component: PurchaseVendorEntity,
    meta: {
      screen_code: "04-002",
    },
  },
  {
    path: "/purchase/purchases_vendor-control",
    name: "PurchaseVendorControlADD",
    component: PurchaseVendorControl,
    meta: {
      screen_code: "04-002",
    },
  },
  {
    path: "/purchase/purchases_vendor-control/:id",
    name: "PurchaseVendorControlEdit",
    component: PurchaseVendorControl,
    meta: {
      screen_code: "04-002",
    },
  },
  // Purchase Order
  {
    path: "/purchase/purchases_order",
    name: "PurchaseRepresentativesEntity",
    component: PurchasesOrderEntityVue,
    meta: {
      screen_code: "04-004",
    },
  },
  {
    path: "/purchase/purchases_order-control",
    name: "PurchasesOrderControlADD",
    component: PurchasesOrderControlVue,
    meta: {
      screen_code: "04-004",
    },
  },
  {
    path: "/purchase/purchases_order-control/:id",
    name: "PurchasesOrderControlEdit",
    component: PurchasesOrderControlVue,
    meta: {
      screen_code: "04-004",
    },
  },
  // Purchase Note
  {
    path: "/purchase/purchases_note",
    name: "PurchasesNoteEntityVue",
    component: PurchasesNoteEntityVue,
    meta: {
      screen_code: "04-005",
    },
  },
  {
    path: "/purchase/purchases_note-control",
    name: "PurchasesNoteControlADD",
    component: PurchasesNoteControlVue,
    meta: {
      screen_code: "04-005",
    },
  },
  {
    path: "/purchase/purchases_note-control/:id",
    name: "PurchasesNoteControlVueEdit",
    component: PurchasesNoteControlVue,
    meta: {
      screen_code: "04-005",
    },
  },
  // Purchase Note
  {
    path: "/purchase/purchases_invoice",
    name: "PurchaseInvoiceEntityVue",
    component: PurchaseInvoiceEntityVue,
    meta: {
      screen_code: "04-006",
    },
  },
  {
    path: "/purchase/purchases_invoice-control",
    name: "PurchaseInvoiceControlADD",
    component: PurchaseInvoiceControlVue,
    meta: {
      screen_code: "04-006",
    },
  },
  {
    path: "/purchase/purchases_invoice-control/:id",
    name: "PurchaseInvoiceControlEdit",
    component: PurchaseInvoiceControlVue,
    meta: {
      screen_code: "04-006",
    },
  },
];
