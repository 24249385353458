<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="600">
         <v-card relative :key="modalKey">
            <v-btn absolute class="ma-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
               color="grey lighten-1" text @click="closeDialog">
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>


            <v-form ref="newItem" v-model="valid" class="py-10 pa-14">
               <v-row align="center">
                  <GenericInput type="select" :lookups="warehouses" :value="newItem.warehouse_id"
                     @input="newItem.warehouse_id = $event" label="warehouse" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="items" :value="newItem.item_id"
                     @input="newItem.item_id = $event" label="item" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>

                  <GenericInput type="select" :disabled="!(newItem.item_id)" :lookups="newItem.item_units || []"
                     :value="newItem.unit_id" @input="newItem.unit_id = $event" label="unit" :multi="false"
                     :required="true" selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>

                  <GenericInput type="float" :value="newItem.price" :disabled="!newItem.item_id"
                     @input="newItem.price = $event" label="price" :required="true" :isLoading="false"
                     :cols="[12, 12, 12]"></GenericInput>

                  <GenericInput type="number" :value="newItem.quantity" @input="newItem.quantity = $event" label="qty"
                     :required="true" :isLoading="false" :cols="[12, 12, 12]"></GenericInput>

                  <GenericInput type="select" :lookups="taxes" :value="newItem.tax_percent_id"
                     @input="newItem.tax_percent_id = $event" label="tax" :multi="false" :required="true"
                     selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>

                  <v-col cols="12" class="d-flex justify-center">
                     <v-btn class="mx-2" text depressed color="red4" @click="closeDialog" :height="37" :width="120">
                        {{ $t('close') }}
                     </v-btn>
                     <v-btn class="mx-2 white--text" @click="save" depressed color="blue4" :disabled="!valid"
                        :height="37" :width="120">
                        {{ $t('add') }}
                     </v-btn>
                  </v-col>
               </v-row>
            </v-form>

         </v-card>
      </v-dialog>
   </v-row>
</template>

<script>
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "PurchaseInvoiceAddItem",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      items: { default: null },
      units: { default: null },
      taxes: { default: null },
      warehouses: { default: null },
      tableRows: { default: null },
   },
   computed: {

   },
   components: {
      GenericInput
   },
   watch: {
      "newItem.item_id"() {
         this.newItem.unit_id = null;
         if (this.newItem.item_id) {
            var getItem = this.$global.FilterArrayOfObject(this.items, 'id', this.newItem.item_id)[0];

            this.newItem.item_units = [];
            this.newItem.item_units.push(
               {
                  id: getItem.small_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.small_unit_id, 'name')
               },
               {
                  id: getItem.mid_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.mid_unit_id, 'name')
               },
               {
                  id: getItem.big_unit_id,
                  name: this.$global.FilterArrayOfObjectElement(this.units, 'id', getItem.big_unit_id, 'name')
               },
            );
            // this.newItem.price = getItem.sale_price;
            switch (getItem.default_report_unit_type) {
               case 1:
                  this.newItem.unit_id = getItem.small_unit_id;
                  break;
               case 2:
                  this.newItem.unit_id = getItem.mid_unit_id;
                  break;
               case 3:
                  this.newItem.unit_id = getItem.big_unit_id;
                  break;
               default:
                  break;
            }
            this.newItem.tax_percent = getItem.tax_percent;
            this.newItem.tax_percent_id = getItem.tax_id;
            this.newItem.min_sale_price = getItem.min_sale_price;
         } else {
            this.newItem.price = null;
         }
      },
   },
   data: () => ({
      isLoading: false,
      valid: false,
      modalKey: 0,
      newItem: {
         item_id: null,
         unit_id: null,
         price: null,
         quantity: null,
         tax_percent: null,
         tax_percent_id: null,
         warehouse_id: null,
      }
   }),
   methods: {
      closeDialog() {
         this.backValueMethod(null, false)
         this.modalKey = this.modalKey + 1;
      },

      save() {
         console.log(this.newItem);
         this.backValueMethod(this.newItem, true);
         this.modalKey = this.modalKey + 1;
      },

   },
   mounted() {

   },
};
</script>
