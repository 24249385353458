<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod"
            :hasSteps="true" :steps="steps" :activeStep="activeStep" :hideDelete='true' createBtnText="save pallet" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="shadow pa-5 pt-7 rounded-xl" color="backgroundW">
               <v-row align="center">
                  <v-col cols="12" class="d-flex justify-center align-center">
                     <div class="body-1 font-weight-medium gray7--text">{{ $t('this pallet was prepared by client') |
                           capitalize
                     }}</div>
                     <GenericInput type="switch" :value="form.by_client" @input="form.by_client = $event" label=""
                        :required="false" :labelInline="false" :hideDetails="false" :isLoading="pageData.editIsLoading"
                        :cols="['auto', 'auto', 'auto']"></GenericInput>
                  </v-col>
                  <v-col cols="12" class="py-0">
                     <div class="subtitle-2 gray7--text">{{ $t("pallet details") | capitalize }}</div>
                  </v-col>
                  <GenericInput type="autocomplete" :lookups="items" :value="form.customer_item_id"
                     :placeholder="'search for item'" @input="form.customer_item_id = $event" label="item name" :multi="false"
                     :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]">
                  </GenericInput>
                  <v-col cols='auto' class="d-flex align-center py-0">
                     <span class="body-2 font-weight-bold text--disabled">{{ $t('or') }}</span>
                     <v-btn color="blue12" class="blue4--text mx-3" @click="$router.push(`/warehouses/customer_item-control`)"
                        :height="40" depressed>{{ $t('add item') }}</v-btn>
                  </v-col>

                  <GenericInput type="number" :value="form.number_of_cases" @input="form.number_of_cases = $event"
                     label="number of cases" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                  </GenericInput>
                  <GenericInput type="number" :value="form.quantity_in_case" @input="form.quantity_in_case = $event"
                     label="qty in cases" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 3]">
                  </GenericInput>
                  <GenericInput type="number" :value="form.batch_number" @input="form.batch_number = $event"
                     label="batch number" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                  </GenericInput>

                  <GenericInput type="date" :value="form.expiry_date" @input="form.expiry_date = $event"
                     label="expire date" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                  </GenericInput>
                  <GenericInput type="autocomplete" :lookups="temperatures" :value="form.storage_temperature_id"
                     @input="form.storage_temperature_id = $event" label="storage temperature" :multi="false"
                     :required="true" selected_label="degree" selected_prop="id" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 4]" />
                  <GenericInput type="textarea" :value="form.note" @input="form.note = $event" label="notes"
                     :required="true" :rowsNumber="5" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->
         <PalletPrintModal :dialog="printPalletDialog" :printData="printData" :backValueMethod="addPalletInShipment" />
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
import PalletPrintModal from "./PalletPrintModal.vue";
export default {
   name: "PalletControl",
   data: () => ({
      pageData: {
         screen_code: "08-002",
         url: null,
         controlRoute: "/warehouses/incoming/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      printPalletDialog: false,
      valid: false,
      actionBtnValid: true,
      tableRows: [],
      items: [],
      temperatures: [],
      printData: null,
      activeStep: 1,
      steps: [{ id: 1, name: 'Car' }, { id: 2, name: 'Pallets' }],
      form: {
         incoming_id: null,
         customer_item_id: null,
         number_of_cases: null,
         quantity_in_case: null,
         batch_number: null,
         expiry_date: null,
         storage_temperature_id: null,
         note: null,
      },
   }),
   components: {
      ControlHeader,
      GenericInput,
      PalletPrintModal
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('pallet') : this.$i18n.t('add new pallet');

      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`incoming/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.incoming_data = response.data.data;
                     this.clients = response.data.customers;
                     this.incoming_pallets = response.data.incoming_pallets;
                     this.incoming_photos = response.data.incoming_photos;
                  }
               })
               this.$api.GET_METHOD(`create_incoming_pallets?customer=${this.$route.params.customer}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.items = response.data.customer_items;
                     this.temperatures = response.data.storage_temperatures;

                  }
               })
            } else {
               this.$router.push('/')
            }
         }
      },
      save() {

         this.form.incoming_id = Number(this.$route.params.id);
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.$api.POST_METHOD(`add_incoming_pallets`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     // this.$router.push(this.pageData.url);
                     response.data.data.id = response.data.id
                     this.printData = response.data.data
                     this.printPalletDialog = true;
                  }
               })
            }

         }
      },
      actionBtnClick() {
         this.$router.push(`/warehouses/incoming-details/${1}`)
      },
      addItem() {
         this.tableRows.push(this.newItem);
         this.newItem = {
            customer_item_id: null,
            unit_id: null,
            price: null,
            count: null,
         }
      },
      removeItem(item, index) {
         console.log("item", item);
         this.tableRows.splice(index, 1);
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`add_incoming_pallets/${this.form.bank_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      addPalletInShipment() {
         this.$router.push({
            name: "IncomingDetails",
            params: { data: this.printData, id: this.$route.params.id },
         });
      }

   },
};
</script>
